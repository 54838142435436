import { apply } from 'file-loader';
import { app, auth, database, functions, messaging, storage }  from './initFirebase';
import { onAuthStateChanged, getIdToken, deleteUser, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signOut, updateProfile } from "firebase/auth";
import { ref, get, set, onValue, query, orderByChild, startAt, equalTo, update, remove, child, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { count, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ref as storageRef, uploadBytes, getDownloadURL, list } from "firebase/storage";
import { jobs } from 'googleapis/build/src/apis/jobs';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { all, match } from 'micromatch';
import { throttle } from 'lodash';


let currentURL;

if (window.location.hostname === "marinetech.app") {
  
  currentURL = "https://marine-center-database-default-rtdb.firebaseio.com";
} else {

  currentURL = "https://marine-center-database-test-data.firebaseio.com";
}


function calculateTotalHeight(element) {

  const elementHeight = element.getBoundingClientRect().height;
  
  const style = window.getComputedStyle(element);
  const marginTop = parseFloat(style.marginTop);
  const marginBottom = parseFloat(style.marginBottom);

  return elementHeight + marginTop + marginBottom;
}

function adjustHeight() {

  const navbar = document.querySelector('.navbar');
  const searchSection = document.querySelector('.search-section');

  const totalNavbarHeight = calculateTotalHeight(navbar);
  const totalSearchSectionHeight = calculateTotalHeight(searchSection);
  const searchScheduleHeight = 40;

  let totalHeight = totalNavbarHeight + totalSearchSectionHeight;

  const heightAdjustmentElements = document.querySelectorAll('.height-adjustment');
  
  heightAdjustmentElements.forEach(element => {

    if(element.id === 'schedule-list'){
      totalHeight += searchScheduleHeight;
    }

    element.style.height = `calc(100vh - ${totalHeight}px)`;
  });
}


const VERSION = process.env.VERSION;
console.log('App version:', VERSION);

function showUpdateNotification() {
  const notification = document.createElement('div');
  notification.textContent = `A new version is available. Click here to update.`;
  notification.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #4CAF50;
    color: white;
    text-align: center;
    line-height: 2.5;
    overflow: hidden;
    z-index: 9999;
    cursor: pointer;
  `;
  notification.addEventListener('click', () => {
    notification.style.display = 'none';
    window.location.reload();
  });
  document.body.appendChild(notification);
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    console.log('Service worker is registered.');
    const swUrl = `/service-worker.js`;
    navigator.serviceWorker.register(swUrl)
      .then(registration => {
        console.log('SW registered:', registration);
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          console.log('New SW found. Installing...');
          toastMessage('New version available. Click on the green bar when it appears.', 'blue');
          newWorker.addEventListener('statechange', () => {
            console.log('New SW state:', newWorker.state);
            if (newWorker.state === 'activated') {
              if (navigator.serviceWorker.controller) {
                setTimeout(() => {
                  showUpdateNotification();
                }, 5000);
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          });
        });
        registration.update();
        setInterval(() => {
          registration.update();
        }, 60 * 60 * 1000); // Check every hour
      })
      .catch(error => {
        console.error('SW registration failed:', error);
      });

  });
}

/* function requestNotificationPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      subscribeUserToPush(); // Subscribe to push notifications
    } else {
      console.log('Notification permission denied.');
    }
  });
}

function subscribeUserToPush() {
  navigator.serviceWorker.ready.then(registration => {
    getVapidPublicKey().then(vapidPublicKey => {
      registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPublicKey // Get the VAPID key
      }).then(subscription => {
        console.log('User is subscribed:', subscription);
        // Send subscription to your server to store
      }).catch(error => {
        console.log('Subscription failed:', error);
      });
    }).catch(error => {
      console.error('Failed to retrieve VAPID public key:', error);
    });
  });
}

function getVapidPublicKey() {
  return fetch('https://getvapidpublickey-uy4ezldynq-uc.a.run.app')
    .then(response => response.json())
    .then(data => data.publicKey);
}


getVapidPublicKey().then(vapidPublicKey => {
  if (vapidPublicKey) {
    getToken(messaging, { vapidKey: vapidPublicKey }).then((currentToken) => {
      if (currentToken) {
        console.log('FCM Token:', currentToken);

      } else {
        console.log('No registration token available.');
      }
    }).catch((err) => {
      console.error('An error occurred while retrieving token:', err);
    });
  } else {
    console.error('Failed to retrieve VAPID public key');
  }
});

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);

}); */



let indexedVersion = 37;
let currentIndexedVersion;

const MOBILE_BREAKPOINT = 600;

const clerkIdInfo = {
  "Chelsea": "cm",
  "Karen": "klc",
  "Kyle": "kb",
  "Mac": "mjh",
  "Sophie": "sll"
};

let globalUserData;
let globalTechList = [];
let globalTechStats;
let haulStatus = {};
let haulWeeks = [];
let haulWeeksCount = {};
let workOrderOpTypes = {};
let currentJobView = 'workOrder';
let workOrderJobIDs = {};

let haulCheck = [];

window.globalUserData = globalUserData;
window.globalTechList = globalTechList;
window.globalTechStats = globalTechStats;

//Colors
const completeRed = '#ffcdd2';
const progressYellow = '#ecedaf';




  function handleAuthStateChange(user) {
    if (!user) {
      document.getElementById('splash-screen').style.display = 'none';
      document.getElementById('login-modal').style.display = 'flex';
      return;
    }
    document.getElementById('login-modal').style.display = 'none';
    document.getElementById('splash-screen').style.display = 'none';
    verifyUserRole(user);
  }
  
    async function verifyUserRole(user) {
      try {
        console.log(user.uid);
        
        const role = await getUserRole(user.uid);
    
        if (role) {
         
          if (role.includes('admin')) {
            console.log('User is an admin.');
            localStorage.setItem('currentUserSecurity', 'admin');
            const setAdminClaim = httpsCallable(functions, 'setAdminClaim');
            try {
              console.log('Admin claim set successfully.');
              await user.getIdToken(true);
              await setAdminClaim({ uid: user.uid });
            } catch (error) {
              console.error('Error setting admin claim:', error);
            }
          }
    
          // Step 3: Proceed with loading data based on the role
          if (role.includes('admin') || role.includes('tech') || role.includes('clerk') || role.includes('office')) {
            if (navigator.onLine) {
              onlineFlow(user);
              localStorage.setItem('currentUserId', user.uid);
            } else {
              offlineFlow(user);
            }
            document.getElementById('currentUserName').innerHTML = user.displayName;
          } else {
            // User has an unapproved role, deny access
            console.error('Access denied. User role is not valid.');
            document.getElementsByTagName('body')[0].innerHTML = await noRoleText();
            document.getElementById('no-role-div').style.display = 'flex';

            document.getElementById('log-out-button-no-role').addEventListener('click', logoutUser);

            document.getElementById('reload-button-no-role').addEventListener('click', () => {
              location.reload();
            });
          }
        } else {
          // No role found, deny access
          console.error('No role found for user.');
          displayMessage('Access Denied', 'No role assigned. Contact administrator.');
          document.getElementsByTagName('body')[0].innerHTML = await noRoleText();
          document.getElementById('no-role-div').style.display = 'flex';

          document.getElementById('log-out-button-no-role').addEventListener('click', logoutUser);

          document.getElementById('reload-button-no-role').addEventListener('click', () => {
            location.reload();
          });
        }
      } catch (error) {
        console.error('Error verifying user role:', error);
      }
    }
    
  function noRoleText(){
    return new Promise((resolve) => {
      resolve(`
      <div id="no-role-div">
      <h1>No Role Assigned</h1>
      <p>Please contact an administrator to assign a role.</p>
      <button id="reload-button-no-role" tooltip="Reload" flow="right">Refresh <img class="invert-color" src="/assets/reload.png" alt="Close Icon" width="24" height="24"></button>
      <button id="log-out-button-no-role" tooltip="Log Out" flow="right">Log Out <img class="invert-color" src="/assets/log_out.png" alt="Close Icon" width="24" height="24"></button>
      </div>`);
    });
  }

  async function getUserRole(uid) {

    if (!navigator.onLine) {
      console.log('User is offline. Skipping function.');
      alert('No Internet Connection');
      return;
    }

    try {
      const userRoleRef = ref(database, `users/${uid}/role`);
      const snapshot = await get(userRoleRef);
          
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
    }
  }
  

function countDatesByWeek(datesArray) {
  const haulingCounts = {};
  const startDate = new Date("2024-09-01");
  const endDate = new Date("2025-01-01");

  // Convert date strings in the array to Date objects for comparison
  const dates = datesArray.map(dateStr => new Date(dateStr)).filter(date => date >= startDate && date <= endDate);

  let currentWeekStart = new Date(startDate);
  
  while (currentWeekStart <= endDate) {
      // Calculate the end of the current week (6 days after the start date)
      let currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekEnd.getDate() + 6);

      const weekKey = `${currentWeekStart.toISOString().split('T')[0]}/${currentWeekEnd.toISOString().split('T')[0]}`;

      const count = dates.filter(date => date >= currentWeekStart && date <= currentWeekEnd).length;

      if (count > 0) {
          haulingCounts[weekKey] = count;
      }

      currentWeekStart.setDate(currentWeekStart.getDate() + 7);
  }

  return haulingCounts;
}


const handleTechNames = (techList) => {
  const techItems = Object.values(techList)
    .filter(tech => tech.role.toLowerCase().includes('tech'))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const firstNameMap = new Map();

  techItems.forEach(tech => {
    const firstName = tech.displayName.split(' ')[0];
    firstNameMap.set(firstName, (firstNameMap.get(firstName) || 0) + 1);
  });

  const processedNames = techItems.map(tech => {
    const nameParts = tech.displayName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    if (firstNameMap.get(firstName) > 1 && lastName) {
      return `${firstName} ${lastName[0]}.`;
    } else {
      return firstName;
    }
  });

  const finalNames = processedNames.reduce((acc, name, index) => {
    if (acc.includes(name)) {
      const nameParts = techItems[index].displayName.split(' ');
      if (nameParts.length > 1) {
        acc.push(`${nameParts[0]} ${nameParts[nameParts.length - 1]}`);
      } else {
        acc.push(techItems[index].displayName);
      }
    } else {
      acc.push(name);
    }
    return acc;
  }, []);

  return finalNames;
};

async function collectTechInformation() {

  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('techData')) {
          db.createObjectStore('techData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('users');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'techDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const idToken = await fetchIdToken();
        const firebaseUrl = `${currentURL}/users.json?auth=${idToken}`;

        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        };

        const response = await fetch(firebaseUrl, options);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const result = await response.json();

        globalUserData = result;
        const techRole = globalUserData[localStorage.getItem('currentUserId')].role;

        document.getElementById('currentUserName').innerHTML = globalUserData[localStorage.getItem('currentUserId')].displayName;
        if(!techRole.includes('admin')){
          switchView('techList');
        }

        const techUsers = Object.entries(result).reduce((acc, [id, userData]) => {
          if (id === 'lastUpdated') return acc;
          if (userData.role.toLowerCase().includes('tech') || userData.role.toLowerCase().includes('admin')) {
            acc[id] = { id, ...userData };
          }
          return acc;
        }, {});

        const transaction = db.transaction(['techData', 'metadata'], 'readwrite');
        const techStore = transaction.objectStore('techData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBTechIDs = await new Promise((resolve, reject) => {
          const request = techStore.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseTechIDs = Object.keys(techUsers);

        Object.values(techUsers).forEach(tech => {
          techStore.put(tech);
        });

        const deletedTechIDs = indexedDBTechIDs.filter(id => !firebaseTechIDs.includes(id));
        deletedTechIDs.forEach(id => techStore.delete(id));

        metadataStore.put({ key: 'techDataLastUpdated', timestamp: firebaseTimestamp });

        await new Promise((resolve, reject) => {
          transaction.oncomplete = () => {
            console.log('All tech data have been updated in IndexedDB.');
            resolve();
          };
          transaction.onerror = (event) => {
            console.error('Transaction error:', event.target.errorCode);
            reject(event.target.error);
          };
        });
      } else {
        // If data is up to date, fetch from IndexedDB
        const transaction = db.transaction(['techData'], 'readonly');
        const techStore = transaction.objectStore('techData');
        const techUsers = await new Promise((resolve, reject) => {
          const request = techStore.getAll();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
        globalUserData = techUsers.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});

        document.getElementById('currentUserName').innerHTML = globalUserData[localStorage.getItem('currentUserId')].displayName;
        const techRole = globalUserData[localStorage.getItem('currentUserId')].role;
        if(!techRole.includes('admin')){
          switchView('techList');
        }
      }
    } else {
      // Offline: fetch from IndexedDB
      const transaction = db.transaction(['techData'], 'readonly');
      const techStore = transaction.objectStore('techData');
      const techUsers = await new Promise((resolve, reject) => {
        const request = techStore.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
      globalUserData = techUsers.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      document.getElementById('currentUserName').innerHTML = globalUserData[localStorage.getItem('currentUserId')].displayName;
      const techRole = globalUserData[localStorage.getItem('currentUserId')].role;
        if(!techRole.includes('admin')){
          switchView('techList');
        }
    }

  } catch (error) {
    console.error("Error collecting tech information:", error);
  }
}

async function initializeDataListener() {

  const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('techData')) {
          db.createObjectStore('techData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

  const jobsRef = ref(database, 'data');

  const firebaseLastUpdated = await fetchFirebaseLastUpdated('data');
  const indexedDBLastUpdated = await fetchIndexedDBLastUpdated(db, 'jobDataLastUpdated');



  // Determine the start date for our query
  let startDate;
  if (firebaseLastUpdated > indexedDBLastUpdated) {
    // If Firebase has newer data, start from the IndexedDB last updated time
    startDate = new Date(indexedDBLastUpdated).toISOString().split('T')[0];
  } else {
    // If IndexedDB is up to date, start from today to check for any new data
    startDate = new Date().toISOString().split('T')[0];
  }

  const recentJobsQuery = query(
    jobsRef,
    orderByChild('Start_Date'),
    startAt(startDate)
  );

  onChildAdded(recentJobsQuery, async (snapshot) => {
    const newJob = snapshot.val();
    const jobId = snapshot.key;
    
    if (jobId === 'lastUpdated') {
      return; 
    }
  
    try {
     
      const jobExists = await jobExistsInIndexedDB(jobId);
  
      if (!jobExists) {
       
        await addNewJobDB(newJob);
        await createNewTableRow(newJob);
        await createNewJobRow(newJob);
        addNewJobtoNotification(newJob);
        updateTable();
        console.log('New job processed:', jobId, newJob);
      }
    } catch (error) {
    }
  });


onChildChanged(jobsRef, async (snapshot) => {

  const updatedJob = snapshot.val();
  const jobId = snapshot.key;

  console.log(jobId);

  if (jobId !== 'lastUpdated') {
    const workOrder = updatedJob.Work_Order;
    const oldJob = await getJobData(jobId);

    const changedKeys = [];

    for (const key in updatedJob) {
      if (updatedJob[key] !== oldJob[key]) {
        changedKeys.push(key, updatedJob[key]);
      }
    }

    await updateOperationTableUI(jobId, updatedJob)

    try {
    
      if(document.querySelector('.job-item[data-id="' + jobId + '"]')) {

        const jobItem = document.querySelectorAll('.job-item[data-id="' + jobId + '"]');

        jobItem.forEach(item => {
          let operationData = JSON.parse(item.getAttribute('data-jobInfo'));
          operationData = updatedJob;
          item.querySelector('.start-date-info').textContent = updatedJob.Start_Date;
          item.setAttribute('data-jobInfo', JSON.stringify(operationData));
          });

      };

      if(document.querySelector('.table-item[data-workorder="' + workOrder + '"]')) {
        const workOrderRow = document.querySelector('.table-item[data-workorder="' + workOrder + '"]')
      let workOrderData = JSON.parse(workOrderRow.getAttribute('data-jobInfo'));

        workOrderData.Short_Description = updatedJob.Short_Description;
        workOrderData.Start_Date = updatedJob.Start_Date;

      workOrderRow.querySelector('.work-order-description').textContent = updatedJob.Short_Description;
      workOrderRow.querySelector('.work-order-start-date').textContent = updatedJob.Start_Date;
      workOrderRow.setAttribute('data-jobInfo', JSON.stringify(workOrderData));
      };

      if(document.querySelector('.op-table-data-row[data-id="' + jobId + '"]')){
        let workOrderOpData = JSON.parse(document.querySelector('.op-table-data-row[data-id="' + jobId + '"]').getAttribute('data-jobInfo'));
        workOrderOpData = updatedJob;
        document.querySelector('.op-table-data-row[data-id="' + jobId + '"]').setAttribute('data-jobInfo', JSON.stringify(workOrderOpData));
        };

        if(oldJob.Status !== 'Ready to Bill' && updatedJob.Status === 'Ready to Bill') {
          submitOpen(jobId);
          changeStatus(updatedJob.Job_ID);
        };

        if(oldJob.Status !== 'In Progress' && updatedJob.Status === 'In Progress') {
          changeStatus(updatedJob.Job_ID, "In Progress");
        };

        if(oldJob.Status !== updatedJob.Status){
          changeStatus(updatedJob.Job_ID, updatedJob.Status);
        };

        if(updatedJob.Notes){
          document.querySelectorAll('.job-item[data-id="' + jobId + '"] .job-notes-button').forEach(item => {
            item.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
          });
        }else{
          document.querySelectorAll('.job-item[data-id="' + jobId + '"] .job-notes-button').forEach(item => {
            item.querySelector('img').style.filter = 'none';
          });
        }
        
        await updateEntireJobDB(updatedJob);
        populateJobsForCurrentTech();

      console.log('Job updated in IndexedDB');
    } catch (error) {
      console.error('Failed to update job in IndexedDB', error);
    }
  }
});

onChildRemoved(jobsRef, async (snapshot) => {
  const jobId = snapshot.key;
  console.log(snapshot.val());
  console.log('Job removed:', jobId);

  await deleteOperationOtherUser(snapshot.val());
});

  const customersRef = ref(database, 'customers');

onChildChanged(customersRef, async (snapshot) => {
  const updatedCustomer = snapshot.val();
  const customerId = snapshot.key;

  console.log(updatedCustomer);
  console.log(customerId);

  if(customerId === 'lastUpdated' || customerId === '') { return };

  try {
    await updateEntireCustomerDB(updatedCustomer);

    if(document.querySelector('.customer-item[data-id="' + customerId + '"]')) {
      let customerData = JSON.parse(document.querySelector('.customer-item[data-id="' + customerId + '"]').getAttribute('data-customerInfo'));
      customerData = updatedCustomer;
      document.querySelector('.customer-item[data-id="' + customerId + '"]').setAttribute('data-customerInfo', JSON.stringify(customerData));
    };

    console.log('Customer updated in IndexedDB');
  } catch (error) {
    console.error('Failed to update customer in IndexedDB', error);
  }
});

// Listening for new customers being added
onChildAdded(customersRef, async (snapshot) => {
  const newCustomer = snapshot.val();
  const customerId = snapshot.key;

  try {
    const customerExists = await customerExistsInIndexedDB(customerId);

    if (!customerExists) {
      await addNewCustomerToDB(newCustomer);
      /* createNewCustomerRow(newCustomer); */  // Update UI to reflect the new customer
      console.log('New customer processed:', customerId, newCustomer);
    }
  } catch (error) {
    console.error('Failed to process new customer:', customerId, error);
  }
});

// Listening for customers being removed
onChildRemoved(customersRef, async (snapshot) => {
  const customerId = snapshot.key;
  console.log('Customer removed:', customerId);

  try {
    await deleteCustomerFromDB(customerId);
    removeCustomerFromUI(customerId);  // Remove the customer from the UI
    console.log('Customer removed from IndexedDB and UI');
  } catch (error) {
    console.error('Failed to remove customer from IndexedDB and UI', error);
  }
});

}

async function addNewJobtoNotification(jobData) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("notificationData")) {
        db.createObjectStore("notificationData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("notificationData", "readwrite");
      const objectStore = transaction.objectStore("notificationData");

      jobData.id = jobData.Job_ID;

      const addRequest = objectStore.add(jobData);

      addRequest.onsuccess = function () {
        console.log(`New job added with Job_ID ${jobData.Job_ID}`);
        resolve();
      };

      addRequest.onerror = function (error) {
        console.error("Error adding new job", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function jobExistsInIndexedDB(jobId) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readonly");
      const objectStore = transaction.objectStore("jobData");

      const getRequest = objectStore.get(jobId);

      getRequest.onsuccess = function () {
        resolve(!!getRequest.result);
      };

      getRequest.onerror = function (error) {
        console.error("Error checking for job", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function updateEntireJobDB(jobData) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      jobData.id = jobData.Job_ID;

      const updateRequest = objectStore.put(jobData);

      updateRequest.onsuccess = function () {
        updateJobRow(jobData.Job_ID, 'Techs', jobData.Techs, jobData);
        resolve();
      };

      updateRequest.onerror = function (error) {
        console.error("Error updating data", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function addNewJobDB(jobData) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      jobData.id = jobData.Job_ID;

      const addRequest = objectStore.add(jobData);

      addRequest.onsuccess = function () {
        console.log(`New job added with Job_ID ${jobData.Job_ID}`);
        // If you have a function to update the UI for new jobs, call it here
        // For example: updateJobRow(jobData.Job_ID, 'Techs', jobData.Techs, jobData);
        resolve();
      };

      addRequest.onerror = function (error) {
        console.error("Error adding new job", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function customerExistsInIndexedDB(customerId) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readonly");
      const objectStore = transaction.objectStore("customerData");

      const getRequest = objectStore.get(customerId);

      getRequest.onsuccess = function () {
        resolve(!!getRequest.result);  // Resolve true if customer exists, otherwise false
      };

      getRequest.onerror = function (error) {
        console.error("Error checking for customer", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function rentalExistsInIndexedDB(rentalId) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("rentalDatabase", "readonly");
      const objectStore = transaction.objectStore("rentalDatabase");

      const getRequest = objectStore.get(rentalId);

      getRequest.onsuccess = function () {
        resolve(!!getRequest.result);
      };

      getRequest.onerror = function (error) {
        console.error("Error checking for customer", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function tagExistsInIndexedDB(tagId) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("mooringDatabase", "readonly");
      const objectStore = transaction.objectStore("mooringDatabase");

      const getRequest = objectStore.get(tagId);

      getRequest.onsuccess = function () {
        resolve(!!getRequest.result);
      };

      getRequest.onerror = function (error) {
        console.error("Error checking for customer", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

// Function to add a new customer to IndexedDB
async function addNewCustomerToDB(customerData) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      customerData.id = customerData.Customer_ID;  // Assuming Customer_ID is the unique identifier

      const addRequest = objectStore.add(customerData);

      addRequest.onsuccess = function () {
        console.log(`New customer added with Customer_ID ${customerData.Customer_ID}`);
        resolve();
      };

      addRequest.onerror = function (error) {
        console.error("Error adding new customer", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

async function updateEntireCustomerDB(customerData) {
  console.log(customerData)
  if(!customerData.Customer_ID){ return; };
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      customerData.id = customerData.Customer_ID;

      const updateRequest = objectStore.put(customerData);

      updateRequest.onsuccess = function () {
        console.log(`Data updated for Customer_ID ${customerData.Customer_ID}`);
        resolve();
      };

      updateRequest.onerror = function (error) {
        console.error("Error updating customer data", error);
        reject(error);
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function (error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}



//End of Listeners

var globalCustomerData = {};
var closeWorkOrders = {};
let jobHours = {};
let techInfo = {};
let customerDB = {};
let boatDB = {};
let activeTableRow;
let selectedJobRow;
var globalWorkOrder;
var globalJobId;
let activeRow = null;
let currentOpCodes = [];
var jobIdGlobal;
let globalKit;
let dataRows;
var globalShrink;
let countObject = {};
let notificationCount = 0;

let events = [];

document.addEventListener('DOMContentLoaded', async () => {
  try {
    await checkAndInitializeGoogleAPIs();
    adjustHeight();
  } catch (error) {
    console.error('Error initializing app:', error);
  }
});

async function formatDate(date) {
  try {
    if (date) {
      date = new Date(date);
    } else {
      date = new Date();
    }

    let year = date.getUTCFullYear();
    let month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // getUTCMonth() returns 0-11
    let day = date.getUTCDate().toString().padStart(2, '0');
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  } catch (error) {
    console.error('Error formatting date', error);
  }
}


function formatPhoneNumber(phone) {
  console.log(phone);
  const cleaned = phone.replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return phone;
}

async function openDatabase() {
  await closeExistingConnections(); // Close any existing connections
  await deleteDatabaseIfVersionChanged(); // Delete if version has changed

  return new Promise((resolve, reject) => {

    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      console.log('onupgradeneeded event triggered for version', currentIndexedVersion);

      // Create object stores if they don't already exist
      const objectStores = [
        { name: 'customerData', keyPath: 'id' },
        { name: 'jobData', keyPath: 'id' },
        { name: 'opCodeData', keyPath: 'id' },
        { name: 'mapDataBoats', keyPath: 'id' },
        { name: 'mapDataMoorings', keyPath: 'id' },
        { name: 'inventoryData', keyPath: 'id' },
        { name: 'receivingData', keyPath: 'id' },
        { name: 'mooringDatabase', keyPath: 'id' },
        { name: 'rentalDatabase', keyPath: 'id' },
        { name: 'vendorData', keyPath: 'id' },
        { name: 'scheduleData', keyPath: 'id' },
        { name: 'techData', keyPath: 'id' },
        { name: 'notificationData', keyPath: 'id' },
        { name: 'metadata', keyPath: 'key' },
      ];

      objectStores.forEach(store => {
        if (!db.objectStoreNames.contains(store.name)) {
          toastMessage('Adding to local database.  Please wait.')
          db.createObjectStore(store.name, { keyPath: store.keyPath });
          console.log(`Object store ${store.name} created`);
        } else {
          console.log(`Object store ${store.name} already exists`);
        }
      });

      const transaction = event.target.transaction;
      const metadataStore = transaction.objectStore('metadata');
      const itemsToAdd = [
        { key: 'customerDataLastUpdated', timestamp: 0 },
        { key: 'jobDataLastUpdated', timestamp: 0 },
        { key: 'opCodeDataLastUpdated', timestamp: 0 },
        { key: 'boatDataLastUpdated', timestamp: 0 },
        { key: 'mooringDataLastUpdated', timestamp: 0 },
        { key: 'inventoryDataLastUpdated', timestamp: 0 },
        { key: 'receivingDataLastUpdated', timestamp: 0 },
        { key: 'mooringDatabaseLastUpdated', timestamp: 0 },
        { key: 'settingsLastUpdated', timestamp: 0 },
        { key: 'vendorDataLastUpdated', timestamp: 0 },
        { key: 'scheduleDataLastUpdated', timestamp: 0 },
        { key: 'techDataLastUpdated', timestamp: 0 },
      ];

      itemsToAdd.forEach(item => {
        metadataStore.put(item);
        console.log(`Metadata item ${item.key} added`);
      });

      transaction.oncomplete = () => {
        console.log('Initial metadata items added.');
      };

      transaction.onerror = (event) => {
        console.error('Error adding initial metadata items:', event.target.error);
        reject(event.target.error);
      };
    };

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
}

const loginContainer = document.querySelector('.login-standard-container');
const signupContainer = document.querySelector('.login-signup-container');
const switchToSignup = document.getElementById('switchToSignup');
const switchToLogin = document.getElementById('switchToLogin');

switchToSignup.addEventListener('click', () => {
  loginContainer.style.display = 'none';
  signupContainer.style.display = 'block';
});

switchToLogin.addEventListener('click', () => {
  signupContainer.style.display = 'none';
  loginContainer.style.display = 'block';
});


function retrieveAndLogAuthToken() {

  if (!navigator.onLine) {
    return; // Exit the function early if offline
  }

  const functions = getFunctions(app);
  const getAuthToken = httpsCallable(functions, 'getGoogleOAuthToken');

  getAuthToken()
    .then((result) => {
      localStorage.setItem('userAccessToken', result.data.accessToken);
    })
    .catch((error) => {
      console.error('Error retrieving OAuth token:', error);
    });
}

function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/calendar');
  provider.addScope('https://www.googleapis.com/auth/spreadsheets');

  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const idToken = credential.idToken;
      const user = result.user;
      document.querySelector('.main-page').style.display = 'flex';
      const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds

      // Save the access token, refresh token, and expiration time to local storage
      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', user.refreshToken);
      localStorage.setItem('expirationTime', expirationTime);

      // Check if the user already exists in the Realtime Database
      const userRef = ref(database, `users/${user.uid}`);
      get(userRef)
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            // User already exists, check if the user has an assigned role
            const userData = snapshot.val();
            if (userData.role) {
              // User has a role, proceed with loading the page
              console.log("User already exists and has a role");
            } else {
              // User doesn't have a role, display a message and prevent page loading
              console.log("User already exists but doesn't have a role");
              displayMessage("Access Denied", "Please contact the administrator to assign a role.");
            }
          } else {
            // User doesn't exist, create the user in the Realtime Database without a role
            set(userRef, {
              email: user.email,
              displayName: user.displayName,
            })
              .then(() => {
                console.log("New user created without a role");
                displayMessage("Access Denied", "Please contact the administrator to assign a role.");
              })
              .catch((error) => {
                console.error("Error creating new user: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking user existence: ", error);
        });
    })
    .catch((error) => {
      console.error("Error signing in with Google: ", error);
    });
}

async function fetchIdToken() {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    const idToken = await user.getIdToken(true);
    return idToken;
  } catch (error) {
    console.error('Error fetching ID token:', error);
    throw error;
  }
}

/* function initializeGoogleAPIs() {

  if(!navigator.onLine){
    return;
  }

  // Initialize the Google Identity Services library
  google.accounts.id.initialize({
    client_id: '66934899393-kevqmctq7l3fpra0ib0nesimpr3ff9jk.apps.googleusercontent.com',
    callback: handleCredentialResponse,
    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets',
  });

  // Prompt the user to select an account
  google.accounts.id.prompt();

  // Initialize the Google API client library
  gapi.client.init({
    apiKey: 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest', 'https://sheets.googleapis.com/$discovery/rest?version=v4']
  }).then(() => {
    console.log('Google API client library is loaded and initialized');
    gapi.client.load('sheets', 'v4');

  }).catch((error) => {
    console.error('Error initializing Google API client library:', error);
  });
} */


function checkAndInitializeGoogleAPIs() {
  return new Promise((resolve, reject) => {
    function check() {
      if (typeof gapi !== 'undefined' && typeof google !== 'undefined') {
        initializeGoogleAPIs().then(resolve).catch(reject);
      } else {
        setTimeout(check, 100);
      }
    }
    check();
  });
}

function initializeGoogleAPIs() {
  if (!navigator.onLine) {
    return Promise.reject('No internet connection');
  }

  return new Promise((resolve, reject) => {
    // Initialize the Google Identity Services library
    if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
      google.accounts.id.initialize({
        client_id: '66934899393-kevqmctq7l3fpra0ib0nesimpr3ff9jk.apps.googleusercontent.com',
        callback: handleCredentialResponse,
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets',
      });
    } else {
      console.warn('Google Identity Services not yet loaded');
    }

    // Initialize the Google API client library
    if (typeof gapi !== 'undefined') {
      gapi.load('client', () => {
        gapi.client.init({
          apiKey: 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U',
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
            'https://sheets.googleapis.com/$discovery/rest?version=v4',
            'https://www.googleapis.com/discovery/v1/apis/translate/v2/rest'
          ]
        }).then(() => {
          // Check if translate API is loaded
          if (gapi.client.translate) {
            console.log('Translate API loaded successfully');
            setupSpeechRecognition();
          } else {
            console.warn('Translate API not loaded');
          }
          resolve();
        }).catch((error) => {
          console.error('Error initializing Google API client library:', error);
          reject(error);
        });
      });
    } else {
      reject('Google API client library not yet loaded');
    }
  });
}

function handleCredentialResponse(response) {

  if (!navigator.onLine) {
    return;
  }

  // Handle the credential response and obtain the access token
  const accessToken = response.credential;
  console.log(accessToken);

  // Decode the credential (JWT) to get the expiration time
  const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
  const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds

  localStorage.setItem('userAccessToken', accessToken);
  localStorage.setItem('expirationTime', expirationTime);

}

async function closeExistingConnections() {
  const databases = await indexedDB.databases();
  for (const database of databases) {
    if (database.name === 'openDatabase') {
      const request = indexedDB.open(database.name, database.version);
      request.onsuccess = () => {
        const db = request.result;
        db.close();
      };
      request.onerror = (event) => {
        console.error(`Failed to close connection to database: ${database.name}`, event.target.error);
      };
    }
  }
}

async function getCurrentDatabaseVersion() {
  const dbName = 'openDatabase';
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const version = db.version;
      db.close();
      resolve(version);
    };

    request.onerror = (event) => {
      console.error('Error checking database version:', event.target.errorCode);
      resolve(null); // In case of error, resolve with null
    };
  });
}

async function deleteDatabaseIfVersionChanged() {
  const dbName = 'openDatabase';
  const currentVersion = await getCurrentDatabaseVersion();
  if (currentVersion !== null && currentVersion < indexedVersion) {
    console.log('New version detected. Deleting old database...');
    toastMessage('Updating Local Database.  This will take a few moments.');
    currentIndexedVersion = indexedVersion;
    await new Promise((resolve, reject) => {
      const deleteRequest = indexedDB.deleteDatabase(dbName);

      deleteRequest.onerror = (event) => {
        console.error('Error deleting database:', event.target.errorCode);
        reject(event.target.errorCode);
      };

      deleteRequest.onsuccess = (event) => {
        console.log('Database deleted successfully');
        resolve();
      };
    });
  } else {
    currentIndexedVersion = currentVersion;
  }

}

async function onlineFlow(user) {
  try {
    await checkAuthState();
    const hasAccess = await getUserRole(user.uid);
    if (!hasAccess) {
      console.log('No Role!');
      document.getElementById('no-role').style.display = "flex";
      return;
    }
    initializeWeekStart();
    await openDatabase();
    await initializeDatabase();
    await loadIframes();
    await getCurrentUserData();
    await populateVendorLists();
    await initializeDataListener();

    document.getElementById('currentUser').value = user.displayName;
  } catch (error) {
    console.error("Error during online flow:", error);
  }
}

async function offlineFlow(user) {
  try {
    // Load data from IndexedDB cache
    await loadDataFromIndexedDB();
    await loadIframes();
    document.getElementById('currentUser').value = user.displayName;
    // Get user info from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      document.getElementById('currentUser').value = userInfo.displayName;
    }

    // Additional offline operations...
  } catch (error) {
    console.error("Error during offline flow:", error);
  }
}

async function loadDataFromIndexedDB() {
  try {
    await Promise.all([
      getJobsOpen(),
      getCustomerOpen(),
      getOpCodes(),
      getMapData(),
      getInventory(),
      getReceiving(),
      getVendors(),
      getMooringDatabaseOpen(),
      getRentalDatabaseOpen(),
      populateVendorLists(),
      /* getScheduleOpen() */
    ])
    console.log("Loading data from IndexedDB...");
  } catch (error) {
    console.error('Error loading data from IndexedDB', error);
  }
}

onAuthStateChanged(auth, handleAuthStateChange);

// Listen for online/offline events
window.addEventListener('online', () => {
  console.log('App is online');
  const user = auth.currentUser;
  console.log(user);
  if (user) onlineFlow(user);
});

window.addEventListener('offline', () => {
  console.log('App is offline');
  const user = auth.currentUser;
  if (user) offlineFlow(user);
});

async function initializeDatabase() {

  try {
    // Perform data synchronization
    await collectTechInformation();
    await Promise.all([
      getCustomerOpen(),
      getJobsOpen(),
      getOpCodes(),
      getMapData(),
      getInventory(),
      getReceiving(),
      getVendors(),
      getMooringDatabaseOpen(),
      getRentalDatabaseOpen(),
      /* getScheduleOpen() */
    ]);

  } catch (error) {
    console.error('Failed to initialize the database:', error);
    throw error;
  }
}

async function loadIframes() {
  // Check if the database is initialized and ready to use
  try {
    // Load the iframes
    document.getElementById('maps').innerHTML = '<iframe src="/maps" class="page-frame"></iframe>';
    document.getElementById('spring').innerHTML = '<iframe src="/spring" class="page-frame"></iframe>';
    document.getElementById('hauling').innerHTML = '<iframe src="/hauling" class="page-frame"></iframe>';
    document.getElementById('customers').innerHTML = '<iframe src="/customers" class="page-frame"></iframe>';
    document.getElementById('mooringDatabase').innerHTML = '<iframe src="/mooringDatabase" class="page-frame"></iframe>';
    document.getElementById('rentalDatabase').innerHTML = '<iframe src="/rentalDatabase" class="page-frame"></iframe>';
    document.getElementById('mooringHaul').innerHTML = '<iframe src="/mooringHaul" class="page-frame"></iframe>';
    document.getElementById('inventoryDatabase').innerHTML = '<iframe src="/inventory" class="page-frame"></iframe>';
    document.getElementById('receivingDatabase').innerHTML = '<iframe src="/receiving" class="page-frame"></iframe>';
    /* document.getElementById('schedule').innerHTML = '<iframe src="/schedule" class="page-frame"></iframe>'; */

  } catch (error) {
    console.error('Error loading iFrames', error);
  };
}

function checkAuthState() {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const accessToken = localStorage.getItem('userAccessToken');
        localStorage.setItem('userInfo', JSON.stringify(user));
        localStorage.setItem('currentUserId', user.uid);
        if (accessToken) {
          resolve(accessToken);
        } else {
          reject(new Error('Access token not available'));
        }
      } else {
        resolve(null);
      }
    });
  });
}

async function getCurrentUserData() {
  try {
    const user = auth.currentUser;
    if (user) {
      const email = user.email;
      return user;
    }
  } catch (error) {
    console.error('Cannot get current user info' + error);
  };
}

async function fetchFirebaseLastUpdated(firebasePath) {
  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/${firebasePath}/lastUpdated.json?auth=${idToken}`;

    const response = await fetch(firebaseUrl);
    if (!response.ok) throw new Error(`Firebase fetch failed with status ${response.status}`);

    const timestamp = await response.json();
    return timestamp;
  } catch (error) {
    console.error(`Error fetching Firebase last updated timestamp for ${firebasePath}:`, error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

async function fetchIndexedDBLastUpdated(db, metadataKeyName) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['metadata'], 'readonly');
    const store = transaction.objectStore('metadata');
    const request = store.get(metadataKeyName);

    request.onsuccess = () => {
      resolve(request.result ? request.result.timestamp : 0);
    };

    request.onerror = (event) => {
      console.error(`Error fetching IndexedDB last updated timestamp for ${metadataKeyName}:`, event.target.error);
      reject(event.target.error);
    };
  });
}

async function updateTimestamps(dataStoreNameIDB, dataStoreNameFirebase, timestamp) {
  // Timestamp to use for both IndexedDB and Firebase updates
  let currentTimestamp = timestamp ? timestamp : new Date().getTime();

  try {
    // Update timestamp in IndexedDB
    const db = await openIndexedDB(); // Assuming this function abstracts the IndexedDB open request
    const tx = db.transaction(['metadata'], 'readwrite');
    const store = tx.objectStore('metadata');
    const key = `${dataStoreNameIDB}LastUpdated`;
    store.put({ key: key, timestamp: currentTimestamp });

    // Update timestamp in Firebase
    const firebasePath = `${dataStoreNameFirebase}/lastUpdated`;
    if(dataStoreNameFirebase !== 'skip'){
    await updateFirebaseTimestamp(firebasePath, currentTimestamp);
    }
  } catch (error) {
    console.error(`Error updating timestamps for ${dataStoreNameIDB}:`, error);
  }
}

async function updateFirebaseTimestamp(path, timestamp) {
  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/${path}.json?auth=${idToken}`;

    const response = await fetch(firebaseUrl, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(timestamp),
    });

    if (!response.ok) throw new Error('Firebase update failed');
  } catch (error) {
    console.error('Error updating Firebase timestamp:', error);
    throw error; // It's generally a good practice to throw the error to be handled by the caller
  }
}

async function getCustomerOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('customerData')) {
          db.createObjectStore('customerData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    let firebaseTimestamp, indexedDBTimestamp;

    if (navigator.onLine) {
      firebaseTimestamp = await fetchFirebaseLastUpdated('customers');
      indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'customerDataLastUpdated');
    }

    if (navigator.onLine && (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp)) {
      // Online and data needs updating
      const customersRef = ref(database, '/customers');
      const snapshot = await get(customersRef);

      const transaction = db.transaction(['customerData', 'metadata'], 'readwrite');
      const store = transaction.objectStore('customerData');
      const metadataStore = transaction.objectStore('metadata');

      // Get all customer IDs from IndexedDB
      const indexedDBCustomerIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseCustomerIDs = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        firebaseCustomerIDs.push(childKey);

        // Add or update customer data in IndexedDB
        store.put({ id: childKey, ...childData });
      });

      // Identify and remove deleted customers from IndexedDB
      const deletedCustomerIDs = indexedDBCustomerIDs.filter(id => !firebaseCustomerIDs.includes(id));
      deletedCustomerIDs.forEach(id => store.delete(id));

      // Update the timestamp in metadata store after successful updates
      metadataStore.put({ key: 'customerDataLastUpdated', timestamp: firebaseTimestamp });

      await new Promise((resolve, reject) => {
        transaction.oncomplete = () => {
          console.log('All customers have been updated in IndexedDB.');
          resolve();
        };
        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
          reject(event.target.error);
        };
      });
    }

    // Whether online or offline, fetch customer data from IndexedDB
    fetchCustomerDataAndGenerateTable();

  } catch (error) {
    console.error('Error in getCustomerOpen:', error);
  }
}

async function getMooringDatabaseOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mooringDatabase')) {
          db.createObjectStore('mooringDatabase', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('mooringDatabase');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'mooringDatabaseLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const mooringRef = ref(database, '/mooringDatabase/tags');
        const snapshot = await get(mooringRef);

        const transaction = db.transaction(['mooringDatabase', 'metadata'], 'readwrite');
        const store = transaction.objectStore('mooringDatabase');
        const metadataStore = transaction.objectStore('metadata');

        // Get all mooring IDs from IndexedDB
        const indexedDBMooringIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseMooringIDs = [];

        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          firebaseMooringIDs.push(childKey);

          // Add or update mooring data in IndexedDB
          store.put({ id: childKey, ...childData });
        });

        // Identify and remove deleted mooring from IndexedDB
        const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
        deletedMooringIDs.forEach(id => store.delete(id));

        // Update the timestamp in metadata store after successful updates
        metadataStore.put({ key: 'mooringDatabaseLastUpdated', timestamp: firebaseTimestamp });

        transaction.oncomplete = () => {
          console.log('Mooring database has been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error in getMooringDatabaseOpen:', error);
  }
}

async function getRentalDatabaseOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mooringDatabase')) {
          db.createObjectStore('mooringDatabase', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('mooringDatabase');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'mooringDatabaseLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const mooringRef = ref(database, '/mooringDatabase/rentals');
        const snapshot = await get(mooringRef);

        const transaction = db.transaction(['rentalDatabase', 'metadata'], 'readwrite');
        const store = transaction.objectStore('rentalDatabase');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBMooringIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseMooringIDs = [];

        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          firebaseMooringIDs.push(childKey);

          store.put({ id: childKey, ...childData });
        });

        // Identify and remove deleted mooring from IndexedDB
        const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
        deletedMooringIDs.forEach(id => store.delete(id));


        transaction.oncomplete = () => {
          console.log('Rental database has been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error in getRentalDatabaseOpen:', error);
  }
}

async function getJobsOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('jobData')) {
          db.createObjectStore('jobData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('data');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'jobDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const dbRef = ref(database, '/data');

        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const dataArray = Object.entries(data || {}).map(([id, details]) => ({
              id,
              ...details,
            }));

            const transaction = db.transaction(['jobData', 'metadata'], 'readwrite');
            const jobStore = transaction.objectStore('jobData');
            const metadataStore = transaction.objectStore('metadata');

            const indexedDBJobIDs = await new Promise((resolve, reject) => {
              const request = jobStore.getAllKeys();
              request.onsuccess = () => resolve(request.result);
              request.onerror = () => reject(request.error);
            });

            const firebaseJobIDs = dataArray.map(job => job.id);

            dataArray.forEach(job => {
              jobStore.put(job);
            });

            const deletedJobIDs = indexedDBJobIDs.filter(id => !firebaseJobIDs.includes(id));
            deletedJobIDs.forEach(id => jobStore.delete(id));

            metadataStore.put({ key: 'jobDataLastUpdated', timestamp: firebaseTimestamp });

            await new Promise((resolve, reject) => {
              transaction.oncomplete = () => {
                console.log('All job data have been updated in IndexedDB.');
                resolve();
              };
              transaction.onerror = (event) => {
                console.error('Transaction error:', event.target.errorCode);
                reject(event.target.error);
              };
            });
          }
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }else{
        console.log('Loading Jobs from IndexedDB');
      }
    }

    mainTable();

  } catch (error) {
    console.error('Error in getJobsOpen:', error);
  }
}

async function getMapData() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mapDataBoats')) {
          db.createObjectStore('mapDataBoats', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('mapDataMoorings')) {
          db.createObjectStore('mapDataMoorings', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      // Fetch timestamps
      const firebaseTimestampBoats = await fetchFirebaseLastUpdated('maps/boats');
      const indexedDBTimestampBoats = await fetchIndexedDBLastUpdated(db, 'boatDataLastUpdated');

      const firebaseTimestampMoorings = await fetchFirebaseLastUpdated('maps/moorings');
      const indexedDBTimestampMoorings = await fetchIndexedDBLastUpdated(db, 'mooringDataLastUpdated');

      // Update boats data if necessary
      if (!indexedDBTimestampBoats || indexedDBTimestampBoats < firebaseTimestampBoats) {
        const boatsRef = ref(database, '/maps/boats');
        const snapshotBoats = await get(boatsRef);

        const transactionBoats = db.transaction(['mapDataBoats', 'metadata'], 'readwrite');
        const boatStore = transactionBoats.objectStore('mapDataBoats');
        const metadataStore = transactionBoats.objectStore('metadata');

        const indexedDBBoatIDs = await new Promise((resolve, reject) => {
          const request = boatStore.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseBoatIDs = [];

        snapshotBoats.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          firebaseBoatIDs.push(childKey);

          boatStore.put({ id: childKey, ...childData });
        });

        const deletedBoatIDs = indexedDBBoatIDs.filter(id => !firebaseBoatIDs.includes(id));
        deletedBoatIDs.forEach(id => boatStore.delete(id));

        metadataStore.put({ key: 'boatDataLastUpdated', timestamp: firebaseTimestampBoats });

        transactionBoats.oncomplete = () => {
          console.log('All boats have been updated in IndexedDB.');
        };

        transactionBoats.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
      // Update moorings data if necessary
      if (!indexedDBTimestampMoorings || indexedDBTimestampMoorings < firebaseTimestampMoorings) {
        const mooringsRef = ref(database, '/maps/moorings');
        const snapshotMoorings = await get(mooringsRef);

        const transactionMoorings = db.transaction(['mapDataMoorings', 'metadata'], 'readwrite');
        const mooringStore = transactionMoorings.objectStore('mapDataMoorings');
        const metadataStore = transactionMoorings.objectStore('metadata');

        const indexedDBMooringIDs = await new Promise((resolve, reject) => {
          const request = mooringStore.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseMooringIDs = [];

        snapshotMoorings.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();

          firebaseMooringIDs.push(childKey);

          mooringStore.put({ id: childKey, ...childData });
        });

        const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
        deletedMooringIDs.forEach(id => mooringStore.delete(id));

        metadataStore.put({ key: 'mooringDataLastUpdated', timestamp: firebaseTimestampMoorings });

        transactionMoorings.oncomplete = () => {
          console.log('All moorings have been updated in IndexedDB.');
        };

        transactionMoorings.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error in getMapData:', error);
  }
}
  

async function getOpCodes() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('opCodeData')) {
          db.createObjectStore('opCodeData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('opcodes');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'opCodeDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const opCodesRef = ref(database, '/opcodes');
        const snapshot = await get(opCodesRef);
        const opCodes = snapshot.val();

        const transaction = db.transaction(['opCodeData', 'metadata'], 'readwrite');
        const store = transaction.objectStore('opCodeData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseOpCodeIDs = Object.keys(opCodes);

        Object.entries(opCodes).forEach(([key, value]) => {
          store.put({ id: key, ...value });
        });

        const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
        deletedOpCodeIDs.forEach(id => store.delete(id));

        metadataStore.put({ key: 'opCodeDataLastUpdated', timestamp: firebaseTimestamp });

        transaction.oncomplete = () => {
          console.log('All op codes have been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error synchronizing op codes:', error);
  }
}

async function getInventory() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('inventoryData')) {
          db.createObjectStore('inventoryData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('inventory');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'inventoryDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const opCodesRef = ref(database, '/inventory');
        const snapshot = await get(opCodesRef);
        const opCodes = snapshot.val();

        const transaction = db.transaction(['inventoryData', 'metadata'], 'readwrite');
        const store = transaction.objectStore('inventoryData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseOpCodeIDs = Object.keys(opCodes);

        Object.entries(opCodes).forEach(([key, value]) => {
          store.put({ id: key, ...value });
        });

        const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
        deletedOpCodeIDs.forEach(id => store.delete(id));

        metadataStore.put({ key: 'inventoryDataLastUpdated', timestamp: firebaseTimestamp });

        transaction.oncomplete = () => {
          console.log('All inventory has been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error synchronizing inventory:', error);
  }
}

async function getVendors() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('vendorData')) {
          db.createObjectStore('vendorData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('settings');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'settingsLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const opCodesRef = ref(database, '/settings/vendorList');
        const snapshot = await get(opCodesRef);
        const opCodes = snapshot.val();

        const transaction = db.transaction(['vendorData', 'metadata'], 'readwrite');
        const store = transaction.objectStore('vendorData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseOpCodeIDs = Object.keys(opCodes);
        console.log(opCodes)

        Object.entries(opCodes).forEach(([key, value]) => {
          store.put({ id: key, ...value });
        });

        const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
        deletedOpCodeIDs.forEach(id => store.delete(id));

        metadataStore.put({ key: 'settingsLastUpdated', timestamp: firebaseTimestamp });

        transaction.oncomplete = () => {
          console.log('All settings has been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error synchronizing settings:', error);
  }
}

async function getReceiving() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('receivingData')) {
          db.createObjectStore('receivingData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('receiving');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'receivingDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const opCodesRef = ref(database, '/receiving');
        const snapshot = await get(opCodesRef);
        const opCodes = snapshot.val();

        const transaction = db.transaction(['receivingData', 'metadata'], 'readwrite');
        const store = transaction.objectStore('receivingData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseOpCodeIDs = Object.keys(opCodes);

        Object.entries(opCodes).forEach(([key, value]) => {
          store.put({ id: key, ...value });
        });

        const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
        deletedOpCodeIDs.forEach(id => store.delete(id));

        metadataStore.put({ key: 'receivingDataLastUpdated', timestamp: firebaseTimestamp });

        transaction.oncomplete = () => {
          console.log('All receiving has been updated in IndexedDB.');
        };

        transaction.onerror = (event) => {
          console.error('Transaction error:', event.target.errorCode);
        };
      }
    }
  } catch (error) {
    console.error('Error synchronizing inventory:', error);
  }
}

async function getScheduleOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('scheduleData')) {
          db.createObjectStore('scheduleData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('scheduleDatabase');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'scheduleDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const dbRef = ref(database, '/scheduleDatabase');

        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const dataArray = Object.entries(data || {}).map(([id, details]) => ({
              id,
              ...details,
            }));

            const transaction = db.transaction(['scheduleData', 'metadata'], 'readwrite');
            const jobStore = transaction.objectStore('scheduleData');
            const metadataStore = transaction.objectStore('metadata');

            const indexedDBJobIDs = await new Promise((resolve, reject) => {
              const request = jobStore.getAllKeys();
              request.onsuccess = () => resolve(request.result);
              request.onerror = () => reject(request.error);
            });

            const firebaseJobIDs = dataArray.map(job => job.id);

            dataArray.forEach(job => {
              jobStore.put(job);
            });

            const deletedJobIDs = indexedDBJobIDs.filter(id => !firebaseJobIDs.includes(id));
            deletedJobIDs.forEach(id => jobStore.delete(id));

            metadataStore.put({ key: 'scheduleDataLastUpdated', timestamp: firebaseTimestamp });

            await new Promise((resolve, reject) => {
              transaction.oncomplete = () => {
                console.log('All schedule data has been updated in IndexedDB.');
                resolve();
              };
              transaction.onerror = (event) => {
                console.error('Transaction error:', event.target.errorCode);
                reject(event.target.error);
              };
            });
          }
        } catch (error) {
          console.error('Error fetching schedule from Firebase:', error);
        }
      }
    }

  } catch (error) {
    console.error('Error at getScheduleData:', error);
  }
}

function updateSignInStatus(isSignedIn) {
  if (isSignedIn) {
    // User is signed in. You can now make API calls.
  } else {
    // User is not signed in. Start sign-in process.
    gapi.auth2.getAuthInstance().signIn();
  }
}

function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.signIn({ prompt: 'none' }).then(() => {
        const currentUser = auth2.currentUser.get();
        currentUser.reloadAuthResponse().then((authResponse) => {
          const newAccessToken = authResponse.access_token;
          localStorage.setItem('accessToken', newAccessToken);
          resolve(newAccessToken);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    } else {
      reject(new Error('Refresh token not available'));
    }
  });
}



async function listUpcomingEvents() {
  try {

    if (!gapi || !gapi.client) {
      throw new Error('gapi.client is not loaded');
    }

    const accessToken = await checkAuthState();

    gapi.client.setToken(accessToken);

    const response = await gapi.client.request({
      'path': 'https://www.googleapis.com/calendar/v3/calendars/c_8b3bf39137e47f5a8b783bbb1a392348e66b57064559d6b4e7c0f8c0be3d41b3@group.calendar.google.com/events',
      'method': 'GET',
      'params': {
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 20,
        'orderBy': 'startTime'
      },
      'headers': {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    var events = response.result.items;
    displayEvents(events);
  } catch (error) {
    console.error('Error fetching upcoming events:', error);
  }
}

async function addUserToDatabase() {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently authenticated.');
      return;
    }

    const uid = currentUser.uid;
    const email = currentUser.email; // Use the email of the authenticated user
    const role = document.getElementById('user-role').value;
    const displayName = document.getElementById('display-name').value;
    const userData = {
      "role": role,
      "displayName": displayName,
      "email": email
    };

    // Create a reference to the 'users' collection and set the user data
    await set(ref(database, `users/${uid}`), userData);
    console.log('User added to database successfully.');
  } catch (error) {
    console.error('Error adding user to database:', error);
  }
}
/* document.getElementById('addUserButton').addEventListener('click',addUserToDatabase) */

function loginUser(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      document.querySelector('.main-page').style.display = 'flex';
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Show an error message to your user or log it
      console.error("Error signing in: ", errorCode, errorMessage);
    });
}

function logoutUser() {
  signOut(auth)
    .then(() => {
      console.log("User signed out");
      location.reload();
    })
    .catch((error) => {
      // An error happened
      console.error("Error signing out: ", error);
    });
}

document.getElementById('sign-up-button').addEventListener('click', () => {

  const email = document.getElementById('signupEmail').value;
  const password = document.getElementById('signupPassword').value;
  const userName = document.getElementById('signupName').value;
  createUser(email, password, userName);

});


function createUser(email, password, userName) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log("User created:", user);
      
      // Update user profile with displayName
      return updateProfile(user, {
        displayName: userName
      }).then(() => {
        console.log("User profile updated with displayName:", userName);
        const userId = user.uid;

        // Create a reference to the user in the Realtime Database
        const userRef = ref(database, 'users/' + userId);
        
        // Set user details in the Realtime Database
        return set(userRef, {
          displayName: userName,
          email: user.email,
          role: 'pending',  // Default role set to pending for approval
          approved: false   // Requires admin approval
        });
      });
    })
    .then(() => {
      // Update the lastUpdated timestamp in the Realtime Database
      const timeStampRef = ref(database, 'users/lastUpdated');
      const timeStamp = new Date().getTime();
      return set(timeStampRef, timeStamp);
    })
    .then(() => {
      // Show success message and toggle the login/sign-up containers
      console.log("User data added to the database and timestamp updated.");
      document.querySelector('.login-standard-container').style.display = 'flex';
      document.querySelector('.login-signup-container').style.display = 'none';
    })
    .catch((error) => {
      // Handle errors in any of the previous steps
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error("Error in user creation flow:", errorCode, errorMessage);
      if (errorCode.includes('auth/email-already-in-use')) {
        toastMessage('Username not available.  Please use a different email.', 'red');
      } else {
      toastMessage('Error creating user: ' + errorMessage, 'red');
      }
    });
}



function displayMessage(title, message) {
  // Display the message to the user (you can customize this function based on your UI)
  alert(`${title}\n${message}`);
}

document.getElementById('log-in-button').addEventListener('click', function (event) {
  event.preventDefault();
  const email = document.getElementById('user-email').value;
  const password = document.getElementById('user-password').value;
  loginUser(email, password)
  document.getElementById('login-modal').style.display = 'none';
});

document.getElementById('log-in-google').addEventListener('click', function (event) {
  event.preventDefault();
  signInWithGoogle()
  document.getElementById('login-modal').style.display = 'none';
});

/*     document.getElementById('signup-form').addEventListener('submit', function(event) {
        event.preventDefault();
        const email = document.getElementById('signup-email').value;
        const password = document.getElementById('signup-password').value;
        createUser(email, password);
    }); */


async function fetchJobsWithLaborAndPartsByWorkOrder(workOrder) {
  try {
    // Reference to your Firebase database
    const jobsRef = ref(database, 'data'); // Assuming 'data' contains your jobs

    const snapshot = await get(query(jobsRef, orderByChild('Work_Order'), equalTo(workOrder.toString())));

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const job = childSnapshot.val();

        // Check and process labor data if exists
        if (job.Labor) {
          console.log("Labor Data for Work Order " + workOrder + ":");
          Object.values(job.Labor).forEach(labor => {
            console.log(labor); // Process each labor entry as needed
          });
        } else {
          console.log("No Labor Data found for Work Order " + workOrder);
        }

        // Check and process parts data if exists
        if (job.Parts) {
          console.log("Parts Data for Work Order " + workOrder + ":");
          Object.values(job.Parts).forEach(part => {
            console.log(part); // Process each parts entry as needed
          });
        } else {
          console.log("No Parts Data found for Work Order " + workOrder);
        }
      });
    } else {
      console.log("No matching jobs found for Work Order " + workOrder);
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

function openIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase', currentIndexedVersion);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
}

function addJobToIndexedDB(db, job, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.put({ id: jobId, ...job });
}

function updateJobInIndexedDB(db, job, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.put({ id: jobId, ...job });
}

function deleteJobFromIndexedDB(db, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.delete(jobId);
}

function hideJob(jobId) {
  const jobItem = document.querySelector(`.job-item[data-id="${jobId}"]`);
  if (jobItem) {
    jobItem.style.display = 'none';
  }
}

function showJob(jobId) {
  console.log(jobId);
  const jobItem = document.querySelector(`.job-item[data-id="${jobId}"]`);
  if (jobItem) {
    jobItem.style.display = 'flex';
  }
}

async function renderJobsInChunks(jobsArray, jobListDiv, chunkSize = 100) {
  for (let i = 0; i < jobsArray.length; i += chunkSize) {
    const fragment = document.createDocumentFragment();
    const chunk = jobsArray.slice(i, i + chunkSize);

    chunk.forEach((job) => {
      const jobDiv = createJobRow(job);
      fragment.appendChild(jobDiv);
    });

    jobListDiv.appendChild(fragment);
    await new Promise(requestAnimationFrame); // Allow browser to handle other tasks
  }
}


async function populateJobList() {
  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('openDatabase', currentIndexedVersion);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });

    const transaction = db.transaction(['jobData'], 'readonly');
    const objectStore = transaction.objectStore('jobData');
    const jobListDiv = document.getElementById('jobList');
    const jobsArray = [];

    await new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const job = cursor.value;
          if (job.Status && job.Description && !['Declined', 'Hold'].includes(job.Status)) {
            jobsArray.push(job);
          }
          cursor.continue();
        } else {
          resolve();
        }
      };
    });

    jobListDiv.innerHTML = '';
    await renderJobsInChunks(jobsArray, jobListDiv);
    db.close();

    jobListDiv.addEventListener('click', throttle(opCheck, 200));
    populateJobsForCurrentTech();
    populateJobsByWeek();
    updateWeekView();
    countDatesByWeek(haulWeeks);
  } catch (error) {
    console.error("Error fetching job data from IndexedDB:", error);
  }
}



function createJobRow(job) {
  const jobDiv = document.createElement('div');
  jobDiv.className = 'job-item';

/*   if((job.Description.toLowerCase().includes('launch') || job.Description.toLowerCase().includes('haul')) && job.Description.toLowerCase().includes('storage')) {
    jobDiv.className = 'haul-item';
  } */

  const jobDivStatus = document.createElement('div');
  jobDivStatus.className = 'status-info';

  if(job.Status === 'Needs Scheduling'){
    jobDivStatus.style.backgroundColor = 'blue';
  } else if(job.Status === 'On Work Schedule'){
    jobDivStatus.style.backgroundColor = 'green';
  } else if(job.Status === 'In Progress'){
    jobDivStatus.style.backgroundColor = 'yellow';
  } else if(job.Status === 'Ready to Bill' || job.Status === 'Complete'){
    jobDivStatus.style.backgroundColor = 'red';
  } else if(job.Status === 'Hold'){
    jobDivStatus.style.backgroundColor = 'grey';
  } else if(job.Status === 'Declined'){
    jobDivStatus.style.backgroundColor = 'black';
  } else if(job.Status === 'Ready to Frame' || job.Status === 'Vacuumed' || job.Status === 'Framed' || job.Status === 'Covered' || job.Status === 'Tightened'){
    jobDivStatus.style.backgroundColor = 'orange';
  }

  const excludeCheckBox = document.createElement('input');
  excludeCheckBox.type = 'checkbox';
  excludeCheckBox.className = 'exclude-checkbox';

  excludeCheckBox.addEventListener('change', (e) => {
    if (e.target.checked) {
      jobDiv.style.backgroundColor = 'lightgrey';
      jobDiv.style.opacity = '0.5';
      jobDiv.classList.add('excluded');
      document.getElementById('reset-exclude').style.display = "flex";
    }
    else {
      jobDiv.style.backgroundColor = 'initial';
      jobDiv.style.opacity = '1';
      jobDiv.classList.remove('excluded');
    }
    });
  

  jobDivStatus.appendChild(excludeCheckBox);


  const jobDivNotes = document.createElement('div');
  jobDivNotes.className = 'job-div-notes';

  const jobDivMain = document.createElement('div');
  jobDivMain.className = 'job-div-main';

  jobDiv.setAttribute('data-id', job.Job_ID);
  jobDiv.setAttribute('data-tech', job.Techs);
  jobDiv.setAttribute('data-techId', job.Techs != '' ? (getTechId(job.Techs) || '') : '');
  jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));
  jobDiv.setAttribute('data-customerid', job.Customer_ID);
  jobDiv.setAttribute('data-boatid', job.Boat_ID);

  if(haulStatus[job.Boat_ID]){
    jobDiv.setAttribute('data-hauled', haulStatus[job.Boat_ID]);    
  }

 /*  jobDiv.addEventListener('click', (e) => { opCheck(e) }); */

  if (job.Schedule_Date && job.Schedule_Date !== '') {
    events.push({
      id: job.Job_ID,
      customer: job.Customer_Name,
      boat: job.Boat_Name,
      date: job.Schedule_Date,
      status: job.Status,
      tech: job.Techs,
      description: job.Description,
    });
  }

  const opCodeWorkOrderDiv = document.createElement('div');
  opCodeWorkOrderDiv.className = 'op-code-short-description-info hide-mobile';

  const opCodeP = document.createElement('p');
  opCodeP.style.fontWeight = 'bold';
  opCodeP.classList.add('op-code-short-description-p');
  opCodeP.innerHTML = job.Short_Description_DM ? `${job.OpCode}: ${job.Short_Description_DM.split(':')[0]}` : `${job.OpCode}: ${job.Description.split(':')[0]}`;

  const workOrderP = document.createElement('p');
  workOrderP.innerHTML = "WO: " + job.Work_Order;
  workOrderP.className = 'work-order-jobs';

  opCodeWorkOrderDiv.appendChild(opCodeP);
  opCodeWorkOrderDiv.appendChild(workOrderP);

  const customerBoatDiv = document.createElement('div');
  customerBoatDiv.className = 'customer-boat-info hide-mobile';

  const customerNameP = document.createElement('p');
  customerNameP.innerHTML = job.Customer_Name;
  customerNameP.className = 'customer-name-jobs';
  customerBoatDiv.appendChild(customerNameP);

  const boatNameP = document.createElement('p');
  boatNameP.innerHTML = job.Boat_Name;
  boatNameP.className = 'boat-name-jobs';
  customerBoatDiv.appendChild(boatNameP);

  const descriptionTechDiv = document.createElement('div');
  descriptionTechDiv.className = 'description-info';

  const jobDescriptionP = document.createElement('p');
  jobDescriptionP.className = 'job-description hide-mobile';  
  let jobDescription = job.Description.includes(':') ? job.Description.split(':')[0] : job.Description;
  jobDescriptionP.innerHTML = jobDescription;
  /* descriptionTechDiv.appendChild(jobDescriptionP); */

  const techP = document.createElement('p');
  techP.className = 'tech-select noPrint';

  const techDropdown = document.createElement('select');
  techDropdown.className = 'tech-info';

  const deleteOption = document.createElement('option');
  deleteOption.value = '';
  deleteOption.textContent = 'Select Tech';
  techDropdown.appendChild(deleteOption);

  globalTechList.forEach(name => {
    const optionElement = document.createElement('option');
    optionElement.value = name.split(':')[0];
    optionElement.textContent = name;
    techDropdown.appendChild(optionElement);
  });

  if (job.Techs) {
    techDropdown.value = job.Techs;
    techDropdown.setAttribute('data-selected-tech', job.Techs);
  }

  techDropdown.addEventListener("change", async function () {
    const data = JSON.parse(this.closest('.job-item').getAttribute('data-jobInfo'));
  
    
    if (data.Estimated_Hours === '' || data.Estimated_Hours === 0) {
      try {
        await showEstimatedHoursPopup(data, jobDiv);  
      } catch (error) {
        console.error(error);  
        return;
      }
    }
  
    console.log(this.value);
    try {
      const oldTech = this.getAttribute('data-selected-tech');
      const newTech = this.value;
      this.setAttribute('data-selected-tech', newTech);
  
      const id = data.Job_ID;
  
      if (oldTech) {
        console.log('Old Tech:', oldTech);
      }
  
      await addJobToTech(data, newTech);
      await updateJobRow(id, 'Techs', newTech);
      await updateWeekView();
      populateJobsForCurrentTech();
      populateJobsByWeek();
      updateTimestamps('jobData','data');
  
    } catch (error) {
      console.log(error);
    }
  });
  

  techP.appendChild(techDropdown);
  descriptionTechDiv.appendChild(techP);

  const dateDiv = document.createElement('div');
  dateDiv.className = 'date-info';
  const requestDateP = document.createElement('p');
  const requestDateLabel = document.createElement('strong');
  requestDateLabel.textContent = 'Start Date: ';
  requestDateP.appendChild(requestDateLabel);
  requestDateP.className = 'start-date-info hide-mobile';
  let jobDate;

  const formatDate = (date) => {
    if (date && date.includes('-')) {
      const parts = date.split('-');
      if (parts.length === 3) {
        return `${parts[1]}/${parts[2]}/${parts[0]}`;
      }
    }
    return 'Invalid Date';
  };
  
  if (job.Schedule_Date && job.Schedule_Date !== '') {
    jobDate = formatDate(job.Schedule_Date);
  } else if (job.Start_Date && job.Start_Date !== '') {
    jobDate = formatDate(job.Start_Date);
  } else if (job.Request_Date && job.Request_Date !== '') {
    if (job.Category === 'Winterizing' && job.Haul_Date === '') {
      jobDate = formatDate(job.Request_Date) + ' (No Haul Date)';
      requestDateLabel.textContent = 'Request Date: ';
      requestDateLabel.style.color = 'red';
    } else if (job.Category === 'Spring Commissioning' && job.Launch_Date === '') {
      jobDate = formatDate(job.Request_Date) + ' (No Launch Date)';
      requestDateLabel.textContent = 'Request Date: ';
      requestDateLabel.style.color = 'red';
    } else {
      jobDate = formatDate(job.Request_Date);
    }
  }
  
  if (!jobDate) {
    jobDate = 'Date Not Available';
  }

  requestDateP.innerHTML += jobDate;
  dateDiv.appendChild(requestDateP);

  const scheduledDateDiv = document.createElement('div');
  scheduledDateDiv.className = 'scheduled-date-info';
  const scheduleDateLabel = document.createElement('strong');
  scheduleDateLabel.textContent = 'Scheduled: ';

  const scheduleDateP = document.createElement('input');
  scheduleDateP.type = 'date';
  scheduleDateP.className = 'schedule-date-info';
  scheduleDateP.value = job.Schedule_Date ? job.Schedule_Date : '';
  if (!job.Schedule_Date || job.Schedule_Date === '') {
    scheduleDateP.style.color = 'red';
  }

  scheduleDateP.addEventListener('change', async (e) => {
    const jobId = e.target.closest('.job-item').getAttribute('data-id');
    const date = e.target.value;
    updateJobInfo(jobId, 'Schedule_Date', e.target.value);
    updateJobRow(jobId, 'Schedule_Date', e.target.value);
    applyFilters();
    applySorting();
    searchCall();
    searchJobs();
    searchSchedule();
    searchTech();
    jobsArrayForWeek.forEach(job => {
      if(job.Job_ID?.toString() === jobId.toString()){
        job.Schedule_Date = date;
      }
    });
    updateWeekView();
  });

  scheduledDateDiv.appendChild(scheduleDateLabel);
  scheduledDateDiv.appendChild(scheduleDateP);
  dateDiv.appendChild(scheduledDateDiv);

  const statusPriorityDiv = document.createElement('div');
  statusPriorityDiv.className = 'status-priority-info hide-mobile';
  statusPriorityDiv.classList.add('hide-mobile');
  const statusP = document.createElement('p');
  statusP.className = 'status-info-p';
  const statusLabel = document.createElement('strong');
  statusLabel.textContent = 'Status: ';
  statusP.appendChild(statusLabel);
  const status = job.Status !== 'On Work Schedule' ? job.Status : 'Needs Scheduling';
  statusP.innerHTML += status;
  statusPriorityDiv.appendChild(statusP);

  const priorityP = document.createElement('p');
  const priorityLabel = document.createElement('strong');
  priorityLabel.textContent = 'Priority: ';
  priorityP.appendChild(priorityLabel);
  priorityP.innerHTML += job.Priority ? job.Priority : 'Normal';
  statusPriorityDiv.appendChild(priorityP);

  const noteDiv = document.createElement('div');
  noteDiv.className = 'notes-info';
  const jobNotesP = document.createElement('button');
  jobNotesP.className = 'job-notes-button';

  jobNotesP.addEventListener('click', async () => {
    const jobDiv = jobNotesP.closest('[data-id]');
    const jobId = jobDiv.getAttribute('data-id');
    const jobNotesData = await getJobData(jobId);

    document.getElementById('office-notes').classList.add('active');
    document.getElementById('note-job-id').value = jobId;

    const noteArea = document.getElementById('note-area');
    noteArea.innerHTML = '';

    if (jobNotesData.Notes) {
      Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
        const noteContainer = document.createElement('div');
        noteContainer.className = 'note-pair';
        noteContainer.id = key;

        const noteInfo = document.createElement('div');
        noteInfo.className = 'note-info';

        const noteText = document.createElement('p');
        noteText.textContent = note.Note;

        const techInfo = document.createElement('div');
        techInfo.className = 'note-info-tech';

        techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;

        const deleteButton = document.createElement('button');
        deleteButton.textContent = 'Delete';
        deleteButton.className = 'delete-note';
        deleteButton.addEventListener('click', async () => {
          await deleteNotes(jobId, key);
          noteContainer.remove();
        });

        noteInfo.appendChild(noteText);
        noteInfo.appendChild(techInfo);
        noteInfo.appendChild(deleteButton);
        noteContainer.appendChild(noteInfo);

        noteArea.appendChild(noteContainer);
      });
    }
  });

  jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;

  if (job.Notes) {
    jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
  }

  noteDiv.appendChild(jobNotesP);

  const mobileOpDiv = document.createElement('div');
  mobileOpDiv.className = 'mobile-op-info show-mobile';
  mobileOpDiv.innerHTML = job.OpCode + ": " + job.Description.split(':')[0];

  const mobileCustomerBoatDiv = document.createElement('div');
  mobileCustomerBoatDiv.className = 'mobile-customer-boat-info show-mobile';
  mobileCustomerBoatDiv.innerHTML = job.Customer_Name.split(', ')[0] + ' / ' + job.Boat_Name;

  jobDivMain.appendChild(mobileOpDiv);
  jobDivMain.appendChild(mobileCustomerBoatDiv);
  jobDivMain.appendChild(opCodeWorkOrderDiv);
  jobDivMain.appendChild(customerBoatDiv);
  jobDivMain.appendChild(dateDiv);
  jobDivMain.appendChild(descriptionTechDiv);
  jobDivMain.appendChild(statusPriorityDiv);

  jobDivNotes.appendChild(noteDiv);

  jobDiv.appendChild(jobDivStatus);
  jobDiv.appendChild(jobDivMain);
  jobDiv.appendChild(jobDivNotes);

  if(job.Status === 'Ready to Bill' || job.Status === 'Complete'){
    jobDiv.classList.add('complete-job');
  }

  if(job.Status === 'Closed'){
    jobDiv.classList.add('closed-job');
  }

  return jobDiv;
}

function createNewJobRow(job) {
  const jobDiv = document.createElement('div');
  jobDiv.className = 'job-item';

  const jobDivStatus = document.createElement('div');
  jobDivStatus.className = 'status-info';
  jobDivStatus.style.backgroundColor = job.Status === 'In Progress' ? 'yellow' : 'green';

  const jobDivNotes = document.createElement('div');
  jobDivNotes.className = 'job-div-notes';

  const jobDivMain = document.createElement('div');
  jobDivMain.className = 'job-div-main';

  jobDiv.setAttribute('data-id', job.Job_ID);
  jobDiv.setAttribute('data-tech', job.Techs);
  jobDiv.setAttribute('data-techId', job.Techs != '' ? (getTechId(job.Techs) || '') : '');
  jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));
  jobDiv.setAttribute('data-customerid', job.Customer_ID);
  jobDiv.setAttribute('data-boatid', job.Boat_ID);
  jobDiv.addEventListener('click', (e) => { opCheck(e) });

  if (job.Schedule_Date) {
    events.push({
      id: job.Job_ID,
      customer: job.Customer_Name,
      boat: job.Boat_Name,
      date: job.Schedule_Date,
      status: job.Status,
      tech: job.Techs,
      description: job.Description,
    });
  }

  const opCodeWorkOrderDiv = document.createElement('div');
  opCodeWorkOrderDiv.className = 'op-code-short-description-info hide-mobile';

  const opCodeP = document.createElement('p');
  opCodeP.classList.add('op-code-short-description-p');
  opCodeP.innerHTML =  job.Short_Description_DM ? `${job.OpCode}: ${job.Short_Description_DM.split(':')[0]}` : `${job.OpCode}: ${job.Description.split(':')[0]}`;

  const workOrderP = document.createElement('p');
  const workOrderLabel = document.createElement('strong');
  workOrderLabel.textContent = 'WO: ';
  workOrderP.appendChild(workOrderLabel);
  workOrderP.innerHTML += job.Work_Order;
  workOrderP.className = 'work-order-jobs';

  opCodeWorkOrderDiv.appendChild(opCodeP);
  opCodeWorkOrderDiv.appendChild(workOrderP);

  const customerBoatDiv = document.createElement('div');
  customerBoatDiv.className = 'customer-boat-info hide-mobile';

  const customerNameP = document.createElement('p');
  customerNameP.innerHTML = job.Customer_Name;
  customerNameP.className = 'customer-name-jobs';
  customerBoatDiv.appendChild(customerNameP);

  const boatNameP = document.createElement('p');
  boatNameP.innerHTML = job.Boat_Name;
  boatNameP.className = 'boat-name-jobs';
  customerBoatDiv.appendChild(boatNameP);

  const categoryWorkOrderDiv = document.createElement('div');
  categoryWorkOrderDiv.className = 'category-work-order-info hide-mobile';

  const categoryP = document.createElement('p');
  categoryP.innerHTML += job.Category;
  categoryP.className = 'category-info';
  categoryWorkOrderDiv.appendChild(categoryP);

  const descriptionTechDiv = document.createElement('div');
  descriptionTechDiv.className = 'description-info';

  const jobDescriptionP = document.createElement('p');
  jobDescriptionP.className = 'job-description hide-mobile';  
  let jobDescription = job.Description.includes(':') ? job.Description.split(':')[0] : job.Description;
  jobDescriptionP.innerHTML = jobDescription;
  /* descriptionTechDiv.appendChild(jobDescriptionP); */

  const techP = document.createElement('p');
  techP.className = 'tech-select noPrint';

  const techDropdown = document.createElement('select');
  techDropdown.className = 'tech-info';

  const deleteOption = document.createElement('option');
  deleteOption.value = '';
  deleteOption.textContent = 'Select Tech';
  techDropdown.appendChild(deleteOption);

  globalTechList.forEach(name => {
    const optionElement = document.createElement('option');
    optionElement.value = name.split(':')[0];
    optionElement.textContent = name;
    techDropdown.appendChild(optionElement);
  });

  if (job.Techs) {
    techDropdown.value = job.Techs;
    techDropdown.setAttribute('data-selected-tech', job.Techs);
  }

  techDropdown.addEventListener("change", async function () {
    const data = JSON.parse(this.closest('.job-item').getAttribute('data-jobInfo'));
  
    
    if (data.Estimated_Hours === '' || data.Estimated_Hours === 0) {
      try {
        await showEstimatedHoursPopup(data, jobDiv);  
      } catch (error) {
        console.error(error);  
        return;
      }
    }
  
    
    try {
      const oldTech = this.getAttribute('data-selected-tech');
      const newTech = this.value;
      this.setAttribute('data-selected-tech', newTech);
  
      const id = data.Job_ID;
  
      if (oldTech) {
        console.log('Old Tech:', oldTech);
      }
  
      await addJobToTech(data, newTech);
      await updateJobRow(id, 'Techs', newTech);
      await updateWeekView();
      populateJobsForCurrentTech();
      populateJobsByWeek();
      updateTimestamps('jobData','data');
  
    } catch (error) {
      console.log(error);
    }
  });
  

  techP.appendChild(techDropdown);
  descriptionTechDiv.appendChild(techP);

  const dateDiv = document.createElement('div');
  dateDiv.className = 'date-info';
  const requestDateP = document.createElement('p');
  const requestDateLabel = document.createElement('strong');
  requestDateLabel.textContent = 'Start Date: ';
  requestDateP.appendChild(requestDateLabel);
  requestDateP.className = 'start-date-info hide-mobile';
  let jobDate;
  
  // Helper function to format dates
  const formatDate = (date) => {
    if (date && date.includes('-')) {
      const parts = date.split('-');
      if (parts.length === 3) {
        return `${parts[1]}/${parts[2]}/${parts[0]}`;  // Format as MM/DD/YYYY
      }
    }
    return 'Invalid Date';
  };
  
  if (job.Schedule_Date && job.Schedule_Date !== '') {
    jobDate = formatDate(job.Schedule_Date);
  } else if (job.Start_Date && job.Start_Date !== '') {
    jobDate = formatDate(job.Start_Date);
  } else if (job.Request_Date && job.Request_Date !== '') {
    if (job.Category === 'Winterizing' && job.Haul_Date === '') {
      jobDate = formatDate(job.Request_Date) + ' (No Haul Date)';
    } else if (job.Category === 'Spring Commissioning' && job.Launch_Date === '') {
      jobDate = formatDate(job.Request_Date) + ' (No Launch Date)';
    } else {
      jobDate = formatDate(job.Request_Date);
    }
  }
  
  if (!jobDate) {
    jobDate = 'Date Not Available';
  }

  requestDateP.innerHTML += jobDate;
  dateDiv.appendChild(requestDateP);

  const scheduledDateDiv = document.createElement('div');
  scheduledDateDiv.className = 'scheduled-date-info';
  const scheduleDateLabel = document.createElement('strong');
  scheduleDateLabel.textContent = 'Scheduled: ';

  const scheduleDateP = document.createElement('input');
  scheduleDateP.type = 'date';
  scheduleDateP.className = 'schedule-date-info';
  scheduleDateP.value = job.Schedule_Date ? job.Schedule_Date : '';
  if (!job.Schedule_Date || job.Schedule_Date === '') {
    scheduleDateP.style.color = 'red';
  }

  scheduleDateP.addEventListener('change', async (e) => {
    const jobId = e.target.closest('.job-item').getAttribute('data-id');
    const date = e.target.value;
    updateJobInfo(jobId, 'Schedule_Date', e.target.value);
    jobsArrayForWeek.forEach(job => {
      if(job.Job_ID?.toString() === jobId.toString()){
        job.Schedule_Date = date;
      }
    });
    applyFilters();
    applySorting();
    searchCall();
    searchJobs();
    searchSchedule();
    searchTech();
    updateWeekView();
  });

  scheduledDateDiv.appendChild(scheduleDateLabel);
  scheduledDateDiv.appendChild(scheduleDateP);
  dateDiv.appendChild(scheduledDateDiv);

  const statusPriorityDiv = document.createElement('div');
  statusPriorityDiv.className = 'status-priority-info hide-mobile';
  statusPriorityDiv.classList.add('hide-mobile');
  const statusP = document.createElement('p');
  const statusLabel = document.createElement('strong');
  statusLabel.textContent = 'Status: ';
  statusP.appendChild(statusLabel);
  statusP.innerHTML += job.Status;
  statusPriorityDiv.appendChild(statusP);

  const priorityP = document.createElement('p');
  const priorityLabel = document.createElement('strong');
  priorityLabel.textContent = 'Priority: ';
  priorityP.appendChild(priorityLabel);
  priorityP.innerHTML += job.Priority ? job.Priority : 'Normal';
  statusPriorityDiv.appendChild(priorityP);

  const noteDiv = document.createElement('div');
  noteDiv.className = 'notes-info';
  const jobNotesP = document.createElement('button');

  jobNotesP.addEventListener('click', async () => {
    const jobDiv = jobNotesP.closest('[data-id]');
    const jobId = jobDiv.getAttribute('data-id');
    const jobNotesData = await getJobData(jobId);

    document.getElementById('office-notes').classList.add('active');
    document.getElementById('note-job-id').value = jobId;

    const noteArea = document.getElementById('note-area');
    noteArea.innerHTML = '';

    if (jobNotesData.Notes) {
      Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
        const noteContainer = document.createElement('div');
        noteContainer.className = 'note-pair';
        noteContainer.id = key;

        const noteInfo = document.createElement('div');
        noteInfo.className = 'note-info';

        const noteText = document.createElement('p');
        noteText.textContent = note.Note;

        const techInfo = document.createElement('div');
        techInfo.className = 'note-info-tech';

        techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;

        const deleteButton = document.createElement('button');
        deleteButton.textContent = 'Delete';
        deleteButton.className = 'delete-note';
        deleteButton.addEventListener('click', async () => {
          await deleteNotes(jobId, key);
          noteContainer.remove();
        });

        noteInfo.appendChild(noteText);
        noteInfo.appendChild(techInfo);
        noteInfo.appendChild(deleteButton);
        noteContainer.appendChild(noteInfo);

        noteArea.appendChild(noteContainer);
      });
    }
  });

  jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;

  if (job.Notes) {
    jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
  }

  noteDiv.appendChild(jobNotesP);

  const mobileOpDiv = document.createElement('div');
  mobileOpDiv.className = 'mobile-op-info show-mobile';
  mobileOpDiv.innerHTML = job.OpCode + ": " + job.Description.split(':')[0];

  const mobileCustomerBoatDiv = document.createElement('div');
  mobileCustomerBoatDiv.className = 'mobile-customer-boat-info show-mobile';
  mobileCustomerBoatDiv.innerHTML = job.Customer_Name.split(', ')[0] + ' / ' + job.Boat_Name;

  jobDivMain.appendChild(mobileOpDiv);
  jobDivMain.appendChild(mobileCustomerBoatDiv);
  jobDivMain.appendChild(opCodeWorkOrderDiv);
  jobDivMain.appendChild(customerBoatDiv);
/*   jobDivMain.appendChild(categoryWorkOrderDiv); */
  jobDivMain.appendChild(dateDiv);
  jobDivMain.appendChild(descriptionTechDiv);
  jobDivMain.appendChild(statusPriorityDiv);

  jobDivNotes.appendChild(noteDiv);

  jobDiv.appendChild(jobDivStatus);
  jobDiv.appendChild(jobDivMain);
  jobDiv.appendChild(jobDivNotes);

  document.getElementById('jobList').appendChild(jobDiv);
  toastMessage('New job added','green')
  
  const notificationJob = document.createElement('div');
  notificationJob.className = 'notification-job';
  notificationJob.innerHTML = `<p>${job.Customer_Name} / ${job.Boat_Name}</p>: <p>${job.Description}</p> (Date: ${job.Start_Date})`;
  document.getElementById('notification-list').appendChild(notificationJob);
  document.getElementById('notification-count').innerHTML = parseInt(document.getElementById('notification-count').innerHTML) + 1;
}


document.getElementById('print-job-table').addEventListener('click', () => {
  printUnfilteredRowsInTable('jobList');
});

document.getElementById('print-tech-table').addEventListener('click', () => {
  printUnfilteredRowsInTable('tech-list');
});

document.getElementById('reset-exclude').addEventListener('click', () => {
  const excludedJobs = document.querySelectorAll('.excluded');
  excludedJobs.forEach(job => {
    console.log(job);
    const row = job.closest('.job-item');
    row.style.backgroundColor = 'initial';
    row.style.opacity = '1';
    row.classList.remove('excluded');
    job.querySelector('.exclude-checkbox').checked = false;
  });
  document.getElementById('reset-exclude').style.display = "none";
});

async function printUnfilteredRowsInTable(table) {
  try {
    
    const jobListDiv = document.getElementById(table);
    
    const jobRows = jobListDiv.children;


    const newWindow = window.open('', '_blank');

    let techs = '';
    let techNotes = '';

    if(table === 'jobList'){
      techs = '<th>Tech</th>';
      techNotes = '<th>Notes</th>';
    }
  
    let tableContent = `
      <html>
        <head>
          <title>Operation List</title>
          <style>
            body {
              margin: 1in;
              font-family: Arial, sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: center;
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
            th {
              background-color: #4CAF50;
              color: white;
            }
          </style>
        </head>
        <body>
          <h2>Job List</h2>
          <table>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Boat</th>
                <th>Description</th>
                <th>Date</th>
                ${techs}
                ${techNotes}
              </tr>
            </thead>
            <tbody>`;


    for (let row of jobRows) {
      
      if (!row.classList.contains('filtered') &&
          !row.classList.contains('haul-item') &&
          !row.classList.contains('complete-job') &&
          !row.classList.contains('excluded') &&
          row.style.display !== 'none') {

        const jobData = JSON.parse(row.getAttribute('data-jobInfo'));

        const customer = row.querySelector('.customer-name-jobs')?.textContent || '';
        const boat = row.querySelector('.boat-name-jobs')?.textContent || '';
        let description = '';

        if(table === 'jobList'){
        description = row.querySelector('.op-code-short-description-p')?.textContent || '';
        }else if(table === 'tech-list'){
        description = row.querySelector('.description-info').querySelector('p')?.textContent || '';
        }

        let date = row.querySelector('.start-date-info')?.textContent || '';
        date = date.replace('Start Date: ', '');

        let techData = '';
        let noteData = '';

        let tech = row.querySelector('.tech-info')?.dataset.selectedTech || ''
        let techNote = jobData.Tech_Notes || '';

        if(table === 'jobList'){
        techData = '<td>' + tech + '</td>';
        noteData = '<td>' + techNote + '</td>';
        }
    
        tableContent += `
          <tr>
            <td>${customer}</td>
            <td>${boat}</td>
            <td>${description}</td>
            <td>${date}</td>
            ${techData}
            ${noteData}
          </tr>`;
      }
    }

    tableContent += `
            </tbody>
          </table>
        </body>
      </html>`;

    newWindow.document.write(tableContent);
    newWindow.document.close();

    newWindow.focus();
    newWindow.print();

    newWindow.onafterprint = () => {
      newWindow.close();
    };

  } catch (error) {
    console.error("Error printing unfiltered job rows:", error);
  }
}

function showEstimatedHoursPopup(jobData, row) {
  return new Promise((resolve, reject) => {
  const popup = document.getElementById('estimatedHoursPopup');
  const input = document.getElementById('estimatedHoursInput');
  const submitButton = document.getElementById('submitEstimatedHours');
  const cancelButton = document.getElementById('cancelEstimatedHours');

  popup.style.display = 'flex';

  submitButton.onclick = async () => {
    const hours = parseFloat(input.value);
    console.log(hours);
    if (hours > 0) {
      await updateJobInfo(jobData.Job_ID, 'Estimated_Hours', hours);
      const rowData = JSON.parse(row.getAttribute('data-jobInfo'));
      rowData.Estimated_Hours = hours;
      row.setAttribute('data-jobInfo', JSON.stringify(rowData));
      popup.style.display = 'none';
      input.value = '';
      resolve();
    } else {
      console.log('Please enter a valid number of hours.');
    }
  };

  input.addEventListener('keypress', async (e) => {
    if (e.key === 'Enter') {
      const hours = parseFloat(input.value);
    console.log(hours);
    if (hours > 0) {
      await updateJobInfo(jobData.Job_ID, 'Estimated_Hours', hours);
      const rowData = JSON.parse(row.getAttribute('data-jobInfo'));
      rowData.Estimated_Hours = hours;
      row.setAttribute('data-jobInfo', JSON.stringify(rowData));
      popup.style.display = 'none';
      input.value = '';
      resolve();
    } else {
      console.log('Please enter a valid number of hours.');
    }
    }
  });

  cancelButton.onclick = () => {
    popup.style.display = 'none';
    input.value = ''; 
    reject('User cancelled');
  };
  });
}

async function populateJobsForCurrentTech() {
  document.getElementById('tech-list').innerHTML = '';
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (!userInfo || !userInfo.displayName) {
    console.error("User info is not available.");
    return;
  }

  const currentTech = userInfo.displayName.split(' ')[0];

  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('openDatabase', currentIndexedVersion);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });

    const transaction = db.transaction(['jobData'], 'readonly');
    const objectStore = transaction.objectStore('jobData');
    const jobListDiv = document.getElementById('tech-list');
    const jobsArray = [];

    await new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const job = cursor.value;
          if (job.Techs && job.Techs.includes(currentTech)) {
            jobsArray.push(job);
          }
          cursor.continue();
        } else {
          resolve();
        }
      };
    });

/*     jobsArray.sort((a, b) => {
      const aDate = a.Schedule_Date ? new Date(a.Schedule_Date) : new Date(a.Start_Date);
      const bDate = b.Schedule_Date ? new Date(b.Schedule_Date) : new Date(b.Start_Date);
      return aDate - bDate;
    }); */

    jobsArray.forEach((job) => {
      if(job.Status === 'Ready to Bill' || job.Status === 'Complete' || job.Status === 'Closed' || job.Status === 'Declined' || job.Status === 'Hold'
      ) {
        return;
      }

      if(job.Schedule_Date !== ''){
        if(new Date(job.Schedule_Date) > new Date()){
          return;
        };
      };

      const jobDiv = document.createElement('div');
      jobDiv.className = 'job-item';
      jobDiv.setAttribute('data-id', job.Job_ID);
      jobDiv.setAttribute('data-tech', job.Techs);
      jobDiv.setAttribute('data-techId', job.Techs ? (getTechId(job.Techs) || '') : '');
      jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));

      if(haulStatus[job.Boat_ID]){
        jobDiv.setAttribute('data-hauled', haulStatus[job.Boat_ID]);
      }

      jobDiv.addEventListener('click', (e) => { opCheck(e) });

      const customerBoatDiv = document.createElement('div');
      customerBoatDiv.className = 'customer-boat-info';
      customerBoatDiv.innerHTML = `<p class='customer-name-jobs'>${job.Customer_Name}</p><p class='boat-name-jobs'>${job.Boat_Name}</p>`;

      const descriptionDiv = document.createElement('div');
      descriptionDiv.className = 'description-info';
      let jobDescription = job.Description.split(':')[0];
      descriptionDiv.innerHTML = `<p>${jobDescription}</p>`;

      const statusDiv = document.createElement('div');
      statusDiv.className = 'status-info';
      statusDiv.style.backgroundColor = job.Status === 'In Progress' ? 'yellow' : 'green';
      statusDiv.innerHTML = ' ';

      const requestDate = document.createElement('div');
      requestDate.className = 'start-date-info hide-mobile';
      requestDate.innerHTML = job.Schedule_Date ? job.Schedule_Date : job.Start_Date;

      const noteDiv = document.createElement('div');
      noteDiv.className = 'notes-info';
      const jobNotesP = document.createElement('button');

      jobNotesP.addEventListener('click', async () => {

        const jobDiv = jobNotesP.closest('[data-id]');
        const jobId = jobDiv.getAttribute('data-id');
        const jobNotesData = await getJobData(jobId);


        document.getElementById('office-notes').classList.add('active');
        document.getElementById('note-job-id').value = jobId;

        const noteArea = document.getElementById('note-area');
        noteArea.innerHTML = '';

        if (jobNotesData.Notes) {
          Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
            const noteContainer = document.createElement('div');
            noteContainer.className = 'note-pair';
            noteContainer.id = key;

            const noteInfo = document.createElement('div');
            noteInfo.className = 'note-info';

            const noteText = document.createElement('p');
            noteText.textContent = note.Note;

            const techInfo = document.createElement('div');
            techInfo.className = 'note-info-tech';

            techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;

            const deleteButton = document.createElement('button');
            deleteButton.textContent = 'Delete';
            deleteButton.className = 'delete-note';
            deleteButton.addEventListener('click', async () => {
              await deleteNotes(jobId, key);
              noteContainer.remove();
            });

            noteInfo.appendChild(noteText);
            noteInfo.appendChild(techInfo);
            noteInfo.appendChild(deleteButton);
            noteContainer.appendChild(noteInfo);

            noteArea.appendChild(noteContainer);
          });
        }
      });

      jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;

      if (job.Notes) {
        // If job.Notes is not blank, apply a red filter
        jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
      }

      noteDiv.appendChild(jobNotesP);

      jobDiv.appendChild(statusDiv);
      jobDiv.appendChild(customerBoatDiv);
      jobDiv.appendChild(descriptionDiv);
      jobDiv.appendChild(requestDate);
      jobDiv.appendChild(noteDiv);
      jobListDiv.appendChild(jobDiv);
    });

    db.close();

  } catch (error) {
    console.error("Error fetching job data from IndexedDB:", error);
  }

  document.getElementById('assignment-check').style.display = 'none';

  if( document.getElementById('tech-list').innerHTML === ''){
    document.getElementById('tech-list').innerHTML = '<p class="no-assignments">No jobs scheduled</p>';
  }

}

let currentWeekStartJobs;
let currentMonday;

function normalizeDate(dateString) {
  const date = new Date(dateString);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function adjustToNextMondayIfWeekend(jobDate) {
  const dayOfWeek = jobDate.getDay(); // 0 = Sunday, 6 = Saturday
  
  if (dayOfWeek === 0) {
    
    jobDate.setDate(jobDate.getDate() + 1);
  } else if (dayOfWeek === 6) {
  
    jobDate.setDate(jobDate.getDate() + 2);
  }
  
  return jobDate;
}

function getWeekDates(date) {
  const monday = new Date(date);
  monday.setDate(date.getDate() - date.getDay() + 1); // Move to Monday of the current week
  monday.setHours(0, 0, 0, 0); // Set time to midnight (start of the day)

  const friday = new Date(monday); // Create a new date for Friday
  friday.setDate(monday.getDate() + 4); // Move to Friday
  friday.setHours(23, 59, 59, 999); // Set time to the end of the day on Friday

  return { monday, friday };
}


function formatDateForJobs(date) {
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
}

const jobsArrayForWeek = [];

async function populateJobsByWeek() {
  document.getElementById('schedule-list').innerHTML = '';
  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('openDatabase', currentIndexedVersion);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });

    const transaction = db.transaction(['jobData'], 'readonly');
    const objectStore = transaction.objectStore('jobData');
    const jobListDiv = document.getElementById('schedule-list');
    

    await new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const job = cursor.value;

            jobsArrayForWeek.push(job);

          cursor.continue();
        } else {
          resolve();
        }
      };
    });

    
    jobsArrayForWeek.sort((a, b) => {
      const aWorkOrder = a.Work_Order || '';
      const bWorkOrder = b.Work_Order || '';
      
      const workOrderComparison = aWorkOrder.localeCompare(bWorkOrder);
      
      if (workOrderComparison !== 0) {
        return workOrderComparison;
      }
    
      const aDate = a.Schedule_Date ? new Date(a.Schedule_Date) : new Date(a.Start_Date);
      const bDate = b.Schedule_Date ? new Date(b.Schedule_Date) : new Date(b.Start_Date);
      
      return aDate - bDate;
    });
    
    
    const dateNavDiv = document.createElement('div');
    dateNavDiv.id = 'date-navigation';
    dateNavDiv.innerHTML = `
      <button id="prev-week" style="display: none;">&lt;</button>
      <span id="week-dates" style="cursor: pointer;"></span>
      <button id="next-week">&gt;</button>
    `;
    jobListDiv.parentNode.insertBefore(dateNavDiv, jobListDiv);

    const weekDatesSpan = document.getElementById('week-dates');
    const prevWeekBtn = document.getElementById('prev-week');
    const nextWeekBtn = document.getElementById('next-week');
    const datePicker = document.getElementById('date-picker'); 


    // Initialize current week
    currentWeekStartJobs = new Date();

    // Event listeners for week navigation
    prevWeekBtn.addEventListener('click', () => {
      currentWeekStartJobs.setDate(currentWeekStartJobs.getDate() - 7);
      updateWeekDisplay();
      filterAndDisplayJobs();
    });

    nextWeekBtn.addEventListener('click', () => {
      currentWeekStartJobs.setDate(currentWeekStartJobs.getDate() + 7);
      updateWeekDisplay();
      filterAndDisplayJobs();
    });

    weekDatesSpan.addEventListener('click', () => {
      const dateModal = document.getElementById('date-picker-modal')
      dateModal.style.display = 'flex';
      dateModal.focus();
    });

    // When the user selects a date from the date picker
    datePicker.addEventListener('change', () => {
      const selectedDate = new Date(datePicker.value);
      currentWeekStartJobs = selectedDate;
      document.getElementById('date-picker-modal').style.display = 'none';
      updateWeekDisplay();
      filterAndDisplayJobs();
    });

    function updateWeekDisplay() {
      const { monday, friday } = getWeekDates(currentWeekStartJobs);
      weekDatesSpan.textContent = `${formatDateForJobs(monday)} - ${formatDateForJobs(friday)}`;
      
      const today = new Date();
      currentMonday = getWeekDates(today).monday;
      prevWeekBtn.style.display = currentWeekStartJobs > currentMonday ? 'inline' : 'none';

    }

    function filterAndDisplayJobs() {

      const { monday, friday } = getWeekDates(currentWeekStartJobs);
      jobListDiv.innerHTML = '';

      jobsArrayForWeek.forEach((job) => {
 
        if (!job.Description) {
          return;
        }
            
        if (job.Status === 'Ready to Bill' || job.Status === 'Complete' || job.Status === 'Closed' || job.Status === 'Declined' || job.Status === 'Hold') {
          return;
        }
   
        let jobDate = job.Schedule_Date ? normalizeDate(job.Schedule_Date) : normalizeDate(job.Start_Date);   
        
         
        jobDate = adjustToNextMondayIfWeekend(jobDate);

           
        if (jobDate.getTime() >= monday.getTime() && jobDate.getTime() <= friday.getTime()) {
            const jobDiv = createJobRow(job);
            jobListDiv.appendChild(jobDiv);

        }
      });
    }
    
    updateWeekDisplay();
    filterAndDisplayJobs();
    db.close();
    console.log('Jobs by week populated');
  } catch (error) {
    console.error("Error fetching job data from IndexedDB:", error);
  }
}

async function updateJobDatesBasedOnBoatId(customerId, boatId, index, value){
  try {

    const elements = document.querySelectorAll('[data-boatid="' + boatId + '"]');
    elements.forEach( async element => {
      const jobData = JSON.parse(element.getAttribute('data-jobInfo'));
      console.log(jobData);

      if(index === 'Haul_Date'){
        jobData.Haul_Date = value;

        if(jobData.Category === 'Winterizing'){
          jobData.Start_Date = value;
        }
      }

      if(index === 'Launch_Date'){
        jobData.Launch_Date = value;
      
        if(jobData.Category === 'Spring Commissioning'){
          jobData.Start_Date = value;
        }
      }

      if(element.classList.contains('job-item')){


        if(jobData.Category === 'Winterizing'){
        await updateJobInfo(jobData.Job_ID, 'Haul_Date', value);
        await updateJobInfo(jobData.Job_ID, 'Start_Date', value);
        }

        if(jobData.Category === 'Spring Commissioning'){
        await updateJobInfo(jobData.Job_ID, 'Launch_Date', value);
        await updateJobInfo(jobData.Job_ID, 'Start_Date', value);
        }

        const dateElement = element.querySelector('.start-date-info');
        let jobDate;

        if (jobData.Schedule_Date && jobData.Schedule_Date !== '') {
          jobDate = await formatDate(jobData.Schedule_Date);
        } else if (jobData.Start_Date && jobData.Start_Date !== '') {
          jobDate = await formatDate(jobData.Start_Date);
        } else if (jobData.Request_Date && jobData.Request_Date !== '') {
          if (jobData.Category === 'Winterizing' && jobData.Haul_Date === '') {
            jobDate = await formatDate(jobData.Request_Date) + ' (No Haul Date)';
            requestDateLabel.textContent = 'Request Date: ';
            requestDateLabel.style.color = 'red';
          } else if (jobData.Category === 'Spring Commissioning' && jobData.Launch_Date === '') {
            jobDate = await formatDate(jobData.Request_Date) + ' (No Launch Date)';
            requestDateLabel.textContent = 'Request Date: ';
            requestDateLabel.style.color = 'red';
          } else {
            jobDate = await formatDate(jobData.Request_Date);
          }
        }
        dateElement.innerHTML = jobDate;

        element.setAttribute('data-jobInfo', JSON.stringify(jobData));
      }

      if(element.classList.contains('table-item')){
          const dateElement = element.querySelector('.work-order-start-date');

          if(jobData.Category === 'Winterizing'){
            dateElement.innerHTML = await formatDate(jobData.Haul_Date);
          }

          if(jobData.Category === 'Spring Commissioning'){
            dateElement.innerHTML = await formatDate(jobData.Launch_Date);
          }

          element.setAttribute('data-jobInfo', JSON.stringify(jobData));
      }

    });
  }catch(error){
  console.error("Error updating job data:", error);
}
}

async function updateJobRow(jobId, key, value, dataSet) {

  try {
    let jobRow = document.querySelectorAll(`.job-item[data-id="${jobId}"]`);

    if (!jobRow) {
        console.error(`Job with ID ${jobId} not found`);
        return;
    }

    jobRow.forEach(row => {

      let currentJobInfo = JSON.parse(row.getAttribute('data-jobInfo'));

      currentJobInfo[key] = value;
      row.setAttribute('data-jobInfo', JSON.stringify(currentJobInfo));
  
      // Update HTML elements
      if (key === 'Customer_Name') {
        row.querySelector('.customer-name-jobs').textContent = value;
      }
      if (key === 'Boat_Name') {
        row.querySelector('.boat-name-jobs').textContent = value;
      }
      if (key === 'Category') {
        row.querySelector('.category-info').textContent = value;
      }
      if (key === 'Work_Order') {
        row.querySelector('.work-order-jobs').innerHTML = `<strong>WO: </strong>${value}`;
      }
      if (key === 'Description') {
        const jobDescription = value.includes(':') ? value.split(':')[0] : currentJobInfo.Short_Description;
        row.querySelector('.description-info p:first-child').textContent = jobDescription;
      }
      if (key === 'Techs' && row.querySelector('.tech-info')) {
        row.querySelector('.tech-info').value = value;
        row.querySelector('.tech-info').setAttribute('data-selected-tech', value);

      }
      if (key === 'Start_Date') {
        const dateElement = row.querySelector('.start-date-info');
          let jobDate;
          jobDate = new Date(value).getFullYear() === new Date().getFullYear() ?
          value.split('-').slice(1).join('-') : value;
          dateElement.innerHTML = `<strong>Start: </strong>${jobDate}`;
      }
      if (key === 'Schedule_Date' && row.querySelector('.schedule-date-info')) {
        const scheduleDateInput = row.querySelector('.schedule-date-info');
        scheduleDateInput.value = value;
        
        if(value === ''){
          let jobDate = currentJobInfo['Start_Date'];
          jobDate = jobDate.split('-')[1] + '/' + jobDate.split('-')[2] + '/' + jobDate.split('-')[0];
          row.querySelector('.start-date-info').innerHTML = `<strong>Start: </strong>${jobDate}`;
        }else{
          let jobDate;
          jobDate = new Date(value).getFullYear() === new Date().getFullYear() ?
          value.split('-')[1] + '/' + value.split('-')[2] + '/' + value.split('-')[0] : value;
          row.querySelector('.start-date-info').innerHTML = `<strong>Start: </strong>${jobDate}`;
        }
        scheduleDateInput.style.color = value ? '' : 'red';
        applySorting();
      }
      if (key === 'Status') {
        row.querySelector('.status-priority-info p:first-child').innerHTML = `<strong>Status: </strong>${value}`;
        const statusIndicator = row.querySelector('.status-info');
        statusIndicator.style.backgroundColor = value === 'In Progress' ? 'yellow' : 'green';
      }
      if (key === 'Priority') {
        row.querySelector('.status-priority-info p:last-child').innerHTML = `<strong>Priority: </strong>${value || 'Normal'}`;
      }
      if(key === 'Notes'){
        const jobNotesP = row.querySelector('.notes-info button');
        if (value) {
          jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
        } else {
          jobNotesP.querySelector('img').style.filter = '';
        }
      }
    })
    
  } catch (error) {
    console.error("Error updating job row:", error);
  }

}

async function mainTable() {

  // The load() function is setting up the Scheduler and also the Tech List
/*  await load(); */

  try {
    document.getElementById('table-content').innerHTML = '';
    document.getElementById("table-loading-icon").style.display = "flex";

    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const store = transaction.objectStore('jobData');

      const getAllRequest = store.getAll();

      getAllRequest.onerror = (event) => {
        console.error('Error fetching job data:', event.target.errorCode);
      };

      getAllRequest.onsuccess = async (event) => {
        const dataArray = event.target.result;

        var awaiting = [];
        var order = [];
        var closed = [];
        var operationsByWorkOrder = {};

        dataArray.forEach(data => {

          const workOrderNumber = data['Work_Order'];
          const status = data['Status'];

          if (!operationsByWorkOrder[workOrderNumber]) {
            operationsByWorkOrder[workOrderNumber] = [];
          }

          operationsByWorkOrder[workOrderNumber].push(status);
    
 
          if(data.Category){

            if(!workOrderOpTypes[data.Work_Order]){
              workOrderOpTypes[data.Work_Order] = [];
            }

            workOrderOpTypes[data.Work_Order].push(data.Op_Type);
          }

          if (data['Techs'] && (data['Status'] === 'On Work Schedule' || data['Status'] === 'In Progress')) {
            if (!techInfo[data['Techs']]) {
              techInfo[data['Techs']] = [];
            }
            techInfo[data['Techs']].push([data['Job_ID'], data['Customer_Name'], data['Boat_Name'], data['Work_Order'], data['Description'], data['Status'], data['Notes'], data['Start_Date'], data['Transfer_to_Billing']]);
          }
        });
        
        countObject = dataArray.reduce((accumulator, item) => {
          const workOrder = item.Work_Order;
          const dependency = item.Operation_Dependency;

          if (!accumulator[workOrder]) {
            accumulator[workOrder] = {
              Count: 0,
              Dependency: 0
            };
          }

          if (item.Status && item.Description) {

            accumulator[workOrder].Count += 1;
            accumulator[workOrder].Dependency += Number(dependency);
            accumulator[workOrder].Customer_Name = item.Customer_Name;
            accumulator[workOrder].Customer_ID = item.Customer_ID;

          }

          return accumulator;
        }, {});

        /* window.workOrderData = countObject; */

        const workOrderStatuses = await setUpWorkOrderStatuses(operationsByWorkOrder);
        
        var wo = dataArray.map(data => data['Work_Order']);
        var unique = [...new Set(wo)];

        var woList = [];

        for (let workOrder of unique) {
          let jobsForWorkOrder = dataArray.filter(data => String(data['Work_Order']).trim() === String(workOrder).trim());

          // Find the first non-declined job for this work order
          let nonDeclinedJob = jobsForWorkOrder.find(job => job['Status'] !== 'Declined' && job['Status'] !== 'Hold');
          
          if (nonDeclinedJob) {
            woList.push(nonDeclinedJob);
          }
        }

   
        renderTable(woList, workOrderStatuses);
        
        console.log('Processing job data complete');

      };
    };

    await populateJobList();
    document.querySelectorAll('.operation-container-row').forEach((element) => {
      element.remove();
    });

  } catch (error) {
    console.error('Error fetching job data:', error);
  }
}

async function setUpWorkOrderStatuses(operationsByWorkOrder){

  try {
    let workOrderStatuses = {};

        Object.keys(operationsByWorkOrder).forEach(workOrderNumber => {
          const operations = operationsByWorkOrder[workOrderNumber];
        
          // Initialize the work order with default status
          let workOrderStatus = {
            Work_Order: workOrderNumber,
            Status: 'Needs Scheduling'
          };
        
          // Determine the status based on the operations
          if (operations.every(status => status === "Closed" || status === "Declined")) {
            // All statuses are "Closed" or "Declined"
            workOrderStatus.Status = 'Closed';
          } else if (operations.every(status => 
            ["Ready to Bill", "Complete", "Closed"].includes(status))) {
            // All statuses are "Ready to Bill", "Complete", or "Closed"
            workOrderStatus.Status = 'Ready to Bill';
          } else if (operations.every(status => 
            ["On Work Schedule", "Estimate", "Declined"].includes(status))) {
            // All statuses are "On Work Schedule", "Estimate", or "Declined"
            workOrderStatus.Status = 'Needs Scheduling';
          } else {
            // Default case for mixed statuses
            workOrderStatus.Status = 'In Progress';
          }
        
          // Store the work order status
          workOrderStatuses[workOrderNumber] = workOrderStatus;
        });
        return workOrderStatuses;
      }catch(error){
        console.error("Error setting up work order statuses:", error);
      }
}

async function renderTable(dataList, workOrderStatuses) {
  const dataSection = document.getElementById('table-content');
  dataSection.innerHTML = ''; // Clear existing content

  // Array to hold all created rows
  const rows = [];

  for (const data of dataList) {
    
    if (!data['Status'] ||
      (countObject[data['Work_Order']] && countObject[data['Work_Order']].Count == 0) ||
      data['Status'] === 'Declined') {
      continue;
    }

    // Await the result of createTableRow since it's an async function
    const row = await createTableRow(data, workOrderStatuses);

    // Collect the row if it's valid
    if (row) {
      rows.push({ row, data }); // Store the row and its associated data for sorting
    }
  }

  // Sort the rows array based on the provided logic
  rows.sort((a, b) => {
    let aDate = a.data['Start_Date'] ? new Date(a.data['Start_Date']) : new Date(a.data['Request_Date']);
    let bDate = b.data['Start_Date'] ? new Date(b.data['Start_Date']) : new Date(b.data['Request_Date']);

    if (aDate && bDate) {
      return aDate.getTime() - bDate.getTime();
    } else if (aDate) {
      return -1;
    } else if (bDate) {
      return 1;
    } else {
      return 0;
    }
  });

  // Use a DocumentFragment to batch DOM updates
  const fragment = document.createDocumentFragment();

  // Append the sorted rows to the fragment
  rows.forEach(({ row }) => {
    fragment.appendChild(row);
  });

  document.getElementById("table-loading-icon").style.display = "none";
  dataSection.appendChild(fragment);

  dataSection.addEventListener('click', handleRowClick);
}

async function createTableRow(data, workOrderStatuses) {
  let customerData = null;
  let boatData = null;

  try {
    const customerAndBoatData = await getCustomerDataById(data['Customer_ID'], data['Boat_ID'], data);
    customerData = customerAndBoatData.customerData;
    boatData = customerAndBoatData.boatData;
  } catch (error) {
    console.error('Error fetching customer and boat data:', error);
    console.log('Data causing issue:', data);
    // You can return here if you don't want to proceed with incomplete data.
    return; // Or decide to handle default values for missing data below.
  }

  if (!customerData || !boatData) {
    console.warn('Incomplete data for row:', { customerData, boatData, data });
    return; // Skip creating the row if critical data is missing.
  }

  const row = document.createElement("div");
  data.Status = workOrderStatuses[data['Work_Order']].Status ? workOrderStatuses[data['Work_Order']].Status : data.Status;

  row.setAttribute('data-jobInfo', JSON.stringify(data));
  row.setAttribute('data-customerId', data['Customer_ID']);
  row.setAttribute('data-boatId', data['Boat_ID']);
  row.setAttribute('data-workOrder', data['Work_Order']);
  row.setAttribute('data-optypes', JSON.stringify(workOrderOpTypes[data['Work_Order']]));

  if (haulStatus[data['Boat_ID']]) {
    row.setAttribute('data-hauled', haulStatus[data['Boat_ID']]);
  }
  
  row.className = 'table-item';

  if (workOrderStatuses[data['Work_Order']].Status === 'Ready to Bill') {
    row.classList.add('complete-job');
  }

  if (workOrderStatuses[data['Work_Order']].Status === 'Closed') {
    row.classList.add('closed-job');
  }

  const statusVisual = document.createElement('div');
  statusVisual.className = 'status-info';

  switch (workOrderStatuses[data['Work_Order']].Status) {
    case 'In Progress':
      statusVisual.style.backgroundColor = 'yellow';
      break;
    case 'Ready to Bill':
    case 'Complete':
      statusVisual.style.backgroundColor = 'red';
      break;
    case 'Closed':
      statusVisual.style.backgroundColor = 'rgb(184, 140, 140)';
      break;
    case 'Ready to Frame':
    case 'Vacuumed':
    case 'Framed':
    case 'Covered':
    case 'Tightened':
      statusVisual.style.backgroundColor = 'orange';
      break;
    default:
      statusVisual.style.backgroundColor = 'green';
  }

  statusVisual.innerHTML = '&nbsp;';

  const infoDiv = document.createElement('div');
  infoDiv.className = 'info-div';

  const customerDiv = document.createElement('div');
  customerDiv.className = 'job-group';
  customerDiv.innerHTML = `
    <span class="bold-font">${data['Customer_Name'] || 'Unknown Customer'}</span>
    <span class="boat-name-jobs">${data['Boat_Name'] || 'Unknown Boat'}</span>
  `;

  const descDiv = document.createElement('div');
  descDiv.className = 'job-group';
  descDiv.innerHTML = `
    <span class="work-order-description">${data['Short_Description']}</span>
    <span class="group-row">
      <span class="bold-font">WO: </span>
      <span>${data['Work_Order']}</span>
    </span>
  `;

  const operationsDiv = document.createElement('div');
  operationsDiv.className = 'job-group hide-mobile';
  operationsDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Operations: </span>
      <span class="operation-count">${countObject[data['Work_Order']] ? countObject[data['Work_Order']].Count : 0}</span>
    </span>
    <span class="group-row">
      <span class="bold-font">Date: </span>
      <span class='work-order-start-date'>${data['Start_Date'] ? data['Start_Date'] : data['Request_Date']}</span>
    </span>
  `;

  const statusDiv = document.createElement('div');
  statusDiv.className = 'job-group hide-mobile';
  statusDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Status: </span>
      <span class="status-info-p">${workOrderStatuses[data['Work_Order']].Status}</span>
    </span>
    <span class="group-row">
      <span class="bold-font">Priority: </span>
      <span>${data['Priority']}</span>
    </span>
  `;

  infoDiv.appendChild(customerDiv);
  infoDiv.appendChild(descDiv);
  infoDiv.appendChild(operationsDiv);
  infoDiv.appendChild(statusDiv);

  row.appendChild(statusVisual);
  row.appendChild(infoDiv);

  return row;
}

async function createNewTableRow(data, opCount) {
  let customerData = null;
  let boatData = null;

  if(document.querySelector('.table-item[data-workOrder="' + data['Work_Order'] + '"]')){
    return;
  }

  try {
    const customerAndBoatData = await getCustomerDataById(data['Customer_ID'], data['Boat_ID'], data);
    customerData = customerAndBoatData.customerData;
    boatData = customerAndBoatData.boatData;
  } catch (error) {
    console.error('Error fetching customer and boat data:', error);
    console.log('Data causing issue:', data);
    // You can return here if you don't want to proceed with incomplete data.
    return; // Or decide to handle default values for missing data below.
  }

  if (!customerData || !boatData) {
    console.warn('Incomplete data for row:', { customerData, boatData, data });
    return; // Skip creating the row if critical data is missing.
  }

  const row = document.createElement("div");

  row.setAttribute('data-jobInfo', JSON.stringify(data));
  row.setAttribute('data-customerId', data['Customer_ID']);
  row.setAttribute('data-boatId', data['Boat_ID']);
  row.setAttribute('data-workOrder', data['Work_Order']);
  row.setAttribute('data-optypes', JSON.stringify(workOrderOpTypes[data['Work_Order']]));

  if (haulStatus[data['Boat_ID']]) {
    row.setAttribute('data-hauled', haulStatus[data['Boat_ID']]);
  }
  
  row.className = 'table-item';

  if (data.Status === 'Ready to Bill') {
    row.classList.add('complete-job');
  }

  if (data.Status === 'Closed') {
    row.classList.add('closed-job');
  }

  const statusVisual = document.createElement('div');
  statusVisual.className = 'status-info';

  switch (data.Status) {
    case 'In Progress':
      statusVisual.style.backgroundColor = 'yellow';
      break;
    case 'Ready to Bill':
    case 'Complete':
      statusVisual.style.backgroundColor = 'red';
      break;
    case 'Closed':
      statusVisual.style.backgroundColor = 'rgb(184, 140, 140)';
      break;
    case 'Ready to Frame':
    case 'Vacuumed':
    case 'Framed':
    case 'Covered':
    case 'Tightened':
      statusVisual.style.backgroundColor = 'orange';
      break;
    default:
      statusVisual.style.backgroundColor = 'green';
  }

  statusVisual.innerHTML = '&nbsp;';

  const infoDiv = document.createElement('div');
  infoDiv.className = 'info-div';

  const customerDiv = document.createElement('div');
  customerDiv.className = 'job-group';
  customerDiv.innerHTML = `
    <span class="bold-font">${data['Customer_Name'] || 'Unknown Customer'}</span>
    <span class="boat-name-jobs">${data['Boat_Name'] || 'Unknown Boat'}</span>
  `;

  const descDiv = document.createElement('div');
  descDiv.className = 'job-group';
  descDiv.innerHTML = `
    <span class="work-order-description">${data['Short_Description']}</span>
    <span class="group-row">
      <span class="bold-font">WO: </span>
      <span>${data['Work_Order']}</span>
    </span>
  `;

  const operationsDiv = document.createElement('div');
  operationsDiv.className = 'job-group hide-mobile';
  operationsDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Operations: </span>
     <span class="operation-count">${
  countObject[data['Work_Order']] 
    ? countObject[data['Work_Order']].Count 
    : (opCount !== undefined && opCount !== null 
        ? opCount 
        : 0)
}</span>

    </span>
    <span class="group-row">
      <span class="bold-font">Date: </span>
      <span class='work-order-start-date'>${data['Start_Date'] ? data['Start_Date'] : data['Request_Date']}</span>
    </span>
  `;

  const statusDiv = document.createElement('div');
  statusDiv.className = 'job-group hide-mobile';
  statusDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Status: </span>
      <span class="status-info-p">${data.Status}</span>
    </span>
    <span class="group-row">
      <span class="bold-font">Priority: </span>
      <span>${data['Priority']}</span>
    </span>
  `;

  infoDiv.appendChild(customerDiv);
  infoDiv.appendChild(descDiv);
  infoDiv.appendChild(operationsDiv);
  infoDiv.appendChild(statusDiv);

  row.appendChild(statusVisual);
  row.appendChild(infoDiv);

  console.log(row);

  document.getElementById('table-content').appendChild(row);
  toastMessage('New job added', 'green');

}

function handleRowClick(e) {

  const row = e.target.closest('.table-item');
  if (!row) return;

  selectWorkOrder(e);
}

function closeAllOperations(e){
  
  const jobList = e.target.closest('.table-item').getAttribute('data-jobList');


  updateMultipleJobStatuses(jobList, "Closed");

}

document.getElementById('log-out-button').addEventListener('click', logoutUser);

/* document.getElementById('open-filter').addEventListener('click', () => {
  
  const value = document.getElementById('filter-box').style.display;

  if(value == 'none' || !value){
    document.getElementById('filter-box').style.display = 'flex';
  }else{
    document.getElementById('filter-box').style.display = 'none';
  };
}); */

document.getElementById('codeSearch').addEventListener('input', () => {
  codeSearch()
})

document.getElementById('search').addEventListener('input', searchCall)

document.getElementById('customerDatabaseButton').addEventListener('click', async () => {

  try {
    await fetchCustomerDataAndGenerateTable();
    document.getElementById('Add').style.visibility = "hidden"
    document.getElementById('customerData').style.display = "flex"
    document.getElementById("customer-search").focus()
  } catch (error) {
    console.error('Error fetching customer data:', error);
  };
});

document.getElementById('customerDatabaseEmail').addEventListener('click', customerEmail)

document.getElementById('open-add-labor').addEventListener('click', () => {
  console.log(new Date());
  console.log(new Date().toISOString());
  console.log(new Date().toISOString().split('T')[0]);
  document.getElementById('labor-entry-date').value = new Date().toISOString().split('T')[0];
  document.getElementById('labor-section').style.display = 'flex';
  document.getElementById('labor-entry-description').innerHTML = '';
});

document.getElementById('open-add-parts').addEventListener('click', () => {
  document.getElementById('part-entry-date').value = new Date().toISOString().split('T')[0];
  document.getElementById('parts-section').style.display = 'flex';
  document.getElementById('parts-table-body').innerHTML = '';
  initializePartsTable();
});


document.getElementById('add-labor').addEventListener('click', submitLabor);

document.getElementById('add-part').addEventListener('click', () => {
  addParts();
});

document.getElementById('save-parts').addEventListener('click', () => {
  document.getElementById('parts-section').style.display = 'none'; 
});

document.addEventListener('DOMContentLoaded', function () {

  retrieveAndLogAuthToken();

  // Add button placeholder
  document.querySelector('.add-button-placeholder').addEventListener('click', addOpen);

  // Schedule button
  document.getElementById('scheduleButton').addEventListener('click', () => {
    document.getElementById('tech-data').style.display = 'flex';
  });

  // Add open button
  document.getElementById('myBtn').addEventListener('click', addOpen);

  // Calculator button
  document.getElementById('calc-button').addEventListener('click', function () {
    document.getElementById('calcBody').style.display = 'flex';
  });

  // Close button for pop-out
  document.getElementById('close-btn').addEventListener('click', function () {
    // You need to define what should happen when this button is clicked
  });

  // Calendar close button
  document.getElementById('calendar-close-btn').addEventListener('click', function () {
    // You need to define what should happen when this button is clicked
  });

  // Generate general work order button
  document.getElementById('general-work-order-button').addEventListener('click', function () {
    getWorkOrderNumber('general');
  });

  // Generate winter work order button
  document.getElementById('winter-work-order-button').addEventListener('click', function () {
    getWorkOrderNumber('winter');
  });

  // Generate spring work order button
  document.getElementById('spring-work-order-button').addEventListener('click', function () {
    getWorkOrderNumber('spring');
  });

  document.getElementById('add-btn').addEventListener('click', () => {
    document.getElementById('activeJobs').innerHTML = '';
    document.getElementById('work-order-type-select').value = 'general-work-order'
    currentOpCodes = []
    AddRow();
  });

  // Complete and close add button
  document.getElementById('add-ops-btn').addEventListener('click', closeAdd);

  // Add job button
  document.getElementById('addJobButton').addEventListener('click', function () {
    document.querySelector('.jobForm').scrollTop = 0;
    addJob();
  });

  // Delete job button
  document.getElementById('deleteJobButton').addEventListener('click', function () {
    deleteJob(this);
  });

  document.getElementById('mobile-menu-button').addEventListener('click', () => {
    document.querySelector('.page-select').classList.toggle('hide-mobile');
  })

  // Add part order button
  document.getElementById('add-part-order').addEventListener('click', () => {
    addPartOrder();
  });

  document.getElementById('open-part-order').addEventListener('click', () => {
    partBox()
  });

  // Clear part order button
  document.getElementById('clear-part-btn').addEventListener('click', clearPartOrder);

  // Add new customer button
  document.getElementById('add-customer-btn').addEventListener('click', addNewCustomer);

  // Job complete button
  document.querySelector('.job-complete-button').addEventListener('click', () => {
    submitOpen();
  });

  document.getElementById('entryButton').addEventListener('click', showStatus);

  document.getElementById('holdButton').addEventListener('click', holdOperation);

  document.getElementById('removeHoldButton').addEventListener('click', removeHoldOperation);

  document.getElementById('deleteButton').addEventListener('click', deleteOperation)

  document.getElementById('entryButtonMobile').addEventListener('click', showStatus);

  document.getElementById('holdButtonMobile').addEventListener('click', holdOperation);

  document.getElementById('deleteButtonMobile').addEventListener('click', deleteOperation)

  document.getElementById('partBoxButtonMobile').addEventListener('click', partBox)

  document.getElementById('change-view-button').addEventListener('click', function (event) {
    event.stopPropagation();
    toggleView('change-view', this, event);
  });

  document.getElementById('filter-view-button').addEventListener('click', function (event) {
    event.stopPropagation();
    toggleView('filter-view', this, event);
  });

  document.getElementById('sort-view-button').addEventListener('click', function (event) {
    event.stopPropagation();
    toggleView('sort-view', this, event);
  });


  document.addEventListener('click', function (event) {
    if(event.target.closest('button')?.id === 'log-out-button-no-role' || event.target.closest('button')?.id === 'log-out-button' || event.target.closest('button')?.id === 'reload-button-no-role'){
      return;
    }
    closeOpenViews(event.target);

    const updateDiv = document.getElementById('Update');
    const openSlidePanel = updateDiv.classList.contains('active');

    if (openSlidePanel && 
        !updateDiv.contains(event.target) && 
        !event.target.closest('[class*="slide-panel-"]') &&
        !event.target.closest('#partsModal') &&
        !event.target.closest('#laborAndPartsQS') &&
        !event.target.closest('#kit-select-modal') &&
        !event.target.closest('#kit-parts-modal') &&
        !event.target.closest('.part-popup') &&
        !event.target.closest('#shrinkwrap-menu') &&
        !event.target.closest('#billing-modal') ){

      event.preventDefault();
      
      updateDiv.classList.remove('active');
      document.querySelectorAll('.slide-panel-static').forEach(panel => {
        panel.style.display = 'none';
      });

      document.querySelectorAll('.slide-panel-static-sub').forEach(panel => {
        panel.style.display = 'none';
      });

      if(document.getElementById('partsModal').style.display === 'flex'){
        document.getElementById('partsModal').style.display = 'none';
      }
    }
});


  function toggleView(viewId, button,) {
    var view = document.getElementById(viewId);

    // Toggle classes using toggle method
    view.classList.toggle('hideBox');
    view.classList.toggle('showBox');

    // Update position and size if shown
    if (view.classList.contains('showBox')) {
      var buttonRect = button.getBoundingClientRect();

      // Get the computed style of the view
      var viewStyle = window.getComputedStyle(view);
      var viewWidth = viewStyle.width;

      // Add transition to button
      button.style.transition = 'width 0.3s ease-in-out';
      button.style.backgroundColor = 'var(--nav-background-color)';
      button.style.color = 'white';

      // Set the button width to match the view
      setTimeout(() => {
        button.style.width = viewWidth;
      }, 0);

      // Make the view visible
      view.style.visibility = 'visible';
    } else {
      // Animate button back to original width
      button.style.transition = 'width 0.3s ease-in-out';
      button.style.width = '';
      button.style.backgroundColor = '';
      button.style.color = 'black';
      view.style.visibility = 'hidden';
    }
  }

  document.querySelector('.page-buttons').addEventListener('click', () => {
    const menuButton = document.getElementById('mobile-menu-button');
    if (!menuButton.classList.contains('hide-mobile')) {
      menuButton.click();
      menuButton.querySelector('svg').classList.toggle('toggle');
    }
  });

  document.getElementById('map-open-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
    document.getElementById('maps').style.display = 'flex';
    document.getElementById('maps').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Maps";
    document.getElementById('maps').querySelector('iframe').contentWindow.postMessage({ action: 'renderMaps' }, '*');
  });

  document.getElementById('customers-open-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
    document.getElementById('customers').style.display = 'flex';
    document.getElementById('customers').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Customer Database";
  });

  document.getElementById('open-work-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
    document.getElementById('open-work').style.display = 'flex';
    document.getElementById('open-work').style.visibility = 'visible';
    document.getElementById('work-order-view').style.display = 'flex';
    document.getElementById('current-page').innerHTML = "Work Orders";
    switchView(currentJobView);
  });

  document.getElementById('spring-open-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    const viewBox = document.getElementById('change-view')
    closeOpenViews();
    viewBox.classList.remove('showBox');

    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
    document.getElementById('spring').style.display = 'flex';
    document.getElementById('spring').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Spring Commissioning";
  });

  document.getElementById('hauling-open-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    const viewBox = document.getElementById('change-view')
    closeOpenViews();
    viewBox.classList.remove('showBox');

    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
    document.getElementById('hauling').style.display = 'flex';
    document.getElementById('hauling').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Hauling";
  });

  document.getElementById('mooring-menu-open-button').addEventListener('click', () => {

    const elements = document.querySelectorAll('.page-view')

    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });

    document.getElementById('rentalDatabase').style.display = 'flex';
    document.getElementById('rentalDatabase').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Rental Database";
  });


  document.getElementById('inventory-menu-open-button').addEventListener('click', () => {

    const elements = document.querySelectorAll('.page-view')

    elements.forEach(page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });

    document.getElementById('inventoryDatabase').style.display = 'flex';
    document.getElementById('inventoryDatabase').style.visibility = 'visible';
    document.getElementById('current-page').innerHTML = "Inventory Database";
  });

  /* document.getElementById('schedule-open-button').addEventListener('click', () => {

    const elements = document.querySelectorAll('.page-view')
 
    elements.forEach( page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
 
     document.getElementById('schedule').style.display = 'flex';
     document.getElementById('schedule').style.visibility = 'visible';
     document.getElementById('current-page').innerHTML = "Work List";
    }); */

});

function isMobile() {
  return window.innerWidth <= MOBILE_BREAKPOINT;
}

// Function to safely apply focus only on non-mobile devices
function focusIfNotMobile(element) {
  if (!isMobile()) {
    element.focus();
  }
}

function switchView(view) {

  currentJobView = view;

/*   if(view === 'workOrder'){
    document.getElementById('billing-filter-section').style.display = 'flex';
  }else{
    document.getElementById('billing-filter-section').style.display = 'none';
  } */

  const workOrderView = document.getElementById('work-order-view');
  const workOrderSearch = document.getElementById('search');
  const jobView = document.getElementById('job-view');
  const jobSearch = document.getElementById('job-search');
  const scheduleView = document.getElementById('schedule-view');
  const scheduleSearch = document.getElementById('schedule-search');
  const techView = document.getElementById('tech-view');
  const techSearch = document.getElementById('tech-search');
  const viewBox = document.getElementById('change-view');
  const currentPage = document.getElementById('current-page');

  closeOpenViews();
  viewBox.classList.remove('showBox');

  const elements = document.querySelectorAll('.page-view');
  elements.forEach(page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });

  document.getElementById('open-work').style.display = 'flex';
  document.getElementById('open-work').style.visibility = 'visible';

  // Hide all views and searches
  [workOrderView, jobView, scheduleView, techView].forEach(v => v.style.display = 'none');
  [workOrderSearch, jobSearch, scheduleSearch, techSearch].forEach(s => s.classList.add('hidden'));

  // Show the selected view and search
  switch (view) {
    case 'job':
      jobView.style.display = 'flex';
      jobSearch.classList.remove('hidden');
      currentPage.innerHTML = "Operations";
      document.querySelectorAll('.print-table-button').forEach(button => button.style.display = 'none');
      document.getElementById('print-job-table').style.display = 'flex';
      focusIfNotMobile(jobSearch);
      break;
    case 'workOrder':
      workOrderView.style.display = 'flex';
      workOrderSearch.classList.remove('hidden');
      currentPage.innerHTML = "Work Orders";
      document.querySelectorAll('.print-table-button').forEach(button => button.style.display = 'none');
      focusIfNotMobile(workOrderSearch);
      break;
    case 'schedule':
      scheduleView.style.display = 'flex';
      scheduleSearch.classList.remove('hidden');
      currentPage.innerHTML = "Week Schedule";
      document.querySelectorAll('.print-table-button').forEach(button => button.style.display = 'none');
      focusIfNotMobile(scheduleSearch);
      break;
      case 'techList':
      techView.style.display = 'flex';
      techSearch.classList.remove('hidden');
      currentPage.innerHTML = "Schedule";
      document.querySelectorAll('.print-table-button').forEach(button => button.style.display = 'none');
      document.getElementById('print-tech-table').style.display = 'flex';
      focusIfNotMobile(techSearch);
      break;
  }
}

// Event listeners
document.getElementById('switch-job-view').addEventListener('click', () => switchView('job'));
document.getElementById('switch-work-order-view').addEventListener('click', () => switchView('workOrder'));
document.getElementById('switch-schedule-view').addEventListener('click', () => switchView('schedule'));
document.getElementById('switch-tech-view').addEventListener('click', () => switchView('techList'));

document.addEventListener('DOMContentLoaded', function () {
  const mooringButton = document.getElementById('mooring-open-button');
  const mooringModal = document.getElementById('mooring-selection');

  mooringButton.addEventListener('click', function (e) {
    e.stopPropagation();
    mooringModal.style.display = mooringModal.style.display === 'flex' ? 'none' : 'flex';
  });

  document.addEventListener('click', function (e) {
    if (!mooringModal.contains(e.target) && e.target !== mooringButton) {
      mooringModal.style.display = 'none';
    }
  });

  setupButtonListeners();

});

document.getElementById('haul-launch-open-button').addEventListener('click', function() {
  this.classList.add('no-tooltip');
});

document.getElementById('mooring-database-open-button').addEventListener('click', () => {

  const elements = document.querySelectorAll('.page-view');
  elements.forEach(page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });

  const mooringDatabase = document.getElementById('mooringDatabase');
  mooringDatabase.style.display = 'flex';
  mooringDatabase.style.visibility = 'visible';

  document.getElementById('current-page').innerHTML = "Mooring Database";
});

function setupButtonListeners() {
  const haulLaunchOpenButton = document.getElementById('haul-launch-open-button');
  const haulingOpenButton = document.getElementById('hauling-open-button');
  const springOpenButton = document.getElementById('spring-open-button');

  const mooringOpenButton = document.getElementById('mooring-open-button');
  const mooringSelection = document.getElementById('mooring-selection');

  function handleHaulLaunchClick(event) {

    event.preventDefault();
    event.stopPropagation();

    const today = new Date();
    const currentYear = today.getFullYear();
    const august15th = new Date(currentYear, 7, 15);

    if (today > august15th) {
      haulingOpenButton.click();
    } else {
      springOpenButton.click();
    }
  }

  function checkMobileView() {
    const isMobileView = window.innerWidth <= 768;

    if (isMobileView) {

      mooringSelection.classList.remove('mooring-open-modal');
      mooringSelection.classList.add('modal-switch');
      const mooringButtons = document.querySelectorAll('.mooringButton');

    mooringButtons.forEach(button => {

    const img = button.querySelector('img');

    if (img) {
      const altText = img.getAttribute('alt');

        if (!button.getAttribute('data-original-content')) {
          button.setAttribute('data-original-content', button.innerHTML);
        }
        img.style.display = 'none';
        button.innerHTML = altText;
        button.style.width = 'fit-content';
        button.style.padding = '.5rem';
        button.style.backgroundColor = 'var(--nav-background-color)';
        button.style.color = 'white';
        button.style.borderRadius = '5px';
      }
    
  });

      haulLaunchOpenButton.addEventListener('click', handleHaulLaunchClick);

    } else {
      const mooringButtons = document.querySelectorAll('.mooringButton');

      mooringButtons.forEach(button => {
      const img = button.querySelector('img');
      const originalContent = button.getAttribute('data-original-content');
        if (originalContent) {
          button.innerHTML = originalContent;
        }
        img.style.display = 'inline-block';
      });

      haulLaunchOpenButton.removeEventListener('click', handleHaulLaunchClick);
      const haulLaunchButton = document.getElementById('haul-launch-open-button');
      const haulLaunchModal = document.getElementById('haul-launch-selection');

  haulLaunchButton.addEventListener('click', function (e) {
    e.stopPropagation();
    haulLaunchModal.style.display = haulLaunchModal.style.display === 'block' ? 'none' : 'block';

    if (haulLaunchModal.style.display === 'block') {
      haulLaunchOpenButton.classList.add('no-tooltip');
    } else {
      haulLaunchOpenButton.classList.remove('no-tooltip');
    }

  });

  document.addEventListener('click', function (e) {

    if (!haulLaunchModal.contains(e.target) && e.target !== haulLaunchButton) {
      haulLaunchModal.style.display = 'none';
      haulLaunchOpenButton.classList.remove('no-tooltip');
    }
  });
    }
  }

  checkMobileView();

  window.addEventListener('resize', checkMobileView);
}


function closeOpenViews(exceptElement) {

  if (exceptElement && exceptElement.closest('div').classList.contains('filter-box')) {
    return;
  }

  var openViews = document.querySelectorAll('.showBox');
  openViews.forEach(function (view) {
    if (view !== exceptElement && !view.contains(exceptElement)) {
      view.classList.remove('showBox');
      view.classList.add('hideBox');
      view.style.visibility = 'hidden';

      // Reset the associated button's width
      var buttonId = view.id.replace('-view', '-view-button');
      var button = document.getElementById(buttonId);
      if (button) {
        button.style.transition = 'width 0.3s ease-in-out';
        button.style.width = '';
        button.style.backgroundColor = '';
        button.style.color = 'black';
      }
    }
  });
}

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("pop-out-box");
  var style = window.getComputedStyle(element);
  var width = style.getPropertyValue("width");

  document.getElementById('pop-out-box').style.left = "-600px";
})

document.addEventListener('DOMContentLoaded', async () => {

  if (!navigator.onLine) {
    console.log('User is offline. Skipping function.');
    return;
  }

  try {

    const hourlyRateRef = ref(database, '/pricing/Hourly Rate');
    const snapshot = await get(hourlyRateRef);
    const hourlyRate = snapshot.val();
    document.getElementById('currentLaborRate').value = hourlyRate;
  } catch (error) {
    console.error("Error fetching hourly rate: ", error);
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0]; // Format today's date as "yyyy-mm-dd"
  fetchTideInfo(formattedDate); // Fetch tide info for today

  const dateInput = document.getElementById('tideDateSelect');
  dateInput.value = formattedDate;

  // Listen for changes in the date input to fetch new tide info
  dateInput.addEventListener('change', (event) => {
    fetchTideInfo(event.target.value);
  });
});

document.addEventListener('DOMContentLoaded', () => {
  var elements = document.querySelectorAll('.engineW')
  elements.forEach((element) => {
    element.addEventListener('click', () => {
      document.getElementById('bayTestWinter').checked = element.checked
    })
  })
})

document.addEventListener('DOMContentLoaded', () => {
  var elements = document.querySelectorAll('.engineS')
  elements.forEach((element) => {
    element.addEventListener('click', () => {
      document.getElementById('bayTestSpring').checked = element.checked
    })
  })
})

document.addEventListener('DOMContentLoaded', async function () {
  var date = new Date().toLocaleDateString('en-us', { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' });
  var year = date.split('/')[2];
  var nextYear = (Number(year) + 1);
  document.getElementById('work-order-form-dates-launch-date').value = nextYear + "-04-01";
});

document.addEventListener('DOMContentLoaded', async function () {
  await fetchParts();
  Promise.all([
    renderLaborData(),
    renderPartsData()
  ])
})


const openBtn = document.getElementById("open-btn");
const calendarOpenBtn = document.getElementById('calendar-open-btn')
const popOutBox = document.getElementById("pop-out-box");
const calendarPopOutBox = document.getElementById('calendar-pop-out-box')
const closeBtn = document.getElementById("close-btn");
const calendarCloseBtn = document.getElementById("calendar-close-btn");

openBtn.addEventListener("click", () => {
  document.getElementById('pop-out-box').style.left = '50px'
  popOutBox.classList.add("open");
});

calendarOpenBtn.addEventListener("click", async () => {
  await listUpcomingEvents();
  document.getElementById('calendar-pop-out-box').style.left = '0px'
  popOutBox.classList.add("open");
});

closeBtn.addEventListener("click", () => {
  var element = document.getElementById("pop-out-box");
  var style = window.getComputedStyle(element);
  var width = style.getPropertyValue("width");

  document.getElementById('pop-out-box').style.left = "-600px"
  popOutBox.classList.remove("open");
});

calendarCloseBtn.addEventListener("click", () => {
  var element = document.getElementById("calendar-pop-out-box");
  var style = window.getComputedStyle(element);
  var width = style.getPropertyValue("width");

  document.getElementById('calendar-pop-out-box').style.left = "-600px"
  calendarPopOutBox.classList.remove("open");
});



async function fetchTideInfo(dateSelect) {
  if (!navigator.onLine) {
    console.log('User is offline. Skipping tide info fetch.');
    return null;
  }

  const tideLocation = '8516945';
  const tideEndpoint = 'https://api.tidesandcurrents.noaa.gov/api/prod/datagetter';
  let tideDate;

  if (!dateSelect) {
    const today = new Date();
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const date = today.getDate().toString().padStart(2, '0');
    tideDate = `${year}-${month}-${date}`;
  } else {
    const [year, month, date] = dateSelect.split('-');
    tideDate = `${year}-${month}-${date}`;
  }

  const early = new Date(`${tideDate} 04:30:00 AM`);
  const late = new Date(`${tideDate} 05:30:00 PM`);
  const urlDate = tideDate.replace(/-/g, '');
  const locationParams = `&station=${tideLocation}`;
  const product = 'predictions';
  const params = `?begin_date=${urlDate}&end_date=${urlDate}&time_zone=lst&interval=hilo&datum=MLLW&format=json&units=english`;

  try {
    const response = await fetch(tideEndpoint + params + locationParams + '&product=' + product + '&application=client-side-js', {
      method: 'GET'
    });

    // Check if the response is OK (status 200-299)
    if (!response.ok) {
      console.error(`Tide data fetch failed: ${response.statusText}`);
      return null;
    }

    const data = await response.json();

    // Validate that predictions exist and are an array
    if (!data.predictions || !Array.isArray(data.predictions)) {
      console.error('Invalid tide data response format:', data);
      return null;
    }

    const tides = data.predictions;
    let highTide, lowTide;

    tides.forEach(tide => {
      const tideTime = new Date(tide.t);
      if (tideTime > early && tideTime < late) {
        if (tide.type === "H") {
          highTide = formatTime(tideTime, 'America/New_York');
        } else if (tide.type === "L") {
          lowTide = formatTime(tideTime, 'America/New_York');
        }
      }
    });

    // Ensure both highTide and lowTide have values
    if (!highTide || !lowTide) {
      console.warn('Could not determine high or low tide times for the selected date.');
    }

    setTides([highTide, lowTide]);
    return { highTide, lowTide, tideDate };
  } catch (error) {
    console.error('Failed to fetch tide data:', error);
    return null;
  }
}


// Utility function to format time in "h:mm a" format
function formatTime(date, timeZone) {
  // This function will format the time based on the client's locale.
  // Adjust as necessary for your specific requirements.
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true, timeZone: timeZone });
}

function setTides(tides) {
  document.getElementById('highTide').innerHTML = tides[0]
  document.getElementById('lowTide').innerHTML = tides[1]
}

function displayEvents(events) {

  const container = document.getElementById("eventsContainer");
  container.innerHTML = ""; // Clear previous events

  const currentDate = new Date().toISOString().split('T')[0];

  let groupedEvents = {};
  events.forEach(event => {
    let key;
    if (event.start.date) {
      key = event.start.date;
    } else if (event.start.dateTime) {
      key = event.start.dateTime.split('T')[0];
    }

    if (key && key >= currentDate) {
      if (!groupedEvents[key]) {
        groupedEvents[key] = [];
      }
      groupedEvents[key].push(event);
    }
  });

  for (let date in groupedEvents) {
    let dateDiv = document.createElement("div");
    dateDiv.className = "date-section";

    let dateHeader = document.createElement("h2");
    let eventDate = new Date(date + "T00:00:00");
    dateHeader.textContent = eventDate.toLocaleDateString('default', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    });

    dateDiv.appendChild(dateHeader);

    groupedEvents[date].forEach(event => {

      let eventDiv = document.createElement("div");
      eventDiv.className = "event-item";
      let title = document.createElement("span");

      if (event.start.date) {
        title.textContent = event.summary;
      } else if (event.start.dateTime) {
        const startDateTime = new Date(event.start.dateTime);
        const endDateTime = new Date(event.end.dateTime);

        const startTime = startDateTime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });

        const endTime = endDateTime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        });

        const time = startTime === endTime ? startTime : `${startTime} - ${endTime}`;
        title.textContent = `${event.summary}\n(${time})`;
      }

      eventDiv.appendChild(title);
      dateDiv.appendChild(eventDiv);
    });

    container.appendChild(dateDiv);
  }
}

async function holdOperation() {
  const jobID = document.getElementById('currentJobId').value;
  try {
    const dbRef = ref(database, `/data/${jobID}`);
    await update(dbRef, { "Status": "Hold" });
    await updateJobDB(jobID, 'Status', "Hold");
    mainTable();
  } catch (error) {
    console.error('Failed to put job on hold', error);
  };
};

async function removeHoldOperation() {
  const jobID = document.getElementById('currentJobId').value;
  try {
    const dbRef = ref(database, `/data/${jobID}`);
    await update(dbRef, { "Status": "On Work Schedule" });
    await updateJobDB(jobID, 'Status', "On Work Schedule");
    mainTable();
  } catch (error) {
    console.error('Failed to put job on hold', error);
  };
};

async function fetchParts() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('inventoryData')) {
          db.createObjectStore('inventoryData', { keyPath: 'Part_Number' });
        }
      };

      dbPromise.onsuccess = event => resolve(event.target.result);
      dbPromise.onerror = event => reject(event.target.error);
    });

    const transaction = db.transaction('inventoryData', 'readonly');
    const store = transaction.objectStore('inventoryData');

    const data = await new Promise((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = event => resolve(request.result);
      request.onerror = event => reject(request.error);
    });

    partsList(data);

  } catch (error) {
    console.error('Failed to fetch data from IndexedDB:', error);
  }
}

function handleEmptyTable() {
  const tableContent = document.getElementById('table-content');
  const visibleJobs = tableContent.querySelectorAll('.table-item[style="display: flex;"]');
  const emptyMessage = tableContent.querySelector('.empty-table-message');

  if (visibleJobs.length === 0) {
    if (!emptyMessage) {
      const newEmptyMessage = document.createElement('div');
      newEmptyMessage.textContent = 'No matching work orders found.';
      newEmptyMessage.className = 'empty-table-message';
      tableContent.appendChild(newEmptyMessage);
    }
  } else if (emptyMessage) {
    emptyMessage.remove();
  }
}

function handleEmptyJobList() {
  const jobSection = document.getElementById('jobList');
  const visibleJobs = jobSection.querySelectorAll('.job-item:not(.filtered)');
  const emptyMessage = jobSection.querySelector('.empty-message') || document.createElement('div');

  emptyMessage.classList.add('empty-message');
  emptyMessage.textContent = 'No jobs match the current filters.';

  if (visibleJobs.length === 0) {
    if (!jobSection.contains(emptyMessage)) {
      jobSection.appendChild(emptyMessage);
    }
  } else {
    if (jobSection.contains(emptyMessage)) {
      jobSection.removeChild(emptyMessage);
    }
  }
}

function codeSearch() {
  const searchQuery = document.getElementById('codeSearch').value.toLowerCase();

  var categoryContainers = document.querySelectorAll('.category-container');

  categoryContainers.forEach(categoryDiv => {
    const rows = Array.from(categoryDiv.querySelectorAll('.op-code-row'));

    let categoryHasMatch = false;

    rows.forEach(row => {
      const rowData = JSON.parse(row.getAttribute('data-opcode'));

      let matchFound = false;

      for (const key in rowData) {
        const value = String(rowData[key]).toLowerCase();

        if (value.includes(searchQuery)) {
          matchFound = true;
          categoryHasMatch = true;
          break;
        }
      }

      if (!matchFound) {
        if(row.classList.contains('hidden')){
          return;
        }
        row.classList.add("hidden");
      } else {
        if(row.classList.contains('hidden')){
          row.classList.remove('hidden');
          row.classList.add('temp-visible');
        }
        row.classList.remove('hidden');
      }
    });

    if (!categoryHasMatch) {
      categoryDiv.style.display = 'none';
    } else {
      categoryDiv.style.display = 'flex';
    }
  });
}


function customerEmail() {
  document.getElementById('customerEmailModal').style.display = 'flex';
}

function customerDataSpring() {
  document.getElementById('Spring').style.visibility = "hidden"
  document.getElementById('customerData').style.display = "flex"
  document.getElementById("customer-search").focus()
}

function formatCurrency(value) {
  // Convert string to a number if it's not already
  const numberValue = typeof value === "string" ? parseFloat(value) : value;

  // Use Intl.NumberFormat to format the number as currency
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // You can adjust the options as needed
  });

  return formatter.format(numberValue);
}

function parseDate(dateString) {
  return new Date(dateString);
}

function holdTable() {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function (event) {
    const db = event.target.result;
    db.createObjectStore("openData", { keyPath: "id" });
  };

  openRequest.onsuccess = function (event) {
    const db = event.target.result;

    const tableTx = db.transaction("openData", "readonly");
    const tableStore = tableTx.objectStore("openData");
    const tableRequest = tableStore.getAll();

    tableRequest.onsuccess = function (event) {
      const data = event.target.result;
      const dataArray = data
        .map((wo) => {
          if (typeof wo === "object") {
            return Object.values(wo); // Convert object to array
          }
          return wo;
        })

      var Parent = document.getElementById('hold-body')
      while (Parent.hasChildNodes()) {
        Parent.removeChild(Parent.firstChild)
      }


      var holdCount = 0
      dataArray.forEach(function (r) {
        if (r[12].toLowerCase() !== 'hold') {
          return
        }
        holdCount + 1
        var tbody = document.getElementById("hold-body")
        var row = document.createElement("tr")
        var jobId = document.createElement("td")
        jobId.className = "main-data"
        jobId.textContent = r[0]
        var name = document.createElement("td")
        name.className = "main-data"
        name.textContent = r[1]
        var customerId = document.createElement("td")
        customerId.className = "main-data"
        customerId.textContent = r[2]
        var boat = document.createElement("td")
        boat.className = "main-data"
        boat.textContent = r[3]
        var boatId = document.createElement("td")
        boatId.className = "main-data"
        boatId.textContent = r[4]
        var email = document.createElement("td")
        email.className = "main-data"
        email.textContent = r[5]
        var workOrder = document.createElement("td")
        workOrder.className = "main-data hide-mobile"
        workOrder.textContent = r[6]
        var category = document.createElement("td")
        category.className = "main-data"
        category.textContent = r[7]
        var opType = document.createElement("td")
        opType.className = "main-data"
        opType.textContent = r[8]
        var description = document.createElement('td')
        description.className = 'main-data'
        description.textContent = r[9]
        var date = document.createElement('td')
        date.className = 'main-data hide-mobile'
        date.textContent = r[10].split('-')[1] + '/' + r[10].split('-')[2] + '/' + r[10].split('-')[0]
        var tech = document.createElement('td')
        tech.className = 'main-data'
        tech.textContent = r[11]
        var status = document.createElement('td')
        status.className = 'main-data'
        status.textContent = r[12]
        var hours = document.createElement('td')
        hours.className = 'main-data'
        hours.textContent = r[13]
        var charge = document.createElement('td')
        charge.className = 'main-data'
        charge.textContent = r[14]
        var notes = document.createElement('td')
        notes.className = 'main-data'
        notes.textContent = r[15]
        var begin = document.createElement('td')
        begin.className = 'main-data'
        begin.textContent = r[16]
        var shortDesc = document.createElement('td')
        shortDesc.className = 'main-data'
        shortDesc.textContent = r[17]
        var launch = document.createElement('td')
        launch.className = 'main-data hide-mobile'
        if (r[19] == null || r[19] == '' || r[19] == 'undefined') {
          launch.textContent = ''
        } else { launch.textContent = r[19].split('-')[1] + '/' + r[19].split('-')[2] + '/' + r[19].split('-')[0] }
        var add = document.createElement("td")
        add.innerHTML = "<button class='returnOpButton' onclick='returnOperation()'><img src='/assets/close_icon.png' alt='Close Icon' width='24' height='24'></button>"


        row.appendChild(jobId).style.display = 'none'
        row.appendChild(name)
        row.appendChild(customerId).style.display = 'none'
        row.appendChild(boat)
        row.appendChild(boatId).style.display = 'none'
        row.appendChild(email).style.display = 'none'
        row.appendChild(workOrder)
        row.appendChild(category).style.display = 'none'
        row.appendChild(opType).style.display = 'none'
        row.appendChild(shortDesc).style.display = 'none'
        row.appendChild(description)
        row.appendChild(date).style.display = 'none'
        row.appendChild(tech).style.display = 'none'
        row.appendChild(status).style.display = 'none'
        row.appendChild(hours).style.display = 'none'
        row.appendChild(charge).style.display = 'none'
        row.appendChild(notes).style.display = 'none'
        row.appendChild(begin).style.display = 'none'
        row.appendChild(launch)
        row.appendChild(add)
        tbody.appendChild(row)
      })
    }
  }
  showGeneral()
}

function returnOperation() {
  var tbody = document.getElementById("hold-body");
  tbody.onclick = function (e) {
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    if (target.innerHTML == "undo") {
      while (target && target.nodeName !== "TR") {
        target = target.parentNode;
      }
      if (target) {
        var cells = target.getElementsByTagName("td");
        for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);
        }
      }

      var jobId = data[0];
      var dbRef = ref(database, 'data/' + jobId);
      dbRef.update({
        Status: "On Work Schedule"
      }).then(function () {
        console.log("Status updated successfully.");
      }).catch(function (error) {
        console.error("Error updating status: ", error);
      });

      document.getElementById('selectOption').style.display = 'none';
    }
  };
}



function showBoxes() {

  var checks = document.querySelectorAll('.customCheck');

  checks.forEach(check => {
    // If the element is currently being displayed as a table cell...
    if (check.style.display === 'table-cell') {
      // ...change it to be hidden
      check.style.display = 'none';
      document.querySelector('.customCheckHeader').style.display = "none"
      document.getElementById('showOps').innerHTML = "Select Operations"
      document.getElementById('moveOps').style.display = "none"
      document.getElementById('showOps').classList.remove("hideOp")
      document.getElementById('showOps').classList.add("showOp")
      check.children[0].checked = false
    } else {
      // ...otherwise, change it to be displayed as a table cell
      check.style.display = 'table-cell';
      document.querySelector('.customCheckHeader').style.display = "table-cell"
      document.getElementById('showOps').innerHTML = "Hide Checkboxes"
      document.getElementById('showOps').classList.add("hideOp")
      document.getElementById('showOps').classList.remove("showOp")
      document.getElementById('moveOps').style.display = "block"
      check.children[0].checked = false
    }
  })

}

function moveOperations() {
  const boatId = document.getElementById('op-body').rows[0].children[4].innerHTML
  const workOrder = document.getElementById('op-body').rows[0].children[6].innerHTML
  getOpenWorkOrders(boatId, workOrder)
}

function moveCheckedOpsToCurrent(e) {

  const previousWorkOrder = document.getElementById('activeWorkOrder').value
  const currentWorkOrder = e.target.parentElement.children[5].innerHTML
  const currentCategory = e.target.parentElement.children[6].innerHTML
  const currentShortDesc = e.target.parentElement.children[7].innerHTML


  const jobs = []

  var ops = document.querySelectorAll('.customCheck')
  ops.forEach(op => {

    const checkbox = op.children[0]
    if (checkbox.checked === true) {
      const jobId = op.parentElement.children[0].innerHTML
      const customerId = document.getElementById('customerIdMove').value
      const boatId = document.getElementById('boatIdMove').value
      const workOrder = currentWorkOrder
      const oldWorkOrder = previousWorkOrder
      const category = currentCategory
      const shortDesc = currentShortDesc
      const description = op.parentElement.children[9].innerHTML
      var opCodeCheck = op.parentElement.children[19].innerHTML
      if (opCodeCheck === '') {
        var opCode = prompt('No OpCode for "' + op.parentElement.children[9].innerHTML + '". Please enter one.')
      } else { var opCode = op.parentElement.children[19].innerHTML }

      jobs.push([jobId, customerId, boatId, workOrder, category, shortDesc, description, opCode, oldWorkOrder])
    }
  })

  jobs.forEach(job => {

    const serviceLog = {}

    serviceLog.id = job[0]
    serviceLog.customerId = job[1]
    serviceLog.boatId = job[2]
    serviceLog.workOrder = job[3]
    serviceLog.category = job[4]
    serviceLog.shortDesc = job[5]
    serviceLog.description = job[6]
    serviceLog.opCode = job[7]
    serviceLog.oldWorkOrder = job[8]
    if (job[4].toLowerCase() === 'internal') {
      serviceLog.type = "Internal"
    } else { serviceLog.type = "Retail" }

    transferRecord(serviceLog)

  })

  document.getElementById('work-order-button-move').style.display = "inline-block"
  document.getElementById('open-wo-table').style.display = "table"
}

function transferRecord(serviceLog) {
  var jobId = serviceLog.id;
  var workOrder = serviceLog.workOrder;
  var category = serviceLog.category;
  var shortDesc = serviceLog.shortDesc;
  var opCode = serviceLog.opCode;

  try {
    // Update work order info in Firebase Realtime Database
    ref(database, 'data/' + jobId).update({
      WorkOrder: workOrder,
      Category: category,
      ShortDescription: shortDesc,
      OpCode: opCode
    }).then(function () {
      // If successful, delete operation code
      deleteOpCode(serviceLog.oldWorkOrder, opCode);
      console.log("Work order info updated successfully.");
    }).catch(function (error) {
      console.error("Error updating work order info:", error);
    });

    // Format data or perform any other actions
    // (Note: You'll need to implement any additional functionality here)

    toastMessage("Transfer Complete. Please Delete Old Work Order In Dockmaster.", 'green');
  } catch (e) {
    console.error("Error transferring record:", e);
    toastMessage("Error transferring record. Please check the console for details.", 'red');
  }
}

async function addLaborFirebase(laborData) {
console.log(laborData);
  try {
    const laborRef = ref(database, 'data/' + laborData.Job_ID + '/Labor/' + laborData.ID);

    console.log(laborRef);

    set(laborRef, laborData, (error) => {
      if (error) {
        console.error("Error adding labor record:", error);
      } else {
        console.log("Labor record added successfully!");
      }
    });
  } catch (error) {
    console.error('Failed to add labor to Firebase', error);
  }

}

async function submitLabor() {

  try {
    const originalText = document.getElementById('labor-entry-description').value;
    const labor = originalText.replace(/&nbsp;/g, '');

    let techSelector;

    if (document.getElementById('labor-entry-tech').value === '' || document.getElementById('labor-entry-tech').value === 'default') {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const currentTech = userInfo.displayName.split(' ')[0];
      techSelector = currentTech;
    } else {
      techSelector = document.getElementById('labor-entry-tech').value;
    }

    const jobId = document.getElementById('currentJobId').value;
    const entryId = await generateId();
    const entryDate = document.getElementById('labor-entry-date').value;
    const date = await formatDate(entryDate);
    const category = document.getElementById('currentCategory').value;
    const opType = document.getElementById('currentOpType').value;
    const status = document.getElementById('currentStatus').value;
    const assignedTech = document.getElementById('currentTech').value;


    const laborData = {
      'Description': labor,
      'Hours': document.getElementById('labor-entry-hours').value,
      'Category': category,
      'Op_Type': opType,
      'Job_ID': jobId,
      'ID': entryId,
      'Boat_Name': document.getElementById('currentBoat').value,
      'Customer_Name': document.getElementById('currentName').value,
      'Time_Submitted': date,
      'Work_Order': document.getElementById('currentWorkOrder').value,
      'Tech': techSelector
    }

    await addLaborFirebase(laborData);
    await updateLaborIndex(jobId, laborData);
    updateTimestamps('jobData', 'data');

    if (status == 'On Work Schedule') {

      updateJobStatus(jobId, 'In Progress')

      if(selectedJobRow){
        selectedJobRow.style.backgroundColor = 'rgb(236, 237, 175)';
      }

      if(activeTableRow){
        activeTableRow.querySelector('.status-info').style.backgroundColor = 'yellow';
      }
    
    };
    toastMessage('Labor Submitted', 'green');
    document.getElementById('labor-entry-description').value = '';
    document.getElementById('labor-entry-hours').value = '';
    generateLaborTable(jobId);
    document.getElementById('labor-section').style.display = "none";
  } catch (error) {
    console.error('Failed to submit labor', error);
    toastMessage('Failed to submit labor', 'red');
  }

}


async function getTodaysDate() {
  const today = new Date();
  const year = today.getFullYear().toString();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const date = today.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + date;
}

function initializePartsTable() {
  const tbody = document.getElementById("parts-table-body");
  tbody.innerHTML = '';
}

function addParts(quick) {

  const jobId = document.getElementById('currentJobId').value;

  // Create and show the part number input popup
  if(quick !== undefined && quick !== null && quick !== '') {
    const partNumber = quick.split('(')[0].trim();
    let description;

    if(quick.includes('(')){
    description = quick.split('(')[1].split(')')[0].trim();
    } else {
      description = partNumber;
    }

    showQuantityPopup(partNumber, description)
    return
  }
  const partPopup = createPopup('Enter Part Number');
  const partInput = document.createElement('input');
  partInput.type = 'text';
  partInput.placeholder = 'Part Number';
  partInput.className = 'new-part-input';

  partInput.addEventListener('keydown',async (e) => {
    if (e.key === 'Enter') {
    const partNumber = partInput.value.trim();
    if (partNumber !== '') {
      closePopup(partPopup);
      const description = await showDescriptionPopup(partNumber);
      showQuantityPopup(partNumber, description);;
    }
  }
  });

  if (typeof quick === 'string') {
    partInput.value = quick;
  }
  partPopup.appendChild(partInput);

  const suggestionContainer = document.createElement('div');
  suggestionContainer.classList.add('suggestion-list');
  suggestionContainer.style.display = 'none';

  const submitButton = document.createElement('button');
  submitButton.textContent = 'Submit';
  submitButton.classList.add('pop-up-submit-button');

  submitButton.addEventListener('click',async () => {
    const partNumber = partInput.value.trim();
    if (partNumber !== '') {
      closePopup(partPopup);
      const description = await showDescriptionPopup(partNumber);
      showQuantityPopup(partNumber, description);;
    }
  });

  const cancelButton = document.createElement('button');
  cancelButton.textContent = 'Cancel';
  cancelButton.classList.add('pop-up-cancel-button');
  cancelButton.addEventListener('click', () => {
    closePopup(partPopup);
  });

  partPopup.appendChild(submitButton);
  partPopup.appendChild(cancelButton);

  partPopup.appendChild(suggestionContainer);

  partInput.addEventListener('input', async function () {
    try {
      const query = partInput.value.toLowerCase().trim();
      suggestionContainer.innerHTML = '';

      let inventoryData = await fetchDataFromStore('inventoryData');
      const filteredData = inventoryData.filter(item => {
        const itemValues = Object.values(item).map(value => value.toString().toLowerCase());
        return query.split(' ').every(term => itemValues.some(value => value.includes(term)));
      });

      filteredData.forEach(item => {
        const suggestionItem = document.createElement('div');
        suggestionItem.classList.add('suggestion-item');
        suggestionItem.textContent = `${item.Part_Number} (${item.Description})`;
        suggestionItem.addEventListener('click', () => {
          console.log(item);
          partInput.value = `${item.Part_Number} (${item.Description})`;
          suggestionContainer.style.display = 'none';
          closePopup(partPopup);
          showQuantityPopup(item.Part_Number, item.Description, item.id);
        });
        suggestionContainer.appendChild(suggestionItem);
      });

      suggestionContainer.style.display = filteredData.length ? 'flex' : 'none';
    } catch (error) {
      console.error('Failed to fetch data from IndexedDB:', error);
    }
  });

  document.body.appendChild(partPopup);
  partInput.focus();
}

async function showDescriptionPopup(partNumber) {
  return new Promise((resolve) => {
    const descPopup = createPopup(`Enter Description for ${partNumber}`);
    const descInput = document.createElement('input');
    descInput.type = 'text';
    descInput.placeholder = 'Description';
    descInput.className = 'new-desc-input';

    descInput.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
      const description = descInput.value.trim();
      if (description !== '') {
        closePopup(descPopup);
        resolve(description); // Return the entered description
      }
    }
    });

    descPopup.appendChild(descInput);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Submit';
    confirmButton.classList.add('pop-up-submit-button')

    confirmButton.addEventListener('click', () => {
      const description = descInput.value.trim();
      if (description !== '') {
        closePopup(descPopup);
        resolve(description); // Return the entered description
      }
    });

    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.classList.add('pop-up-cancel-button');
    cancelButton.addEventListener('click', () => {
      closePopup(descPopup);
      resolve(''); // Return an empty string
    });

    descPopup.appendChild(confirmButton);
    descPopup.appendChild(cancelButton);

    document.body.appendChild(descPopup);
    descInput.focus();
  });
}


async function showQuantityPopup(partNumber, description, partId) {
  // If the description is blank, ask the user for it
  if (!description || description.trim() === '') {
    description = await showDescriptionPopup(partNumber);
  }

  const qtyPopup = createPopup('Enter Quantity');
  const qtyInput = document.createElement('input');

  qtyInput.type = 'number';
  qtyInput.placeholder = 'Quantity';
  qtyInput.className = 'new-qty-input';

  qtyInput.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
    const quantity = qtyInput.value.trim();
    if (quantity !== '') {
      addPartToTable(partNumber, description, quantity);
      reduceInventory(partId, quantity);
      closePopup(qtyPopup);
    }
  }
  });

  qtyPopup.appendChild(qtyInput);


  const confirmButton = document.createElement('button');
  confirmButton.textContent = 'Submit';
  confirmButton.classList.add('pop-up-submit-button');

  confirmButton.addEventListener('click', () => {
    const quantity = qtyInput.value.trim();
    if (quantity !== '') {
      addPartToTable(partNumber, description, quantity);
      reduceInventory(partId, quantity);
      closePopup(qtyPopup);
    }
  });

  const cancelButton = document.createElement('button');
  cancelButton.textContent = 'Cancel';
  cancelButton.classList.add('pop-up-cancel-button');
  cancelButton.addEventListener('click', () => {
    closePopup(qtyPopup);
  });
  

  qtyPopup.appendChild(confirmButton);
  qtyPopup.appendChild(cancelButton);

  document.body.appendChild(qtyPopup);
  qtyInput.focus();
}

async function retrieveQtyInfo(itemId) {
  const apiUrl = `https://your-api-url.com/api/v2/Inventory/RetrieveQtyInfo?ItemId=${itemId}&LocationCode=1`;
  
  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer YOUR_ACCESS_TOKEN' // Add token if needed
      }
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }

    const data = await response.json();
    console.log(data);
    return data; // Process data as needed
  } catch (error) {
    console.error('Error fetching quantity info:', error);
    return null;
  }
}

async function addPartToTable(partNumber, description, quantity) {
const today = await getTodaysDate();

  try {
    const tbody = document.getElementById("parts-table-body");
    const row = document.createElement("div");
    row.className = "parts-data-row";

    const id = document.createElement('p');
    id.textContent = await generateId();
    id.className = "id-selector";
    id.style.display = "none";

    row.setAttribute('data-id', id.textContent);

    const part = document.createElement('div');
    part.textContent = `${partNumber} (${description})`;
    part.className = "parts-selector";

    const qtyContainer = document.createElement('div');
    qtyContainer.className = 'qty-container';

    const qty = document.createElement('div');
    qty.textContent = quantity;
    qty.className = "qty-selector";

    qtyContainer.appendChild(qty);

    const del = document.createElement('button');
    del.className = 'parts-data-delete-button table-button';
    del.innerHTML = '<img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">';
    del.addEventListener('click', deleteParts);

    row.appendChild(id);
    row.appendChild(part);
    row.appendChild(qtyContainer);
    row.appendChild(del);

    tbody.appendChild(row);

    // Add part to Firebase and update timestamps
    const partData = {
      'Part_Number': partNumber,
      'Quantity': quantity,
      'Category': document.getElementById('currentCategory').value,
      'Op_Type': document.getElementById('currentOpType').value,
      'Job_ID': document.getElementById('currentJobId').value,
      'ID': id.textContent,
      'Boat_Name': document.getElementById('currentBoat').value,
      'Customer_Name': document.getElementById('currentName').value,
      'Time_Submitted': today,
      'Work_Order': document.getElementById('currentWorkOrder').value,
      'Tech': getTechSelector(),
      'Description': description
    };

    await addPartsFirebase(partData);
    await updatePartsIndex(document.getElementById('currentJobId').value, partData);
    updateTimestamps('jobData', 'data');
    generatePartsTable(document.getElementById('currentJobId').value);
  } catch (error) {
    console.error('Failed to add part to table:', error);
  }

}

function createPopup(title) {
  const popup = document.createElement('div');
  popup.className = 'part-popup';
  popup.style.position = 'fixed';
  popup.style.left = '50%';
  popup.style.top = '25%';
  popup.style.transform = 'translate(-50%, -50%)';
  popup.style.backgroundColor = 'white';
  popup.style.padding = '1rem';
  popup.style.boxShadow = 'rgba(0, 0, 0, 0.5) 0px 0px 1000px 1000px';
  popup.style.zIndex = '2000';
  popup.style.borderRadius = '10px';
  popup.style.display = 'flex';
  popup.style.flexDirection = 'column';

  const titleElement = document.createElement('h3');
  titleElement.textContent = title;

  const submitButton = document.createElement('button');
  submitButton.textContent = 'Submit';
  submitButton.classList.add('pop-up-submit-button');
  submitButton.addEventListener('click', () => {
    closePopup(popup);
  });

  const cancelButton = document.createElement('button');
  cancelButton.textContent = 'Cancel';
  cancelButton.classList.add('pop-up-cancel-button');
  cancelButton.addEventListener('click', () => {
    closePopup(popup);
  });

  popup.appendChild(titleElement);

  return popup;
}

function closePopup(popup) {
  document.body.removeChild(popup);
}

async function reduceInventory(partId, quantityChange) {
  console.log(partId, quantityChange);
  try {
      // Open the IndexedDB transaction and object store
      const db = await openDatabase();
      const transaction = db.transaction('inventoryData', 'readwrite');
      const store = transaction.objectStore('inventoryData');
      
      // Get the inventory item by partId
      const request = store.get(partId);
      const inventoryItem = await new Promise((resolve, reject) => {
          request.onsuccess = (event) => {
              resolve(event.target.result);
          };
          request.onerror = (event) => {
              reject(new Error('Unable to retrieve part data'));
          };
      });

      if (!inventoryItem) {
          throw new Error(`Part with ID ${partId} not found`);
      }

      // Update the quantity
      const updatedQty = inventoryItem.Qty - quantityChange;
      if (updatedQty < 0) {
          throw new Error('Quantity cannot be less than zero');
      }

      // Update the item with the new quantity
      inventoryItem.Qty = updatedQty;

      // Save the updated item back to the IndexedDB
      const updateRequest = store.put(inventoryItem);
      await new Promise((resolve, reject) => {
          updateRequest.onsuccess = () => resolve();
          updateRequest.onerror = () => reject(new Error('Unable to update inventory'));
      });

      console.log(`Inventory for part ${partId} updated. New quantity: ${updatedQty}`);
  } catch (error) {
      console.error('Error updating inventory:', error);
  }
}


function getTechSelector() {
  const techSelect = document.getElementById('part-entry-tech');
  if (techSelect.value === '' || techSelect.value === 'default') {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo.displayName.split(' ')[0];
  }
  return techSelect.value;
}

async function addPartsFirebase(partData) {
console.log(partData);
  try {
    const partRef = ref(database, 'data/' + partData.Job_ID + '/Parts/' + partData.ID);

    set(partRef, partData, (error) => {
      if (error) {
        console.error("Error adding labor record:", error);
      } else {
        console.log("Labor record added successfully!");
      }
    });
  } catch (error) {
    console.error('Failed to add part to Firebase:', error);
  }

}

async function getJobData(jobId) {
  console.log(jobId);
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open('openDatabase', currentIndexedVersion);

    openRequest.onerror = (event) => {
      reject(new Error(`Error opening database: ${event.target.errorCode}`));
    };

    openRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');
      const getRequest = objectStore.get(jobId);

      getRequest.onerror = (event) => {
        reject(new Error(`Error getting job data: ${event.target.errorCode}`));
      };

      getRequest.onsuccess = (event) => {
        if (getRequest.result) {

          resolve(getRequest.result);
        } else {

          resolve(null);
        }
      };
    };
  });
}


function updateParts(e) {
  if (
    e.target.getAttribute('data-check') === 'save' ||
    e.target.textContent === 'save' ||
    e.target.getAttribute('data-check') === 'update' ||
    e.target.textContent === 'update'
  ) {
    toastMessage('Applying Changes', 'blue');
    document.getElementById('currentStatus').value = 'In Progress';
    const targetRow = e.target.closest('tr');
    if (targetRow) {
      const cells = targetRow.querySelectorAll('td');
      const description = cells[5].textContent.replace(/ {2,}/g, ' ').trim();
      const jobId = document.getElementById('currentJobId').value;
      getJobData(jobId);

      let selectedTech
      if (cells[1].querySelector('select').getAttribute('data-selected-tech')) {
        selectedTech = cells[1].querySelector('select').getAttribute('data-selected-tech')
      } else { selectedTech = cells[1].textContent }

      const dataLog = {
        ID: cells[0].textContent.trim(),
        Tech: selectedTech, // Use the selected tech value
        CustomerName: cells[2].textContent.trim(),
        BoatName: cells[3].textContent.trim(),
        PartNumber: cells[5].textContent.trim(),
        Quantity: cells[6].textContent.trim(),
        Category: document.getElementById('currentCategory').value,
        OpType: document.getElementById('currentOpType').value,
        WorkOrder: cells[4].textContent.trim(),
        Timestamp: cells[7].textContent.trim()
      };

      const jobsRef = ref(database, 'data/' + jobId + '/Parts/' + dataLog.ID);
      set(jobsRef, dataLog)
        .then(() => {
          toastMessage("Firebase update succeeded", 'green');
          updatePartsIndex(jobId, dataLog);

          if (e.target.classList.contains('parts-data-button')) {
            const button = targetRow.querySelector('.parts-data-button');
            button.innerHTML = "<button class='parts-update-button table-button'><img src='/assets/close_icon.png' alt='Close Icon' width='24' height='24'>"
            button.setAttribute('data-check', 'update');
          }

        })
        .catch((error) => {
          toastMessage("Firebase update failed", 'red');
        });
    }
  }
}

async function updateLaborIndex(jobId, laborEntry) {

  try {
    const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version

    openRequest.onerror = function (event) {
      console.error('Error opening database:', event.target.errorCode);
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readwrite');
      const objectStore = transaction.objectStore('jobData');

      const jobRequest = objectStore.get(jobId);

      jobRequest.onerror = function (event) {
        console.error('Error fetching job data:', event.target.errorCode);
      };

      jobRequest.onsuccess = function (event) {
        const jobData = jobRequest.result;

        if (jobData) {
          // Assuming laborEntry is an object with a unique ID itself
          // If it doesn't have an ID, you should assign it one
          const laborId = laborEntry.ID; // Ensure this ID is properly assigned or generated

          if (!jobData.Labor) {
            jobData.Labor = {}; // Initialize if it doesn't exist
          }

          // Update the specific labor entry within the job
          jobData.Labor[laborId] = laborEntry;

          // Save the updated jobData back to the database
          const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry

          updateRequest.onerror = function (event) {
            console.error('Error updating job data:', event.target.errorCode);
          };

          updateRequest.onsuccess = function (event) {
            console.log('Job data updated successfully');
            // Handle successful update, e.g., updating the UI or notifying the user
          };
        } else {
          console.error('Job not found:', jobId);
        }
      };
    };
  } catch (error) {
    console.error('Failed to update labor index:', error);
  }

}

function removeLaborFromIndex(jobId, laborId) {
  const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version
  openRequest.onerror = function (event) {
    console.error('Error opening database:', event.target.errorCode);
  };
  openRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction(['jobData'], 'readwrite');
    const objectStore = transaction.objectStore('jobData');
    const jobRequest = objectStore.get(jobId);
    jobRequest.onerror = function (event) {
      console.error('Error fetching job data:', event.target.errorCode);
    };
    jobRequest.onsuccess = function (event) {
      const jobData = jobRequest.result;
      if (jobData && jobData.Labor && jobData.Labor[laborId]) {
        delete jobData.Labor[laborId]; // Remove the specific labor entry from the job
        const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
        updateRequest.onerror = function (event) {
          console.error('Error updating job data:', event.target.errorCode);
        };
        updateRequest.onsuccess = function (event) {
          console.log('Labor entry removed successfully');
          // Handle successful removal, e.g., updating the UI or notifying the user
        };
      } else {
        console.error('Labor entry not found:', laborId);
      }
    };
  };
}

async function updatePartsIndex(jobId, partEntry) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = indexedDB.open('openDatabase', currentIndexedVersion);

      openRequest.onerror = function (event) {
        console.error('Error opening database:', event.target.errorCode);
        reject(event.target.errorCode);
      };

      openRequest.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(['jobData'], 'readwrite');
        const objectStore = transaction.objectStore('jobData');

        const jobRequest = objectStore.get(jobId);

        jobRequest.onerror = function (event) {
          console.error('Error fetching job data:', event.target.errorCode);
          reject(event.target.errorCode);
        };

        jobRequest.onsuccess = function (event) {
          const jobData = jobRequest.result;

          if (jobData) {
            const partId = partEntry.ID;
            if (!jobData.Parts) {
              jobData.Parts = {};
            }

            jobData.Parts[partId] = partEntry;

            const updateRequest = objectStore.put(jobData);

            updateRequest.onerror = function (event) {
              console.error('Error updating job data:', event.target.errorCode);
              reject(event.target.errorCode);
            };

            updateRequest.onsuccess = function () {
              console.log('Job data updated successfully');
              resolve();
            };
          } else {
            console.error('Job not found:', jobId);
            reject('Job not found');
          }
        };
      };
    } catch (error) {
      console.error('Failed to update parts index:', error);
      reject(error);
    }
  });
}


function removePartsFromIndex(jobId, partId) {
  const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version
  openRequest.onerror = function (event) {
    console.error('Error opening database:', event.target.errorCode);
  };
  openRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction(['jobData'], 'readwrite');
    const objectStore = transaction.objectStore('jobData');
    const jobRequest = objectStore.get(jobId);
    jobRequest.onerror = function (event) {
      console.error('Error fetching job data:', event.target.errorCode);
    };
    jobRequest.onsuccess = function (event) {
      const jobData = jobRequest.result;
      if (jobData && jobData.Parts && jobData.Parts[partId]) {
        delete jobData.Parts[partId]; // Remove the specific labor entry from the job
        const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
        updateRequest.onerror = function (event) {
          console.error('Error updating job data:', event.target.errorCode);
        };
        updateRequest.onsuccess = function (event) {
          console.log('Labor entry removed successfully');
          // Handle successful removal, e.g., updating the UI or notifying the user
        };
      } else {
        console.error('Labor entry not found:', partId);
      }
    };
  };
}


function updateLabor(e) {
  if (
    e.target.getAttribute('data-check') === 'save' ||
    e.target.textContent === 'save' ||
    e.target.getAttribute('data-check') === 'update' ||
    e.target.textContent === 'update'
  ) {
    toastMessage('Applying Changes', 'blue');
    document.getElementById('currentStatus').value = 'In Progress';
    const targetRow = e.target.closest('tr');
    if (targetRow) {
      const cells = targetRow.querySelectorAll('td');
      const description = cells[5].textContent.replace(/ {2,}/g, ' ').trim();
      const jobId = document.getElementById('currentJobId').value;
      let selectedTech
      if (cells[1].querySelector('select').getAttribute('data-selected-tech')) {
        selectedTech = cells[1].querySelector('select').getAttribute('data-selected-tech')
      } else { selectedTech = cells[1].textContent }

      getJobData(jobId);
      const dataLog = {
        ID: cells[0].textContent.trim(),
        Tech: selectedTech,
        CustomerName: cells[2].textContent.trim(),
        BoatName: cells[3].textContent.trim(),
        Description: cells[5].textContent.trim(),
        Hours: cells[6].textContent.trim(),
        Category: document.getElementById('currentCategory').value,
        OpType: document.getElementById('currentOpType').value,
        WorkOrder: cells[4].textContent.trim(),
        TimeSubmitted: cells[7].textContent.trim()
      };
      const jobsRef = ref(database, 'data/' + jobId + '/Labor/' + dataLog.ID);
      set(jobsRef, dataLog)
        .then(() => {
          toastMessage("Firebase update succeeded", 'green');
          updateLaborIndex(jobId, dataLog);
          const button = e.target.closest('button');
          console.log(button)
          if (button.classList.contains('labor-data-button')) {
            button.innerHTML = '<img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">';
            button.classList.remove('labor-data-button');
            button.classList.add('labor-update-button');
            button.setAttribute('data-check', 'update');
          }
        })
        .catch((error) => {
          toastMessage("Firebase update failed", 'red');
        });
    }
  }
}

function deleteLabor(e) {
  const button = e.target.closest('button');
  if (button && button.classList.contains('labor-data-delete-button')) {
    const laborEntry = button.closest('.labor-entry');
    if (laborEntry) {
      const jobId = document.getElementById('currentJobId').value;
      const laborId = laborEntry.getAttribute('data-id');

      const confirmDelete = confirm('Are you sure you want to delete this labor record?');
      if (confirmDelete) {
        toastMessage('Deleting Labor Record', 'blue');
        const laborRef = ref(database, 'data/' + jobId + '/Labor/' + laborId);
        remove(laborRef)
          .then(() => {
            toastMessage("Labor record deleted successfully", 'green');
            laborEntry.remove();
            removeLaborFromIndex(jobId, laborId);
            updateLaborCount();
          })
          .catch((error) => {
            toastMessage("Failed to delete labor record", 'red');
          });
      }
    }
  }
}

function deleteParts(e) {

  const button = e.target.closest('button');
  if (button && button.classList.contains('parts-data-delete-button')) {
    const partEntry = button.closest('.part-entry') ? button.closest('.part-entry') : button.closest('.parts-data-row');
    if (partEntry) {
      const jobId = document.getElementById('currentJobId').value;
      const partId = partEntry.getAttribute('data-id');

      const confirmDelete = confirm('Are you sure you want to delete this part record?');
      if (confirmDelete) {
        toastMessage('Deleting Parts Record', 'blue');
        const partsRef = ref(database, 'data/' + jobId + '/Parts/' + partId);
        remove(partsRef)
          .then(() => {
            toastMessage("Part record deleted successfully", 'green');
            partEntry.remove();
            removePartsFromIndex(jobId, partId);
            updatePartsCount();

            if(partEntry === button.closest('.parts-data-row')) {
            generatePartsTable(document.getElementById('currentJobId').value);
            }
          })
          .catch((error) => {
            toastMessage("Failed to delete part record", 'red');
          });
      }
    }
  }
}

function updateLaborCount() {
  const laborEntries = document.querySelectorAll('#labor-table-body-current .labor-entry:not(.header)');
  document.getElementById('labor-header').innerHTML = 'Labor (' + laborEntries.length + ')';
}

function updatePartsCount() {
  const partEntries = document.querySelectorAll('#parts-table-body-current .part-entry:not(.header)');
  document.getElementById('parts-header').innerHTML = 'Parts (' + partEntries.length + ')';
}


async function showStatus() {

  try {

      var id = document.getElementById('currentJobId').value

      await generateLaborTable(id)
      await generatePartsTable(id)

      fillInCustomerData();

      document.getElementById('statusTable').style.display = "flex"

  } catch (error) {
    console.error('Failed to fetch op data:', error);
  }
}

function partBox() {

  document.getElementById('partsModal').style.display = 'flex'
  document.getElementById('partId').value = '';
  document.getElementById('partVendorList').value = '';
  document.getElementById('vendor').value = '';
  document.getElementById('manufacturer').value = '';

  var tbody = document.getElementById("op-body")

  tbody.onclick = function (e) {

    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }

    document.getElementById('partWorkOrder').value = data[6]
    document.getElementById('partJobId').value = data[0]

  }
}

async function getCustomerDataById(customerId, boatId, data) {
  return new Promise((resolve, reject) => {
    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your DB name and version

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject('Database error: ' + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      // Check if customerId is valid
      if (!customerId) {
        console.warn('Invalid customerId:', customerId);
        reject('Invalid customerId provided');
        return;
      }

      const customerRequest = store.get(customerId);

      customerRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
        console.warn('Problematic customerId:', customerId);
        reject('Error fetching customer data: ' + event.target.errorCode);
      };

      customerRequest.onsuccess = (event) => {
        const customerData = event.target.result;
        if (customerData) {
          if (boatId && customerData.Boats && customerData.Boats[boatId]) {
            resolve({
              customerData: customerData,
              boatData: customerData.Boats[boatId]
            });
          } else if (boatId) {
            console.warn('Boat data not found for customerId:', customerId, 'boatId:', boatId);
            resolve({
              customerData: customerData,
              boatData: null
            }); // Resolve without stopping the function
          } else {
            resolve({
              customerData: customerData
            });
          }
        } else {
          console.warn('Customer data not found for customerId:', customerId);
          resolve(null); // Resolve with null if no data is found
        }
      };
    };

    request.onupgradeneeded = (event) => {
      // No changes needed in the upgrade logic
      console.info('Database upgrade triggered.');
    };
  });
}


async function checkAndUpdateDMToken() {
  const apiRef = child(ref(database), 'API');
  const snapshot = await get(apiRef);
  const dmConfig = snapshot.val();

  const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000;
  const lastUpdated = dmConfig.lastUpdated || 0;

  if (Date.now() - lastUpdated > twoDaysInMillis) {
    const functions = getFunctions();
    const updateToken = httpsCallable(functions, 'getDMTokenAndUpdateManual');
    
    try {
      await updateToken();
    } catch (error) {
      console.error('Error updating token:', error);
      throw error;
    }

    const updatedSnapshot = await get(apiRef);
    return updatedSnapshot.val().DockmasterDMToken;
  }

  return dmConfig.DockmasterDMToken;
}



async function getCustomerBalance(customer) {

  const DOCKMASTER_API_KEY = await checkAndUpdateDMToken();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Customers/RetrieveCustomer/${customer}`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if(!result.Invoices){
      return null;
    }

    if (result.Invoices) {
      const rows = [];

      for (const inv of result.Invoices) {

        if(!inv.Description.includes('WO Billing')){
          continue;
        }

        const tbody = document.getElementById('invoice-body');
        tbody.innerHTML = '';

        if (inv.Description.split(' ')[0] !== '') {
          const workOrderCheck = inv.Description.split(' ')[0];
          const workOrderData = await getWorkOrderInfoDM(workOrderCheck, inv.DueDate, inv.Id);

          console.log(workOrderData);

          if(workOrderData.length === 0) {
            return
          }

          for (const r of workOrderData) {
            const row = document.createElement('tr');
            const invoiceNumber = document.createElement('td');
            invoiceNumber.textContent = inv.Id;
            const workOrder = document.createElement('td');
            workOrder.innerHTML = r.Work_Order;
            const description = document.createElement('td');
            description.innerHTML = r.Description.split(':')[0];
            description.style.fontSize = '.75rem';
            const date = document.createElement('td');
            date.innerHTML = r.Bill_Date;

            const startDate = new Date(r.Bill_Date);
            const endDate = new Date();
            const differenceInMilliseconds = endDate - startDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            const pastDue = document.createElement('td');
            pastDue.innerHTML = differenceInDays;
            const amount = document.createElement('td');
            amount.innerHTML = r.Charge;

            row.appendChild(invoiceNumber);
            row.appendChild(workOrder);
            row.appendChild(description);
            row.appendChild(date);
            row.appendChild(pastDue);
            row.appendChild(amount);

            rows.push(row);
          }
        } else {
          const row = document.createElement('tr');
          const invoiceNumber = document.createElement('td');
          invoiceNumber.textContent = inv.Id;
          const workOrder = document.createElement('td');
          workOrder.innerHTML = "";
          const description = document.createElement('td');
          description.innerHTML = 'Point of Sale';
          const date = document.createElement('td');
          date.innerHTML = inv.DueDate;

          const startDate = new Date(inv.DueDate);
          const endDate = new Date();
          const differenceInMilliseconds = endDate - startDate;
          const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

          const pastDue = document.createElement('td');
          pastDue.innerHTML = differenceInDays;
          const amount = document.createElement('td');
          amount.innerHTML = `$${Number(inv.InvoiceAmount).toFixed(2)}`;

          row.appendChild(invoiceNumber);
          row.appendChild(workOrder);
          row.appendChild(description);
          row.appendChild(date);
          row.appendChild(pastDue);
          row.appendChild(amount);

          rows.push(row);
        }
      }

      // Sort rows by date
      rows.sort((a, b) => {
        const dateA = new Date(a.querySelector('td:nth-child(4)').innerText);
        const dateB = new Date(b.querySelector('td:nth-child(4)').innerText);
        return dateA - dateB;
      });

      // Append sorted rows to the table
      const tbody = document.getElementById('invoice-body');
      rows.forEach(row => tbody.appendChild(row));
    

    let balance = result.Balance;
    let numStr = balance.toString();

    if (numStr.length < 3) {
      while (numStr.length < 3) {
        numStr = '0' + numStr;
      }
    }

    let formattedNumber = numStr.slice(0, -2) + '.' + numStr.slice(-2);
    return formattedNumber;
  }
  } catch (error) {
    console.error('Error fetching customer balance:', error);
    return null;
  }
}

async function getWorkOrderInfoDM(workOrder, billDate, invoiceNumber) {

  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Service/WorkOrders/Retrieve?Id=${workOrder}&Detail=true`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };
  const data = [];
  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if(!result || !result.Operations || result.Operations.length === 0) {
      return;
    }

    result.Operations.forEach(op => {
      const info = {
        "Charge": `$${Number(op.TotalCharges).toFixed(2).toLocaleString()}`,
        "Description": op.LongDesc,
        "Work_Order": workOrder,
        "Bill_Date": billDate,
        "Invoice": invoiceNumber
      };
      data.push(info);
    });

    return data;
  } catch (error) {
    console.log(error);
  };

}

async function updateWorkOrderDM(serviceLog) {

  // Assuming `serviceLog` is an object that contains all necessary data
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();

  // Define the API endpoint for updating work order information
  const DM_URL = 'https://api.dockmaster.com:4134/';
  const url = `${DM_URL}api/v2/Service/WorkOrders/Update?OverwriteComments=false`;

  // Prepare the payload based on `serviceLog`
  const payload = {
    CustId: serviceLog.DMCustomer,
    WOId: serviceLog.Work_Order,
    Type: serviceLog.type,
    LocationCode: "1",
    ClerkId: serviceLog.clerkId,
    OperationCodes: [{
      ShortDesc: serviceLog.Short_Description_DM ? serviceLog.Short_Description_DM : serviceLog.Short_Description,
      Opcode: serviceLog.OpCode,
      LongDesc: serviceLog.Description,
      TechDesc: serviceLog.Description,
      Desc: serviceLog.Short_Description_DM ? serviceLog.Short_Description_DM : serviceLog.Short_Description,
    }]
  };

  // Conditionally add optional fields
  if (serviceLog.flatLabor || serviceLog.flatPerFoot || serviceLog.flatMethod) {
    if (serviceLog.flatLabor !== '') {
      payload.OperationCodes[0].FlatRateAmount = serviceLog.flatLabor.toString();
    }
    if (serviceLog.flatPerFoot !== '') {
      payload.OperationCodes[0].FlatRatePerFootRate = serviceLog.flatPerFoot.toString();
    }
    if (serviceLog.flatMethod !== '') {
      payload.OperationCodes[0].FlatRatePerFootMethod = serviceLog.flatMethod.toString();
    }
  }

  if(serviceLog.Title){
    payload.Title = serviceLog.Title;
  }

  // Prepare the request options
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload) // Ensure the payload is stringified
  };

  // Execute the API call
  try {
    const response = await fetch(url, options);
    console.log(response);
    const result = await response.json();


    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return result; // You might want to return something more specific or handle it differently
  } catch (error) {
    console.error('Error in updating work order:', error);
    return null; // Or handle the error as you see fit
  }
}

async function deleteOperationDM(WOId, Operation) {
  // Assuming `WOId` and `Operation` are provided as parameters
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();

  // Define the API endpoint for deleting an operation
  const DM_URL = 'https://api.dockmaster.com:4134/';
  const url = `${DM_URL}api/v2/Service/WorkOrders/DeleteOperation?WOId=${WOId}&Operation=${Operation}`;

  // Prepare the request options
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };

  // Execute the API call
  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log('Op Deleted in Dockmaster', result);
    return result; // You might want to return something more specific or handle it differently
  } catch (error) {
    console.error('Error in deleting work order operation:', error);
    return null; // Or handle the error as you see fit
  }
}


function showBalance(data) {

  google.script.run.withSuccessHandler((info) => {
    var invoices = info;
    invoices.forEach(inv => {

      let tbody = document.getElementById('invoice-body');
      let row = document.createElement('tr');

      let invoice = document.createElement('td');
      let wo = document.createElement('td');
      let shortDesc = document.createElement('td')
      let date = document.createElement('td');
      let days = document.createElement('td');
      let amount = document.createElement('td');

      invoice.textContent = inv.Id;
      wo.textContent = inv.Description.split(' : ')[0];
      shortDesc.textContent = inv.ShortDesc
      date.textContent = inv.DueDate;
      days.textContent = inv.DaysFromDue.toString().split('-')[1];
      amount.textContent = formatCurrency(inv.Amount);

      row.appendChild(invoice);
      row.appendChild(wo);
      row.appendChild(shortDesc)
      row.appendChild(date);
      row.appendChild(days);
      row.appendChild(amount);

      if (inv.DaysFromDue.toString().split('-')[1] > 30) {
        row.style.backgroundColor = '#fffcbf'
      }

      if (inv.DaysFromDue.toString().split('-')[1] > 60) {
        row.style.backgroundColor = '#ffbfc0'
      }

      // Append the row directly to the tbody
      tbody.appendChild(row);
    });
  }).getCustomerInvoiceData(data[22]);
}

document.addEventListener('click', (e) => {
  if ( (e.target.id === 'table-content' || e.target.id === 'search') && activeRow) {
    console.log('Clicked on table content');
    removeOperationDetails(activeRow);
  }
})

async function selectWorkOrder(e) {

  const clickedRow = e.target.closest('.table-item');
  const tableContent = document.getElementById('table-content');

  if (!clickedRow) return;

  const data = clickedRow;
  activeTableRow = data;
  const info = JSON.parse(data.getAttribute('data-jobInfo'));
  const jobId = data.getAttribute('data-id');
  const customerId = data.getAttribute('data-customerId');
  const boatId = data.getAttribute('data-boatId');
  const workOrder = data.getAttribute('data-workOrder');

  globalWorkOrder = workOrder;
  document.getElementById('activeWorkOrder').value = workOrder;
  tableContent.style.padding = '0rem';

  if (clickedRow === activeRow && !isIgnoredElement(e.target) && activeRow) {
    // Remove the operation details container if the same row is clicked again
    removeOperationDetails(clickedRow);
  } else if (!isIgnoredElement(e.target)) {

    if (activeRow) removeOperationDetails(activeRow);

    // Set the new active row
    activeRow = clickedRow;
    activeTableRow = activeRow;
    activeRow.style.position = 'sticky';
    activeRow.style.top = '0';
    activeRow.classList.add('active-work-order');

    displayOperations(globalWorkOrder, clickedRow); // Display the operations related to the work order
  }

  const parentTop = tableContent.getBoundingClientRect().top;
  const childTop = e.target.closest('.table-item').getBoundingClientRect().top;
  
  const scrollOffset = childTop - parentTop + tableContent.scrollTop;

  tableContent.scrollTo({
      top: scrollOffset,
      behavior: 'smooth'
  });
}

// Helper function to populate boat form fields
function fillBoatInfoForm(boatInfo) {
  document.getElementById('work-order-form-boat-length').value = boatInfo.Boat_Length || '';
  document.getElementById('work-order-form-boat-beam').value = boatInfo.Boat_Beam || '';
  document.getElementById('work-order-form-boat-mooring-number-slip').value = boatInfo.Mooring_Number || '';
  document.getElementById('work-order-form-boat-mooring-tag').value = boatInfo.Tag_Number || '';
  document.getElementById('work-order-form-boat-bottom-paint').value = boatInfo.Bottom_Paint_Type || '';
  document.getElementById('work-order-form-boat-bottom-color').value = boatInfo.Bottom_Paint_Color || '';
  document.getElementById('work-order-form-boat-mast-length').value = boatInfo.Mast_Length || '';
  document.getElementById('work-order-form-boat-engine-count').value = boatInfo.Number_of_Engines || '';
  document.getElementById('work-order-form-boat-battery-count').value = boatInfo.Number_of_Batteries || '';
  document.getElementById('work-order-form-boat-fuel-type').value = boatInfo.Fuel_Type || '';
  document.getElementById('work-order-form-boat-boat-type').value = boatInfo.Boat_Type || '';
  document.getElementById('work-order-form-boat-engine-type').value = boatInfo.Engine_Type || '';
}

// Helper function to remove operation details
function removeOperationDetails(row) {
 
  const existingContainerRow = row.parentNode.querySelector('.operation-container-row');
  if (existingContainerRow) {
    existingContainerRow.remove();
    row.style.position = '';
    row.style.top = '';
    row.classList.remove('active-work-order');
    document.getElementById('table-content').style.padding = '1rem 0rem';
  }
  activeRow = null;
  activeTableRow = null;
  selectedJobRow = null;
}

// Helper function to determine if the clicked element should be ignored
function isIgnoredElement(target) {
  return target.tagName === 'BUTTON' || target.id === 'search' || target.id === 'table-content';
}


async function getCustomerDMID(customerId) {
  return new Promise((resolve, reject) => {
    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your DB name and version

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject('Database error: ' + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      // Get the customer data by customerId
      const customerRequest = store.get(customerId);

      customerRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
        reject('Error fetching customer data: ' + event.target.errorCode);
      };

      customerRequest.onsuccess = (event) => {
        const customerData = event.target.result;
        // Ensure customerData and Boats[boatId] exists
        if (customerData) {
          resolve(customerData.DM_Customer);
        } else {
          reject('Boat data not found for customerId: ' + customerId + ', boatId: ' + boatId);
        }
      };
    };

    request.onupgradeneeded = (event) => {
      // Placeholder for database upgrade logic, if necessary
      // This is only called if you're connecting to a higher version of the database
      // than what exists in the browser, allowing you to create object stores, etc.
    };
  });
}



async function displayOperations(workOrderNumber, afterRow) {

  const info = JSON.parse(afterRow.getAttribute('data-jobInfo'));

  document.getElementById('invoice-body').innerHTML = '';
  const currentUser = auth.currentUser;
  const hasAccess = await getUserRole(currentUser.uid);

  const balanceButton = document.createElement('button')
  balanceButton.classList.add('hide-mobile');


  fetchOperationsForWorkOrder(workOrderNumber)
    .then(async operations => {

      // Check if operations are available
      if (!operations || operations.length === 0) {
        console.log('No operations found for this work order.');
        return; // Exit if no operations
      }

      // Remove existing operation containers if any
      const existingContainer = afterRow.parentNode.querySelector('.operation-container');
      if (existingContainer) {
        existingContainer.remove();
      }

      if (afterRow.nextElementSibling && afterRow.nextElementSibling.classList.contains('operation-container-row')) {
        afterRow.parentNode.removeChild(afterRow.nextElementSibling);
        return; // Exit the function to toggle off the display of operations
      }

      // Create a new table row to host the operations container
      let containerRow = document.createElement('div');
      containerRow.className = 'operation-container-row'; // For potential styling or identification

      let buttonRow = document.createElement('div');
      buttonRow.className = 'operation-button-row';

      // Create a table cell that spans all columns
      let containerCell = document.createElement('div');
      containerCell.colSpan = afterRow.children.length; // Set colspan to match the number of columns in the parent table
      containerCell.className = 'operation-div'

      // Create a container div for the operations table
      let containerDiv = document.createElement('div');
      containerDiv.style.width = '100%'; // Ensure it spans the full width
      containerDiv.className = 'operation-container'; // For styling

      // Create the operations table within the div
      const operationsTable = document.createElement('div');
      operationsTable.id = 'operations-table'; // Add styling as needed

      opTableList(operations, operationsTable);

      const checkingTheCheck = operations.forEach(op => console.log(op.Status));

      const checkOverallStatus = operations.every(op => op.Status === 'Closed' || op.Status === 'Declined');

      console.log(checkOverallStatus);

      if (hasAccess.includes('admin') && activeRow != null) {

        balanceButton.id = 'balance-amount';
        document.getElementById('invoice-customer').innerHTML = info.Customer_Name;

        balanceButton.addEventListener('click', (e) => {
          console.log('Customer Balance Clicked')
          e.stopPropagation();
          document.getElementById('invoiceModal').style.display = 'flex';
        })

        balanceButton.style.display = "flex";
        balanceButton.innerHTML = 'Customer Balance: $--.--';
      }
      const addOpButton = document.createElement('button');
      addOpButton.addEventListener('click', addOperation);
      addOpButton.className = 'add-op-button';
      addOpButton.innerHTML = 'Add Operation';

      const customerRecordButton = document.createElement('button');
      customerRecordButton.addEventListener('click', (e) => {
        e.stopPropagation();
        callOpenCustomerData(info.Customer_ID);
      });
      customerRecordButton.className = 'customer-record-button';
      customerRecordButton.classList.add('hide-mobile');
      customerRecordButton.innerHTML = 'Customer Record';

      const workOrderEditButton = document.createElement('button');
      workOrderEditButton.id = 'edit-work-order-button';
      workOrderEditButton.addEventListener('click', (e) => {
        editWorkOrderData(document.querySelector('.active-work-order'));
      });
      workOrderEditButton.className = 'customer-record-button';
      workOrderEditButton.classList.add('hide-mobile');
      workOrderEditButton.innerHTML = 'Edit Work Order' + '<img src="/assets/edit_icon.png" alt="Edit Icon" width="18" height="18">';

      const closeWorkOrderButton = document.createElement('button');
      closeWorkOrderButton.addEventListener('click', (e) => {
        e.stopPropagation();
        const operationData = []
        const operations = document.querySelectorAll('.op-table-data-row');
        operations.forEach(op => {
          operationData.push(JSON.parse(op.getAttribute('data-jobinfo')));
          });
          console.log(operationData);
          
        });

      closeWorkOrderButton.className = 'close-work-order-button';
      closeWorkOrderButton.classList.add('hide-mobile');
      closeWorkOrderButton.innerHTML = 'Close Work Order' + '<img class="icon-white" src="/assets/close-circle.png" alt="Edit Icon" width="18" height="18">';

      buttonRow.appendChild(balanceButton);
      buttonRow.appendChild(addOpButton);
      buttonRow.appendChild(customerRecordButton);

      if(checkOverallStatus){
        buttonRow.appendChild(closeWorkOrderButton);
      }else{
        buttonRow.appendChild(workOrderEditButton);
      }

      containerRow.appendChild(buttonRow);

      containerCell.appendChild(containerDiv);
      containerDiv.appendChild(operationsTable);
      containerRow.appendChild(containerCell);

      afterRow.parentNode.insertBefore(containerRow, afterRow.nextSibling);

      await collectBalance(info.Customer_ID);
    })
    .catch(error => {
      console.error("Failed to fetch operations:", error);
    });

}

document.getElementById('customerRecordMobile').addEventListener('click', (e) => {
  const customerId = document.getElementById('updateCustomerId').value;
  e.stopPropagation();
  callOpenCustomerData(customerId);
  document.getElementById('Update').style.display = 'none';
});

async function collectBalance(customerId) {
  const customerDM = await getCustomerDMID(customerId);
  const balance = await getCustomerBalance(customerDM)

  if(balance){
  document.getElementById('balance-amount').innerHTML = `Current Balance: $${Number(balance).toFixed(2).toLocaleString()}`
  }

}


function fetchOperationsForWorkOrder(workOrderNumber) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase');

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');

      const getRequest = objectStore.getAll();

      getRequest.onsuccess = () => {
        const allJobs = getRequest.result;
        const filteredJobs = allJobs.filter(job => {
          return (
            job.Work_Order === workOrderNumber &&
            job.Category.toString() !== 'Declined'
          );
        });
        resolve(filteredJobs);
      };

      getRequest.onerror = (event) => {
        reject(new Error(`Error fetching operations from IndexedDB: ${event.target.error}`));
      };
    };

    request.onerror = (event) => {
      reject(new Error(`Error opening IndexedDB: ${event.target.error}`));
    };
  });
}


function opTableList(ops, operationsTable) {

  ops.sort((a, b) => {
    const dateA = a.Schedule_Date ? new Date(a.Schedule_Date) : new Date(a.Start_Date);
    const dateB = b.Schedule_Date ? new Date(b.Schedule_Date) : new Date(b.Start_Date);
    return dateA - dateB;
  })

  ops.forEach(function (r) {

    if(r.Status.toString() === 'Declined') {
      console.log(r);
      return;
    };

    var row = document.createElement("div")
    row.className = 'op-table-data-row'
    row.addEventListener('click', (e) => { opCheck(e) })
    row.setAttribute('data-jobInfo', JSON.stringify(r))
    row.setAttribute('data-id', r['Job_ID']);


    const descriptionWorkOrderDiv = document.createElement('div');
    descriptionWorkOrderDiv.className = 'op-description-work-order-div hide-mobile';

    const workOrder = document.createElement("p")
    workOrder.className = "op-data-work-order hide-mobile"
    workOrder.textContent = `WO: ${r.Work_Order}`;
    workOrder.setAttribute("contenteditable", "true")

    const description = document.createElement("p");
    description.className = 'op-data-description';
    description.textContent = r.Short_Description_DM ? `${r.OpCode}: ${r.Short_Description_DM.split(':')[0].split('(')[0]}` : `${r.OpCode}: ${r.Description.split(':')[0].split('(')[0]}`;
    description.style.fontWeight = 'bold';

    const opCode = document.createElement("p")
    opCode.className = 'op-data'
    opCode.textContent = `OpCode: ${r.OpCode}`

    descriptionWorkOrderDiv.appendChild(description);
    /* descriptionWorkOrderDiv.appendChild(workOrder); */

    const customerBoatDiv = document.createElement('div');
    customerBoatDiv.className = 'op-customer-boat-div hide-mobile';

    const customerName = document.createElement("p")
    customerName.className = "op-data"
    customerName.textContent = r.Customer_Name
    customerName.style.fontWeight = 'bold';

    const boatName = document.createElement("p")
    boatName.className = "op-data"
    boatName.textContent = r.Boat_Name

    customerBoatDiv.appendChild(customerName);
    customerBoatDiv.appendChild(boatName);

    const dateDiv = document.createElement('div');
    dateDiv.className = 'op-date-div';
    const requestDateP = document.createElement('p');
    const requestDateLabel = document.createElement('strong');
    requestDateLabel.textContent = 'Start: ';
    requestDateP.appendChild(requestDateLabel);
    requestDateP.className = 'op-start-date-info hide-mobile';
    let jobDate;

    if (r.Schedule_Date) {
      if (r.Schedule_Date.split('-')[0] === new Date().getFullYear().toString()) {
        jobDate = r.Schedule_Date.split('-')[1] + '-' + r.Schedule_Date.split('-')[2];
      } else {
        jobDate = r.Schedule_Date;
      }
    } else {
      if (r.Start_Date.split('-')[0] === new Date().getFullYear().toString()) {
        jobDate = r.Start_Date.split('-')[1] + '-' + r.Start_Date.split('-')[2];
      } else {
        jobDate = r.Start_Date;
      }
    }

    requestDateP.innerHTML += jobDate;
    dateDiv.appendChild(requestDateP);

    // Create the label for the schedule date input

    const scheduledDateDiv = document.createElement('div');
    scheduledDateDiv.className = 'op-scheduled-date-info';
    const scheduleDateLabel = document.createElement('strong');
    scheduleDateLabel.textContent = 'Scheduled: ';

    const scheduleDateP = document.createElement('input');
    scheduleDateP.type = 'date';
    scheduleDateP.className = 'schedule-date-info';
    scheduleDateP.value = r.Schedule_Date ? r.Schedule_Date : '';
    if (!r.Schedule_Date || r.Schedule_Date === '') {
      scheduleDateP.style.color = 'red';
    }

    scheduleDateP.addEventListener('change', async (e) => {
      const jobId = JSON.parse(e.target.closest('.op-table-data-row').getAttribute('data-jobInfo')).Job_ID;
      const date = e.target.value;
      updateJobInfo(jobId, 'Schedule_Date', date);
      updateJobRow(jobId, 'Schedule_Date', date);
      jobsArrayForWeek.forEach(job => {
        if(job.Job_ID?.toString() === jobId.toString()){
          job.Schedule_Date = date;
        }
      });
      applyFilters();
      applySorting();
      searchCall();
      searchJobs();
      searchSchedule();
      searchTech();
      updateWeekView();
    });

    // Append the label and input to the dateDiv
    scheduledDateDiv.appendChild(scheduleDateLabel);
    scheduledDateDiv.appendChild(scheduleDateP);
    dateDiv.appendChild(scheduledDateDiv);

    const tech = document.createElement("p");
    tech.className = "op-tech-select noPrint";
    const techDropdown = document.createElement('select');

    const deleteOption = document.createElement('option');
    deleteOption.value = '';
    deleteOption.textContent = 'Select Tech';
    techDropdown.appendChild(deleteOption);

    const techNames = globalTechList;
    techNames.forEach(name => {
      const optionElement = document.createElement('option');
      optionElement.value = name.split(':')[0];
      optionElement.textContent = name;
      techDropdown.appendChild(optionElement);
    });

    if (r.Techs) {
      techDropdown.value = r.Techs;
      techDropdown.setAttribute('data-selected-tech', r.Techs)
    }

    techDropdown.addEventListener("change", async function () {

      const data = JSON.parse(this.closest('.op-table-data-row').getAttribute('data-jobInfo'));
    
      
      if (data.Estimated_Hours === '' || data.Estimated_Hours === 0) {
        try {
          await showEstimatedHoursPopup(data, row);  
        } catch (error) {
          console.error(error);  
          return;
        }
      }
    
      console.log('Tech Selected:', this.value);
      
      try {
        const oldTech = this.getAttribute('data-selected-tech');
        let newTech = this.value;
        this.setAttribute('data-selected-tech', newTech);
    
        const id = data.Job_ID;
    
        if (oldTech) {
          console.log('Old Tech:', oldTech);
        }
    
        await addJobToTech(data, newTech);
        await updateJobRow(id, 'Techs', newTech);
        await updateWeekView();
        populateJobsForCurrentTech();
        populateJobsByWeek();
        updateTimestamps('jobData','data');
    
      } catch (error) {
        console.log(error);
      }
    });
    

    tech.appendChild(techDropdown)

    if (r.Status.toString() === "Ready to Bill" || r.Status.toString() === "Complete") {
      row.style.backgroundColor = '#ffcdd2'
    } else if (r.Status.toString() === 'Hold') {
      row.style.backgroundColor = "#b0b0b0"
      row.style.fontStyle = "italic"
    } else if (r.Status.toString() === 'Awaiting Approval') {
      row.style.backgroundColor = "#3e0054"
      row.style.color = "white"
      row.style.fontStyle = "italic"
    } else if (r.Status.toString() === 'In Progress') {
      row.style.backgroundColor = "#ecedaf"
      row.style.color = "black"
    } else if(r.Status.toString() === 'Closed'){
      row.style.backgroundColor = "#b88c8c"
      row.style.fontStyle = "italic"
    }

    const statusPriorityDiv = document.createElement('div');
    statusPriorityDiv.className = 'op-status-priority-div hide-mobile';

    const statusDiv = document.createElement('div');
    statusDiv.className = 'op-status-info';
    const statusP = document.createElement('p');
    statusP.textContent = 'Status:';
    statusP.style.fontWeight = 'bold';

    const currentStatus = document.createElement('p');
    currentStatus.className = 'op-current-status';
    currentStatus.textContent = r.Status === "Complete" ? "Ready to Bill" : r.Status;

    statusDiv.appendChild(statusP);
    statusDiv.appendChild(currentStatus);

    const priorityDiv = document.createElement('div');
    priorityDiv.className = 'op-priority-info';

    const priorityP = document.createElement('p');
    priorityP.textContent = 'Priority: ';
    priorityP.style.fontWeight = 'bold';

    const prioritySelect = document.createElement('select');
    prioritySelect.className = 'priority-select';
    prioritySelect.addEventListener('change', async function () {
      const id = JSON.parse(this.closest('.op-table-data-row').getAttribute('data-jobInfo')).Job_ID;
      const priority = this.value;
      await updateJobInfo(id, 'Priority', priority);
      await updateJobRow(id, 'Priority', priority);
    });

    const priorityOption = document.createElement('option');
    priorityOption.value = '';
    priorityOption.textContent = '';
    prioritySelect.appendChild(priorityOption);

    const priorities = ['Low', 'High'];
    priorities.forEach(p => {
      const option = document.createElement('option');
      option.value = p;
      option.textContent = p;
      prioritySelect.appendChild(option);
    });

    if (r.Priority) {
      prioritySelect.value = r.Priority;
    }

    priorityDiv.appendChild(priorityP);
    priorityDiv.appendChild(prioritySelect);

    statusPriorityDiv.appendChild(statusDiv);
    statusPriorityDiv.appendChild(priorityDiv);

  const noteDiv = document.createElement('div');
  noteDiv.className = 'notes-info';
  const jobNotesP = document.createElement('button');

  jobNotesP.addEventListener('click', async () => {
    const jobDiv = jobNotesP.closest('[data-id]');
    const jobId = jobDiv.getAttribute('data-id');
    const jobNotesData = await getJobData(jobId);

    document.getElementById('office-notes').classList.add('active');
    document.getElementById('note-job-id').value = jobId;

    const noteArea = document.getElementById('note-area');
    noteArea.innerHTML = '';

    if (jobNotesData.Notes) {
      Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
        const noteContainer = document.createElement('div');
        noteContainer.className = 'note-pair';
        noteContainer.id = key;
    
        const noteInfo = document.createElement('div');
        noteInfo.className = 'note-info';
    
        const noteText = document.createElement('p');
        noteText.className = 'note-p';
        noteText.textContent = note.Note;
    
        const techInfo = document.createElement('div');
        techInfo.className = 'note-info-tech';
    
        const techData = document.createElement('p');
        techData.className = 'note-tech-name';
        techData.textContent = `${note.Tech} | ${note.Date}, ${note.Time}`;
    
        techInfo.appendChild(techData);
    
    
        noteContainer.addEventListener('click', () => {
         console.log(jobId, key)
          const confirmationModal = document.createElement('div');
          confirmationModal.className = 'confirmation-note-delete-modal';
    
          const modalContent = document.createElement('div');
          modalContent.className = 'note-delete-modal-content';
    
          const confirmationMessage = document.createElement('p');
          confirmationMessage.textContent = 'Are you sure you want to delete this note?';
    
          const confirmButton = document.createElement('button');
          confirmButton.textContent = 'Confirm';
          confirmButton.className = 'confirm-delete';
    
          const cancelButton = document.createElement('button');
          cancelButton.textContent = 'Cancel';
          cancelButton.className = 'cancel-delete';
    
          confirmButton.addEventListener('click', async () => {
            await deleteNotes(jobId, key);
            noteContainer.remove();
            confirmationModal.remove();
          });
    
          cancelButton.addEventListener('click', () => {
            confirmationModal.remove();
          });
    
          modalContent.appendChild(confirmationMessage);
          modalContent.appendChild(confirmButton);
          modalContent.appendChild(cancelButton);
          confirmationModal.appendChild(modalContent);
    
          document.body.appendChild(confirmationModal);
        });
    
        noteInfo.appendChild(noteText);
        noteInfo.appendChild(techInfo);
        noteContainer.appendChild(noteInfo);
    
        noteArea.appendChild(noteContainer);
      });
    }
    
  });

  jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;

  if (job.Notes) {
    jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
  }

  noteDiv.appendChild(jobNotesP);


    if (r.Parts_Ordered == "Part Ordered") {
      row.classList.add('flash-animation');
    }

/*     if (r.Parts_Kit_Status.includes('Needs Kit')) {
      row.style.backgroundColor = "#3838B4"
      row.style.color = "white"
    } */

    const mobileOpDiv = document.createElement('div');
    mobileOpDiv.className = 'mobile-op-info show-mobile';
    mobileOpDiv.innerHTML = r.OpCode + ": " + r.Description.split(':')[0];
  
    const mobileCustomerBoatDiv = document.createElement('div');
    mobileCustomerBoatDiv.className = 'mobile-customer-boat-info show-mobile';
    mobileCustomerBoatDiv.innerHTML = r.Customer_Name.split(', ')[0] + ' / ' + r.Boat_Name;

    const opDivMain = document.createElement('div');
    opDivMain.className = 'op-div-main';

    opDivMain.appendChild(mobileOpDiv);
    opDivMain.appendChild(mobileCustomerBoatDiv);
    opDivMain.appendChild(descriptionWorkOrderDiv);
    /* opDivMain.appendChild(customerBoatDiv); */
    opDivMain.appendChild(dateDiv);
    opDivMain.appendChild(tech);
    opDivMain.appendChild(statusPriorityDiv);

    row.appendChild(opDivMain);

    row.appendChild(noteDiv);

    operationsTable.appendChild(row);
  })

};

async function createNewOpRow(opData) {
  if (opData.Status.toString() === 'Declined') {
    console.log(opData);
    return null;  // Skip if declined
  }

  var row = document.createElement("div");
  row.className = 'op-table-data-row';
  row.addEventListener('click', (e) => { opCheck(e); });
  row.setAttribute('data-jobInfo', JSON.stringify(opData));
  row.setAttribute('data-id', opData['Job_ID']);

  const descriptionWorkOrderDiv = document.createElement('div');
  descriptionWorkOrderDiv.className = 'op-description-work-order-div hide-mobile';

  const workOrder = document.createElement("p");
  workOrder.className = "op-data-work-order hide-mobile";
  workOrder.textContent = `WO: ${opData.Work_Order}`;
  workOrder.setAttribute("contenteditable", "true");

  const description = document.createElement("p");
  description.className = 'op-data-description';
  description.textContent = `${opData.OpCode}: ${opData.Description.split(':')[0]}`;
  description.style.fontWeight = 'bold';

  const opCode = document.createElement("p");
  opCode.className = 'op-data';
  opCode.textContent = `OpCode: ${opData.OpCode}`;

  descriptionWorkOrderDiv.appendChild(description);
  descriptionWorkOrderDiv.appendChild(workOrder);

  const customerBoatDiv = document.createElement('div');
  customerBoatDiv.className = 'op-customer-boat-div hide-mobile';

  const customerName = document.createElement("p");
  customerName.className = "op-data";
  customerName.textContent = opData.Customer_Name;
  customerName.style.fontWeight = 'bold';

  const boatName = document.createElement("p");
  boatName.className = "op-data";
  boatName.textContent = opData.Boat_Name;

  customerBoatDiv.appendChild(customerName);
  customerBoatDiv.appendChild(boatName);

  const dateDiv = document.createElement('div');
  dateDiv.className = 'op-date-div';

  const requestDateP = document.createElement('p');
  const requestDateLabel = document.createElement('strong');
  requestDateLabel.textContent = 'Requested: ';
  requestDateP.appendChild(requestDateLabel);
  requestDateP.className = 'op-start-date-info hide-mobile';

  let jobDate;
  if (opData.Schedule_Date) {
    if (opData.Schedule_Date.split('-')[0] === new Date().getFullYear().toString()) {
      jobDate = opData.Schedule_Date.split('-')[1] + '-' + opData.Schedule_Date.split('-')[2];
    } else {
      jobDate = opData.Schedule_Date;
    }
  } else {
    if (opData.Start_Date.split('-')[0] === new Date().getFullYear().toString()) {
      jobDate = opData.Start_Date.split('-')[1] + '-' + opData.Start_Date.split('-')[2];
    } else {
      jobDate = opData.Start_Date;
    }
  }

  requestDateP.innerHTML += jobDate;
  dateDiv.appendChild(requestDateP);

  const scheduledDateDiv = document.createElement('div');
  scheduledDateDiv.className = 'op-scheduled-date-info';
  const scheduleDateLabel = document.createElement('strong');
  scheduleDateLabel.textContent = 'Scheduled: ';

  const scheduleDateP = document.createElement('input');
  scheduleDateP.type = 'date';
  scheduleDateP.className = 'schedule-date-info';
  scheduleDateP.value = opData.Schedule_Date ? opData.Schedule_Date : '';
  if (!opData.Schedule_Date || opData.Schedule_Date === '') {
    scheduleDateP.style.color = 'red';
  }

  scheduleDateP.addEventListener('change', async (e) => {
    const jobId = JSON.parse(e.target.closest('.op-table-data-row').getAttribute('data-jobInfo')).Job_ID;
    const date = e.target.value;
    await updateJobInfo(jobId, 'Schedule_Date', date);  // Async operation
    await updateJobRow(jobId, 'Schedule_Date', date);  // Async operation
  });

  scheduledDateDiv.appendChild(scheduleDateLabel);
  scheduledDateDiv.appendChild(scheduleDateP);
  dateDiv.appendChild(scheduledDateDiv);

  const tech = document.createElement("p");
  tech.className = "op-tech-select noPrint";
  const techDropdown = document.createElement('select');

  const deleteOption = document.createElement('option');
  deleteOption.value = '';
  deleteOption.textContent = 'Select Tech';
  techDropdown.appendChild(deleteOption);

  const techNames = globalTechList;
  techNames.forEach(name => {
    const optionElement = document.createElement('option');
    optionElement.value = name.split(':')[0];
    optionElement.textContent = name;
    techDropdown.appendChild(optionElement);
  });

  if (opData.Techs) {
    techDropdown.value = opData.Techs;
    techDropdown.setAttribute('data-selected-tech', opData.Techs);
  }


  techDropdown.addEventListener("change", async function () {
    const row = this.closest('.op-table-data-row');
    const data = JSON.parse(row.getAttribute('data-jobInfo'));
  
    if (data.Estimated_Hours === '' || data.Estimated_Hours === 0) {
      try {
        await showEstimatedHoursPopup(data, jobDiv);
      } catch (error) {
        console.error(error);
        return;
      }
    }
  
    
    try {
      const oldTech = this.getAttribute('data-selected-tech');
      const newTech = this.value;
      this.setAttribute('data-selected-tech', newTech);
  
      const id = data.Job_ID;
  
      if (oldTech) {
        console.log('Old Tech:', oldTech);
      }
  
      await addJobToTech(data, newTech);
      await updateJobRow(id, 'Techs', newTech);
      await updateWeekView();
      populateJobsForCurrentTech();
      populateJobsByWeek();
      updateTimestamps('jobData','data');
  
    } catch (error) {
      console.log(error);
    }
  });
  

  tech.appendChild(techDropdown);

  if (opData.Status.toString() === "Ready to Bill" || opData.Status.toString() === "Complete") {
    row.style.backgroundColor = '#ffcdd2';
  } else if (opData.Status.toString() === 'Hold') {
    row.style.backgroundColor = "#b0b0b0";
    row.style.fontStyle = "italic";
  } else if (opData.Status.toString() === 'Awaiting Approval') {
    row.style.backgroundColor = "#3e0054";
    row.style.color = "white";
    row.style.fontStyle = "italic";
  } else if (opData.Status.toString() === 'In Progress') {
    row.style.backgroundColor = "#ecedaf";
    row.style.color = "black";
  } else if (opData.Status.toString() === 'Closed') {
    row.style.backgroundColor = "#b88c8c";
    row.style.fontStyle = "italic";
  }

  const statusPriorityDiv = document.createElement('div');
  statusPriorityDiv.className = 'op-status-priority-div hide-mobile';

  const statusDiv = document.createElement('div');
  statusDiv.className = 'op-status-info';
  const statusP = document.createElement('p');
  statusP.textContent = 'Status:';
  statusP.style.fontWeight = 'bold';

  const currentStatus = document.createElement('p');
  currentStatus.className = 'op-current-status';
  currentStatus.textContent = opData.Status;

  statusDiv.appendChild(statusP);
  statusDiv.appendChild(currentStatus);

  const priorityDiv = document.createElement('div');
  priorityDiv.className = 'op-priority-info';

  const priorityP = document.createElement('p');
  priorityP.textContent = 'Priority: ';
  priorityP.style.fontWeight = 'bold';

  const prioritySelect = document.createElement('select');
  prioritySelect.className = 'priority-select';
  prioritySelect.addEventListener('change', async function () {
    const id = JSON.parse(this.closest('.op-table-data-row').getAttribute('data-jobInfo')).Job_ID;
    const priority = this.value;
    await updateJobInfo(id, 'Priority', priority);  // Async operation
    await updateJobRow(id, 'Priority', priority);  // Async operation
  });

  const priorityOption = document.createElement('option');
  priorityOption.value = '';
  priorityOption.textContent = '';
  prioritySelect.appendChild(priorityOption);

  const priorities = ['Low', 'High'];
  priorities.forEach(p => {
    const option = document.createElement('option');
    option.value = p;
    option.textContent = p;
    prioritySelect.appendChild(option);
  });

  if (opData.Priority) {
    prioritySelect.value = opData.Priority;
  }

  priorityDiv.appendChild(priorityP);
  priorityDiv.appendChild(prioritySelect);

  statusPriorityDiv.appendChild(statusDiv);
  statusPriorityDiv.appendChild(priorityDiv);

  const noteDiv = document.createElement('div');
  noteDiv.className = 'op-notes-div';
  const jobNotesP = document.createElement('button');

  jobNotesP.addEventListener('click', async () => {
    const jobDiv = jobNotesP.closest('[data-jobInfo]');
    const jobId = JSON.parse(jobDiv.getAttribute('data-jobInfo')).Job_ID;
    const jobNotesData = await getJobData(jobId);  // Async operation

    document.getElementById('office-notes').classList.add('active');
    document.getElementById('note-job-id').value = jobId;

    const noteArea = document.getElementById('note-area');
    noteArea.innerHTML = '';

    if (jobNotesData.Notes) {
      Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
        const noteContainer = document.createElement('div');
        noteContainer.className = 'note-pair';
        noteContainer.id = key;

        const noteInfo = document.createElement('div');
        noteInfo.className = 'note-info';

        const noteText = document.createElement('p');
        noteText.textContent = note.Note;

        const techInfo = document.createElement('div');
        techInfo.className = 'note-info-tech';
        techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;

        const deleteButton = document.createElement('button');
        deleteButton.textContent = 'Delete';
        deleteButton.className = 'delete-note';
        deleteButton.addEventListener('click', async () => {
          await deleteNotes(jobId, key);  // Async operation
          noteContainer.remove();
        });

        noteInfo.appendChild(noteText);
        noteInfo.appendChild(techInfo);
        noteInfo.appendChild(deleteButton);
        noteContainer.appendChild(noteInfo);

        noteArea.appendChild(noteContainer);
      });
    }
  });

  jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;

  if (opData.Notes) {
    jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
  }

  noteDiv.appendChild(jobNotesP);

  const opDivMain = document.createElement('div');
  opDivMain.className = 'op-div-main';

  opDivMain.appendChild(descriptionWorkOrderDiv);
  opDivMain.appendChild(customerBoatDiv);
  opDivMain.appendChild(dateDiv);
  opDivMain.appendChild(tech);
  opDivMain.appendChild(statusPriorityDiv);

  row.appendChild(opDivMain);
  row.appendChild(noteDiv);

  return row;
}



async function getPartById(id) {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    const transaction = db.transaction(['inventoryData'], 'readonly');
    const store = transaction.objectStore('inventoryData');
    
    return new Promise((resolve, reject) => {
      const request = store.get(id);
      request.onsuccess = () => {
        if (request.result) {
          resolve(request.result);
        } else {
          resolve(null); // Part not found
        }
      };
      request.onerror = () => reject(request.error);
    });

  } catch (error) {
    console.error('Error retrieving part:', error);
    throw error;
  }
}


document.getElementById('open-part-kit').addEventListener('click', async (e) => {
  e.stopPropagation();
  const customerId = document.getElementById('updateCustomerId').value;
  const boatId = document.getElementById('updateBoatId').value;

  const customerData = await getCustomerDataById(customerId, boatId);

  const partsKits = customerData.boatData.Parts_Kits;
  
  displayPartsKits(partsKits);
});

document.getElementById('create-new-kit').addEventListener('click', async (e) => {
  e.stopPropagation();
  const kitName = await showKitPopup();
  if(!kitName) return;
  displayKitParts('', kitName);
  document.getElementById('currentPartKitName').value = kitName;
});

async function showKitPopup() {

  return new Promise((resolve) => {
    const kitPopup = createPopup('Enter Kit Name');
    kitPopup.className = 'kit-popup';
    const kitInput = document.createElement('input');

    kitInput.type = 'text';
    kitInput.placeholder = 'Kit Name';
    kitInput.className = 'new-kit-input';

    kitInput.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        const kitName = kitInput.value.trim();
        if (kitName !== '') {
          closePopup(kitPopup);
          resolve(kitName);
        }
      }
    });

    kitPopup.appendChild(kitInput);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Submit';
    confirmButton.classList.add('pop-up-submit-button');

    confirmButton.addEventListener('click', () => {
      const kitName = kitInput.value.trim();
      if (kitName !== '') {
        closePopup(kitPopup);

        resolve(kitName);
      }
    });

    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.classList.add('pop-up-cancel-button');
    cancelButton.addEventListener('click', () => {
      closePopup(kitPopup);
      resolve(null);
    });

    kitPopup.appendChild(confirmButton);
    kitPopup.appendChild(cancelButton);

    document.body.appendChild(kitPopup);
    kitInput.focus();
  });
}

async function displayPartsKits(kits) {
  // Get the modal from the HTML and make it visible
  const kitModal = document.getElementById('kit-select-modal');
  const kitCollection = document.getElementById('kit-collection');
  kitCollection.innerHTML = ''; // Clear existing buttons

  if(kits){
  Object.keys(kits).forEach(kitName => {
    const kitButton = document.createElement('button');
    kitButton.className = 'kit-select-button';
    kitButton.textContent = kitName;
    kitButton.addEventListener('click', () => displayKitParts(kits[kitName], kitName));
    kitCollection.appendChild(kitButton);
  });
}

  // Show the kit selection modal
  kitModal.style.display = 'flex';
}

function displayKitParts(kit, kitName) {

  document.getElementById('currentPartKitName').value = kitName;
  // Hide the kit selection modal
  const kitModal = document.getElementById('kit-select-modal');
  kitModal.style.display = 'none';

  // Show the parts modal and update its content
  const partsModal = document.getElementById('kit-parts-modal');
  const partsContainer = document.getElementById('parts-container');
  const kitNameHeader = document.getElementById('kit-name-header');
  partsContainer.innerHTML = ''; // Clear existing parts
  kitNameHeader.textContent = `${kitName} Parts`;

  // Display each part
  Object.entries(kit).forEach(async ([partNumber, partInfo]) => {
    let partData = { Location: 'N/A' };
    if (partInfo.id) {
      partData = await getPartById(partInfo.id);
    }

    kit[partNumber].Location = partData.Location;

    const quantity = document.createElement('input');
    quantity.className = 'kit-part-quantity'
    quantity.type = 'number';
    quantity.value = partInfo.qty;
    quantity.addEventListener('input', async (e) => {
      partInfo.qty = e.target.value;
      await updatePartsKit(document.getElementById('updateCustomerId').value, document.getElementById('updateBoatId').value, kitName, kit);
    });

    const partDiv = document.createElement('div');
    partDiv.className = 'part-row';
    partDiv.setAttribute('data-part-id', partInfo.id);
    partDiv.innerHTML = `
      <div>
        ${partNumber}
        <br/>
        <small class="gray-text">${partInfo.description}</small>
      </div>
      <div id="quantity-container"></div>
      <div>${partData.Location}</div>
      <div><button class="exclude-btn">Exclude</button></div>
    `;

    partDiv.querySelector('#quantity-container').appendChild(quantity);

    const excludeBtn = partDiv.querySelector('.exclude-btn');
    excludeBtn.addEventListener('click', () => {
      partDiv.classList.toggle('exclude');
      excludeBtn.textContent = partDiv.classList.contains('exclude') ? 'Include' : 'Exclude';
    });

    partsContainer.appendChild(partDiv);
  });

  // Add to Job button functionality
  const addToJobBtn = document.getElementById('add-to-job');
  addToJobBtn.onclick = () => {
    const includedParts = Array.from(partsContainer.querySelectorAll('.part-row:not(.exclude)')).map(partDiv => {
      const divs = partDiv.querySelectorAll('div');
      const partNumber = divs[0].childNodes[0].textContent.trim();
      const qty = divs[1].querySelector('input').value;
      const description = divs[0].querySelector('small').textContent.trim();
      const id = partDiv.getAttribute('data-part-id');

      return {
        partNumber,
        qty,
        description,
        id
      };
    });

    addPartsKitToJob(includedParts);
    partsModal.style.display = 'none';
  };

  // Check for print button and remove if it exists
  if (document.querySelector('.print-kit-button')) {
  document.querySelector('.print-kit-button').remove();
  }

  partsModal.style.display = 'flex';

  const printButton = document.createElement('button');
  printButton.className = 'print-kit-button';
  printButton.textContent = 'Print Kit';
  printButton.addEventListener('click', () => printKitPartsTable(kit, kitName));

  document.getElementById('part-kit-buttons').appendChild(printButton); 
}

function printKitPartsTable(kit, kitName) {
  console.log('Printing kit:', kitName, kit);
  // Create a new window for printing
  const printWindow = window.open('', '_blank');
  
  // Sort the kit parts by Location
  const sortedKitParts = Object.entries(kit).sort((a, b) => {
    const locationA = a[1].Location || '';
    const locationB = b[1].Location || '';
    return locationB.localeCompare(locationA);
  });

  // Start building the HTML content
  let htmlContent = `
    <html>
    <head>
      <title>${kitName} Parts Kit</title>
      <style>
        body { font-family: Arial, sans-serif; }
        table { width: 100%; border-collapse: collapse; }
        th, td { border: 1px solid black; padding: 8px; text-align: left; }
        th { background-color: #f2f2f2; }
        .part-description { font-size: 0.8em; color: #666; }
      </style>
    </head>
    <body>
      <h1>${kitName} Parts Kit</h1>
      <table>
        <thead>
          <tr>
            <th style="text-align: center; vertical-align: middle; font-size: 1.25rem; background-color:darkgray; color:white;">Part Number</th>
            <th style="text-align: center; vertical-align: middle; font-size: 1.25rem; background-color:darkgray; color:white;">Description</th>
            <th style="text-align: center; vertical-align: middle; font-size: 1.25rem; background-color:darkgray; color:white;">Quantity</th>
            <th style="text-align: center; vertical-align: middle; font-size: 1.25rem; background-color:darkgray; color:white;">Location</th>
            <th style="text-align: center; vertical-align: middle; font-size: 1.25rem; background-color:darkgray; color:white;">Included</th>
          </tr>
        </thead>
        <tbody>
  `;

  // Add each part to the table
  sortedKitParts.forEach(([partNumber, partInfo]) => {
    htmlContent += `
      <tr>
        <td style="text-align: center; vertical-align: middle;">${partNumber}</td>
        <td style="text-align: center; vertical-align: middle;">
          <br>
          <span>${partInfo.description || ''}</span>
        </td>
        <td style="text-align: center; vertical-align: middle;">${partInfo.qty}</td>
        <td style="text-align: center; vertical-align: middle;">${partInfo.Location || ''}</td>
        <td style="text-align: center; vertical-align: middle;"><input type="checkbox" style="width: 30px; height: 30px; text-align:center;"></td>
      </tr>
    `;
  });

  // Close the table and HTML structure
  htmlContent += `
        </tbody>
      </table>
    </body>
    </html>
  `;

  // Write the content to the new window and print
  printWindow.document.write(htmlContent);
  printWindow.document.close();
  printWindow.print();
}

document.getElementById('add-part-to-kit').addEventListener('click', async (e) => {
  e.stopPropagation();
  document.getElementById('partSelectModal').style.display = 'flex';
});

async function addPartToPartsKit(partData, kitName) {
  // Retrieve the customer and boat IDs from the DOM
  const customerId = document.getElementById('updateCustomerId').value;
  const boatId = document.getElementById('updateBoatId').value;

  // Fetch the current customer data
  const customerData = await getCustomerDataById(customerId, boatId);

  console.log(customerData);

  if(!customerData.boatData.Parts_Kits){
    customerData.boatData.Parts_Kits = {};
  }

  let partsKits = customerData.boatData.Parts_Kits;


  if (!partsKits[kitName]) {
    partsKits[kitName] = {};
  }

  const data = await showKitQuantityPopup(partData.Part_Number, partData.Description);

  const partNumber = partData.Part_Number;
  partsKits[kitName][partNumber] = {
    partNumber: partData.Part_Number,
    description: partData.Description ? partData.Description : data.New_Description,
    qty: data.Qty,
    id: partData.id,
  };

  await updatePartsKit(customerId, boatId, kitName, partsKits[kitName]);

  displayKitParts(partsKits[kitName], kitName)
}

async function showKitQuantityPopup(partNumber, description) {
  // If the description is blank, ask the user for it
  if (!description || description.trim() === '') {
    description = await showDescriptionPopup(partNumber);
  }

  return new Promise((resolve) => {
    const qtyPopup = createPopup('Enter Quantity');
    const qtyInput = document.createElement('input');

    qtyInput.type = 'number';
    qtyInput.placeholder = 'Quantity';
    qtyInput.className = 'new-qty-input';

    qtyInput.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        const quantity = qtyInput.value.trim();
        if (quantity !== '') {
          closePopup(qtyPopup);
          const data = {
            "Qty": quantity,
            "New_Description": description
          };
          resolve(data); // Resolve the Promise with the data
        }
      }
    });

    qtyPopup.appendChild(qtyInput);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Submit';
    confirmButton.classList.add('pop-up-submit-button')

    confirmButton.addEventListener('click', () => {
      const quantity = qtyInput.value.trim();
      if (quantity !== '') {
        closePopup(qtyPopup);
        const data = {
          "Qty": quantity,
          "New_Description": description
        };
        resolve(data);
      }
    });

    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.classList.add('pop-up-cancel-button');
    cancelButton.addEventListener('click', () => {
      closePopup(qtyPopup);
      resolve(null);
    });

    qtyPopup.appendChild(confirmButton);
    qtyPopup.appendChild(cancelButton);

    document.body.appendChild(qtyPopup);
    qtyInput.focus();
  });
}

async function updatePartsKit(customerId, boatId, kitName, kitData) {

  console.log(customerId, boatId, kitName, kitData);

  try {
   
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/customers/${customerId}/Boats/${boatId}/Parts_Kits.json?auth=${idToken}`;

    const partialUpdate = { [kitName]: kitData };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updatePartsKitDB(customerId, boatId, kitName, kitData);
    updateTimestamps('customerData', 'customers');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

async function updatePartsKitDB(customerId, boatId, kitName, kitData) {
  try {

    const dbPromise = indexedDB.open('openDatabase', indexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    const transaction = db.transaction(['customerData'], 'readwrite');
    const store = transaction.objectStore('customerData');
    
    // Fetch existing customer data
    const existingCustomerData = await new Promise((resolve, reject) => {
      const request = store.get(customerId);
      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event.target.error);
    });

    if(!existingCustomerData.Boats[boatId].Parts_Kits){
      existingCustomerData.Boats[boatId].Parts_Kits = {};
    };

    existingCustomerData.Boats[boatId].Parts_Kits[kitName] = kitData;

    // Save the updated customer data back to IndexedDB
    await new Promise((resolve, reject) => {
      const updateRequest = store.put(existingCustomerData);
      updateRequest.onsuccess = () => resolve();
      updateRequest.onerror = (event) => reject(event.target.error);
    });

    console.log('Customer data updated successfully in both Firebase and IndexedDB');
  } catch (error) {
    console.error('Error updating customer data:', error);
    throw error;
  }
}


async function addPartsKitToJob(kit) {

    kit.forEach(async part => {

      const partNumber = part.partNumber;
      const description = part.description;
      const quantity = part.qty;
      const today = await getTodaysDate();
      const id = part.id;

      const partData = {
        'Part_Number': partNumber,
        'Quantity': quantity,
        'Category': document.getElementById('currentCategory').value,
        'Op_Type': document.getElementById('currentOpType').value,
        'Job_ID': document.getElementById('currentJobId').value,
        'ID': id,
        'Boat_Name': document.getElementById('currentBoat').value,
        'Customer_Name': document.getElementById('currentName').value,
        'Time_Submitted': today,
        'Work_Order': document.getElementById('currentWorkOrder').value,
        'Tech': getTechSelector(),
        'Description': description
      };

      await addPartsFirebase(partData);
      await updatePartsIndex(document.getElementById('currentJobId').value, partData);
      await reduceInventory(id, quantity);
    });

    await updateTimestamps('jobData', 'data');
    await generatePartsTable(document.getElementById('currentJobId').value);
}


async function addOperation(e) {

  e.stopPropagation()

  const storage = document.getElementById('winter-storage-section')
  const winterSpring = document.getElementById('storage-work-order-section')
  const dates = document.getElementById('work-order-date-section')
  const general = document.getElementById('general-work-order-section')

  storage.classList.add('hidden-form-section');
  dates.classList.add('hidden-form-section');
  winterSpring.classList.add('hidden-form-section')
  winterSpring.style.display='none';
  general.classList.remove('hidden-form-section');
  general.style.display='flex';

  document.getElementById('general-work-order-button').style.display = 'none';
  document.getElementById('customerDatabaseButton').style.display = 'none';
  document.querySelector('.work-order-type').style.display = 'none';


  const rowData = JSON.parse(document.getElementById('operations-table').querySelector('.op-table-data-row').getAttribute('data-jobInfo'));
  const workOrderNumber = rowData.Work_Order;

  const allRows = document.getElementById('operations-table').querySelectorAll('.op-table-data-row');

  currentOpCodes = []
  for (let row of allRows) {
    const info = JSON.parse(row.getAttribute('data-jobInfo'));

    if (info.OpCode) {
      currentOpCodes.push(info.OpCode);
    }
  }

  console.log(currentOpCodes);

  document.getElementById('overallCategory').value = rowData.Category;

  let codeTable = document.getElementById('op-code-body').querySelectorAll('.op-code-row');

  for (let code of codeTable) {
    code.classList.remove('hidden')
  }

  const customerData = await getCustomerRecord(rowData.Customer_ID);
  console.log(customerData);
  const boatData = customerData.Boats[rowData.Boat_ID];
  console.log(boatData);

  let customerName;

  if(customerData.Last_Name){
    customerName = customerData.Last_Name + ', ' + customerData.First_Name;
  }else{
    customerName = customerData.First_Name;
  };

  document.getElementById('work-order-form-customer-name').value = customerName;

  document.getElementById('work-order-form-customer-id').value = customerData.Customer_ID;
  document.getElementById('work-order-form-customer-DMid').value = customerData.DM_Customer;



  if (boatData.Boat_Name != null) {
    document.getElementById('work-order-form-boat-name').value = boatData.Boat_Name;
    document.getElementById('work-order-form-boat-id').value = boatData.Boat_ID;
    document.getElementById('work-order-form-boat-DMid').value = boatData.DM_Boat;
    document.getElementById('work-order-form-boat-mooring-number-slip').value = boatData.Mooring_Number;
    document.getElementById('work-order-form-boat-length').value = boatData.Boat_Length;
    document.getElementById('work-order-form-boat-beam').value = boatData.Boat_Beam;
    document.getElementById('work-order-form-boat-combo').value = boatData.Combo_Key;
    document.getElementById('work-order-form-boat-mooring-tag').value = boatData.Tag_Number;
    document.getElementById('work-order-form-boat-mooring-weight').value = boatData.Mooring_Weight;
    document.getElementById('work-order-form-boat-bottom-color').value = boatData.Bottom_Paint_Color;
    document.getElementById('work-order-form-boat-bottom-paint').value = boatData.Bottom_Paint_Type;
    document.getElementById('work-order-form-boat-mast-length').value = boatData.Mast_Length;
    document.getElementById('work-order-form-boat-engine-count').value = boatData.Number_of_Engines;
    document.getElementById('work-order-form-boat-battery-count').value = boatData.Number_of_Batteries;
    document.getElementById('work-order-form-boat-engine-type').value = boatData.Engine_Type;
    document.getElementById('work-order-form-boat-boat-type').value = boatData.Boat_Type;

  };

  document.getElementById('work-order-form-customer-email').value = customerData.Email;
  document.getElementById('work-order-form-customer-phone').value = customerData.Phone;
  document.getElementById('work-order-form-customer-street').value = customerData.Street;
  document.getElementById('work-order-form-customer-city').value = customerData.City;
  document.getElementById('work-order-form-customer-state').value = customerData.State;
  document.getElementById('work-order-form-customer-zip').value = customerData.Zip;

  document.getElementById('winterWorkOrder').value = rowData.Work_Order;
  document.getElementById('springWorkOrder').value = rowData.Work_Order;
  document.getElementById('generalWorkOrder').value = rowData.Work_Order;
  document.getElementById('shortDesc').value = rowData.Short_Description;

  let field = document.createElement('fieldset');
  field.id = workOrderNumber.toString();
  let legend = document.createElement('legend');
  legend.innerHTML = workOrderNumber.toString();
  field.appendChild(legend);
  document.getElementById('activeJobs').appendChild(field);


  document.getElementById('work-order-form-dates-launch-date').value = boatData.Launch_Date;
  document.getElementById('work-order-form-dates-haul-date').value = boatData.Haul_Date;

  addOpen("add")
}


function addOpen(newOp) {

  var add = document.getElementById("Add")
  add.style.display = "flex"
  setTimeout(() => {
    document.querySelector('.modal-inside').scrollTop = 0
  }, 100)


  add.addEventListener('click', (e) => {
    if (e.target.id == 'exit' || e.target.id == 'exitIcon') {
      add.style.display = "none"

      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;

      document.getElementById('general-work-order-button').style.display = 'flex'
      document.getElementById('customerDatabaseButton').style.display = 'flex'
      document.getElementById('search').focus()
      document.getElementById("work-order-form-customer-name").value = ""
      document.getElementById("work-order-form-customer-id").value = ""
      document.getElementById("work-order-form-customer-DMid").value = ""
      document.getElementById("work-order-form-customer-street").value = ""
      document.getElementById("work-order-form-customer-city").value = ""
      document.getElementById("work-order-form-customer-state").value = ""
      document.getElementById("work-order-form-customer-zip").value = ""
      document.getElementById("work-order-form-boat-name").value = ""
      document.getElementById("work-order-form-boat-id").value = ""
      document.getElementById("work-order-form-boat-DMid").value = ""
      document.getElementById("generalWorkOrder").value = ""
      document.getElementById("winterWorkOrder").value = ""
      document.getElementById("springWorkOrder").value = ""
      document.getElementById('work-order-form-dates-launch-date').value = nextYear + "-04-01";
      document.getElementById('work-order-form-dates-haul-date').value = ""
      document.getElementById('work-order-form-customer-phone').value = "";
      document.getElementById('work-order-form-customer-email').value = "";
      document.getElementById('work-order-form-storage-confirm').checked = false
      document.getElementById('work-order-form-boat-mooring-number-slip').value = ''
      document.getElementById('work-order-form-boat-length').value = ''
      document.getElementById('work-order-form-boat-beam').value = ''
      document.getElementById('work-order-form-boat-combo').value = ''
      document.getElementById('work-order-form-boat-mooring-tag').value = ''
      document.getElementById('work-order-form-boat-bottom-color').value = ''
      document.getElementById('work-order-form-boat-bottom-paint').value = ''
      document.getElementById('work-order-form-boat-mast-length').value = ''
      document.getElementById('work-order-form-boat-engine-count').value = ''
      document.getElementById('work-order-form-boat-battery-count').value = ''
      document.getElementById('work-order-form-boat-engine-type').value = ''
      document.getElementById('work-order-form-boat-boat-type').value = ''
      document.getElementById('work-order-form-boat-fuel-type').value = ''
      document.getElementById('work-order-form-boat-fuel-capacity').value = ''
      document.getElementById('shortDesc').value = ""

      var Parent = document.getElementById('activeJobs')
      while (Parent.hasChildNodes()) {
        Parent.removeChild(Parent.firstChild)
      }

      document.getElementById('add-ops-btn').classList.add('hidden')
      document.getElementById('add-btn').classList.remove('hidden')

      let codeTable = document.getElementById('op-code-body').querySelectorAll('.op-code-row')

      const activeJobs = document.getElementsByClassName('active')

      for (let i = 0; i < activeJobs.length; i++) {

        const number = activeJobs[0].id.at(-1)

        document.getElementById("category" + number).value = ""
        document.getElementById("opType" + number).value = ""
        document.getElementById('opCode' + number).value = ""
        document.getElementById("description" + number).value = ""
        document.getElementById("date" + number).value = ""
        document.getElementById("tech" + number).value = ""
        document.getElementById("status" + number).value = ""
        document.getElementById("job" + number).classList.remove('active')
        document.getElementById("job" + number).classList.add('inactive')
        document.getElementById('addJobButton' + number).classList.remove('hidden')
        document.getElementById('deleteJobButton' + number).classList.add('hidden')
        document.getElementById('jobExit' + number).classList.remove('hidden')
        document.getElementById('jobMinimize' + number).classList.add('hidden')

      }

      var Parent = document.getElementById('activeJobs')
      while (Parent.hasChildNodes()) {
        Parent.removeChild(Parent.firstChild)
      }

      document.getElementById('generalWorkOrder').scrollIntoView()
    }
  })

  if (newOp) {
    return
  } else { document.getElementById('customerDatabaseButton').click() }
}

document.getElementById('exit-invoice').addEventListener('click', () => {
  document.getElementById('invoiceModal').style.display = 'none';
})

document.getElementById('receivePart').addEventListener('click', () => {
  let div = document.getElementById('receive-div');
  div.classList.toggle('hidden')
})


function opCheck(e, jobData) {

  let data;
  let row;

  if (e) {
    e.stopPropagation();
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON' || e.target.parentElement.tagName === 'BUTTON' || e.target.className === "customCheck" || e.target.tagName === 'SELECT') {
      return
    }
    if (e.target.closest('.op-table-data-row')) {
      e.target.closest('.op-table-data-row').classList.add('selectedRow')
      row = e.target.closest('.op-table-data-row');
      selectedJobRow = row;
    }

    if (e.target.closest('.op-table-data-row')) {
      e.target.closest('.op-table-data-row').classList.add('selectedRow')
      row = e.target.closest('.op-table-data-row');
      selectedJobRow = row;
    }

    if (e.target.closest('.job-item')) {
      row = e.target.closest('.job-item');
    }

    if (e.target.closest('.haul-item')) {
      row = e.target.closest('.haul-item');
    }

    if (row && row.getAttribute('data-jobInfo')) {
      data = JSON.parse(row.getAttribute('data-jobInfo'));
    }

    if (row && row.getAttribute('data-jobInfo')) {
      data = JSON.parse(row.getAttribute('data-jobInfo'));
    };
  } else {
    data = jobData
  }

  globalJobId = data.Job_ID
  globalShrink = data
  console.log(data);
  document.getElementById('currentJobId').value = data.Job_ID

  document.getElementById('jobId').value = data.Job_ID
  document.getElementById('updateName').value = data.Customer_Name
  document.getElementById('currentName').value = data.Customer_Name
  document.getElementById('currentCustomerName').innerHTML = data.Customer_Name;
  document.getElementById('currentBoatName').innerHTML = data.Boat_Name;
  document.getElementById('currentOpenOp').innerHTML = "Op: " + data.Description.split(":")[0]
  document.getElementById('currentShortDesc').value = data.Short_Description

  document.getElementById('updateFirstName').value = data.Customer_Name.split(', ')[1]
  document.getElementById('updateLastName').value = data.Customer_Name.split(', ')[0]
  document.getElementById('updateCustomerId').value = data.Customer_ID

  document.getElementById('updateBoat').value = data.Boat_Name.toString().replace(/&amp;/g, '&');
  document.getElementById('currentBoat').value = data.Boat_Name.toString().replace(/&amp;/g, '&');

  document.getElementById('updateBoatId').value = data.Boat_ID


  let customerData = globalCustomerData.find(customer => customer.Customer_ID === data.Customer_ID);

  document.getElementById('updateEmail').value = customerData.Email;
  document.getElementById('updateComboKey').value = customerData.Boats[data.Boat_ID].Combo_Key;
  document.getElementById('updateMooringNumber').value = customerData.Boats[data.Boat_ID].Mooring_Number;
  document.getElementById('updateWorkOrder').value = data.Work_Order

  document.getElementById('updateCategory').value = data.Category

  document.getElementById('updateOpType').value = data.Op_Type

  document.getElementById('updateDescription').value = data.Description.replace(/&amp;/g, '&');

    document.getElementById('updateTechNotes').value = data.Tech_Notes ? data.Tech_Notes : '';

  document.getElementById('currentJobDesc').value = data.Description.replace(/&amp;/g, '&');

  document.getElementById('currentTech').value = data.Techs
  document.getElementById('updateStatus').value = data.Status
  document.getElementById('currentStatus').value = data.Status
  console.log(data.Status);
  document.getElementById('updateHours').value = data.Estimated_Hours
  document.getElementById('updateAmount').value = data.Charge
  document.getElementById('updateStartDate').value = data.Start_Date;
  document.getElementById('updateShortDesc').value = data.Short_Description.replace(/&amp;/g, '&');

  document.getElementById('updateOpCode').value = data.OpCode.toString()
  document.getElementById('currentOpenOpCode').innerHTML = data.OpCode.toString();
  document.getElementById('currentOpCode').value = data.OpCode
  document.getElementById("currentWorkOrder").value = data.Work_Order
  document.getElementById("work-order-form-dates-launch-date").value = data.Launch_Date
  document.getElementById("work-order-form-dates-haul-date").value = data.Haul_Date
  document.getElementById('partWorkOrder').value = data.Work_Order
  document.getElementById('currentCategory').value = data.Category
  document.getElementById('currentOpType').value = data.Op_Type
  document.getElementById('currentCustomerId').value = data.Customer_ID
  document.getElementById('currentBoatId').value = data.Boat_ID
  document.getElementById('partJobId').value = data.Job_ID

  if (data.Status == 'Hold') {
    document.getElementById('holdButton').style.display = 'none'
    document.getElementById('removeHoldButton').style.display = 'block'
  } else {
    document.getElementById('holdButton').style.display = 'block'
  }

  if(data.Description.toLowerCase().includes('shrinkwrap boat') && data.Status !== 'Vents Installed'){
    document.getElementById('shrinkwrap-menu-button').style.display = 'flex';
  }else{
    document.getElementById('shrinkwrap-menu-button').style.display = 'none';
  }

document.getElementById('Update').classList.add('active')

if(data.Status === 'Complete' || data.Status === 'Ready to Bill' || data.Status === 'Closed'){
  document.querySelector('.job-complete-button').style.display = "none";
  document.getElementById('open-billing').style.display = "block";
}else{
  document.querySelector('.job-complete-button').style.display = "block";
  document.getElementById('open-billing').style.display = "none";
}

if (data.Op_Type === 'Shrinkwrap'){
  document.getElementById('shrinkwrap-included').classList.remove('hidden');
}else{
  document.getElementById('shrinkwrap-included').classList.add('hidden');
}

  let oldButtons = document.querySelectorAll('.map-button');
  if (oldButtons) {
    oldButtons.forEach(button => button.remove());
  };

  let mapButton = document.createElement('button')
  mapButton.id = 'showMap'
  mapButton.className = 'map-button'
  mapButton.innerHTML = 'Show on Map'
  mapButton.addEventListener('click', () => {
    document.getElementById('mapView').style.display = 'flex'
  })
  mapButton.setAttribute('data-boatId', data.Boat_ID)
  /* document.getElementById('operations-field').appendChild(mapButton) */

}

async function editWorkOrderData(element) {
  const operations = Array.from(document.querySelectorAll('#operations-table .op-table-data-row'));

  const moveOperationSelectContainer = document.getElementById('moveOperationSelectContainer');
  moveOperationSelectContainer.innerHTML = ''; // Clear existing checkboxes

  // Map operations to an array of objects with parsed data
  const operationData = operations.map(operation => {
    const data = JSON.parse(operation.getAttribute('data-jobInfo'));
    return {
      opCode: data.OpCode,
      description: data.Description.includes(':') ? data.Description.split(':')[0] : data.Description,
      jobId: data.Job_ID,
      workOrder: data.Work_Order,
      data
    };
  });

  // Sort operations by OpCode
  operationData.sort((a, b) => a.opCode.localeCompare(b.opCode));

  // Render sorted operations
  operationData.forEach(({ opCode, description, jobId, workOrder, data }) => {
    // Create a checkbox input
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.value = opCode;
    checkbox.id = `operation-${opCode}`;
    checkbox.setAttribute('data-jobid', jobId);
    checkbox.setAttribute('data-workorder', workOrder);
    checkbox.setAttribute('data-jobinfo', JSON.stringify(data));

    // Create a label for the checkbox
    const label = document.createElement('p');
    label.htmlFor = `operation-${opCode}`;
    label.textContent = `${opCode} - ${description}`;

    // Wrap checkbox and label in a container
    const div = document.createElement('div');
    div.classList.add('checkbox-item');
    div.appendChild(checkbox);
    div.appendChild(label);

    moveOperationSelectContainer.appendChild(div);
  });

  const jobData = JSON.parse(element.getAttribute('data-jobinfo'));
  const currentShortDescription = jobData.Short_Description;
  const currentStartDate = jobData.Start_Date;
  const customerData = await getCustomerRecord(jobData.Customer_ID);
  const boatData = customerData.Boats[jobData.Boat_ID];

  document.getElementById('update-work-order-customer-id').value = jobData.Customer_ID;
  document.getElementById('update-work-order-boat-id').value = jobData.Boat_ID;
  document.getElementById('update-work-order-customer-DMid').value = customerData.DM_Customer;

  document.getElementById('customerName').innerHTML = jobData.Customer_Name;
  document.getElementById('boatName').innerHTML = jobData.Boat_Name;
  document.getElementById('workOrderNumber').innerHTML = jobData.Work_Order;

  document.getElementById('updateWorkOrderShortDescription').value = currentShortDescription;
  document.getElementById('updateWorkOrderStartDate').value = currentStartDate;

  document.getElementById('jobInfoModal').style.display = 'flex';
}


document.getElementById('move-operation-work-order-button').addEventListener('click',async () => {
  const customerId = document.getElementById('update-work-order-customer-id').value;
  const boatId = document.getElementById('update-work-order-boat-id').value;
  const customerData = await getCustomerRecord(customerId);
  const dmCustomerId = customerData.DM_Customer;
  const boatData = customerData.Boats[boatId];
  const dmBoatId = boatData.DM_Boat;
  const clerkId = globalUserData[localStorage.getItem('currentUserId')].clerkId;
  const type = 'R';
  const title = document.getElementById('move-operation-work-order-title').value;

  getWorkOrderNumberMove(clerkId, dmCustomerId, dmBoatId, type, title)
  .then(newWorkOrder => {
  document.getElementById('moveOperationWorkOrder').value = newWorkOrder;
  return newWorkOrder;
  })
  .then((newWorkOrder) => {
  deleteOperationDM(newWorkOrder, "CREATE");
  });

});

document.getElementById('move-operations-button').addEventListener('click', async () => {
  const moveOperationSelect = document.getElementById('moveOperationSelectContainer');
  const selectedOperations = moveOperationSelect.querySelectorAll('input[type="checkbox"]:checked');
  const customerId = document.getElementById('update-work-order-customer-id').value;
  const customerData = await getCustomerRecord(customerId);
  const clerkId = globalUserData[localStorage.getItem('currentUserId')].clerkId;
  const newWorkOrder = document.getElementById('moveOperationWorkOrder').value;
  

  let operationsByWorkOrder = {};

  if (selectedOperations.length === 0) {
    toastMessage('Please select at least one operation to move.', 'red');
    return;
  }

  for (const operation of selectedOperations) {
    const jobId = operation.getAttribute('data-jobid');
    const workOrder = operation.getAttribute('data-workorder');
    const opCode = operation.value;

    await deleteOperationDM(workOrder, opCode);

    document.querySelector(`.op-table-data-row[data-id="${jobId}"]`).remove();

    const jobData = JSON.parse(operation.getAttribute('data-jobinfo'));

    if (!operationsByWorkOrder[newWorkOrder]) {
      operationsByWorkOrder[newWorkOrder] = [];
    }

    operationsByWorkOrder[newWorkOrder].push(jobData.Status);

    let type;
    if (jobData.Status.toLowerCase() === 'internal') {
      type = 'I';
    } else {
      type = 'R';
    }

    const serviceLog = {
      DMCustomer: customerData.DM_Customer,
      Work_Order: newWorkOrder,
      type: type,
      LocationCode: "1",
      clerkId: clerkId,
      Long_Description: jobData.Description,
      Description: jobData.Description,
      Short_Description: jobData.Short_Description_DM ? jobData.Short_Description_DM : jobData.Short_Description,
      OpCode: jobData.OpCode,
    };

    await updateWorkOrderDM(serviceLog);
    await updateJobInfo(jobId, 'Work_Order', newWorkOrder);
  }

  if (document.querySelector('#table-content .table-item[data-workorder="' + newWorkOrder + '"]')) {
    console.log(document.querySelector('#table-content .table-item[data-workorder="' + newWorkOrder + '"]'));
    return;
  } else {
    const data = JSON.parse(selectedOperations[0].getAttribute('data-jobinfo'));
    const opCount = selectedOperations.length;
    data.Work_Order = newWorkOrder;
    data.Short_Description = document.getElementById('move-operation-work-order-title').value;
    
    await createNewTableRow(data, opCount);
  }
});




document.getElementById('update-work-order-button').addEventListener('click', async () => {
  const shortDescription = document.getElementById('updateWorkOrderShortDescription').value;
  const startDate = document.getElementById('updateWorkOrderStartDate').value;

    const operationList = document.getElementById('operations-table').querySelectorAll('.op-table-data-row');
    operationList.forEach(async operation => {
      const jobId = operation.getAttribute('data-id');
    
      if(jobId){
        await updateJobInfo(jobId, 'Start_Date', startDate.value);
        await updateJobInfo(jobId, 'Short_Description', shortDescription.value);

        if(startDate.value.split('-')[0] === new Date().getFullYear().toString()){
          operation.querySelector('.op-start-date-info').innerHTML = '<strong>Start Date:</strong> ' + startDate.value.split('-')[1] + '-' + startDate.value.split('-')[2];
        }else{
          operation.querySelector('.op-start-date-info').innerHTML = '<strong>Start Date:</strong> ' + startDate.value;
        }
      }

    });

    document.getElementById('jobInfoModal').remove();

  });

document.getElementById('close-update-work-order-button').addEventListener('click', () => {
    document.getElementById('jobInfoModal').remove();
  });

document.getElementById('shrinkwrap-menu-button').addEventListener('click', async () => {
  const jobId = document.getElementById('currentJobId').value;
  const jobData = await getJobData(jobId)
  const customerData = await getCustomerDataById(jobData.Customer_ID, jobData.Boat_ID);
  const customer = customerData.customerData;
  const boatData = customer.Boats[jobData.Boat_ID];
  let currentStatus;

  const buttons = document.querySelector('#shrinkwrap-menu-inside').querySelectorAll('button');
  buttons.forEach(button => { button.style.display = 'block'});

  if( boatData.Date_Hauled.split('-')[0] === new Date().getFullYear().toString() ){
      currentStatus = 'Ready to Frame'
     }

  if( jobData.Status === 'Framed' ){
    document.getElementById('boat-framed').style.display = 'none';
  }

  if( jobData.Status === 'Vacuumed' ){
    document.getElementById('boat-framed').style.display = 'none';
    document.getElementById('boat-vacuumed').style.display = 'none';
  }

  if( jobData.Status === 'Covered' ){
    document.getElementById('boat-framed').style.display = 'none';
    document.getElementById('boat-vacuumed').style.display = 'none';
    document.getElementById('boat-covered').style.display = 'none';
  }

  if( jobData.Status === 'Tightened' ){
    document.getElementById('boat-framed').style.display = 'none';
    document.getElementById('boat-vacuumed').style.display = 'none';
    document.getElementById('boat-covered').style.display = 'none';
    document.getElementById('boat-tightened').style.display = 'none';
  }

  if( jobData.Status === 'Awaiting Review'){
    const checkboxes = document.querySelector('.shrinkwrap-menu-content').querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.checked = true;
      checkbox.disabled = true;
    });
  }
  document.getElementById('shrinkwrap-menu').style.display = 'flex';
});

function showHoursPopup(button) {

  return new Promise((resolve, reject) => {
    const popup = createPopup('Enter Hours');
    const input = document.createElement('input');
    input.type = 'number';
    input.placeholder = 'Hours';
    input.className = 'shrinkwrap-hours-input';
    popup.appendChild(input);

    const confirmButton = document.createElement('button');
    confirmButton.textContent = 'Submit';
    confirmButton.classList.add('pop-up-submit-button');
    popup.appendChild(confirmButton);

    const cancelButton = document.createElement('button');
    cancelButton.textContent = 'Cancel';
    cancelButton.classList.add('pop-up-cancel-button');
    popup.appendChild(cancelButton);

    document.body.appendChild(popup);
    input.focus();

    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        popup.style.display = 'none';
        button.style.display = 'none';
        resolve(input.value);
      }
    });

    confirmButton.onclick = () => {
      popup.style.display = 'none';
      button.style.display = 'none';
      resolve(input.value);
    };

    cancelButton.onclick = () => {
      popup.style.display = 'none';
      input.value = ''; 
      reject('User cancelled');
    };

  });
}

document.getElementById('shrinkwrap-menu-inside').addEventListener('click', async (e) => {
  e.stopPropagation();

  if (e.target.tagName === 'BUTTON') {
    const currentButton = e.target;
    const previousButton = currentButton.previousElementSibling;

    if (previousButton && window.getComputedStyle(previousButton).display !== 'none') {
   
      const visibleChild = Array.from(currentButton.parentElement.children).find(child => window.getComputedStyle(child).display !== 'none');
      if (visibleChild) {
        toastMessage(`Please check off "${visibleChild.textContent}" before proceeding.`,'red');
        return;
      }
    }

    const jobId = document.getElementById('currentJobId').value;
    const hours = await showHoursPopup(currentButton);
    await shrinkwrapLabor(jobId, currentButton.getAttribute('data-type'), hours);
  }
});


async function shrinkwrapLabor(jobId, laborType, hours) {

  try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const currentTech = userInfo.displayName.split(' ')[0];
      const entryId = await generateId();
      const date = await getTodaysDate();
      let status = ''

      const frameLabor = "Built shrinkwrap frame. Secured frame to boat using shrinkwrap string.  Placed carpet under all supports.";
      const vacuumLabor = "Checked bilge and all compartments for water.  Vacuumed all compartments.";
      const coveredLabor = "Covered boat with shrinkwrap.  Secured shrinkwrap under string and secured all seams with shrinkwrap tape.";
      const tightenLabor = "Tightened shrinkwrap.  Inspected seams and checked for any holes or tears.";
      const ventLabor = "Installed shrinkwrap vents to allow for air circulation.";

      let labor = '';

      if(laborType === 'Framed'){
        labor = frameLabor;
        status = 'Framed';
      }

      if(laborType === 'Vacuumed'){
        labor = vacuumLabor;
        status = 'Vacuumed';
      }

      if(laborType === 'Covered'){
        labor = coveredLabor;
        status = 'Covered';
      }

      if(laborType === 'Tightened'){
        labor = tightenLabor;
        status = 'Tightened';
      }

      if(laborType === 'Vents Installed'){
        labor = ventLabor;
        status = 'Awaiting Review';
      }

    const laborData = {
      'Description': labor,
      'Hours': hours,
      'Category': 'Winterizing',
      'Op_Type': 'Shrinkwrap',
      'Job_ID': jobId,
      'ID': entryId,
      'Boat_Name': document.getElementById('updateBoat').value,
      'Customer_Name': document.getElementById('updateName').value,
      'Time_Submitted': date,
      'Work_Order': document.getElementById('updateWorkOrder').value,
      'Tech': currentTech
    }

    await addLaborFirebase(laborData);
    await updateLaborIndex(jobId, laborData);
    updateTimestamps('jobData', 'data');
    updateJobStatus(jobId, status);

    document.getElementById('updateStatus').value = status;

      if(selectedJobRow){
        selectedJobRow.style.backgroundColor = 'rgb(236, 237, 175)';
      }

      if(activeTableRow){
        activeTableRow.querySelector('.status-info').style.backgroundColor = 'yellow';
      }
    
    toastMessage('Labor Submitted', 'green');
    generateLaborTable(jobId);

  } catch (error) {
    console.error('Failed to submit labor', error);
    toastMessage('Failed to submit labor', 'red');
  }
}

function opCheckSchedule(data) {
  globalJobId = data.Job_ID
  globalShrink = data
  document.getElementById('currentJobId').value = data.Job_ID

  document.getElementById('jobId').value = data.Job_ID
  document.getElementById('updateName').value = data.Customer_Name
  document.getElementById('currentName').value = data.Customer_Name
  document.getElementById('currentBoatName').value = data.Boat_Name;
  document.getElementById('currentOpenOp').innerHTML = "Op: " + data.Description.split(":")[0]
  document.getElementById('currentShortDesc').value = data.Short_Description

  document.getElementById('updateFirstName').value = data.Customer_Name.split(', ')[1]
  document.getElementById('updateLastName').value = data.Customer_Name.split(', ')[0]
  document.getElementById('updateCustomerId').value = data.Customer_ID

  document.getElementById('updateBoat').value = data.Boat_Name.replace(/&amp;/g, '&');
  document.getElementById('currentBoat').value = data.Boat_Name.replace(/&amp;/g, '&');

  document.getElementById('updateBoatId').value = data.Boat_ID

  document.getElementById('updateEmail').value = data.Email
  document.getElementById('updateWorkOrder').value = data.Work_Order

  document.getElementById('updateCategory').value = data.Category

  document.getElementById('updateOpType').value = data.Op_Type

  document.getElementById('updateDescription').value = data.Description.replace(/&amp;/g, '&');
  document.getElementById('currentJobDesc').value = data.Description.replace(/&amp;/g, '&');

  if (data.Tech) {
    document.getElementById('updateTech').value = data.Tech;
  } else if (data.Techs) {
    document.getElementById('updateTech').value = data.Techs;
  };
  document.getElementById('updateStatus').value = data.Status
  document.getElementById('currentStatus').value = data.Status
  document.getElementById('updateHours').value = data.Estimated_Hours
  document.getElementById('updateAmount').value = data.Charge
  document.getElementById('updateStartDate').value = data.Start_Date;
  document.getElementById('updateShortDesc').value = data.Short_Description.replace(/&amp;/g, '&');

  document.getElementById('updateOpCode').value = data.OpCode.toString()
  document.getElementById('currentOpCode').value = data.OpCode
  document.getElementById("work-order-form-dates-launch-date").value = data.Launch_Date
  document.getElementById("work-order-form-dates-haul-date").value = data.Haul_Date
  document.getElementById('partWorkOrder').value = data.Work_Order
  document.getElementById('currentCategory').value = data.Category
  document.getElementById('currentOpType').value = data.Op_Type
  document.getElementById('currentCustomerId').value = data.Customer_ID
  document.getElementById('currentBoatId').value = data.Boat_ID
  document.getElementById('partJobId').value = data.Job_ID


  if ((data.Description.toLowerCase().includes('remove batter') || data.Description.toLowerCase().includes('store batter')) && document.getElementById('work-order-form-boat-battery-count').value === '') {
    const batteryCount = prompt('Enter Number of Batteries')
    document.getElementById('work-order-form-boat-battery-count').value = batteryCount
  } else { document.getElementById('selectOption').style.display = "flex" }

  let mapButton = document.createElement('button')
  mapButton.id = 'showMap'
  mapButton.className = 'map-button'
  mapButton.innerHTML = 'Show on Map'
  mapButton.addEventListener('click', () => {
    document.getElementById('mapView').style.display = 'flex'
  })
  mapButton.setAttribute('data-boatId', data.Boat_ID)
  /* document.getElementById('operations-field').appendChild(mapButton) */

}

function showUpdate() {
  document.getElementById('Update').style.display = "flex";
}

var modalSelect = document.getElementById("addSelectModal")
var modalAdd = document.getElementById("Add");
var modalCustomer = document.getElementById("customerData");
var modalUpdate = document.getElementById("Update");
var modalNewCustomer = document.getElementById('addNewCustomer')

// Get the <span> element that closes the modal
var closeBtnAdd = document.getElementById("exit");
var closeBtnCustomer = document.getElementById("exitCustomer");
var closeBtnUpdate = document.getElementById("exitUpdate");

var closeBtnOp = document.getElementById('exitOp')

document.getElementById("exitTech").addEventListener('click', () => {
  document.getElementById("techTable").style.display = "none"
  tableMaker()
})

document.getElementById("exitWorkOrder").addEventListener('click', () => {
  document.getElementById("woTable").style.display = "none"
  tableMaker()
})

function closeAdd() {

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;

  document.getElementById('Add').style.display = "none"
  document.getElementById('general-work-order-button').style.display = 'flex'
  document.getElementById('customerDatabaseButton').style.display = 'flex'
  document.querySelector('.work-order-type').style.display = 'flex';
  document.getElementById("generalWorkOrder").value = ""
  document.getElementById("winterWorkOrder").value = ""
  document.getElementById("springWorkOrder").value = ""
  document.getElementById("work-order-form-customer-id").value = ""
  document.getElementById("work-order-form-customer-DMid").value = ""
  document.getElementById("work-order-form-boat-id").value = ""
  document.getElementById("work-order-form-boat-DMid").value = ""
  document.getElementById("work-order-form-customer-name").value = ""
  document.getElementById("work-order-form-boat-name").value = ""
  document.getElementById('work-order-form-customer-phone').value = "";
  document.getElementById("work-order-form-customer-email").value = ""
  document.getElementById("work-order-form-customer-street").value = "";
  document.getElementById("work-order-form-customer-city").value = "";
  document.getElementById("work-order-form-customer-state").value = "";
  document.getElementById("work-order-form-customer-zip").value = "";
  document.getElementById('shortDesc').value = ""
  document.getElementById('work-order-form-dates-haul-date').value = ''
  document.getElementById('work-order-form-dates-launch-date').value = nextYear + "-04-01";
  document.getElementById('work-order-form-storage-confirm').checked = false
  document.getElementById('work-order-form-boat-mooring-number-slip').value = ''
  document.getElementById('work-order-form-boat-length').value = ''
  document.getElementById('work-order-form-boat-beam').value = ''
  document.getElementById('work-order-form-boat-combo').value = ''
  document.getElementById('work-order-form-boat-mooring-tag').value = ''
  document.getElementById('work-order-form-boat-bottom-color').value = ''
  document.getElementById('work-order-form-boat-bottom-paint').value = ''
  document.getElementById('work-order-form-boat-mast-length').value = ''
  document.getElementById('work-order-form-boat-engine-count').value = ''
  document.getElementById('work-order-form-boat-battery-count').value = ''
  document.getElementById('work-order-form-storage-price').value = ''
  document.getElementById('storagePrice').innerHTML = ''
  document.getElementById('shortDesc').value = ""

  var Parent = document.getElementById('activeJobs')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

  document.getElementById('add-ops-btn').classList.add('hidden')
  document.getElementById('add-btn').classList.remove('hidden')

  let codeTable = document.getElementById('op-code-body').querySelectorAll('.op-code-row');

  for (let code of codeTable) {
    code.classList.remove('hidden')
  }

  const activeJobs = document.getElementsByClassName('active')

  for (let i = 0; i < activeJobs.length; i++) {

    const number = activeJobs[0].id.at(-1)

    document.getElementById("category" + number).value = ""
    document.getElementById("opType" + number).value = ""
    document.getElementById('opCode' + number).value = ""
    document.getElementById("description" + number).value = ""
    document.getElementById("date" + number).value = ""
    document.getElementById("tech" + number).value = ""
    document.getElementById("status" + number).value = ""
    document.getElementById("job" + number).classList.remove('active')
    document.getElementById("job" + number).classList.add('inactive')
    document.getElementById('addJobButton' + number).classList.remove('hidden')
    document.getElementById('deleteJobButton' + number).classList.add('hidden')
    document.getElementById('jobExit' + number).classList.remove('hidden')
    document.getElementById('jobMinimize' + number).classList.add('hidden')
  }

  var Parent = document.getElementById('activeJobs')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }
}

closeBtnCustomer.onclick = function () {
  modalCustomer.style.display = "none";
  if (modalAdd.style.visibility = "hidden") {
    modalAdd.style.visibility = "visible"
  }
}

async function generateId() {
  try{
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }catch(error){
    console.error("Error generating ID: ", error);
  };
}


closeBtnOp.onclick = () => {
  document.getElementById('opTable').style.display = "none"
  document.querySelector('.opButtonColumn').style.display = "none"
  document.getElementById('loading-icon-op').style.display = "flex"
  document.getElementById('workOrderMove').value = ''
  document.getElementById('shortDescMove').value = ''

  var selectedRows = document.querySelectorAll('.selectedWorkOrder')
  selectedRows.forEach(row => { row.classList.remove('selectedWorkOrder') })

  var checks = document.querySelectorAll('.customCheck');

  checks.forEach(check => {
    check.style.display = 'none';
    document.querySelector('.customCheckHeader').style.display = "none"
    document.getElementById('showOps').innerHTML = "Select Operations"
    document.getElementById('moveOps').style.display = "none"
    document.getElementById('showOps').classList.remove("hideOp")
    document.getElementById('showOps').classList.add("showOp")
    check.children[0].checked = false
  }
  )
}

document.getElementById('exitOpWo').addEventListener('click', () => {
  document.getElementById('openWOTable').style.display = "none"
  document.getElementById('newWorkGroup').style.display = "none"
  document.getElementById('work-order-button-move').style.display = "inline-block"
  document.getElementById('open-wo-table').style.display = "table"
})

async function saveToIndexedDB(item) {
  const db = await openIndexedDB();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("openData", "readwrite");
    const objectStore = transaction.objectStore("openData");
    const request = objectStore.add(item);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
}

function AddRow() {

  document.getElementById('Add').style.display = "none"
  document.getElementById('general-work-order-button').style.display = 'flex'
  document.getElementById('customerDatabaseButton').style.display = 'flex'
  document.getElementById("work-order-form-customer-id").value = ""
  document.getElementById("work-order-form-customer-DMid").value = ""
  document.getElementById("work-order-form-boat-id").value = ""
  document.getElementById("work-order-form-boat-DMid").value = ""
  document.getElementById("work-order-form-customer-name").value = ""
  document.getElementById("work-order-form-boat-name").value = ""
  document.getElementById("generalWorkOrder").value = ""
  document.getElementById('work-order-form-customer-phone').value = "";
  document.getElementById("work-order-form-customer-email").value = "";
  document.getElementById('work-order-form-customer-street').value = "";
  document.getElementById('work-order-form-customer-city').value = "";
  document.getElementById('work-order-form-customer-state').value = "";
  document.getElementById('work-order-form-customer-zip').value = "";
  document.getElementById('shortDesc').value = "";
  document.getElementById('winterWorkOrder').value = ''
  document.getElementById('springWorkOrder').value = ''
  document.getElementById('work-order-form-dates-haul-date').value = ''
  document.getElementById('work-order-form-dates-launch-date').value = (Number(new Date().getFullYear()) + 1) + '-04-01';
  document.getElementById('work-order-form-storage-confirm').checked = false
  document.getElementById('work-order-form-boat-mooring-number-slip').value = ''
  document.getElementById('work-order-form-boat-length').value = ''
  document.getElementById('work-order-form-boat-beam').value = ''
  document.getElementById('work-order-form-boat-combo').value = ''
  document.getElementById('work-order-form-boat-mooring-tag').value = ''
  document.getElementById('work-order-form-boat-bottom-color').value = ''
  document.getElementById('work-order-form-boat-bottom-paint').value = ''
  document.getElementById('work-order-form-boat-mast-length').value = ''
  document.getElementById('work-order-form-boat-engine-count').value = ''
  document.getElementById('work-order-form-boat-battery-count').value = ''
  document.getElementById('work-order-form-storage-price').value = ''
  document.getElementById('storagePrice').innerHTML = ''

  document.getElementById('exit').scrollIntoView()

  var Parent = document.getElementById('activeJobs')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

}

function clearActiveOps() {

  var Parent = document.getElementById('activeJobs')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

  document.getElementById('add-ops-btn').classList.add('hidden')
  document.getElementById('add-btn').classList.remove('hidden')

  let codeTable = document.getElementById('op-code-body').querySelectorAll('.op-code-row');

  for (let code of codeTable) {
    code.classList.remove('hidden')
  }
}

async function showConfirmationPopup(message) {
  return new Promise((resolve) => {
    const popup = document.createElement('div');
    popup.className = 'confirmation-popup';
    popup.innerHTML = `
      <div class="popup-content">
        <p>${message}</p>
        <button class="yes-btn">Yes</button>
        <button class="no-btn">No</button>
      </div>
    `;

    document.body.appendChild(popup);

    const yesBtn = popup.querySelector('.yes-btn');
    const noBtn = popup.querySelector('.no-btn');

    yesBtn.addEventListener('click', () => {
      document.body.removeChild(popup);
      resolve(true);
    });

    noBtn.addEventListener('click', () => {
      document.body.removeChild(popup);
      resolve(false);
    });
  });
}


async function deleteOperation() {

  var jobId = document.getElementById('currentJobId').value;

  const jobInfo = await getJobDataDB(jobId);

  const WOId = jobInfo.Work_Order;
  const operation = jobInfo.OpCode;

  const confirmDelete = await showConfirmationPopup('Are you sure you want to delete this job?');

  if (!confirmDelete) { return };

  document.getElementById('selectOption').style.display = 'none';

  if (confirmDelete) {
    const laborRef = ref(database, 'data/' + jobId);
    remove(laborRef)
      .then(() => {
        deleteDataFromOpenDatabase(jobId);
        deleteOperationDM(WOId, operation);
        toastMessage("Job deleted successfully", 'green');
        document.getElementById('Update').classList.remove('active');    
        var deleteRow = document.querySelectorAll('.selectedRow')
        var opTable = document.getElementById('operations-table')
        deleteRow.forEach(row => opTable.removeChild(row))
        deleteRow.forEach(row => row.classList.remove('selectedRow'))
        document.querySelector(`#jobList .job-item[data-id="${jobId}"]`).remove();
        searchCall();
        searchJobs();
        searchSchedule();
        searchTech();
        if(opTable.children.length === 0){
          document.getElementById('active-work-order').remove();
        }
      })
      .catch((error) => {
        console.log("Failed to delete labor record", 'red');
      });

    const idToken = await fetchIdToken();
    const firebaseUrl = currentURL;
    const timestampPath = `/data/lastUpdated.json?auth=${idToken}`;

    try {
      const timestampResponse = await fetch(firebaseUrl + timestampPath, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ".sv": "timestamp" })
      });
      const timestampResult = await timestampResponse.json();
      console.log("Timestamp updated successfully", timestampResult);
    } catch (e) {
      console.error("Error updating timestamp: " + e.toString());
    }
    const opCount = document.getElementById('operations-table').children.length;
    if(document.querySelector('.active-work-order')){
    document.querySelector('.active-work-order').querySelector('.operation-count').innerHTML = opCount;
    }
  }

  if (document.getElementById('operations-table') && document.getElementById('operations-table').children.length === 0) {
    console.log(document.getElementById('operations-table').children.length)
    activeTableRow.remove();
    document.querySelector('.operation-container-row').remove();
    activeTableRow = null;
    selectedJobRow = null;
    activeRow = null;
  }
}

async function deleteOperationOtherUser(job) {

      try{
        deleteDataFromOpenDatabase(job.Job_ID);      
        document.querySelector(`#jobList .job-item[data-id="${job.Job_ID}"]`).remove();

        if(document.getElementById('operations-table').querySelector(`.op-table-data-row[data-id="${job.Job_ID}"]`)){
          const opCount = document.getElementById('operations-table').children.length;
          document.querySelector('.active-work-order').querySelector('.operation-count').innerHTML = opCount;
          document.getElementById('operations-table').querySelector(`.op-table-data-row[data-id="${job.Job_ID}"]`).remove();
        }else if(!document.getElementById('operations-table').querySelector(`.op-table-data-row[data-id="${job.Job_ID}"]`)){
          const currentCount = document.querySelector('.table-item[data-workorder="' + job.Work_Order + '"]').querySelector('.operation-count')
          currentCount.innerHTML = parseInt(currentCount.innerHTML) - 1;
        }

      }catch(error) {
        console.log("Failed to delete labor record", 'red');
      };
  
}


function deleteDataFromOpenDatabase(jobId) {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction("jobData", "readwrite");
    const store = transaction.objectStore("jobData");

    store.delete(jobId);
  }

}

function tableDelete(jobId) {

  var tbody = document.getElementById("op-body")
  var tr = tbody.getElementsByTagName("tr")[0];

  for (i = 0; i < tbody.rows.length; i++) {
    if (tbody.rows[i].cells[0].innerHTML == jobId)
      tbody.rows[i].style.display = "none"
  }
}

document.getElementById('generalWorkOrder').addEventListener('input', () => {

})

async function fetchCustomerDataAndGenerateTable() {
  try {
    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      const getAllRequest = store.getAll();

      getAllRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
      };

      getAllRequest.onsuccess = (event) => {
        const dataArray = event.target.result;
        generateCustomerTable(dataArray);
      };
    };
  } catch (error) {
    console.log(error);
  }
}

let haulDateCheck = {};

function generateCustomerTable(dataArray) {

  globalCustomerData = dataArray;

  const customerTables = ['tbody', 'customer-table-body', 'customer-email-body']

  for (let table of customerTables) {
    var parent = document.getElementById(table);
    while (parent.hasChildNodes()) {
      parent.removeChild(parent.firstChild);
    }
  }

  dataArray = dataArray.sort((a, b) => {
    if (a.Last_Name && b.Last_Name) {
      return a.Last_Name.localeCompare(b.Last_Name);
    } else if (a.Last_Name) {
      return -1;
    } else if (b.Last_Name) {
      return 1;
    } else {
      return 0;
    }
  });

  dataArray.forEach(function (customer) {

    if(customer.Boats){
      Object.values(customer.Boats).forEach(boat => {

        const currentYear = new Date().getFullYear().toString();

/*         if(boat.Haul_Date?.includes('2023')){
          if(!haulDateCheck[boat.Boat_ID]){

            haulDateCheck[boat.Boat_ID] = {
            hauled: boat.Date_Hauled,
            haul: boat.Haul_Date,
            customerName: customer.Last_Name + ", " + customer.First_Name,
            customerId: boat.Customer_ID,
            boatName: boat.Boat_Name,
            boatId: boat.Boat_ID
          }

        }
      } */

        if(boat.Haul_Date){

        if(boat.Haul_Date.includes(currentYear)){

          if(!boat.Date_Hauled || boat.Date_Hauled === "" || !boat.Date_Hauled.includes(currentYear)){
            haulWeeks.push(boat.Haul_Date);
            haulCheck.push(boat.Boat_ID + " " + boat.Haul_Date);
          }

        }

      }

        if(boat.Date_Hauled){

          if(boat.Date_Hauled.includes(currentYear)){
            haulStatus[boat.Boat_ID] = boat.Date_Hauled;
          }

        }
      });
    }

    var tbody = document.getElementById("tbody");
    var emailBody = document.getElementById('customer-email-body')
    var row = document.createElement("tr");
    var emailRow = document.createElement('tr');
    var nameTd = document.createElement("td");
    nameTd.className = 'form-data';
    nameTd.textContent = customer['Last_Name'] + ", " + customer['First_Name'];
    nameTd.setAttribute('data-customerInfo', JSON.stringify(customer))

    nameTd.addEventListener('click', async (e) => {
      const currentCustomerId = JSON.parse(e.target.getAttribute('data-customerInfo')).id
      const customerInfo = await getCustomerDataById(currentCustomerId);
      const customerData = customerInfo.customerData;
      if (Object.keys(customerData['Boats']).length > 1) {
        showBoatNamesPopup(customerData['Boats']);
      } else {

        const boatKey = Object.keys(customerData['Boats'])[0];
        const boat = customerData['Boats'][boatKey];
        document.getElementById("work-order-form-boat-name").value = boat.Boat_Name
        document.getElementById("work-order-form-boat-id").value = boat.Boat_ID
        document.getElementById("work-order-form-boat-length").value = boat.Boat_Length
        document.getElementById("work-order-form-boat-beam").value = boat.Boat_Beam
        document.getElementById("work-order-form-boat-bottom-paint").value = boat.Bottom_Paint_Type
        document.getElementById("work-order-form-boat-bottom-color").value = boat.Bottom_Paint_Color
        document.getElementById('work-order-form-boat-mast-length').value = boat.Mast_Length
        document.getElementById('work-order-form-boat-boat-type').value = boat.Boat_Type;
        document.getElementById('work-order-form-boat-engine-count').value = boat.Number_of_Engines
        document.getElementById('work-order-form-boat-engine-type').value = boat.Engine_Type;
        console.log(boat.Engine_Type);
        document.getElementById('work-order-form-boat-battery-count').value = boat.Number_of_Batteries
        document.getElementById('work-order-form-boat-combo').value = boat.Combo_Key
        document.getElementById('work-order-form-boat-mooring-number-slip').value = boat.Mooring_Number
        document.getElementById('work-order-form-boat-mooring-tag').value = boat.Tag_Number
        document.getElementById('work-order-form-boat-mooring-weight').value = boat.Mooring_Weight
        document.getElementById('work-order-form-boat-DMid').value = boat.DM_Boat

        storagePrice()
        document.getElementById('customer-search').value = ""
        getOpCodes()
        document.getElementById("customerData").style.display = "none";
      }
      document.getElementById('Add').style.visibility = 'visible';

      document.getElementById("work-order-form-customer-id").value = customerData.Customer_ID
      document.getElementById("work-order-form-customer-name").value = customerData.Last_Name + ', ' + customerData.First_Name
      document.getElementById('work-order-form-customer-DMid').value = customerData.DM_Customer
      document.getElementById('work-order-form-customer-phone').value = formatPhoneNumber(customerData.Phone);
      document.getElementById("work-order-form-customer-email").value = customerData.Email;
      document.getElementById("work-order-form-customer-street").value = customerData.Street;
      document.getElementById("work-order-form-customer-city").value = customerData.City;
      document.getElementById("work-order-form-customer-state").value = customerData.State;
      document.getElementById("work-order-form-customer-zip").value = customerData.Zip;
      resetSearch('myTable')
    });

    const emailTd = document.createElement('td');
    emailTd.className = 'form-data';
    emailTd.textContent = customer['Last_Name'] + ', ' + customer['First_Name'];
    emailTd.value = customer['Email'];
    emailTd.addEventListener('click', (e) => {
      document.getElementById('email-customer').value = e.target.value;
      document.getElementById('customerEmailModal').style.display = "none";
    })

    row.appendChild(nameTd);
    emailRow.appendChild(emailTd);
    tbody.appendChild(row);
    emailBody.appendChild(emailRow);
  });
  populateTable(dataArray)
}


function jsonToCsv(jsonData) {
  // Check if there's data
  if (!jsonData || Object.keys(jsonData).length === 0) {
    console.error('No data to export');
    return;
  }

  // Define the CSV header
  const headers = ['boatId', 'hauled', 'haul', 'customerName', 'customerId', 'boatName'];
  
  // Array to store CSV rows
  const csvRows = [];
  
  // Add the header row to the CSV
  csvRows.push(headers.join(','));

  // Iterate over each item in the JSON object
  for (const boatId in jsonData) {
    if (jsonData.hasOwnProperty(boatId)) {
      const row = [
        boatId,
        jsonData[boatId].hauled || '',  // 'hauled' field (blank if missing)
        jsonData[boatId].haul || '',    // 'haul' field (blank if missing)
        jsonData[boatId].customerName || '',  // 'customerName' field (blank if missing)
        jsonData[boatId].customerId || '',    // 'customerId' field (blank if missing)
        jsonData[boatId].boatName || ''       // 'boatName' field (blank if missing)
      ];

      // Join row data with commas and push to the CSV rows
      csvRows.push(row.join(','));
    }
  }

  // Convert the rows array into a CSV string
  const csvContent = csvRows.join('\n');

  // Create a blob object for the CSV
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link element for the download
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', 'boats_data.csv');
  
  // Append the link to the body and trigger the download
  document.body.appendChild(link);
  link.click();
  
  // Clean up: remove the link after download
  document.body.removeChild(link);
}

function showBoatNamesPopup(boats) {

  var popup = document.getElementById('boat-popup-list')

  while (popup.hasChildNodes()) {
    popup.removeChild(popup.firstChild)
  };

  var boatList = document.createElement('ul');
  Object.values(boats).forEach(function (boat) {
    var listItem = document.createElement('li');
    listItem.textContent = boat['Boat_Name'];
    listItem.addEventListener("click", () => {

      document.getElementById("work-order-form-boat-name").value = boat.Boat_Name
      document.getElementById("work-order-form-boat-id").value = boat.Boat_ID
      document.getElementById("work-order-form-boat-length").value = boat.Boat_Length
      document.getElementById("work-order-form-boat-beam").value = boat.Boat_Beam
      document.getElementById("work-order-form-boat-bottom-paint").value = boat.Bottom_Paint_Type
      document.getElementById("work-order-form-boat-bottom-color").value = boat.Bottom_Paint_Color
      document.getElementById('work-order-form-boat-mast-length').value = boat.Mast_Length
      document.getElementById('work-order-form-boat-engine-count').value = boat.Number_of_Engines
      document.getElementById('work-order-form-boat-engine-type').value = boat.Engine_Type;
      console.log(boat.Engine_Type);
      document.getElementById('work-order-form-boat-fuel-type').value = boat.Fuel_Type;
      document.getElementById('work-order-form-boat-battery-count').value = boat.Number_of_Batteries
      document.getElementById('work-order-form-boat-combo').value = boat.Combo_Key
      document.getElementById('work-order-form-boat-mooring-number-slip').value = boat.Mooring_Number
      document.getElementById('work-order-form-boat-mooring-tag').value = boat.Tag_Number
      document.getElementById('work-order-form-boat-mooring-weight').value = boat.Mooring_Weight
      document.getElementById('work-order-form-boat-DMid').value = boat.DM_Boat
      storagePrice()
      document.getElementById('customer-search').value = ""
      getOpCodes()
      document.getElementById("customerData").style.display = "none";
      /*   popup.style.display = "none" */
      document.getElementById('boat-popup-div').style.display = 'none';
    })
    boatList.appendChild(listItem);

  });

  popup.appendChild(boatList);
  document.getElementById('boat-popup-div').style.display = 'flex';
}

function toastMessage(message, status, time) {
  var x = document.getElementById("snackbar");

  // Set background color if status is provided
  if (status) {
    x.style.backgroundColor = status.toString();
  }

  // Set default timeout duration if not provided
  var timeOut = time || 3000;

  // Convert time from milliseconds to seconds for CSS
  var timeInSeconds = timeOut / 1000;

  // Set the message
  x.innerHTML = message;

  // Set animation with dynamic timing
  x.style.animation = `fadein 0.5s, fadeout 0.5s ${timeInSeconds}s, flash 1s infinite`;
  x.style.animationFillMode = "forwards";

  // Show the snackbar
  x.className = "show";

  // Hide the snackbar after it is shown for 'timeOut' duration
  setTimeout(function () {
    x.className = x.className.replace("show", "");
    x.style.animation = ""; // Reset the animation
  }, timeOut + 500); // Add 500ms to account for the fadein duration
}

document.getElementById('email-open-button').addEventListener('click', () => {
  document.getElementById('emailTable').style.display = 'flex';
})
document.getElementById('email-send').addEventListener('click', sendEmail);


document.getElementById('chat-send').addEventListener('click', sendChat)

function sendChat(e) {
  e.preventDefault();
  // get values to be submitted
  const timestamp = Date.now();
  const userId = auth.currentUser.uid;
  const messageInput = document.getElementById("message-input");
  const message = messageInput.value;
  // clear the input box
  messageInput.value = "";
  // auto scroll to bottom
  document.getElementById("messages").scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  const username = 'Mac';
  set(ref(database, "users/" + userId + "/messages/" + timestamp), {
    username,
    message,
  });
}

function sendEmail() {
  const to = document.getElementById('email-customer').value;
  const subject = document.getElementById('email-subject').value;
  const message = document.getElementById('email-message').value;

  // Define the HTML signature
  const signature = `
    <div style="display:flex; gap:10px; width:100%; justify-content: flex-start; align-items: center;">
      <img style="width:140px; height:140px;" src="https://firebasestorage.googleapis.com/v0/b/marine-center-database.appspot.com/o/logo-256.png?alt=media&token=f93983ec-6bfb-407d-be62-1513c276b2ac">
      <div>
        <span style="font-weight: bold; font-size: 18px;">Service Department</span><br>
        <p>Oyster Bay Marine Center<br>
        p: (516) 624-2400<br>
        a: 5 Bay Ave, Oyster Bay, NY 11771<br>
        w: <a href="http://www.obmc.com">www.obmc.com</a><br>
        e: <a href="mailto:service@obmc.com">service@obmc.com</a></p>
      </div>
    </div>
  `;

  const isHTML = /<\/?[a-z][\s\S]*>/i.test(message);

  const htmlMessage = isHTML ? message : `<p>${message.replace(/\n/g, '<br>')}</p>`;

  const finalMessage = htmlMessage + '<br><br>' + signature;


  const data = {
    to: to,
    subject: subject,
    html: finalMessage
  };

  fetch('https://us-central1-marine-center-database.cloudfunctions.net/sendEmail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  .then(response => {
    if (response.ok) {
      toastMessage('Email Sent', 'green');
      // Clear the form fields
      document.getElementById('email-customer').value = '';
      document.getElementById('email-subject').value = '';
      document.getElementById('email-message').value = '';
    } else {
      console.error('Error sending email');
      // Show an error message to the user
      toastMessage('Error sending email', 'red');
    }
  })
  .catch(error => {
    console.error('Error sending email:', error);
    // Show an error message to the user
    alert('Error sending email');
  });
}


function partSelect() {

  var partTbody = document.getElementById("parts-list-body")

  partTbody.onclick = function (e) {
    e = e || window.event;
    var data = [];
    var target = e.srcElement || e.target;
    while (target && target.nodeName !== "TR") {
      target = target.parentNode;
    }
    if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
        data.push(cells[i].innerHTML);
      }
    }

    var qtyPrompt = window.prompt("Enter Quantity");
    var activeQtyCell = document.getElementById('active-qty-cell');
    var activePartCell = document.getElementById('active-part-cell');

    if (activeQtyCell) {
      activeQtyCell.innerHTML = qtyPrompt;
      activeQtyCell.removeAttribute('id');  // Remove id
    }

    if (activePartCell) {
      activePartCell.innerHTML = data[1];
      activePartCell.removeAttribute('id');  // Remove id
    }

    document.getElementById("partsData").style.display = "none";
  }
}

function partOrderSelect(part) {

  let data = JSON.parse(part);

  let qtyPrompt = window.prompt("Enter Quantity")

  document.getElementById('partId').value = data.id
  document.getElementById('partData').setAttribute('data-part', JSON.stringify(data))
  document.getElementById('orderQty').value = qtyPrompt
  document.getElementById("orderPart").value = data.Part_Number
  document.getElementById("partDescription").value = data.Description
  document.getElementById("manufacturer").value = data.Manufacturer
  document.getElementById('myPartsInput').value = ""
  document.getElementById('partsOrderData').style.display = "none"
  document.getElementById('partVendorList').value = data.Vendor_List;


}

function partSelectData(e) {

  var partTbody = document.getElementById("parts-select-body")
  const row = e.target.closest('tr').querySelectorAll('td')
  const data = [];
  row.forEach(cell => data.push(cell.innerHTML))

  if (document.getElementById('#active-part-cell')) {
    document.querySelector("#active-part-cell").innerHTML = data[2]
  } else { addParts(data[2]) }
  document.getElementById('myPartsSelectInput').value = ""
  document.getElementById('partsSelectData').style.display = "none"


}




document.getElementById('exitPartsData').addEventListener('click', () => {
  document.getElementById('partsData').style.display = ""
  document.getElementById('myPartsInput').value = ""
})

document.getElementById('exitPartsOrderData').addEventListener('click', () => {
  document.getElementById('partsOrderData').style.display = ""
  document.getElementById('myPartsOrderInput').value = ""
})

document.getElementById('exitPartsSelectData').addEventListener('click', () => {
  document.getElementById('partsSelectData').style.display = ""
  document.getElementById('myPartsSelectInput').value = ""
})

document.getElementById('partButton').addEventListener('click', () => {
  document.getElementById('partsOrderData').style.display = "flex"
  console.log('Checked')
})

function calculateSuggestedSalePrice(listId, costId, saleId) {
  const msrp = parseFloat(document.getElementById(listId).value);
  const cost = parseFloat(document.getElementById(costId).value);
  const salePriceInput = document.getElementById(saleId);
  console.log(msrp, cost);
  if (isNaN(msrp) || isNaN(cost)) {
    salePriceInput.placeholder = '';
    return;
  }

  const percent = Math.round(((msrp - cost) / msrp) * 100);
  let suggestedSalePrice;

  if (percent <= 15) {
    suggestedSalePrice = msrp;
  } else if (percent <= 35) {
    suggestedSalePrice = msrp * 0.9; // 10% discount
  } else {
    suggestedSalePrice = msrp * 0.85; // 15% discount
  }

  salePriceInput.placeholder = `Suggested: $${suggestedSalePrice.toFixed(2)}`;
}

// Add event listeners to MSRP and Cost inputs
document.getElementById('listReceived').addEventListener('input', () => {
  let partMSRP = 'listReceived';
  let partCost = 'costReceived';
  let partSalePrice = 'saleReceived';
  calculateSuggestedSalePrice(partMSRP, partCost, partSalePrice);
});

document.getElementById('costReceived').addEventListener('input', () => {
  let partMSRP = 'listReceived';
  let partCost = 'costReceived';
  let partSalePrice = 'saleReceived';
  calculateSuggestedSalePrice(partMSRP, partCost, partSalePrice);
});

async function addPartOrder() {

  let partId = document.getElementById('partId').value;
  let tech = document.getElementById('partTech').value;
  let data = JSON.parse(document.getElementById('partData').getAttribute('data-part'));

  if (tech === "default") {
    toastMessage("Select a Tech", 'red');
    return;
  }

  let date = await formatDate();

  let id = partId ? partId : await generateId();
  let orderId = await generateId();
  let part = document.getElementById('orderPart').value.toUpperCase();
  let qty = document.getElementById('orderQty').value;
  let description = document.getElementById('partDescription').value;
  description = description.charAt(0).toUpperCase() + description.slice(1);
  let vendor = document.getElementById('vendor').value;
  let currentVendorList = document.getElementById('partVendorList').value;
  vendor = currentVendorList.includes(vendor) ? currentVendorList : currentVendorList + ',' + vendor;
  let manufacturer = document.getElementById('manufacturer').value;
  let location = data.Location;
  let orderedDate = date.toString();
  let jobId = document.getElementById('partJobId').value;
  let qtyRemaining = qty;
  let qtyReceived = 0;
  let listPrice = document.getElementById('listReceived').value;
  let cost = document.getElementById('costReceived').value;
  let sale = document.getElementById('saleReceived').value;

  let addToInventory = document.getElementById('addToInventory').checked;
  let partReceived = document.getElementById('receivePart').checked;

  if (partReceived == true) {
    qtyReceived = document.getElementById('qtyReceived').value;
    qtyRemaining = qty - qtyReceived;
  }

  let partData = {
    "Part_Number": part,
    "Manufacturer": manufacturer,
    "Vendor_List": [vendor],
    "Description": description,
    "Location": location,
    "id": id
  };

  if (addToInventory === true && partId === '') {
    partData.Add_Date = date;
    partData.Qty = 0;
  }

  if (partReceived === true && (addToInventory === true || partId !== '')) {
    partData.Qty = Number(qtyReceived) + Number(data.Qty);
    partData.Cost = cost;
    partData.Sale_Price = sale;
  }

  let receiveData = {
    "id": orderId,
    "Part_ID": id,
    "Job_ID": jobId,
    "Quantity_Remaining": qtyRemaining,
    "Quantity_Ordered": qty,
    "Quantity_Received": qtyReceived,
    "Vendor": document.getElementById('vendor').value,
    "Description": description,
    "Date": orderedDate,
    "List_Price": listPrice,
    "Location": location,
    "Cost": cost,
    "Sale_Price": sale,
    "Manufacturer": manufacturer,
    "Part_Number": part,
    "Tech": tech
  };

  // Add the part order to the inventory and pending parts
  await addOrUpdatePart(partData);
  await pendingParts(receiveData);

  // Add a note to the job's Notes section
  const techData = await getCurrentUserData();  // Get current tech user data
  const noteId = await generateId();
  const noteDate = await getFormattedDate();
  const noteTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  
  const noteText = `Ordered part: ${part} (Qty: ${qty}), Tech: ${techData.displayName}, Date: ${noteDate}`;

  const newNote = {
    Note: noteText,
    Tech: techData.displayName,
    Date: noteDate,
    Time: noteTime,
    Job_ID: jobId,
    id: noteId
  };

  // Fetch the current job data
  const currentJobData = await getJobData(jobId);

  // If Notes doesn't exist, create it
  if (!currentJobData.Notes) {
    currentJobData.Notes = {};
  }

  // Add the new note to the Notes object
  currentJobData.Notes[noteId] = newNote;

  // Update the job with the new Notes object
  await updateJobNotes(jobId, 'Notes', currentJobData.Notes);

  // Clear the form inputs
  document.getElementById('orderPart').value = '';
  document.getElementById('orderQty').value = '';
  document.getElementById('partId').value = '';
  document.getElementById('partVendorList').value = '';
  document.getElementById('partData').removeAttribute('data-part');
  document.getElementById('partDescription').value = '';
  document.getElementById('addToInventory').checked = false;
  document.getElementById('receivePart').checked = false;
  document.getElementById('qtyReceived').value = '';
  document.getElementById('partWorkOrder').scrollIntoView();
}


async function addOrUpdatePart(partData) {
  try {
    const partRef = ref(database, `/inventory/${partData.id}`);
    await update(partRef, partData);
    await updateIndexedDB(partData, 'inventoryData');
    updateTimestamps('inventoryData', 'inventory');
  } catch (error) {
    console.error('Failed to add or update part:', error);
  }
}

async function pendingParts(receiveData) {
  try {
    const partRef = ref(database, `/receiving/${receiveData.id}`);
    await update(partRef, receiveData);
    await updateIndexedDB(receiveData, 'receivingData');
    updateTimestamps('receivingData', 'receiving');
  } catch (error) {
    console.error('Failed to add or update part:', error);
  }
}

async function updateIndexedDB(data, storeName) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

      openRequest.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "id" });
        }
      };

      openRequest.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(storeName, "readwrite");
        const objectStore = transaction.objectStore(storeName);

        const getRequest = objectStore.get(data.id);

        getRequest.onsuccess = function (event) {
          let itemToUpdate = event.target.result;

          if (itemToUpdate) {
            // Update only the fields provided in the data object
            for (let key in data) {
              if (data.hasOwnProperty(key) && key !== 'id') {
                itemToUpdate[key] = data[key];
              }
            }
          } else {
            // If the item doesn't exist, use the new data
            itemToUpdate = data;
          }

          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function () {
            console.log(`Data patched for ID ${data.id} in store ${storeName}`);
            window.dispatchEvent(new CustomEvent('indexedDBUpdated', { detail: { storeName, data } }));
            resolve();
          };

          updateRequest.onerror = function (error) {
            console.error("Error patching data", error);
            reject(error);
          };
        };

        getRequest.onerror = function (error) {
          console.error("Error fetching data", error);
          reject(error);
        };

        transaction.oncomplete = function () {
          db.close();
        };
      };

      openRequest.onerror = function (error) {
        console.error("Error opening database", error);
        reject(error);
      };
    } catch (error) {
      console.log("Error updating IndexedDB", error);
      reject(error);
    }
  });
}

document.getElementById('work-order-form-boat-beam').addEventListener('input', () => {
  storagePrice()
})

document.getElementById('work-order-form-boat-length').addEventListener('input', () => {
  storagePrice()
})

function storagePrice() {
  var length = document.getElementById('work-order-form-boat-length').value
  var beam = document.getElementById('work-order-form-boat-beam').value
  var tax = document.getElementById('tax').value
  var environmental = document.getElementById('environmental').value

  if (length <= 20) {
    var price = Number(Number(length) * Number(beam)) * 13.35
  } else if (length > 20 && length <= 26) {
    var price = Number(Number(length) * Number(beam)) * 11.35
  } else if (length > 26 && length <= 32) {
    var price = Number(Number(length) * Number(beam)) * 10.15
  } else if (length > 32 && length <= 39) {
    var price = Number(Number(length) * Number(beam)) * 9.60
  } else if (length > 39 && length <= 50) {
    var price = Number(Number(length) * Number(beam)) * 8.65
  } else if (length > 50 && length <= 70) {
    var price = Number(Number(length) * Number(beam)) * 8.25
  }

  var num = Number(Number(price) + (Number(price) * tax) + (Number(price) * environmental)).toFixed(2)
  document.getElementById('basePrice').innerHTML = Number(price).toLocaleString('en-US') + " (Base Price)"
  document.getElementById('storageTax').innerHTML = "+ " + Number(price * tax).toLocaleString('en-US') + " (Tax)"
  document.getElementById('storageEnv').innerHTML = "+ " + Number(price * environmental).toLocaleString('en-US') + " (Env. Fee)"
  document.getElementById('storagePrice').innerHTML = '$' + Number(num).toLocaleString('en-US') + "(Total)"
  document.getElementById('work-order-form-storage-price').value = '$' + Number(num).toLocaleString('en-US') + "(Total)"

}


const Toast = {
  init() {
    this.hideTimeout = null;

    this.el = document.createElement("div");
    this.el.className = "toast";
    document.body.appendChild(this.el);
  },

  show(message, state) {
    clearTimeout(this.hideTimeout);

    this.el.textContent = message;
    this.el.className = "toast toast--visible";

    if (state) {
      this.el.classList.add(`toast--${state}`);
    }

    this.hideTimeout = setTimeout(() => {
      this.el.classList.remove("toast--visible");
    }, 3000);
  }
};

function exitWindow(e) {

  document.getElementById(e).style.display = 'none'
  if (usingSchedule === true && e === 'selectOption') {
    getTechSchedule()
  }
  if (e === 'selectOption') {
    document.getElementById('showMap').remove()
  }
  var selected = document.querySelectorAll('.selectedRow')
  selected.forEach(row => row.classList.remove('selectedRow'))

}



/* document.addEventListener('click',function(e){

if(e.target.className.includes('tableButtons')){
  var elements = document.getElementsByClassName('tableButtons')
  for(i=0;i<elements.length;i++){
    elements[i].classList.remove('selected')
  }
  e.target.classList.add('selected')
}
}) */


function showSection(sectionId, sectionName, activeId, table) {
  let buttons = document.querySelectorAll('.selectButton');
  buttons.forEach(button => button.classList.remove('selectedButton'));

  let sectionButton = document.getElementById(activeId);
  if (sectionButton) {
    sectionButton.classList.add('selectedButton');
  }

  let sectionsToHide = document.querySelectorAll('.section');
  sectionsToHide.forEach(section => section.style.display = "none");

  let sectionToShow = document.getElementById(sectionId);
  if (sectionToShow) {
    sectionToShow.style.display = "block";
  }

  document.getElementById('tableName').innerHTML = sectionName;
  document.getElementById('active').innerHTML = 'Work Orders: ' + (Number(document.getElementById(table).rows.length) - 1);
}

function printOnlyTable() {
  // Clone the table so the original is unaffected
  var cloneTable = document.getElementById('kitTable').cloneNode(true);

  // Get all the noPrint elements within the cloned table
  var noPrintElements = cloneTable.querySelectorAll('.noPrint');

  // Remove each of the noPrint elements from the cloned table
  noPrintElements.forEach(function (element) {
    element.parentNode.removeChild(element);
  });

  // Open a new window and print the modified cloned table
  var printWindow = window.open('', '', 'width=600,height=600');
  printWindow.document.write('<html><head><title>Print</title></head><body>');

  // Inline styles for the table
  printWindow.document.write('<style>');
  printWindow.document.write('table { border-collapse: collapse; }'); // Ensures that cell borders are combined
  printWindow.document.write('th, td { border: 1px solid black; padding: 8px; }'); // Borders and padding for cells
  printWindow.document.write('th { background-color: grey; }'); // Grey background for headers
  printWindow.document.write('tr:nth-child(odd) { background-color: #f2f2f2; }'); // Lighter grey for every other row
  printWindow.document.write('</style>');

  printWindow.document.write(cloneTable.outerHTML);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
}


document.getElementById('jobExit').addEventListener('click', () => {
  document.getElementById('job').style.display = "none"
  const opCode = document.getElementById("opCode").value
  document.getElementById(opCode).classList.remove('hidden')
})


async function addJob(standard, currentWo) {

  let newData = []
  var data = []

  document.getElementById('active').scrollIntoView();

  const selectedWorkOrderType = document.getElementById('work-order-type-select').value

  var storageCheck = document.getElementById('work-order-form-storage-confirm').checked
  var storagePrice = document.getElementById('storagePrice').innerHTML
  var price = parseFloat(storagePrice.replace(/[$,]/g, ''));
  var length = document.getElementById('work-order-form-boat-length').value
  var beam = document.getElementById('work-order-form-boat-beam').value
  var mooringWeight = document.getElementById('work-order-form-boat-mooring-weight').value
  var mooring = document.getElementById('work-order-form-boat-mooring-number-slip').value
  var combo = document.getElementById('work-order-form-boat-combo').value
  var customerId = document.getElementById('work-order-form-customer-id').value;
  var boat = document.getElementById('work-order-form-boat-name').value;
  var boatId = document.getElementById('work-order-form-boat-id').value;
  var engineCount = document.getElementById('work-order-form-boat-engine-count').value;
  var batteryCount = document.getElementById('work-order-form-boat-battery-count').value;
  var overallCategory = document.getElementById('overallCategory').value
  var generalWorkOrder = document.getElementById('generalWorkOrder').value;
  var winterWorkOrder = document.getElementById('winterWorkOrder').value;
  var springWorkOrder = document.getElementById('springWorkOrder').value;
  var email = document.getElementById('work-order-form-customer-email').value;
  var name = document.getElementById('work-order-form-customer-name').value;
  if (name.includes(',')) {
    var firstName = name.split(', ')[1]
    var lastName = name.split(', ')[0]
  } else {
    var firstName = ''
    var lastName = name
  }

  if (overallCategory !== '') {
    var category = overallCategory
  } else { var category = document.getElementById('category').value }

  if (currentWo) {

    var workOrder = currentWo

  } else {

    if (category === "Winterizing") {

      if (document.getElementById('winterWorkOrder').value === "" && selectedWorkOrderType !== 'general-work-order') {
        toastMessage('No Winter Work Order Number', "red")
        return
      }
      var workOrder = document.getElementById('winterWorkOrder').value
    }

    if (category === "Spring Commissioning" && selectedWorkOrderType !== 'general-work-order') {

      if (document.getElementById('springWorkOrder').value === "") {
        toastMessage('No Spring Work Order Number', "red")
        return
      }
      var workOrder = document.getElementById('springWorkOrder').value
    }

    if (category !== "Winterizing" && category !== "Spring Commissioning") {

      if (document.getElementById('generalWorkOrder').value === "") {
        toastMessage('No Work Order Number', "red")
        return
      }
      var workOrder = document.getElementById('generalWorkOrder').value
    }


    if (generalWorkOrder === "" && winterWorkOrder === "" && springWorkOrder === "") {
      toastMessage('No Work Order Number', "red")
      return
    }

  }

  if (customerId === "") {
    toastMessage('No Customer Selected', "red")
    return
  }

  if (boatId === "" && !document.getElementById('description').value.toLowerCase().includes('moor')) {
    toastMessage('No Boat Selected', "red")
    return
  }

  if (standard === "bottom painting") {
    var opCode = "SP19"
    var opWeight = "1"
    var category = "Spring Commissioning"
    var opType = "Bottom Paint"
    var dmShortDesc = "BOTTOM PAINT, STORAGE"
    var description = 'BOTTOM PAINT, STORAGE : Paint boat with one (1)  coat of anti-fouling paint. Included with winter storage. (No Additional Charge)' + ' (Paint Type: ' + document.getElementById('work-order-form-boat-bottom-paint').value + ' ' + document.getElementById('work-order-form-boat-bottom-color').value + ') [' + Number(((length * beam) * .85) / 400).toFixed(1) + ' gallons per coat]'

  } else if (standard === "hauling") {
    var opCode = "WS01"
    var opWeight = "0"
    var category = "Winterizing"
    var opType = "Hauling"
    var dmShortDesc = "HAUL BOAT, STORAGE"
    var description = "HAUL BOAT, STORAGE : Haul boat and block for storage. Check for scratches, loose or missing paint, and any other damage.  Included with winter storage (No Additional Charge)"
  } else if (standard === "launching") {
    var opCode = "SP37"
    var opWeight = "0"
    var category = "Spring Commissioning"
    var opType = "Hauling"
    var dmShortDesc = "LAUNCH BOAT, STORAGE"
    var description = "LAUNCH BOAT, STORAGE : Launch boat. .  Check for leaks in all readily accessible areas below the waterline.  Included with winter storage. (No Additional Charge)"
  } else if (standard === "pressure washing") {
    var opCode = "WS02"
    var opWeight = "0"
    var category = "Winterizing"
    var opType = "Cleaning"
    var dmShortDesc = "PRESSURE WASH, STORAGE"
    var description = "PRESSURE WASH, STORAGE : Pressure wash bottom.  Use cleaner to remove yellowing at waterline. Included with winter storage (No Additional Charge)"
  } else {
    var opCode = document.getElementById('opCode').value
    var opWeight = document.getElementById('opWeight').value
    var opType = document.getElementById('opType').value
    var description = document.getElementById('description').value
    var dmShortDesc = document.getElementById('dmShortDesc').value

  }

  if (document.getElementById('date').value == '') {
    var date = new Date()
    var date = new Date().toLocaleDateString('en-us', { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' })
    var year = date.split('/')[2]
    var month = date.split('/')[0]
    var day = date.split('/')[1]
    var date = year + "-" + month + "-" + day
    date.toString()
  } else { var date = document.getElementById('date').value; }

  var status = document.getElementById('status').value;
  var hours = document.getElementById('estimatedHours').value;
  var charge = document.getElementById('estimatedCharge').value
  var flatPerFoot = document.getElementById('flatPerFoot').value
  var flatLabor = document.getElementById('flatLabor').value
  var flatMethod = document.getElementById('flatMethod').value

  if (document.getElementById('begin').value == '') {

    var begin = date
  } else {
    var begin = new Date(document.getElementById('begin').value).toLocaleDateString('en-us', { year: "numeric", month: "2-digit", day: "2-digit", timeZone: 'UTC' })
    var beginYear = begin.split('/')[2]
    var beginMonth = begin.split('/')[0]
    var beginDay = begin.split('/')[1]
    var begin = beginYear + "-" + beginMonth + "-" + beginDay
    begin.toString()
  }
  var launch = document.getElementById('work-order-form-dates-launch-date').value
  var haul = document.getElementById('work-order-form-dates-haul-date').value

  if (category === "Winterizing") {
    var shortDesc = "Winterizing"
  } else if (category === "Spring Commissioning") {
    var shortDesc = "Spring Commissioning"
  } else { var shortDesc = document.getElementById('shortDesc').value }

  if (status.toString() === '') {
    var status = "On Work Schedule"
  } else { var status = status }

  if (description.toLowerCase().includes('winterize engine') || description.toLowerCase().includes('commission engine')) {
    var kit = "Needs Kit"
  } else { var kit = '' }

  if (description.toLowerCase().includes('engine') && Number(flatLabor) > 0 && Number(engineCount) >= 1) {
    var flatLabor = Number(flatLabor) * engineCount
    var description = description + ' ENGINE COUNT (' + engineCount + '), TOTAL FLAT RATE = $' + Number(flatLabor).toFixed(2)
  }

  if (description.toLowerCase().includes('engine') && Number(flatLabor) > 0 && (Number(engineCount) == 0 || !engineCount)) {
    var description = description + " ENGINE COUNT IS UNKNOWN.  NUMBER OF ENGINES REQUIRED FOR FLAT RATE CALCULATION."
  }

  if ((description.toLowerCase().includes('battery') || description.toLowerCase().includes('batteries')) && Number(flatLabor) > 0 && Number(batteryCount) >= 1) {
    var flatLabor = Number(flatLabor) * batteryCount
    var description = description + ' [ BATTERY COUNT (' + batteryCount + ') ]'
  }

  if ((description.toLowerCase().includes('battery') || description.toLowerCase().includes('batteries') && (Number(batteryCount) == 0 || !batteryCount))) {
    var description = description + " [ BATTERY COUNT IS UNKNOWN.  NUMBER OF BATTERIES REQUIRED FOR FLAT RATE CALCULATION. ]"
  }

  try {
    let customerDM = await getCustomerRecord(customerId);
    var jobId = await generateId()
    let start
    if (category === "Spring Commissioning" && launch !== '') {
      start = launch
    } else if (category === 'Winterizing' && haul !== '') {
      start = haul
    } else { start = date }

    var serviceLog = {
      "Billing User": "",
      "Boat_ID": boatId,
      "Boat_Name": boat,
      "Category": category,
      "Charge": charge,
      "Customer_ID": customerId,
      "Customer_Name": name,
      "Date Billed": "",
      "Date_Hauled": "",
      "Date_Launched": "",
      "Description": description,
      "Email": email,
      "Estimated_Hours": hours,
      "Event_Added": "",
      "Event_Time": "",
      "Haul_Date": haul,
      "Hold_Days": "",
      "Job_ID": jobId,
      "Labor Billed": "",
      "Launch_Date": launch,
      "Location_Number": "",
      "OpCode": opCode,
      "Op_Type": opType,
      "Operation_Dependency": opWeight,
      "Parts Billed": "",
      "Parts Ordered": "",
      "Parts_Kit_Status": "",
      "Priority": "",
      "Ready": "",
      "Request_Date": date,
      "Short_Description": shortDesc,
      "Short_Description_DM": dmShortDesc,
      "Start_Date": start,
      "Status": status,
      "Status_Of_Boat": "",
      "Techs": "",
      "Total_Hours": 0,
      "Transfer_to_Billing": "",
      "Water": "",
      "Work_Order": workOrder,
      "Work_Order_ID": "",
      "Yard": ""
    };

    let type
    if (category.toLowerCase() === 'internal') {
      type = 'I'
    } else { type = 'R' }

    if (serviceLog.Work_Order === "") {
      toastMessage("No Selected Work Order for This Operation", 'red')
      return
    }

    document.getElementById('job').style.display = 'none'

    const serviceLogForDM = {
      ...serviceLog,
      "type": type,
      "DMCustomer": customerDM.DM_Customer,
      "clerkId": clerkIdInfo[auth.currentUser.displayName],
      "Title": document.getElementById('shortDesc').value
    };

    const serviceLogForIndexed = {
      ...serviceLog,
      "id": serviceLog.Job_ID
    };

    await addJobFirebase(serviceLog);
    await updateOpenDataDB(serviceLogForIndexed);
    toastMessage('Work Order Added', 'green');

    const newOp = await createNewOpRow(serviceLog);

    if(document.getElementById('operations-table')){
      document.getElementById('operations-table').appendChild(newOp);
      const opCount = document.querySelector('.active-work-order').querySelector('.operation-count').innerHTML;
      document.querySelector('.active-work-order').querySelector('.operation-count').innerHTML = Number(opCount) + 1;
    }else if(document.querySelector('.table-item[data-workorder="' + workOrder + '"]')){
      document.querySelector('.table-item[data-workorder="' + workOrder + '"]').querySelector('.operation-count').innerHTML = Number(document.querySelector('.table-item[data-workorder="' + workOrder + '"]').querySelector('.operation-count').innerHTML) + 1;
    }
    
    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(() => {
          // Perform specific actions or execute additional logic
          console.log('Timeout occurred');
          toastMessage('Please check if the work order is open in Dockmaster', 'orange');
        });
      }, 2000); // Adjust the timeout duration as needed (in milliseconds)
    });

    await Promise.race([updateWorkOrderDM(serviceLogForDM), timeout]);

  } catch (error) {
    console.error("An error occurred in the addJob function: ", error);
    // Handle any errors, such as showing a message to the user
  }

  let jobBox = document.createElement('div')
  jobBox.setAttribute('data-opCode', opCode)
  jobBox.setAttribute('data-workOrder', workOrder)
  jobBox.setAttribute('data-job', jobId)
  jobBox.className = 'jobBox'

  let jobDesc = document.createElement('p')
  jobDesc.innerHTML = opCode + ": " + description.split(':')[0];
  jobDesc.setAttribute('data-opCode', opCode)
  jobDesc.setAttribute('data-workOrder', workOrder)

  jobBox.appendChild(jobDesc)

  if(document.querySelector('.active-op')){
    document.querySelector('.active-op').classList.add('hidden');
    document.querySelector('.active-op').classList.remove('active-op');
    currentOpCodes.push(opCode);
  }

  document.getElementById('opCode').value = ''
  document.getElementById("opType").value = ""
  document.getElementById("description").value = ""
  document.getElementById("date").value = ""
  document.getElementById("status").value = ""
  document.getElementById("begin").value = ""
  document.getElementById('estimatedHours').value = "";


  if (!document.getElementById(workOrder)) {

    let field = document.createElement('fieldset')
    field.id = workOrder.toString()
    let legend = document.createElement('legend')
    if (category === "Winterizing") {
      var woType = "(Winterizing)"
    }
    if (category === "Spring Commissioning") {
      var woType = "(Spring Commissioning)"
    }
    if (woType) {
      legend.innerHTML = workOrder.toString() + " " + woType
    } else { legend.innerHTML = workOrder.toString() }

    field.appendChild(legend)
    field.appendChild(jobBox)

    document.getElementById('activeJobs').appendChild(field)

  } else { document.getElementById(workOrder).appendChild(jobBox) }
}

function deleteJob(e) {
  if (e.innerHTML === "Delete Job") {
    var job = document.getElementById(e.parentElement.id)
    document.getElementById(e.parentElement.parentElement.style.display = 'none')
  } else { var job = document.getElementById(e.parentElement.getAttribute('data-job')) }
  job.classList.remove('active')
  job.classList.add('inactive')
  const number = job.id.at(-1)

  document.getElementById('jobExit' + number).classList.remove('hidden')
  document.getElementById('jobMinimize' + number).classList.add('hidden')
  document.getElementById('addJobButton' + number).classList.remove('hidden')
  document.getElementById('deleteJobButton' + number).classList.add('hidden')
  const opCode = document.getElementById("opCode" + number).value
  document.getElementById(opCode).classList.remove('hidden')
  document.getElementById('opCode' + number).value = ''
  document.getElementById('category' + number).value = ''
  document.getElementById('opType' + number).value = ''
  document.getElementById('date' + number).value = ''
  document.getElementById('tech' + number).value = ''
  document.getElementById('begin' + number).value = ''
  document.getElementById('status' + number).value = ''
  document.getElementById('button' + number).remove()

}

document.getElementById('selectOpCode').addEventListener('click', () => {

  document.getElementById('opCodeTable').style.display = "flex"
  document.getElementById('codeSearch').value = ''
  document.getElementById('codeSearch').focus()
  generateOpTable();
})

document.getElementById('section-header-container-storage').addEventListener('click', function() {
  const storagePrice = document.getElementById('work-order-form-storage-data').classList
  this.classList.toggle('open');
  storagePrice.toggle('open');  
});

document.getElementById('section-header-container-customer').addEventListener('click', function() {
  const customerData = document.getElementById('work-order-form-customer-data');
  this.classList.toggle('open');
  customerData.classList.toggle('open');
});

document.getElementById('section-header-container-boat').addEventListener('click', function() {
  const boatData = document.getElementById('work-order-form-boat-data');
  this.classList.toggle('open');
  boatData.classList.toggle('open');
});


function updateOpenDataDB(serviceLog) {
  // Return a new promise
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onerror = function (event) {
      // Reject the promise if we can't open the database
      reject("IndexedDB database error: ", event.target.error);
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("jobData", "readwrite");
      const jobStore = tx.objectStore("jobData");


      // Attempt to store the object in the IndexedDB
      const request = jobStore.put(serviceLog);

      request.onsuccess = function () {
        console.log("Data saved successfully to IndexedDB");
        // Resolve the promise upon successful addition to the database
        resolve("Data saved successfully to IndexedDB");
      };

      request.onerror = function (event) {
        // Reject the promise if there's an error saving the data
        reject("IndexedDB data save error: ", event.target.error);
      };

      // Handle transaction errors
      tx.oncomplete = function () {
        db.close();
      };

      tx.onerror = function (event) {
        reject("Transaction error: ", event.target.error);
      };
    };
  });
}


function generateOpTable() {

  // Open the IndexedDB database
  const request = indexedDB.open('openDatabase', currentIndexedVersion);

  request.onerror = (event) => {
    console.error('Database error:', event.target.errorCode);
  };

  request.onsuccess = (event) => {
    const db = event.target.result;
    const transaction = db.transaction(['opCodeData'], 'readonly');
    const store = transaction.objectStore('opCodeData');

    // Get all the op codes from IndexedDB
    const getAllRequest = store.getAll();

    getAllRequest.onerror = (event) => {
      console.error('Error fetching opCode data:', event.target.errorCode);
    };

    getAllRequest.onsuccess = (event) => {
      const dataArray = event.target.result;
      processOpCodes(dataArray); // Process and display the data
    };
  };
}

function processOpCodes(dataArray) {

  document.getElementById('op-table-div').scrollTop = 0;

  let engineCount = document.getElementById('work-order-form-boat-engine-count').value;
  let batteryCount = document.getElementById('work-order-form-boat-battery-count').value;
  let engineType = document.getElementById('work-order-form-boat-engine-type').value;

  let mainCategory = document.getElementById('overallCategory').value;
  let boatId = document.getElementById('work-order-form-boat-id').value;

  let batteryInfo = batteryCount === '' ? " BATTERY COUNT IS UNKNOWN" : " BATTERY COUNT (" + batteryCount + ")";

  let parentContainer = document.getElementById('op-code-body');
  parentContainer.innerHTML = '';

  let filterBody = document.createElement('div');
  filterBody.className = 'op-filter-body';
  let remainingOps = document.createElement('div');
  remainingOps.className = 'op-filter-body';

  let length = Number(document.getElementById('work-order-form-boat-length').value);
  let beam = Number(document.getElementById('work-order-form-boat-beam').value);
  let driveType = engineType.toLowerCase().includes('sterndrive');
  let boatType = document.getElementById('work-order-form-boat-boat-type').value;
  let fuelType = document.getElementById('work-order-form-boat-fuel-type').value;

  if(engineType.includes('Sterndrive')){
    engineType = engineType.replace('Sterndrive', 'Inboard');
  }

  var categoryContainers = {};
  var categoriesOrder = [];

  Object.values(dataArray).forEach(function (r) {

      if (r.id === 'lastUpdated') {
          return;
      }

      let shouldHide = false;

      if(document.getElementById('work-order-type-select').value !== 'storage-work-order' &&
          (mainCategory === 'General Labor' || mainCategory === '') &&
         (r.Op_Category === 'Winterizing' || r.Op_Category === 'Spring Commissioning')) {
          shouldHide = true;
      }

      if(engineType !== ''){
        if ( (r.Description.split(':')[0].toLowerCase().includes('winterize engine') || 
        r.Description.split(':')[0].toLowerCase().includes('commission engine')) &&
        !r.Description.split(':')[0].toLowerCase().includes(engineType.toLowerCase()) &&
        !r.Description.split(':')[0].toLowerCase().includes('generator') ) {
            shouldHide = true;
    }
      }
     

      if ((r.Description.split(':')[0].toLowerCase().includes('sterndrive') || 
          r.Description.split(':')[0].toLowerCase().includes('outdrive') || 
          r.Description.split(':')[0].toLowerCase().includes('transom assembly')) && driveType === false) {
          shouldHide = true;
      }

      if ((r.Description.toLowerCase().includes('mast') || 
          r.Description.toLowerCase().includes('rigging') || 
          r.Description.toLowerCase().includes('sail') || 
          r.Description.toLowerCase().includes('genoa') ||
          r.Description.toLowerCase().includes('winches') || 
          r.Description.toLowerCase().includes('mainsail')) && 
          !boatType.toLowerCase().includes('power boat')) {
          shouldHide = true;
      }

      if (r.OpCode.toLowerCase().includes('note')) {
          shouldHide = true;
      }

      if (r.Op_Category === "Internal" && document.getElementById('work-order-type-select').value !== 'internal-work-order') {
          shouldHide = true;
      }

      if ((length >= Number(r.Flat_Rate_Length.split(',')[0]) && 
          length <= Number(r.Flat_Rate_Length.split(',')[1])) || 
          r.Flat_Rate_Length === '' || r.Flat_Rate_Length.includes('Engine') ||
          r.Flat_Rate_Length.includes('Mast') || r.Flat_Rate_Length.includes('Generator') ||
          r.Flat_Rate_Length.includes('Battery') || r.Flat_Rate_Length.includes('Storage') ||
          r.Flat_Rate_Length.includes('Shrinkwrap')) {


          // Function to create the category container
          const createCategoryContainer = (category, row) => {
              if (!categoryContainers[category]) {
                  var categoryDiv = document.createElement('div');
                  categoryDiv.id = `category-${category}`;
                  categoryDiv.className = 'category-container';

                  var categoryTitle = document.createElement('h3');
                  categoryTitle.textContent = category;
                  categoryTitle.addEventListener('click', () => {
                      categoryDiv.classList.toggle('collapsed');
                  });
                  categoryDiv.appendChild(categoryTitle);

                  parentContainer.appendChild(categoryDiv);
                  categoryContainers[category] = categoryDiv;
              }

              var categoryDiv = categoryContainers[category];

              // Create or find the Op_Type container within the category
              var opTypeContainer = categoryDiv.querySelector(`#op-type-${r.Op_Type}`);
              if (!opTypeContainer) {
                  opTypeContainer = document.createElement('div');
                  opTypeContainer.id = `op-type-${r.Op_Type}`;
                  opTypeContainer.className = 'op-type-container';

                  var opTypeTitle = document.createElement('h4');
                  opTypeTitle.textContent = r.Op_Type;
                  opTypeContainer.appendChild(opTypeTitle);

                  categoryDiv.appendChild(opTypeContainer);
              }

              // Append the row to the Op_Type container
              opTypeContainer.appendChild(row);
          };

          // Create the row for the operation
          var row = document.createElement("div");
          row.className = "op-code-row";
          if(shouldHide){
              row.classList.add('hidden');
          }
          row.id = r.OpCode;

          var rowData = {
            additionalCategory: r.Additional_Category,
            opCode: r.OpCode,
            opCategory: r.Op_Category,
            opType: r.Op_Type,
            dependency: r.Dependency,
            flatRateLength: r.Flat_Rate_Length,
            hours: r.Hours,
            description: r.Description,
            flatRatePerFoot: r.Flat_Rate_Per_Foot,
            flatRateLabor: r.Flat_Rate_Labor,
            flatRateMethod: r.FlatRatePerFootMethod,
            estimatedCharge: calculateEstimate(r, length, beam, engineCount, batteryCount)
        };

        if (currentOpCodes.includes(r.OpCode)) {
          row.classList.add('hidden');
        }

          row.setAttribute('data-opcode', JSON.stringify(rowData));

          var opCode = document.createElement("p");
          opCode.className = 'form-data';
          opCode.textContent = r.OpCode;

          var description = document.createElement("p");
          description.className = "form-data";
          description.textContent = r.Description.split(':')[0];

          row.appendChild(opCode);
          row.appendChild(description);

          row.addEventListener('click', (e) => {

            var data = JSON.parse(e.target.closest('.op-code-row').getAttribute('data-opcode'));
            console.log(data)
            const searchHide = document.querySelectorAll('.searchHide')
            searchHide.forEach(element => element.classList.remove('searchHide'))
            var storage = document.getElementById('storageStatus').value
    
            document.getElementById('job').style.display = "flex"
            e.target.closest('.op-code-row').classList.add('active-op');
            document.getElementById('opCode').value = data.opCode;
    
            if (data.description.includes(':')) {
              document.getElementById('dmShortDesc').value = data.description.split(':')[0]
            } else {
              document.getElementById('dmShortDesc').value = ''
              document.getElementById('dmShortDesc').focus()
              toastMessage('Add Short Description', 'red')
            }
    
            if (document.getElementById('work-order-form-boat-mooring-number-slip').value === '' && data.description.toString().toLowerCase().includes('mooring')) {
              var mooring = prompt("Enter Mooring Number (XX-##)").toUpperCase()
              if (mooring !== null) {
                document.getElementById('work-order-form-boat-mooring-number-slip').value = mooring.toUpperCase();
                /* google.script.run.updateMooringNumber(boatId, mooring.toUpperCase()); */
              }
            } else {
              var mooring = document.getElementById('work-order-form-boat-mooring-number-slip').value;
            }
    
            if (document.getElementById('work-order-form-boat-mooring-weight').value === "" && data.description.toString().toLowerCase().includes('mooring')) {
              var mooringWeight = prompt('Enter Mooring Weight')
              if (mooringWeight !== null) {
                document.getElementById('work-order-form-boat-mooring-weight').value = mooringWeight;
              }
            } else { var mooringWeight = document.getElementById('work-order-form-boat-mooring-weight').value }
    
    
            if (mainCategory === "Spring Commissioning") {
              document.getElementById('category').value = "Spring Commissioning"
            } else if (mainCategory === "Winterizing") {
              document.getElementById('category').value = "Winterizing"
            } else if (storage === "true" && data.opCategory !== "Winterizing" && data.opCategory !== "Spring Commissioning") {
              toastMessage("Category is 'General'. Select correct category to add to Winter or Spring work order!")
              document.getElementById('category').value = data.opCategory
            }else if(mainCategory === 'General Labor'){
              document.getElementById('category').value = "General Labor"
            }else{ document.getElementById('category').value = data.opCategory }
    
            document.getElementById('opType').value = data.opType;
            document.getElementById('opWeight').value = data.dependency;
            document.getElementById('estimatedCharge').value = data.estimatedCharge;
            document.getElementById('flatMethod').value = data.flatRateMethod;
            document.getElementById('flatPerFoot').value = data.flatRatePerFoot;
            document.getElementById('flatLabor').value = data.flatRateLabor;
            document.getElementById('estimatedHours').value = data.hours;
    
            if (data.description.toLowerCase().includes('bottom paint') && data.description.toLowerCase().includes('storage')) {
              document.getElementById('description').value = data.description + ' (Paint Type: ' + document.getElementById('work-order-form-boat-bottom-paint').value + ' ' + document.getElementById('work-order-form-bottom-color').value + ') [' + Number(((length * beam) * .85) / 400).toFixed(1) + ' gallons per coat]'
            } else if (data.description.toLowerCase().includes('bottom paint') && !data.description.toLowerCase().includes('storage')) {
              var input = prompt('How Many Coats?');
              var coats = parseInt(input)
    
              while (isNaN(coats)) {
                input = prompt('Please enter a number.');
                coats = parseInt(input)
              }
              const numbers = {
                1: 'one',
                2: 'two',
                3: 'three',
                4: 'four',
                5: 'five',
                6: 'six',
                7: 'seven',
                8: 'eight',
                9: 'nine',
                10: 'ten'
              };
    
    
              var coatsNumber = coats
              var coatsString = numbers[coats]
    
              document.getElementById('description').value = data.description + 'Paint boat with ' + coatsString + ' (' + coatsNumber.toString() + ') coat of anti-fouling paint.' + ' (Paint Type: ' + document.getElementById('work-order-form-boat-bottom-paint').value + ' ' + document.getElementById('work-order-form-boat-bottom-color').value + ') [' + Number(((length * beam) * .85) / 400).toFixed(1) + ' gallons per coat]'
            } else if (data.description.includes('(6800)') || data.description.toLowerCase().includes('replace anode')) {
              document.getElementById('description').value = data.description + '\nAnode List: ' + '\n' + document.getElementById('generalAnodeList').value
            } else if (data.opCode === 'DISCNT') {
              var discountInfo = prompt("Enter discount percentage or amount and what to apply it to.")
              document.getElementById('description').value = data.description = "Discount: " + discountInfo
            } else if (data.description.toLowerCase().includes('remove batter') || data.description.toLowerCase().includes('install batter')) {
              document.getElementById('description').value = data.description + batteryInfo
            } else if (data.description.toLowerCase().includes('general rigging labor') || data.description.toLowerCase().includes('general mechanical labor') || data.description.toLowerCase().includes('general boat maintenance')) {
              document.getElementById('description').value = ''
            } else if (data.description.toLowerCase().includes('mooring') && !data.description.toLowerCase().includes('haul mooring') && !data.description.toLowerCase().includes("mooring placement")) {
              if (mooring.includes(',')) {
                updateDescription(data.description, 1)
              } else { document.getElementById('description').value = info + " (MOORING: " + mooring + ")"; }
            } else if (data.description.toLowerCase().includes('haul mooring')) {
              if (mooring.includes(',')) {
                updateDescription(data.description, 2)
              } else { document.getElementById('description').value = "HAUL MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " @ $0.70 PER LB."; }
            } else if (data.description.toLowerCase().includes('mooring placement')) {
              document.getElementById('description').value = "PLACE MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " LBS @ $0.70 PER LB."
            } else { document.getElementById('description').value = data.description }
        
            var elements = document.getElementsByClassName('jobTop')
            for (let element of elements) {
              element.scrollIntoView()
            }
    
          })

          // Append the row to the main category
          createCategoryContainer(r.Op_Category, row);

          // Check if the operation has an Additional_Category and clone the row for that category
          if (r.Additional_Category/*  && document.getElementById('work-order-type-select').value === 'storage-work-order' */) {
              var clonedRow = row.cloneNode(true);

              clonedRow.addEventListener('click', (e) => {

                var data = JSON.parse(e.target.closest('.op-code-row').getAttribute('data-opcode'));
                console.log(data)
                const searchHide = document.querySelectorAll('.searchHide')
                searchHide.forEach(element => element.classList.remove('searchHide'))
                var storage = document.getElementById('storageStatus').value
        
                document.getElementById('job').style.display = "flex"
                e.target.closest('.op-code-row').classList.add('active-op');
                document.getElementById('opCode').value = data.opCode;
        
                if (data.description.includes(':')) {
                  document.getElementById('dmShortDesc').value = data.description.split(':')[0]
                } else {
                  document.getElementById('dmShortDesc').value = ''
                  document.getElementById('dmShortDesc').focus()
                  toastMessage('Add Short Description', 'red')
                }
        
                if (document.getElementById('work-order-form-boat-mooring-number-slip').value === '' && data.description.toString().toLowerCase().includes('mooring')) {
                  var mooring = prompt("Enter Mooring Number (XX-##)").toUpperCase()
                  if (mooring !== null) {
                    document.getElementById('work-order-form-boat-mooring-number-slip').value = mooring.toUpperCase();
                    /* google.script.run.updateMooringNumber(boatId, mooring.toUpperCase()); */
                  }
                } else {
                  var mooring = document.getElementById('work-order-form-boat-mooring-number-slip').value;
                }
        
                if (document.getElementById('work-order-form-boat-mooring-weight').value === "" && data.description.toString().toLowerCase().includes('mooring')) {
                  var mooringWeight = prompt('Enter Mooring Weight')
                  if (mooringWeight !== null) {
                    document.getElementById('work-order-form-boat-mooring-weight').value = mooringWeight;
                  }
                } else { var mooringWeight = document.getElementById('work-order-form-boat-mooring-weight').value }
        
        
                if (mainCategory === "Spring Commissioning") {
                  document.getElementById('category').value = "Spring Commissioning"
                } else if (mainCategory === "Winterizing") {
                  document.getElementById('category').value = "Winterizing"
                } else if (storage === "true" && data.opCategory !== "Winterizing" && data.opCategory !== "Spring Commissioning" && data.additionalCategory === '') {
                  toastMessage("Category is 'General'. Select correct category to add to Winter or Spring work order!")
                  document.getElementById('category').value = data.opCategory
                } else { document.getElementById('category').value = data.additionalCategory }
        
                document.getElementById('opType').value = data.opType;
                document.getElementById('opWeight').value = data.dependency;
                document.getElementById('estimatedCharge').value = data.estimatedCharge;
                document.getElementById('flatMethod').value = data.flatRateMethod;
                document.getElementById('flatPerFoot').value = data.flatRatePerFoot;
                document.getElementById('flatLabor').value = data.flatRateLabor;
                document.getElementById('estimatedHours').value = data.hours;
        
                if (data.description.toLowerCase().includes('bottom paint') && data.description.toLowerCase().includes('storage')) {
                  document.getElementById('description').value = data.description + ' (Paint Type: ' + document.getElementById('work-order-form-boat-bottom-paint').value + ' ' + document.getElementById('work-order-form-bottom-color').value + ') [' + Number(((length * beam) * .85) / 400).toFixed(1) + ' gallons per coat]'
                } else if (data.description.toLowerCase().includes('bottom paint') && !data.description.toLowerCase().includes('storage')) {
                  var input = prompt('How Many Coats?');
                  var coats = parseInt(input)
        
                  while (isNaN(coats)) {
                    input = prompt('Please enter a number.');
                    coats = parseInt(input)
                  }
                  const numbers = {
                    1: 'one',
                    2: 'two',
                    3: 'three',
                    4: 'four',
                    5: 'five',
                    6: 'six',
                    7: 'seven',
                    8: 'eight',
                    9: 'nine',
                    10: 'ten'
                  };
        
        
                  var coatsNumber = coats
                  var coatsString = numbers[coats]
        
                  document.getElementById('description').value = data.description + 'Paint boat with ' + coatsString + ' (' + coatsNumber.toString() + ') coat of anti-fouling paint.' + ' (Paint Type: ' + document.getElementById('work-order-form-boat-bottom-paint').value + ' ' + document.getElementById('work-order-form-boat-bottom-color').value + ') [' + Number(((length * beam) * .85) / 400).toFixed(1) + ' gallons per coat]'
                } else if (data.description.includes('(6800)') || data.description.toLowerCase().includes('replace anode')) {
                  document.getElementById('description').value = data.description + '\nAnode List: ' + '\n' + document.getElementById('generalAnodeList').value
                } else if (data.opCode === 'DISCNT') {
                  var discountInfo = prompt("Enter discount percentage or amount and what to apply it to.")
                  document.getElementById('description').value = data.description = "Discount: " + discountInfo
                } else if (data.description.toLowerCase().includes('remove batter') || data.description.toLowerCase().includes('install batter')) {
                  document.getElementById('description').value = data.description + batteryInfo
                } else if (data.description.toLowerCase().includes('general rigging labor') || data.description.toLowerCase().includes('general mechanical labor') || data.description.toLowerCase().includes('general boat maintenance')) {
                  document.getElementById('description').value = ''
                } else if (data.description.toLowerCase().includes('mooring') && !data.description.toLowerCase().includes('haul mooring') && !data.description.toLowerCase().includes("mooring placement")) {
                  if (mooring.includes(',')) {
                    updateDescription(data.description, 1)
                  } else { document.getElementById('description').value = info + " (MOORING: " + mooring + ")"; }
                } else if (data.description.toLowerCase().includes('haul mooring')) {
                  if (mooring.includes(',')) {
                    updateDescription(data.description, 2)
                  } else { document.getElementById('description').value = "HAUL MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " @ $0.70 PER LB."; }
                } else if (data.description.toLowerCase().includes('mooring placement')) {
                  document.getElementById('description').value = "PLACE MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " LBS @ $0.70 PER LB."
                } else { document.getElementById('description').value = data.description }
      
        
                var elements = document.getElementsByClassName('jobTop')
                for (let element of elements) {
                  element.scrollIntoView()
                }
        
              })
              createCategoryContainer(r.Additional_Category, clonedRow);
          }

          if (!categoriesOrder.includes(r.Op_Category)) {
              categoriesOrder.push(r.Op_Category);
          }

          filterBody.appendChild(categoryContainers[r.Op_Category]);
      }
  });

  // Reorder categories if storage-work-order is selected
  if (document.getElementById('work-order-type-select').value === 'storage-work-order') {
      const orderedCategories = ['Winterizing', 'Spring Commissioning', 'General Labor'];
      categoriesOrder = orderedCategories.concat(categoriesOrder.filter(category => !orderedCategories.includes(category)));
  }

 
  categoriesOrder.forEach(category => {
    if (mainCategory === 'Winterizing' && category === 'Spring Commissioning') {
      return;
  }

  if (mainCategory === 'Spring Commissioning' && category === 'Winterizing') {
      return;
  }

  if (categoryContainers[category]) {
      parentContainer.appendChild(categoryContainers[category]);
  }
  });

  parentContainer.appendChild(filterBody);
  parentContainer.appendChild(remainingOps);
}

// Helper function to calculate the estimated charge based on row data
function calculateEstimate(rowData, length, beam, engineCount, batteryCount) {
  if (rowData.Flat_Rate_Length.includes(',')) {
      return Number(length) * Number(rowData.Flat_Rate_Per_Foot);
  } else if (rowData.Flat_Rate_Length.includes('Engine')) {
      return Number(engineCount) * Number(rowData.Flat_Rate_Labor);
  } else if (rowData.Flat_Rate_Length.includes('Generator')) {
      return Number(rowData.Flat_Rate_Labor);
  } else if (rowData.Flat_Rate_Length.includes('Mast')) {
      return Number(Number(length) * 1.3) * Number(rowData.Flat_Rate_Per_Foot);
  } else if (rowData.Flat_Rate_Length.includes('Battery')) {
      return Number(batteryCount) * Number(rowData.Flat_Rate_Labor);
  } else if (rowData.Flat_Rate_Length === '' && rowData.Flat_Rate_Labor === '' && rowData.Hours !== '') {
      return Number(rowData.Hours) * Number(document.getElementById('currentLaborRate').value);
  } else {
      return '';
  }
}


async function updateDescription(info, select) {

  var mooring = document.getElementById('work-order-form-boat-mooring-number-slip').value;
  if (document.getElementById('work-order-form-boat-mooring-weight').value !== '') {
    var mooringWeight = document.getElementById('work-order-form-boat-mooring-weight').value + ' LBS'
  } else { var mooringWeight = '' }


  if (select === 1) {
    var selectedMooring = await showModalWithMooringOptions(mooring);
    document.getElementById('description').value = info + " (MOORING: " + selectedMooring + ")";
  } else if (select === 2) {
    var selectedMooring = await showModalWithMooringOptions(mooring);
    document.getElementById('description').value = "HAUL MOORING " + selectedMooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " @ $0.70 PER LB.";
  }


}


function getOpenWorkOrders(boatId, workOrder) {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function (event) {
    const db = event.target.result;
    db.createObjectStore("openData", { keyPath: "id" });
  };

  openRequest.onsuccess = function (event) {
    const db = event.target.result;

    const tableTx = db.transaction("openData", "readonly");
    const tableStore = tableTx.objectStore("openData");
    const tableRequest = tableStore.getAll();

    tableRequest.onsuccess = function (event) {
      const data = event.target.result;
      const dataArray = data
        .map((wo) => {
          if (typeof wo === "object") {
            return Object.values(wo); // Convert object to array
          }
          return wo;
        })

      var Parent = document.getElementById("open-wo-body")
      while (Parent.hasChildNodes()) {
        Parent.removeChild(Parent.firstChild)
      }


      const seen = new Set();  // To hold seen values at index 6
      const filterUnique = array => {
        if (seen.has(array[6]) || array[6] === workOrder) {
          return false; // Skip this array, as we've seen this value at index 6 before
        } else {
          seen.add(array[6]); // Remember this value at index 6
          return true;
        }
      };

      const testboat = dataArray.filter(array => array[4] === boatId).filter(filterUnique)

      const woListTable = document.getElementById('open-wo-body')

      testboat.forEach(r => {
        if (r[12] == 'Closed') {
          return
        }
        var row = document.createElement('tr')
        row.addEventListener('click', (e) => {
          moveCheckedOpsToCurrent(e)
        })
        var jobId = document.createElement('td')
        jobId.textContent = r[0]
        jobId.className = "form-data"
        var customer = document.createElement('td')
        customer.textContent = r[1]
        customer.className = "form-data"
        var customerId = document.createElement('td')
        customerId.textContent = r[2]
        customerId.className = "form-data"
        var boat = document.createElement('td')
        boat.textContent = r[3]
        boat.className = "form-data"
        var boatID = document.createElement('td')
        boatID.textContent = r[4]
        boatID.className = "form-data"
        var wo = document.createElement('td')
        wo.textContent = r[6]
        wo.className = "form-data hide-mobile"
        var category = document.createElement('td')
        category.textContent = r[7]
        var shortDesc = document.createElement('td')
        shortDesc.textContent = r[17]
        shortDesc.className = "form-data"
        var date = document.createElement('td')
        date.textContent = r[10].toString()
        date.className = "form-data hide-mobile"

        row.appendChild(jobId).style.display = "none"
        row.appendChild(customer)
        row.appendChild(customerId).style.display = "none"
        row.appendChild(boat)
        row.appendChild(boatID).style.display = "none"
        row.appendChild(wo)
        row.appendChild(category).style.display = "none"
        row.appendChild(shortDesc)
        row.appendChild(date)
        woListTable.appendChild(row)
      })
    }
  }
  document.getElementById('openWOTable').style.display = "flex"
}

async function getWorkOrderNumber(type) {

  if (document.getElementById('work-order-form-customer-id').value === '') {
    toastMessage("No Customer Selected", 'red');
    return;
  }

  if (document.getElementById('work-order-form-customer-DMid').value === '') {
    toastMessage("No Dockmaster Customer ID", 'red');
    return;
  }

  const workOrderButton = document.getElementById(`${type}-work-order-button`);
  const loadingIcon = document.getElementById(`${type}-loading-icon`);

  workOrderButton.style.display = "none";
  loadingIcon.style.display = "flex";

  const functions = getFunctions(app);
  const createWorkOrderFunction = httpsCallable(functions, 'createDockmasterWorkOrder');
  const clerkId = globalUserData[localStorage.getItem('currentUserId')].clerkId;


  const data = {
    clerkId: clerkId,
    dmCustomerId: document.getElementById('work-order-form-customer-DMid').value,
    dmBoatId: document.getElementById('work-order-form-boat-DMid').value,
    type: 'R'
  };

  try {
    const result = await createWorkOrderFunction(data);
    const workOrderNumber = result.data.workOrderNumber;
    console.log(`Work order created: ${workOrderNumber}`);

    document.getElementById(`${type}WorkOrder`).value = workOrderNumber;
    workOrderButton.style.display = "inline-block";
    loadingIcon.style.display = "none";

    const field = document.createElement('fieldset');
    field.id = workOrderNumber.toString();
    const legend = document.createElement('legend');
    let woType = "";
    if (type === "winter") {
      woType = "(Winterizing)";
    } else if (type === "spring") {
      woType = "(Spring Commissioning)";
    }
    legend.innerHTML = woType ? `${workOrderNumber} ${woType}` : workOrderNumber.toString();
    field.appendChild(legend);

    document.getElementById('activeJobs').appendChild(field);
    const storageCheck = document.getElementById('work-order-form-storage-confirm').checked;
    console.log(type, storageCheck);
    if (type === "winter" && storageCheck) {
      console.log('Adding Winter Jobs');
      console.log(workOrderNumber);
      addJob("hauling", workOrderNumber);
      addJob("pressure washing", workOrderNumber);
    }
    if (type === "spring" && storageCheck) {
      addJob("launching", workOrderNumber);
      addJob("bottom painting", workOrderNumber);
    }
    await deleteOperationDM(workOrderNumber, 'CREATE');
  } catch (error) {
    console.error('Error creating work order:', error);
    toastMessage(`Error creating work order: ${error.message}`, 'red');
    workOrderButton.style.display = "inline-block";
    loadingIcon.style.display = "none";
  }
}

async function getWorkOrderNumberMove(clerkId, dmCustomerId, dmBoatId, type, title) {
  const functions = getFunctions(app);
  const createWorkOrderFunction = httpsCallable(functions, 'createDockmasterWorkOrder');

  const data = {
    clerkId: clerkId,
    dmCustomerId: dmCustomerId,
    dmBoatId: dmBoatId,
    type: type
  };

  if(title){
    data.title = title;
  }

  try {
    const result = await createWorkOrderFunction(data);
    return result.data.workOrderNumber;
  } catch (error) {
    console.error('Error creating work order:', error);
    throw error;
  }
}


async function submitOpen(outsideId) {
try{
  let id;
  if (!outsideId) {
    id = document.getElementById('currentJobId').value
  }else{
    id = outsideId
  }

  toastMessage('Marking Job Complete', 'blue')

  const data = {
    Status: 'Ready to Bill',
    Work_Order: document.getElementById('updateWorkOrder').value,
  }

  await changeStatus(id, 'Ready to Bill')
  await updateJobStatus(id, 'Ready to Bill')
  await checkForShrinkwrap(id);
  await updateOperationTableUI(id, data);


  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains("jobData")) {
      db.createObjectStore("jobData", { keyPath: "id" });
    }
  };

  openRequest.onsuccess = function (event) {
    const db = event.target.result;

    const tx = db.transaction("jobData", "readwrite");
    const tableStore = tx.objectStore("jobData");

    const getRequest = tableStore.get(id);

    getRequest.onsuccess = function (event) {
      const record = event.target.result;

      if (record) {
        record.Status = "Ready to Bill";
        record.Operation_Dependency = 0;
        tableStore.put(record);
      }
    };

    getRequest.onerror = function (event) {
      console.error("Error fetching record:", event.target.errorCode);
    };

    tx.onerror = function (event) {
      console.error("Transaction error:", event.target.errorCode);
    };

    tx.oncomplete = function () {
      console.log("Transaction completed.");
    };

    toastMessage("Job Marked Complete", 'green')
    document.getElementById('statusTable').style.display = 'none';
    document.getElementById('Update').classList.remove('active');
    document.querySelector('.job-complete-button').style.display = "none";
  }
}catch(error){
  console.error('Error submitting work order:', error);
}
  
}

async function checkForShrinkwrap(id) {
  new Promise((resolve, reject) => {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onsuccess = function (event) {
    const db = event.target.result;
    const transaction = db.transaction("jobData", "readonly");
    const jobDataStore = transaction.objectStore("jobData");

    const getRequest = jobDataStore.get(id);

    getRequest.onsuccess = function () {
      const job = getRequest.result;
      if (!job) {
        console.error("Job not found.");
        return;
      }

      const workOrder = job.Work_Order;

      // Get all jobs for the specific Work_Order
      const allJobsRequest = jobDataStore.getAll();

      allJobsRequest.onsuccess = function () {
        const allJobs = allJobsRequest.result;
        const matchingJobs = allJobs.filter(j => j.Work_Order === workOrder);

        // Exclude "Shrinkwrap" jobs for allComplete evaluation
        const nonShrinkwrapJobs = matchingJobs.filter(j => j.Op_Type !== "Shrinkwrap");

        const allComplete = nonShrinkwrapJobs.every(
          j =>
            j.Status === "Ready to Bill" ||
            j.Status === "Complete" ||
            j.Status === "Billed" ||
            j.Status === "Closed"
        );
        
        matchingJobs.forEach(matchingJob => {
          if (matchingJob.Op_Type === "Shrinkwrap") {
            if (
              matchingJob.Status === "On Work Schedule" &&
              allComplete
            ) {
              changeStatus(matchingJob.id, "Ready to Frame");
              updateJobStatus(matchingJob.id, "Ready to Frame");
              resolve();
            }
          }
        });
      };

      allJobsRequest.onerror = function () {
        console.error("Failed to fetch jobs for the Work_Order.");
        reject();
      };
    };

    getRequest.onerror = function () {
      console.error("Failed to fetch the job with the provided ID.");
      reject();
    };
  };

  openRequest.onerror = function () {
    console.error("Failed to open IndexedDB.");
    reject();
  };
});
}



async function changeStatus(id, status) {

  new Promise((resolve) => {
  const jobList = document.querySelectorAll('.job-item[data-id="' + id + '"]');

  if(status === 'Ready to Bill' || status === 'Complete'){
    jobList.forEach(job => {

      if(job.querySelector('.status-info-p')){
        job.querySelector('.status-info-p').innerHTML = 'Ready to Bill';
      }
      
      if(job.querySelector('.status-info')){
        job.querySelector('.status-info').style.backgroundColor = 'red';
      }

      job.classList.add('complete-job');
      job.style.display='none';
      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = 'Ready to Bill';
      currentData.Operation_Dependency = 0;
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
    });
    resolve();
  }

  if(status === 'In Progress'){
    jobList.forEach(job => {

      if(job.querySelector('.status-info-p')){
        job.querySelector('.status-info-p').innerHTML = 'In Progress';
      }
      
      if(job.querySelector('.status-info')){
        job.querySelector('.status-info').style.backgroundColor = 'yellow';
      }

      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = 'In Progress';
      currentData.Operation_Dependency = 0;
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
    });
    resolve();
  }

  if(status === 'On Work Schedule'){
    jobList.forEach(job => {
      job.querySelector('.status-info-p').innerHTML = 'Needs Scheduling';
      job.querySelector('.status-info').style.backgroundColor = 'green';
      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = 'On Work Schedule';
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
    });
    resolve();
  }

  if(status === 'Hold'){
    jobList.forEach(job => {
      job.querySelector('.status-info-p').innerHTML = 'Hold';
      job.querySelector('.status-info').style.backgroundColor = 'lightgrey';
      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = 'Hold';
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
    });
    resolve();
  }

  if(status === 'Closed'){
    jobList.forEach(job => {
      job.querySelector('.status-info-p').innerHTML = 'Closed';
      job.querySelector('.status-info').style.backgroundColor = '#b88c8c';
      job.style.fontStyle = "italic";
      job.style.backgroundColor = '#b88c8c';
      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = 'Closed';
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
      job.remove();
    });
    resolve();
  }

  if(status === 'Ready to Frame' || 
     status === 'Framed' ||
     status === 'Vacuumed' ||
     status === 'Covered' ||
     status === 'Tightened'
  ){
    jobList.forEach(job => {
      console.log(job);
      job.querySelector('.status-info-p').innerHTML = status
      job.querySelector('.status-info').style.backgroundColor = 'orange';
      const currentData = JSON.parse(job.getAttribute('data-jobinfo'));
      currentData.Status = status;
      job.setAttribute('data-jobinfo', JSON.stringify(currentData));
    });
    resolve();
  }
});
}

async function updateOperationTableUI(id, data) {

  return new Promise( async (resolve) => {
    const workOrderRow = document.querySelector('.table-item[data-workorder="' + data.Work_Order + '"]');
    const operationRow = document.querySelector('.op-table-data-row[data-id="' + id + '"]');

    if (!workOrderRow || !operationRow) {
      resolve();
      return;
    }

    operationRow.querySelector('.op-current-status').innerHTML = data.Status;

    switch (data.Status) {
      case 'Ready to Bill':
      case 'Complete':
        operationRow.style.backgroundColor = completeRed;
        break;
      case 'In Progress':
        operationRow.style.backgroundColor = progressYellow;
        break;
      case 'Hold':
        operationRow.style.backgroundColor = 'lightgrey';
        break;
      case 'Closed':
        operationRow.style.backgroundColor = '#b88c8c';
        operationRow.style.fontStyle = 'italic';
        break;
      case 'Ready to Frame':
      case 'Framed':
      case 'Vacuumed':
      case 'Covered':
      case 'Tightened':
        operationRow.style.backgroundColor = 'lightorange';
        break;
      case 'On Work Schedule':
        operationRow.style.backgroundColor = 'initial';
        break;
    }

    const jobs = await getJobsByWorkOrder(data.Work_Order);
    const allOperationStatus = jobs.map(job => job.Status);

    const someComplete = allOperationStatus.some(
      status =>
        status === 'Ready to Bill' ||
        status === 'Complete' ||
        status === 'Billed' ||
        status === 'Closed'
    );

    const allComplete = allOperationStatus.every(
      status =>
        status === 'Ready to Bill' ||
        status === 'Complete' ||
        status === 'Billed' ||
        status === 'Closed'
    );

    const allClosed = allOperationStatus.every(
      status => status === 'Closed'
    );

    if (allClosed) {
      workOrderRow.remove();
    } else if (allComplete) {
      workOrderRow.querySelector('.status-info-p').innerHTML = 'Ready to Bill';
      workOrderRow.querySelector('.status-info').style.backgroundColor = 'red';
    } else if (someComplete) {
      workOrderRow.querySelector('.status-info-p').innerHTML = 'In Progress';
      workOrderRow.querySelector('.status-info').style.backgroundColor = 'yellow';
    }

    resolve();
  });
}


function holdDB(id) {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function (event) {
    const db = event.target.result;
    if (!db.objectStoreNames.contains("openData")) {
      db.createObjectStore("openData", { keyPath: "id" });
    }
  };

  openRequest.onsuccess = function (event) {
    const db = event.target.result;

    const tx = db.transaction("openData", "readwrite");
    const tableStore = tx.objectStore("openData");

    const getRequest = tableStore.get(id);

    getRequest.onsuccess = function (event) {
      const record = event.target.result;

      if (record) {
        record[12] = "Hold";
        tableStore.put(record);
      }
    };

    getRequest.onerror = function (event) {
      console.error("Error fetching record:", event.target.errorCode);
    };

    tx.onerror = function (event) {
      console.error("Transaction error:", event.target.errorCode);
    };

    tx.oncomplete = function () {
      console.log("Transaction completed.");
    };

    toastMessage("Job is marked as on hold", 'green')

  }
}

function partsList(dataList) {
  var Parent = document.getElementById('parts-list-body')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

  var Parent = document.getElementById('parts-kit-body')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

  Object.values(dataList).forEach(function (r) {

    var tbodyMain = document.getElementById("parts-list-body")
    var tbodyKit = document.getElementById('parts-kit-body')
    var tbodyOrder = document.getElementById('parts-order-body')
    var tbodySelect = document.getElementById('parts-select-body')
    var row = document.createElement("tr")
    row.setAttribute('data-jobInfo', JSON.stringify(r));
    var partId = document.createElement("td")
    partId.className = "main-data"
    partId.textContent = r.id
    var part = document.createElement("td")
    part.className = "main-data"
    part.textContent = r.Part_Number
    var description = document.createElement("td")
    description.className = "main-data"
    description.textContent = r.Description
    var location = document.createElement('td')
    location.className = 'main-data'
    location.textContent = r.Location

    row.appendChild(partId).style.display = 'none'
    row.appendChild(part)
    row.appendChild(description)
    row.appendChild(location)

    row.addEventListener('click', partSelect)

    tbodyMain.appendChild(row)
    var clonedRow = row.cloneNode(true);
    clonedRow.addEventListener('click', e => {
      const partInfo = JSON.parse(e.target.closest('tr').getAttribute('data-jobInfo'));
      const kitName = document.getElementById('currentPartKitName').value;
      addPartToPartsKit(partInfo, kitName);
    });

    tbodyKit.appendChild(clonedRow);
    var clonedRow2 = row.cloneNode(true);
    clonedRow2.addEventListener('click', e => {
      partOrderSelect(e.target.closest('tr').getAttribute('data-jobInfo'))
    });

    tbodyOrder.appendChild(clonedRow2);
    var clonedRow3 = row.cloneNode(true);
    clonedRow3.addEventListener('click', partSelectData)

    tbodySelect.appendChild(clonedRow3);
  })
}


document.getElementById('myPartsInput').addEventListener('input', e => {searchInventory(e)});
document.getElementById('myPartsKitInput').addEventListener('input', e => {searchInventory(e)});

function searchInventory(e) {
  const searchQuery = e.target.value.toLowerCase().trim();
  const tables = ['partsTable', 'partsOrderTable', 'partsSelectTable', 'partsKitTable'];

  const searchTerms = searchQuery.split(' ');

  for (let table of tables) {
    var tableRows = document.querySelectorAll('#' + table + ' tbody tr:not(.header)');

    for (let row of tableRows) {
      const rowCells = row.querySelectorAll('td');
      let matchFound = true;

      for (const searchTerm of searchTerms) {
        let termMatchFound = false;

        for (const cell of rowCells) {
          const cellText = cell.textContent.toLowerCase();

          if (cellText.includes(searchTerm)) {
            termMatchFound = true;
            break;
          }
        }

        if (!termMatchFound) {
          matchFound = false;
          break;
        }
      }

      if (matchFound) {
        row.style.display = 'table-row';
      } else {
        row.style.display = 'none';
      }
    }
  }
}

document.getElementById('myPartsOrderInput').addEventListener('input', searchOrderInventory);

function searchOrderInventory() {
  const searchQuery = document.getElementById('myPartsOrderInput').value.toLowerCase().trim();
  const tables = ['partsOrderTable'];

  const searchTerms = searchQuery.split(' ');

  for (let table of tables) {
    var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

    for (let row of tableRows) {
      const rowCells = row.querySelectorAll('td');
      let matchFound = true;

      for (const searchTerm of searchTerms) {
        let termMatchFound = false;

        for (const cell of rowCells) {
          const cellText = cell.textContent.toLowerCase();

          if (cellText.includes(searchTerm)) {
            termMatchFound = true;
            break;
          }
        }

        if (!termMatchFound) {
          matchFound = false;
          break;
        }
      }

      if (matchFound) {
        row.style.display = 'table-row';
      } else {
        row.style.display = 'none';
      }
    }
  }
}

function searchGeneralInventory() {
  const searchQuery = document.getElementById('myPartsSelectInput').value.toLowerCase().trim();
  const tables = ['partsSelectTable'];

  const searchTerms = searchQuery.split(' ');

  for (let table of tables) {
    var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

    for (let row of tableRows) {
      const rowCells = row.querySelectorAll('td');
      let matchFound = true;

      for (const searchTerm of searchTerms) {
        let termMatchFound = false;

        for (const cell of rowCells) {
          const cellText = cell.textContent.toLowerCase();

          if (cellText.includes(searchTerm)) {
            termMatchFound = true;
            break;
          }
        }

        if (!termMatchFound) {
          matchFound = false;
          break;
        }
      }

      if (matchFound) {
        row.style.display = 'table-row';
      } else {
        row.style.display = 'none';
      }
    }
  }
}

document.getElementById('customerSearchBar').addEventListener('input', () => {
  searchCustomers('customerSearchBar');
});

document.getElementById('customer-search').addEventListener('input', () => {
  searchCustomers('customer-search');
});

document.getElementById('customer-email-search').addEventListener('input', () => {
  searchCustomers('customer-email-search');
});

function resetSearch(tableId) {
  var tableRows = document.querySelectorAll('#' + tableId + ' tbody tr');

  for (let row of tableRows) {
    row.style.display = 'table-row';
  }
}

function searchCustomers(searchBar) {

  const searchQuery = document.getElementById(searchBar).value.toLowerCase().trim();
  const table = document.getElementById(searchBar).getAttribute('data-table');
  const searchTerms = searchQuery.split(' ');

  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {

      if (!row.classList.contains('header')) {
        row.style.display = 'none';
      }

    }
  }
}



document.addEventListener('DOMContentLoaded', () => {
  document.getElementById('exitCustomerBoat').addEventListener('click', () => {
    document.getElementById('currentCustomerTable').style.display = "none"
  })

  const modals = document.querySelectorAll('.modal')

  modals.forEach(modal => {
      modal.addEventListener('click', (e) => {
        if (e.target.classList.contains('modal')) {
          modal.style.display = 'none'
        }
      })
    });
})

document.getElementById('open-new-customer-form').addEventListener('click', openNewCustomerForm)
document.getElementById('open-new-boat-form').addEventListener('click', openCustomerData)

function openCustomerData() {
  document.getElementById('currentCustomerTable').style.display = "flex"
  document.getElementById('customerPopUp').style.display = "none"
}

function openNewCustomerForm() {
  document.getElementById('addNewCustomer').style.display = "flex"
  document.getElementById('customerPopUp').style.display = "none"
}

function populateTable(uniqueData) {
  var tableBody = document.getElementById('customer-table-body');
  // Clear existing rows
  tableBody.innerHTML = '';

  // Loop through uniqueData array and add rows
  for (var i = 0; i < uniqueData.length; i++) {
    var row = document.createElement('tr');

    var idCell = document.createElement('td');
    idCell.innerText = uniqueData[i].Customer_ID;
    idCell.style.display = 'none';

    var fullNameCell = document.createElement('td');
    fullNameCell.innerText = uniqueData[i].Last_Name + ', ' + uniqueData[i].First_Name

    var lastNameCell = document.createElement('td');
    lastNameCell.innerText = uniqueData[i].Last_Name;
    lastNameCell.style.display = 'none';

    var firstNameCell = document.createElement('td');
    firstNameCell.innerText = uniqueData[i].First_Name;
    firstNameCell.style.display = 'none';

    var streetCell = document.createElement('td');
    streetCell.innerText = uniqueData[i].Street;
    streetCell.style.display = 'none';

    var cityCell = document.createElement('td');
    cityCell.innerText = uniqueData[i].City;
    cityCell.style.display = 'none';

    var stateCell = document.createElement('td');
    stateCell.innerText = uniqueData[i].State;
    stateCell.style.display = 'none';

    var zipCell = document.createElement('td');
    zipCell.innerText = uniqueData[i].Zip;
    zipCell.style.display = 'none';

    var phoneCell = document.createElement('td');
    phoneCell.innerText = uniqueData[i].Phone;
    phoneCell.style.display = 'none';

    var emailCell = document.createElement('td');
    emailCell.innerText = uniqueData[i].Email;
    emailCell.style.display = 'none';

    var customerDM = document.createElement('td');
    customerDM.innerText = uniqueData[i].DM_Customer;
    customerDM.style.display = 'none';

    // Append all cells to the row
    row.append(idCell, fullNameCell, lastNameCell, firstNameCell, streetCell, cityCell, stateCell, zipCell, phoneCell, emailCell, customerDM);

    // Create a scoped function to attach the click event
    (function () {
      var rowData = {
        Customer_ID: uniqueData[i].Customer_ID,
        Last_Name: uniqueData[i].Last_Name,
        First_Name: uniqueData[i].First_Name,
        Street: uniqueData[i].Street,
        City: uniqueData[i].City,
        State: uniqueData[i].State,
        Zip: uniqueData[i].Zip,
        Phone: uniqueData[i].Phone,
        Email: uniqueData[i].Email,
        DM_Customer: uniqueData[i].DM_Customer
      };

      row.addEventListener('click', function () {
        console.log(rowData);
        document.getElementById('customerFormId').value = rowData.Customer_ID
        document.getElementById('customerLastName').value = rowData.Last_Name;
        document.getElementById('customerFirstName').value = rowData.First_Name;
        document.getElementById('customerStreet').value = rowData.Street;
        document.getElementById('customerCity').value = rowData.City;
        document.getElementById('customerState').value = rowData.State;
        document.getElementById('customerZip').value = rowData.Zip;
        document.getElementById('customerPhone').value = rowData.Phone;
        document.getElementById('customerEmail').value = rowData.Email;
        document.getElementById('customerDM').value = rowData.DM_Customer;

        document.getElementById('addNewCustomer').style.display = "flex"
        document.getElementById('currentCustomerTable').style.display = "none"

      });
    })();

    // Append the row to the table body
    tableBody.appendChild(row);
  }
}

document.getElementById('work-order-type-select').addEventListener('change', function () {
  const selectedValue = this.value;
  const storage = document.getElementById('winter-storage-section')
  const winterSpring = document.getElementById('storage-work-order-section')
  const dates = document.getElementById('work-order-date-section')
  const general = document.getElementById('general-work-order-section')
  

  if(selectedValue === 'storage-work-order'){
    storage.classList.remove('hidden-form-section');
    dates.classList.remove('hidden-form-section');
    winterSpring.classList.remove('hidden-form-section')
    winterSpring.style.display='flex'
    general.classList.add('hidden-form-section');
    general.style.display='none';
  }else if(selectedValue !== 'storage-work-order' && !storage.classList.contains('hidden-form-section')){
    storage.classList.add('hidden-form-section');
    dates.classList.add('hidden-form-section');
    winterSpring.classList.add('hidden-form-section')
    winterSpring.style.display='none';
    general.classList.remove('hidden-form-section');
    general.style.display='flex';
  }

});




document.getElementById('myPartsSelectInput').addEventListener('input', searchGeneralInventory)

async function renderPartsData() {
  if (!navigator.onLine) {
    console.log('User is offline. Skipping function.');
    return;
  }

  try {
    const partsRef = ref(database, 'quickSelect/parts');
    const snapshot = await get(partsRef);
    const data = snapshot.val();
    const container = document.getElementById("partsQS");

    if (!data) {
      console.log("No parts data available.");
      return;
    }

    container.innerHTML = '';

    function renderCategory(parentContainer, categoryData, level = 1) {
      Object.keys(categoryData).forEach((key) => {
        const items = categoryData[key];

        const categoryContainer = document.createElement("div");
        categoryContainer.className = "category-container";
        if (level === 1) {
          categoryContainer.classList.add("level-1");
          categoryContainer.setAttribute("data-category", key);
        }

        const header = document.createElement(level === 1 ? "h2" : level === 2 ? "h3" : "h4");
        header.className = level === 1 ? "form-header2" : level === 2 ? "form-subheader" : "form-header3";
        header.innerText = key;
        categoryContainer.appendChild(header);


        if (Array.isArray(items)) {
          const ul = document.createElement("ul");
          ul.className = 'ul-item';
          items.forEach(item => {
            if (item) appendListItem(ul, item, "Parts");
          });
          categoryContainer.appendChild(ul);
        } else if (typeof items === "object" && items !== null) {
          renderCategory(categoryContainer, items, level + 1);
        }

        parentContainer.appendChild(categoryContainer);
      });
    }

    renderCategory(container, data);

  } catch (error) {
    console.error("Error fetching parts data:", error);
  }
}

async function renderLaborData() {


  if (!navigator.onLine) {
    console.log('User is offline. Skipping function.');
    return;
  }
console.log('This is the function.')
  try {
    var laborRef = ref(database, 'quickSelect/labor'); // Adjust this path if your labor data is stored elsewhere in your Firebase database

    // Fetch labor data once from Firebase Realtime Database
    get(laborRef).then((snapshot) => {
      const data = snapshot.val(); // This is your labor data
      var container = document.getElementById("laborQS");

      // Assuming data is an object with keys as categories and values as arrays of tasks
      Object.keys(data).forEach((category) => {
        var header = document.createElement("h2");
        header.className = "form-header2";
        header.innerText = category;
        container.appendChild(header);

        var ul = document.createElement("ul");
        ul.className = 'ul-item';

        // Handling both arrays and objects for nested data
        const items = data[category];
        if (Array.isArray(items)) {
          items.forEach(item => appendListItem(ul, item, "Labor"));
        } else { // If it's an object with further categorization
          Object.keys(items).forEach(subCategory => {
            var subHeader = document.createElement("h3");
            subHeader.className = "form-subheader";
            subHeader.innerText = subCategory;
            ul.appendChild(subHeader);

            items[subCategory].forEach(item => appendListItem(ul, item, 'Labor'));
          });
        }

        container.appendChild(ul);
      });
    })
  } catch (error) {
    console.error("Error fetching labor data:", error);
  };
}

function appendListItem(ul, item, category) {
  const li = document.createElement("li");
  li.className = "list-item";
  li.innerText = `${item}`;
  li.addEventListener('click', (e) => {
    if (category === "Parts") {
      const part = e.target.innerHTML;
      addParts(part);
    }

    if(category === "Labor"){
      const labor = e.target.innerHTML;
      const laborEntryElement = document.getElementById('labor-entry-description');
      const currentLabor = laborEntryElement.value;

      if (e.target.classList.contains('highlighted')) {
        // Remove highlight and remove from labor entry
        e.target.classList.remove('highlighted');
        const laborArray = currentLabor.split('  ');
        const updatedLabor = laborArray.filter(item => item !== labor).join('  ');
        laborEntryElement.value = updatedLabor;
      } else {
        // Add highlight and add to labor entry
        e.target.classList.add('highlighted');
        if (currentLabor === '') {
          laborEntryElement.value = labor;
        } else {
          laborEntryElement.value = currentLabor + '  ' + labor;
        }
      }
    }
  });
  ul.appendChild(li);
}

document.getElementById('searchBarQS').addEventListener('input', () => {
  filterLaborQS();
  filterPartsQS();
});


function filterLaborQS() {
  var input = document.getElementById("searchBarQS");
  var filter = input.value.trim().toLowerCase();
  var container = document.getElementById("laborQS");
  var headers = container.getElementsByClassName("form-header2");

  for (var i = 0; i < headers.length; i++) {
    var header = headers[i];
    var ul = header.nextElementSibling;
    var hasMatchingItem = false;

    // If this header has a UL or nested subcategories
    if (ul && ul.tagName.toLowerCase() === "ul") {
      var listItems = ul.getElementsByTagName("li");

      for (var j = 0; j < listItems.length; j++) {
        var listItemText = listItems[j].textContent || listItems[j].innerText;

        if (listItemText.toLowerCase().includes(filter)) {
          hasMatchingItem = true;
          listItems[j].style.display = "block"; // Show matching items
        } else {
          listItems[j].style.display = "none"; // Hide non-matching items
        }
      }
    } else if (ul && ul.tagName.toLowerCase() === "div") {
      // If it's a nested subcategory container
      var subHeaders = ul.getElementsByClassName("form-subheader");
      for (var k = 0; k < subHeaders.length; k++) {
        var subHeader = subHeaders[k];
        var subUl = subHeader.nextElementSibling;
        var hasSubMatchingItem = false;

        if (subUl && subUl.tagName.toLowerCase() === "ul") {
          var subListItems = subUl.getElementsByTagName("li");

          for (var m = 0; m < subListItems.length; m++) {
            var subListItemText = subListItems[m].textContent || subListItems[m].innerText;

            if (subListItemText.toLowerCase().includes(filter)) {
              hasSubMatchingItem = true;
              subListItems[m].style.display = "block";
            } else {
              subListItems[m].style.display = "none";
            }
          }
        }

        if (hasSubMatchingItem) {
          subHeader.style.display = "block";
          if (subUl) subUl.style.display = "block";
          hasMatchingItem = true;
        } else {
          subHeader.style.display = "none";
          if (subUl) subUl.style.display = "none";
        }
      }
    }

    // Show or hide the main header
    if (hasMatchingItem) {
      header.style.display = "block";
      if (ul) ul.style.display = "block";
    } else {
      header.style.display = "none";
      if (ul) ul.style.display = "none";
    }
  }
}

function filterPartsQS() {
  var input = document.getElementById("searchBarQS");
  var filter = input.value.trim().toLowerCase();
  var container = document.getElementById("partsQS");

  function filterCategory(categoryContainer, level) {
    var headers = categoryContainer.getElementsByClassName(level === 1 ? "form-header2" : level === 2 ? "form-subheader" : "form-header3");
    var hasCategoryMatch = false;

    for (var i = 0; i < headers.length; i++) {
      var header = headers[i];
      var ul = header.nextElementSibling;
      var hasMatchingItem = false;

      if (ul && ul.tagName.toLowerCase() === "ul") {
        var listItems = ul.getElementsByTagName("li");

        for (var j = 0; j < listItems.length; j++) {
          var listItemText = listItems[j].textContent || listItems[j].innerText;

          if (listItemText.toLowerCase().includes(filter)) {
            hasMatchingItem = true;
            listItems[j].style.display = "block";
          } else {
            listItems[j].style.display = "none";
          }
        }
      } else if (ul && ul.tagName.toLowerCase() === "div") {
        // Nested categories
        hasMatchingItem = filterCategory(ul, level + 1);
      }

      if (hasMatchingItem) {
        header.style.display = "block";
        if (ul) ul.style.display = "block";
        hasCategoryMatch = true;
      } else {
        header.style.display = "none";
        if (ul) ul.style.display = "none";
      }
    }
    return hasCategoryMatch;
  }

  filterCategory(container, 1);
}



function writtenNumber(number) {
  if (number == 1) {
    return 'one (1)';
  } else if (number == 2) {
    return 'two (2)';
  } else if (number == 3) {
    return 'three (3)';
  } else if (number == 4) {
    return 'four (4)';
  } else if (number == 5) {
    return 'five (5)';
  } else if (number == 6) {
    return 'six (6)';
  } else if (number == 7) {
    return 'seven (7)';
  } else if (number == 8) {
    return 'eight (8)';
  } else if (number == 9) {
    return 'nine (9)';
  } else if (number == 10) {
    return 'ten (10)';
  } else {
    return 'Invalid input';
  }
}

function showModalWithMooringOptions(mooring) {
  return new Promise((resolve) => {
    var mooringArray = mooring.split(',');
    var mooringModal = document.getElementById('mooringModal-mooring');
    var mooringButtonsContainer = document.getElementById('mooringButtons-mooring');

    // Clear previous buttons
    mooringButtonsContainer.innerHTML = '';

    // Create a button for each mooring option
    mooringArray.forEach(function (mooringOption) {
      var button = document.createElement('button');
      button.textContent = mooringOption.trim();
      button.addEventListener('click', function () {
        // Resolve the promise with the selected mooring option
        resolve(mooringOption.trim());
        mooringModal.style.display = 'none';
      });
      mooringButtonsContainer.appendChild(button);
    });

    // Show the modal
    mooringModal.style.display = 'block';

    // Close button
    var closeButton = document.querySelector('.close-mooring');
    closeButton.onclick = function () {
      mooringModal.style.display = 'none';
    };

    // Close the modal when clicking outside of it
    window.onclick = function (event) {
      if (event.target === mooringModal) {
        mooringModal.style.display = 'none';
      }
    };
  });
}



document.getElementById('openLaborQS').addEventListener('click', () => {
  document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
  document.getElementById('laborQS').style.display = "flex";
  document.getElementById('partsQS').style.display = "none";
  document.getElementById('searchBarQS').focus()

  const jobType = document.getElementById('updateOpType').value;

  document.querySelectorAll('.category-container.level-1').forEach(category => {
    category.classList.remove('hidden');
    const type = category.getAttribute('data-category');

    if(type !== jobType){
      category.classList.add('hidden');
    }else{
      category.classList.remove('hidden');
    }
  });
})

document.getElementById('openPartsQS').addEventListener('click', () => {
  document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
  document.getElementById('laborQS').style.display = "none";
  document.getElementById('partsQS').style.display = "flex";
  document.getElementById('searchBarQS').focus()
  const jobType = document.getElementById('updateOpType').value;

      document.querySelectorAll('.category-container.level-1').forEach(category => {
        category.classList.remove('hidden');
        const type = category.getAttribute('data-category');

        if(type !== jobType){
          category.classList.add('hidden');
        }else{
          category.classList.remove('hidden');
        }
      });

})

document.getElementById('qs-return').addEventListener('click', () => {
  document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
  document.getElementById('searchBarQS').value = '';
  document.querySelectorAll('.ul-item .list-item').forEach(item => item.classList.remove('highlighted'));
});


function openShrinkMenu(status) {

  if (status != "On Work Schedule") {

    if (status == "Framed") {
      document.getElementById('framed').classList += ' hide'
    }

    if (status == "Vacuumed Bilge") {
      document.getElementById('framed').classList += ' hide'
      document.getElementById('vacuumed').classList += ' hide'
    }

    if (status == "Covered") {
      document.getElementById('framed').classList += ' hide'
      document.getElementById('vacuumed').classList += ' hide'
      document.getElementById('covered').classList += ' hide'
    }

    if (status == "Wrap Tightened") {
      document.getElementById('framed').classList += ' hide'
      document.getElementById('vacuumed').classList += ' hide'
      document.getElementById('covered').classList += ' hide'
      document.getElementById('tightened').classList += ' hide'
    }

  }

  document.getElementById('shrinkPopUp').style.display = 'flex'

}

function shrinkSubmit() {

  document.getElementById('processing').style.display = "flex"

  var jobId = document.getElementById('shrinkJobId').value
  var checks = document.getElementsByClassName('shrinkCheck')
  var desc = []
  var hours = []

  for (i = 0; i < checks.length; i++) {
    if (checks[i].checked == true) {
      var status = checks[i].getAttribute('data-status')
      desc.push(checks[i].getAttribute('data-description') + ' ')
      hours.push(checks[i].getAttribute('data-hours'))
    }
  }
  if (localStorage.getItem("currentTech")) {
    var tech = localStorage.getItem('currentTech')
  } else { var tech = toProperCase(prompt('Enter Tech Name')) }
  var dataLog = {}
  dataLog.tech = tech
  dataLog.name = globalShrink[1]
  dataLog.boat = globalShrink[3]
  dataLog.workOrder = globalShrink[6]
  dataLog.desc = desc.flat().toString()
  dataLog.hours = hours.reduce((a, b) => { return Number(a) + Number(b) }, 0)
  dataLog.category = globalShrink[7]
  dataLog.opType = globalShrink[8]
  dataLog.jobId = globalShrink[0]
  dataLog.customerId = globalShrink[2]
  dataLog.boatId = globalShrink[4]
  dataLog.shortDesc = globalShrink[17]

  google.script.run.withSuccessHandler(() => {
    document.getElementById('shrinkPopUp').style.display = "none"
    var Parent = document.getElementById('shrink-part-body')
    while (Parent.hasChildNodes()) {
      Parent.removeChild(Parent.firstChild)
    }

    document.getElementById('shrinkParts').style.display = "flex"

    google.script.run.withSuccessHandler((parts) => {

      document.getElementById('shrinkPartsUsed').style.display = "flex"

      parts.forEach(part => {
        var tbody = document.getElementById('shrink-part-body')
        var row = document.createElement('tr')
        var partNumber = document.createElement('td')
        partNumber.textContent = part[0]
        var qty = document.createElement('td')
        qty.textContent = part[1]

        row.appendChild(partNumber)
        row.appendChild(qty)
        tbody.appendChild(row)
      })
    }).getShrinkPartsUsed(dataLog.jobId)

    document.getElementById('processing').style.display = "none"
  }).updateShrinkStatus(dataLog.jobId, status, dataLog)

}

function toProperCase(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
}

function shrinkPartsSubmit() {
  document.getElementById('processing').style.display = "flex";
  var parts = document.querySelectorAll(".shrinkPart");
  var total = [];

  if (localStorage.getItem("currentTech")) {
    var tech = localStorage.getItem('currentTech')
  } else { var tech = toProperCase(prompt('Enter Tech Name')) }

  parts.forEach(part => {
    if (part.value !== '') {
      var partNumber = part.nextElementSibling.innerHTML;
      var partQty = part.value;
      var dataLog = {};
      dataLog.tech = tech
      dataLog.name = globalShrink[1];
      dataLog.boat = globalShrink[3];
      dataLog.workOrder = globalShrink[6];
      dataLog.part = partNumber;
      dataLog.qty = partQty;
      dataLog.category = globalShrink[7];
      dataLog.opType = globalShrink[8];
      dataLog.jobId = globalShrink[0];
      dataLog.customerId = globalShrink[2];
      dataLog.boatId = globalShrink[4];
      dataLog.shortDesc = globalShrink[17];

      total.push(dataLog); // push the object directly
    }
  });

  google.script.run.withSuccessHandler(() => {
    document.getElementById('shrinkParts').style.display = "none";
    document.getElementById('processing').style.display = "none";
  }).updateShrinkParts(total);

  clearShrinkParts();
}


function clearShrinkParts() {
  document.getElementById('shrinkParts').style.display = "none"
  var parts = document.querySelectorAll(".shrinkPart")

  parts.forEach(part => {
    part.value = ''
  })
}

function openShrinkParts() {
  document.getElementById('shrinkPopUp').style.display = "none"
  document.getElementById('shrink-table').style.display = "none"
  document.getElementById('shrinkPartCheck').style.display = "flex"
  var Parent = document.getElementById('shrink-part-body');
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild);
  }
  document.getElementById('shrinkParts').style.display = "flex";

  google.script.run.withSuccessHandler((parts) => {
    document.getElementById('shrinkPartsUsed').style.display = "flex";

    // Process parts to aggregate quantities for same part number
    let aggregatedParts = {};
    parts.forEach(part => {
      if (aggregatedParts[part[0]]) {
        aggregatedParts[part[0]] += Number(part[1]);
      } else {
        aggregatedParts[part[0]] = Number(part[1]);
      }

    });

    for (let partNumberText in aggregatedParts) {
      var tbody = document.getElementById('shrink-part-body');
      var row = document.createElement('tr');
      var partNumber = document.createElement('td');
      partNumber.textContent = partNumberText;
      var qty = document.createElement('td');
      qty.textContent = aggregatedParts[partNumberText];

      row.appendChild(partNumber);
      row.appendChild(qty);
      tbody.appendChild(row);
    }
    document.getElementById('shrinkPartCheck').style.display = "none"
    document.getElementById('shrink-table').style.display = "table"
  }).getShrinkPartsUsed(globalShrink[0]);
}

// Get the modal
/* var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("selectTable");

// When the user clicks the button, toggle the modal 
btn.onclick = function() {
if (modal.style.display === "block") {
  modal.style.display = "none";
} else {
  modal.style.display = "block";
}
}

// Optional: Hide the modal when clicking outside of it
window.onclick = function(event) {
if (event.target !== modal && event.target !== btn) {
  modal.style.display = "none";
}
} */

function receiveBox() {

  var id = document.getElementById('currentJobId').value

  document.getElementById('part-order-table').style.display = 'flex'
  var rows = document.getElementById('part-table-body').rows;


  for (var i = 0; i < rows.length; i++) {

    var jobId = rows[i].cells[13].textContent;

    // If the jobId does not match the idToMatch, hide the row
    if (jobId !== id) {
      rows[i].style.display = 'none';
    } else {
      // If it matches, ensure the row is visible (in case it was previously hidden)
      rows[i].style.display = '';
    }
  }
}

async function getFormattedDate(date) {
  return new Promise((resolve) => {
    const dateObj = date ? new Date(date) : new Date();
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    resolve(formattedDate);
  });
}

function receiveParts(e) {

  var rowData = e.closest('tr'); // This is a single <tr> element

  // Assuming you want to work with each cell in this row
  var cells = rowData.querySelectorAll('td');
  var data = [];


  cells.forEach(cell => {
    data.push(cell.textContent || cell.innerText);
  });

  var received = parseInt(prompt("Enter Quantity Received"), 10);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  var dataLog = {
    id: data[0],
    tech: data[1],
    wo: data[2],
    part: data[3],
    qty: parseInt(data[4], 10) - received,
    description: data[5],
    vendor: data[6],
    manufacturer: data[7],
    totalReceived: received,
    date: formattedDate,
    jobId: data[13],
    originalQty: data[20]
  };

  if (received === parseInt(data[4], 10)) {
    dataLog.received = "All parts received";

    var partsLog = {
      techName: data[1],
      customerName: data[16],
      boatName: data[17],
      workOrder: data[2],
      partNumber: data[3] + "(" + data[5] + ")",
      quantity: data[4],
      category: data[11],
      opType: data[12],
      jobId: data[13],
      customerId: data[14],
      boatId: data[15],
      shortDesc: data[18],
      originalQty: data[20]
    };


    if (data[2] !== "Stock Order" && data[2] !== "stock" && data[2] !== "Stock") {
      google.script.run
        .withSuccessHandler(function () {

        })
        .partsOrderClicked(partsLog);
    }

    document.getElementById('partNumber').value = data[3];
    document.getElementById('partDesc').value = data[5];
    document.getElementById('partManufacturer').value = data[7];
    document.getElementById('partVendor').value = data[6];
    document.getElementById('partId').value = data[23];


    if (document.getElementById('addToInventory').checked === true) {
      addPart();
    }


  } else {
    dataLog.received = 'Received (' + Number(received) + ')' + '\n' + 'Awaiting (' + (parseInt(data[4], 10) - received) + ')';
  }

  google.script.run
    .withSuccessHandler(function () {
      format(tableMaker);
    })
    .updatePartOrder(dataLog);

};


var dropdowns = document.getElementsByClassName("dropbtn");

// Loop through the buttons and add an onclick listener
for (var i = 0; i < dropdowns.length; i++) {
  dropdowns[i].addEventListener("click", function () {
    this.classList.toggle("active");
    var content = this.nextElementSibling;
    if (content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  });
}



function partReceiveTable(dataArray) {
  var Parent = document.getElementById('part-table-body')
  while (Parent.hasChildNodes()) {
    Parent.removeChild(Parent.firstChild)
  }

  dataArray.forEach(function (r) {

    var tbody = document.getElementById("part-table-body")
    var row = document.createElement("tr")
    var orderId = document.createElement("td")
    orderId.className = "main-data"
    orderId.textContent = r[0]
    var tech = document.createElement("td")
    tech.className = "main-data"
    tech.textContent = r[1]
    var wo = document.createElement("td")
    wo.className = "main-data"
    wo.textContent = r[2]
    var part = document.createElement("td")
    part.className = "main-data"
    part.setAttribute("contenteditable", "true");
    part.textContent = r[3]
    var qty = document.createElement("td")
    qty.className = "main-data"
    qty.textContent = r[4]
    var description = document.createElement("td")
    description.className = "main-data"
    description.textContent = r[5]
    description.setAttribute("contenteditable", "true");
    var vendor = document.createElement("td")
    vendor.className = "main-data"
    vendor.textContent = r[6]
    vendor.setAttribute("contenteditable", "true");
    var manufacturer = document.createElement("td")
    manufacturer.className = "main-data"
    manufacturer.textContent = r[8]
    manufacturer.setAttribute("contenteditable", "true");
    var ordered = document.createElement('td')
    ordered.className = 'main-data'
    ordered.textContent = r[9]
    var backOrder = document.createElement('td')
    backOrder.className = 'main-data'
    backOrder.textContent = r[10]
    var received = document.createElement('td')
    received.className = 'main-data'
    received.textContent = r[11]
    var notes = document.createElement('td')
    notes.className = 'main-data'
    notes.textContent = r[20]
    var category = document.createElement('td')
    category.className = 'main-data'
    category.textContent = r[12]
    var opType = document.createElement('td')
    opType.className = 'main-data'
    opType.textContent = r[13]
    var jobId = document.createElement('td')
    jobId.className = 'main-data'
    jobId.textContent = r[14]
    var customerId = document.createElement('td')
    customerId.className = 'main-data'
    customerId.textContent = r[15]
    var boatId = document.createElement('td')
    boatId.className = 'main-data'
    boatId.textContent = r[16]
    var customer = document.createElement("td")
    customer.className = "main-data"
    customer.textContent = r[17]
    var boat = document.createElement("td")
    boat.className = "main-data"
    boat.textContent = r[18]
    var shortDesc = document.createElement("td")
    shortDesc.className = "main-data"
    shortDesc.textContent = r[19]
    var originalQty = document.createElement('td')
    originalQty.className = 'main-data'
    originalQty.textContent = r[21]
    var add = document.createElement("td")
    add.innerHTML = "<button onclick='receiveParts(this)' class='addOpButton'><img src='/assets/checklist.png' alt='Close Icon' width='24' height='24'></button>"
    var del = document.createElement("td")
    del.innerHTML = "<button onclick='deleteParts(this)' class='deleteOpButton'><img src='/assets/close_icon.png' alt='Close Icon' width='24' height='24'></button>"
    var updateQty = document.createElement("td")
    updateQty.className = "main-data"
    updateQty.textContent = r[22]
    var partId = document.createElement('td')
    partId.className = "main-data"
    partId.textContent = r[23]


    row.appendChild(orderId).style.display = "none"
    row.appendChild(tech)
    row.appendChild(wo)
    row.appendChild(part)
    row.appendChild(qty)
    row.appendChild(description)
    row.appendChild(vendor).style.display = "none"
    row.appendChild(manufacturer).style.display = "none"
    row.appendChild(ordered)
    row.appendChild(backOrder).style.display = "none"
    row.appendChild(received).style.display = "none"
    row.appendChild(category).style.display = "none"
    row.appendChild(opType).style.display = "none"
    row.appendChild(jobId).style.display = "none"
    row.appendChild(customerId).style.display = "none"
    row.appendChild(boatId).style.display = "none"
    row.appendChild(customer).style.display = "none"
    row.appendChild(boat).style.display = "none"
    row.appendChild(shortDesc).style.display = "none"
    row.appendChild(notes).style.display = "none"
    row.appendChild(originalQty).style.display = "none"
    row.appendChild(add)
    row.appendChild(del)
    row.appendChild(updateQty).style.display = "none"
    row.appendChild(partId).style.display = "none"
    tbody.appendChild(row)
  })
  document.getElementById('receiveButton').disabled = false;
}

function closePartTable() {
  document.getElementById("part-order-table").style.display = 'none'
}

document.getElementById('receivePart').addEventListener('click', () => {
  if (document.getElementById('receivePart').checked === true) {
    document.getElementById('listReceived').value = '';
    document.getElementById('costReceived').value = '';
    document.getElementById('saleReceived').value = '';
    document.getElementById('listReceived').focus();

    var qty = prompt('Enter Quantity Received')
    document.getElementById('qtyReceived').value = qty
  } else { document.getElementById('qtyReceived').value = '' }

})

function clearPartOrder() {

  document.getElementById('orderPart').value = ''
  document.getElementById('orderQty').value = ''
  document.getElementById('partDescription').value = ''
  document.getElementById('vendor').value = ''
  document.getElementById('vendorId').value = ''
  document.getElementById('manufacturer').value = ''
  document.getElementById('newPart').value = "Yes"
  document.getElementById('orderedPart').checked = false
  document.getElementById('addToInventory').checked = false
  document.getElementById('receivePart').checked = false
  document.getElementById('qtyReceived').value = ''
  document.getElementById('partWorkOrder').scrollIntoView()

}

function closePartOrderBox() {
  document.getElementById('partsModal').style.display = 'none'
  document.getElementById('orderPart').value = ''
  document.getElementById('partTech').value = ''
  document.getElementById('orderQty').value = ''
  document.getElementById('partDescription').value = ''
  document.getElementById('vendor').value = ''
  document.getElementById('vendorId').value = ''
  document.getElementById('manufacturer').value = ''
  document.getElementById('newPart').value = "Yes"
  document.getElementById('addToInventory').checked = false
  document.getElementById('receivePart').checked = false
  document.getElementById('qtyReceived').value = ''
  document.getElementById('partWorkOrder').scrollIntoView()

}



function discount() {
  var list = document.getElementById('listPrice').value
  var discountGiven = Number(document.getElementById('receivedPercent').value) / 100

  var cost = document.getElementById('costPrice').value

  if (list == '') {
    document.getElementById('listPrice').value = Number(Number(cost) / (1 - discountGiven)).toFixed(2)
    var list = document.getElementById('listPrice').value
  }

  var percent = Math.round(((Number(list) - Number(cost)) / Number(list)) * 100)

  if (percent <= 15) {
    var sale = list
    var discount = "No Discount"
  } else if (percent > 15 && percent <= 25) {
    var discount = "10%"
    var sale = Number(list) - (Number(list) * .10)
  } else if (percent > 25 && percent <= 35) {
    var discount = "10%"
    var sale = Number(list) - (Number(list) * .10)
  } else if (percent > 35 && percent <= 40) {
    var sale = Number(list) - (Number(list) * .15)
    var discount = "15%"
  } else if (percent > 40) {
    var sale = Number(list) - (Number(list) * .15)
    var discount = "15%"
  }

  if (document.getElementById('receivedPercent').value == '') {
    document.getElementById('receivedPercent').value = percent + '%'
  }
  document.getElementById('discountPercent').value = discount
  document.getElementById('salePrice').value = '$' + Number(sale).toFixed(2).toLocaleString()
}

function closeCalc() {
  document.getElementById('calcBody').style.display = 'none'
  document.getElementById('listPrice').value = ''
  document.getElementById('costPrice').value = ''
  document.getElementById('receivedPercent').value = ''
  document.getElementById('discountPercent').value = ''
  document.getElementById('salePrice').value = ''
}

function passId(id, index, value) {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function (event) {
    const db = event.target.result;
    db.createObjectStore("openData", { keyPath: "id" });
  };

  openRequest.onsuccess = function (event) {
    const db = event.target.result;
    const tableTx = db.transaction("openData", "readwrite"); // Changed to readwrite
    const tableStore = tableTx.objectStore("openData");
    const tableRequest = tableStore.getAll();

    tableRequest.onsuccess = function (event) {
      const data = event.target.result;

      // Find the item to update
      const itemToUpdate = data.find(boat => boat.id === id); // Assuming each item has an 'id' field

      if (itemToUpdate) {
        // Update the value
        if (itemToUpdate[index] !== undefined) {
          itemToUpdate[index] = value; // Updated the value directly
        }

        // Put the updated item back into the store
        const updateRequest = tableStore.put(itemToUpdate);

        updateRequest.onsuccess = function () {
          console.log(`Data updated for id ${id}`);
        };

        updateRequest.onerror = function () {
          console.error("Error updating data");
        };
      }
    };

    tableRequest.onerror = function () {
      console.error("Error fetching data");
    };
  };

  openRequest.onerror = function () {
    console.error("Error opening database");
  };
}


function getCustomerRecord(customerId) {
  return new Promise((resolve, reject) => {
    // Open a connection to the IndexedDB database
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion); // Adjust the version number as needed

    openRequest.onerror = function (event) {
      reject("IndexedDB error: " + openRequest.error);
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readonly");
      const objectStore = transaction.objectStore("customerData");
      const request = objectStore.get(customerId);

      request.onsuccess = function (event) {
        // Check if we got something back
        if (request.result) {
          resolve(request.result); // Return the found customer record
        } else {
          resolve(null); // No customer found
        }
      };

      request.onerror = function (event) {
        reject("Could not retrieve customer data: " + request.error);
      };
    };
  });
}

/* function openButtons() {
let buttons = document.querySelectorAll('.tideButton');
buttons.forEach(button => {
  button.classList.toggle('hidden');
});
document.getElementById('btnBox').classList.toggle('hidden')
}

const closerButtons = document.querySelectorAll('.closer')

closerButtons.forEach( button => {
button.addEventListener('click', openButtons)
}) */


async function updateJobDB(jobId, index, value) {

  const today = await getTodaysDate();
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      // Use the get method to fetch the individual item using jobId
      console.log(jobId);
      const getRequest = objectStore.get(jobId);
      console.log(getRequest)
      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;

        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;

          if(value === 'Ready to Bill'){
            itemToUpdate['Complete_Date'] = today;
          }

          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function () {
            console.log(`Data updated for Job_ID ${jobId}`);
          };

          updateRequest.onerror = function () {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  };
}

async function updateCustomerDB(customerId, index, value) {

  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      // Use the get method to fetch the individual item using jobId
      const getRequest = objectStore.get(customerId);
      console.log(getRequest)
      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;

        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;

          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function () {
            console.log(`Data updated for Job_ID ${customerId}`);
          };

          updateRequest.onerror = function () {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${customerId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  };
}

async function updateBoatDB(customerId, boatId, index, value) {
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'id' is the key for customers
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      const getRequest = objectStore.get(customerId);

      getRequest.onsuccess = function (event) {
        const customerData = event.target.result;

        if (customerData) {
          // Find the correct boat
          const boatToUpdate = customerData.Boats[boatId];

          if (boatToUpdate) {
            // Update the specific value for the boat
            boatToUpdate[index] = value;

            // Put the updated customer object back into the store
            const updateRequest = objectStore.put(customerData);

            updateRequest.onsuccess = function () {
              console.log(`Data updated for Boat_ID ${boatId} under Customer_ID ${customerId}`);
            };

            updateRequest.onerror = function () {
              console.error("Error updating data");
            };
          } else {
            console.error(`No boat found with Boat_ID ${boatId}`);
          }
        } else {
          console.error(`No customer found with Customer_ID ${customerId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching customer data");
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  }
}


async function getJobDataDB(jobId) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

      openRequest.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("jobData")) {
          db.createObjectStore("jobData", { keyPath: "id" });
        }
      };

      openRequest.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction("jobData", "readonly");
        const objectStore = transaction.objectStore("jobData");

        const getRequest = objectStore.get(jobId);

        getRequest.onsuccess = function (event) {
          const jobData = event.target.result;
          if (jobData) {
            resolve(jobData);
          } else {
            console.error(`No item found with Job_ID ${jobId}`);
            resolve(null);
          }
        };

        getRequest.onerror = function () {
          console.error("Error fetching data");
          reject(new Error("Error fetching data"));
        };

        transaction.oncomplete = function () {
          db.close();
        };
      };

      openRequest.onerror = function () {
        console.error("Error opening database");
        reject(new Error("Error opening database"));
      };
    } catch (error) {
      console.log("Error retrieving data from IndexedDB", error);
      reject(error);
    }
  });
}

async function getJobsByWorkOrder(workOrder) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

      openRequest.onupgradeneeded = function (event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("jobData")) {
          db.createObjectStore("jobData", { keyPath: "id" });
        }
      };

      openRequest.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction("jobData", "readonly");
        const objectStore = transaction.objectStore("jobData");

        const jobs = [];
        const cursorRequest = objectStore.openCursor();

        cursorRequest.onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            const jobData = cursor.value;
            if (jobData.Work_Order === workOrder) {
              jobs.push(jobData);
            }
            cursor.continue();
          } else {
            // Resolve with all matching jobs once the cursor is done
            resolve(jobs);
          }
        };

        cursorRequest.onerror = function () {
          console.error("Error fetching data");
          reject(new Error("Error fetching data"));
        };

        transaction.oncomplete = function () {
          db.close();
        };
      };

      openRequest.onerror = function () {
        console.error("Error opening database");
        reject(new Error("Error opening database"));
      };
    } catch (error) {
      console.log("Error retrieving data from IndexedDB", error);
      reject(error);
    }
  });
}

function getTechId(name) {
  let techs = {
    "Alex": "56",
    "Antony": "43",
    "Chelsea": "60",
    "Conrad": "66",
    "Darlin": "68",
    "Eduardo": "58",
    "Elvin": "70",
    "Henry": "57",
    "Jorge": "55",
    "Karen": "3",
    "Kyle": "36",
    "Sophie": "61",
    "Liam": "67",
    "Mac": "33"
  }
  return techs[name]
}


async function addJobToTech(row, tech) {

  console.log(row)
  console.log(tech)

  let jobId = row.Job_ID;

  try {

    // Update the Techs value in /data/jobID
    const dataJobRef = ref(database, `data/${jobId}`);
    await update(dataJobRef, {
      Techs: tech,
    });
    await updateJobDB(jobId, "Techs", tech);
    console.log("Techs value updated successfully");
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }
}

async function removeTech(jobId) {

  try {

    // Update the Techs value in /data/jobID
    const dataJobRef = ref(database, `data/${jobId}`);
    await update(dataJobRef, {
      Techs: '',
    });
    await updateJobDB(jobId, "Techs", '');
    console.log("Techs value updated successfully");
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }
}


async function addJobFirebase(serviceLog) {

  let start
  if (serviceLog.Category === "Spring Commissioning" &&
      serviceLog.Description.toLowerCase().includes('bottom paint') &&
      serviceLog.Launch_Date !== "") {
    start = serviceLog.Launch_Date
  } else if (serviceLog.Category === "Spring Commissioning" &&
    serviceLog.Launch_Date !== "") {
    start = serviceLog.Launch_Date
  } else if (serviceLog.Category === 'Winterizing' &&
    serviceLog.Haul_Date !== "") {
    start = serviceLog.Haul_Date
  } else { start = serviceLog.Request_Date }

  let data = {
    'Billing User': "",
    'Boat_ID': serviceLog.Boat_ID,
    'Boat_Name': serviceLog.Boat_Name,
    'Category': serviceLog.Category,
    'Charge': "",
    'Customer_ID': serviceLog.Customer_ID,
    'Customer_Name': serviceLog.Customer_Name,
    'Date Billed': "",
    'Date_Hauled': "",
    'Date_Launched': "",
    'Description': serviceLog.Description,
    'Email': serviceLog.Email,
    'Estimated_Hours': serviceLog.Estimated_Hours,
    'Event_Added': "",
    'Event_Time': "",
    'Haul_Date': serviceLog.Haul_Date,
    'Hold_Days': "",
    'Job_ID': serviceLog.Job_ID,
    'Labor Billed': "",
    'Launch_Date': serviceLog.Launch_Date,
    'Location_Number': "",
    'Notes': serviceLog.Notes,
    'OpCode': serviceLog.OpCode,
    'Op_Type': serviceLog.Op_Type,
    'Operation_Dependency': serviceLog.Operation_Dependency,
    'Parts Billed': "",
    'Parts Ordered': "",
    'Parts_Kit_Status': "",
    'Priority': "",
    'Ready': "",
    'Request_Date': serviceLog.Request_Date,
    'Short_Description': serviceLog.Short_Description,
    'Short_Description_DM': serviceLog.Short_Description_DM,
    'Start_Date': start,
    'Status': serviceLog.Status,
    'Status_Of_Boat': "",
    'Techs': serviceLog.Techs,
    'Transfer_to_Billing': "",
    'Water': "",
    'Work_Order': serviceLog.Work_Order,
    'Work_Order_ID': "",
    'Yard': ""
  };


  let jobId = data.Job_ID;
  const idToken = await fetchIdToken();
  const firebaseUrl = currentURL;
  const path = `/data/${jobId}.json?auth=${idToken}`;
  const timestampPath = `/data/lastUpdated.json?auth=${idToken}`;

  try {
    const response = await fetch(firebaseUrl + path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    });
    const result = await response.json();
    console.log("Job added successfully", result);
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }

  try {
    const timestampResponse = await fetch(firebaseUrl + timestampPath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ".sv": "timestamp" })
    });
    const timestampResult = await timestampResponse.json();
    console.log("Timestamp updated successfully", timestampResult);
  } catch (e) {
    console.error("Error updating timestamp: " + e.toString());
  }

}


document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.exitButton');
  buttons.forEach(button => {
    button.addEventListener('click', e => {
      // Get the button element, whether clicked directly or on a child element
      const targetButton = e.target.closest('button');

      if (targetButton) {
        console.log('Clicked element:', e.target.tagName);
        console.log('Target button:', targetButton.tagName);

        if (targetButton.hasAttribute('data-close')) {
          const elementToClose = document.getElementById(targetButton.getAttribute('data-close'));
          console.log(elementToClose);
          if(elementToClose.id === "Add"){ 
            currentOpCodes = [];
            document.getElementById('work-order-type-select').value = 'general-work-order'

            const storage = document.getElementById('winter-storage-section')
            const winterSpring = document.getElementById('storage-work-order-section')
            const dates = document.getElementById('work-order-date-section')
            const general = document.getElementById('general-work-order-section')
            
              storage.classList.add('hidden-form-section');
              dates.classList.add('hidden-form-section');
              winterSpring.classList.add('hidden-form-section')
              winterSpring.style.display='none';
              general.classList.remove('hidden-form-section');
              general.style.display='flex';

          }

          if (elementToClose) {
            elementToClose.style.display = 'none';

            if(targetButton.getAttribute('data-close') === 'Add') {
              closeAdd();
            }

          }

        }

        if (targetButton.hasAttribute('data-slide')) {
          const elementToSlide = document.getElementById(targetButton.getAttribute('data-slide'));
          if (elementToSlide) {
            elementToSlide.classList.remove('active');
          }
        }
      }
    });
  });
});


document.addEventListener("DOMContentLoaded", () => {

  const buttons = document.querySelectorAll('.tideButton')
  buttons.forEach(button => {
    button.addEventListener('click', (e) => {
      const elements = document.querySelectorAll('.selected-tide-button');
      elements.forEach(element => element.classList.remove('selected-tide-button'));
      e.target.classList.add('selected-tide-button');
    })

  })

})

async function modifyTechJobs(techID, jobID, desc, notes, techName) {
  if (!techID || techID.trim() === '') {
    return;
  }

  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/scheduleDatabase/${techID}/jobs/${jobID}.json?auth=${idToken}`;

    // Construct the body to only include the fields being updated
    const partialUpdate = {
      Description: desc,
      Notes: notes
    };

    // Set up the fetch options for a PATCH request
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate), // Convert the partial update object to a JSON string
    };

    // Make the fetch call to update the job
    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error('HTTP error! status: ' + response.status);
    const result = await response.json();
    console.log("Operation successful", result);

    updateJobDB(jobID, 'Techs', techName);
  } catch (error) {
    console.error("Error modifying tech jobs:", error);
  }
}



async function createEmailTemplate() {
  const templateId = generateId();
  const subject = document.getElementById('email-subject').value;
  const body = document.getElementById('email-message').value;

  try {
    let templateRef;
    if (templateId) {
      templateRef = ref(database, `emailTemplates/${templateId}`);
    } else {
      templateRef = push(ref(database, "emailTemplates"));
    }

    const template = {
      subject: subject,
      body: body
    };

    await set(templateRef, template);
    console.log("Email template created successfully");

    return templateRef.key;
  } catch (error) {
    console.error("Error creating email template:", error);
    return null;
  }
}

async function updateJobStatus(jobID, status) {
  console.log(status);
  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    const firebaseUrl = `${currentURL}/data/${jobID}.json?auth=${idToken}`;

    const today = await getTodaysDate();

    let partialUpdate = {};

    if(status === 'Ready to Bill') {
      partialUpdate = { "Status": status, "Operation_Dependency": 0, "Complete_Date": today };
    }else{
      partialUpdate = { "Status": status, "Operation_Dependency": 0};
    }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    
    await updateJobDB(jobID, 'Status', status);

    if (status === 'Ready to Bill') {
      await updateJobDB(jobID, 'Operation_Dependency', 0);
    }

    if(status === 'Complete'){
      await updateJobDB(jobID, 'Complete_Date', today);
    }

  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}

async function updateMultipleJobStatuses(jobIDs, status) {
  try {
    // Ensure jobIDs is an array
    const jobIDsArray = Array.isArray(jobIDs) ? jobIDs : JSON.parse(jobIDs);

    if (!Array.isArray(jobIDsArray)) {
      throw new Error('Invalid input: jobIDs must be an array or a JSON string representing an array');
    }

    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    const baseUrl = `${currentURL}/data`;

    const updatePromises = jobIDsArray.map(async (jobID) => {
      const firebaseUrl = `${baseUrl}/${jobID}.json?auth=${idToken}`;

      const partialUpdate = { "Status": status, "Operation_Dependency": 0 };

      const options = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(partialUpdate),
      };

      const response = await fetch(firebaseUrl, options);
      if (!response.ok) throw new Error(`HTTP error for job ${jobID}! status: ${response.status}`);
      const result = await response.json();
      console.log(`Operation successful for job ${jobID}`, result);

      updateJobDB(jobID, 'Status', status); //Updates job status
      if (status === 'Ready to Bill') {
        updateJobDB(jobID, 'Operation_Dependency', 0); //Updates job dependency
      }

      return result;
    });

    const results = await Promise.all(updatePromises);
    console.log("All jobs updated successfully", results);
    return results;
  } catch (error) {
    console.error("Error modifying job statuses:", error);
    throw error;
  }
}

async function updateJobInfo(jobID, index, value) {

  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/data/${jobID}.json?auth=${idToken}`;


    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ [index]: value }),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    updateTimestamps('jobData', 'data');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

document.getElementById('note-text').addEventListener('keydown', async (e) => {
  if (e.key !== 'Enter') { return; }

    const jobId = document.getElementById('note-job-id').value;
    const note = document.getElementById('note-text').value;
    const tech = await getCurrentUserData();
    const newId = await generateId();
    const noteDate = await getFormattedDate();
    const noteTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  
    const newNote = {
      Note: note,
      Tech: tech.displayName,
      Date: noteDate,
      Time: noteTime,
      Job_ID: jobId,
      id: newId
    };
  
    const currentJobData = await getJobData(jobId);
    if (!currentJobData.Notes) {
      currentJobData.Notes = {};
    }
  
    currentJobData.Notes[newId] = newNote;
    await updateJobNotes(jobId, 'Notes', currentJobData.Notes);
  
    document.getElementById('note-text').value = '';
  
    const noteArea = document.getElementById('note-area');
    const noteContainer = document.createElement('div');
    noteContainer.className = 'note-pair';
    noteContainer.id = newId;
  
    const noteInfo = document.createElement('div');
    noteInfo.className = 'note-info';
  
    const noteText = document.createElement('p');
    noteText.className = 'note-p';
    noteText.textContent = note;
  
    const techInfo = document.createElement('div');
    techInfo.className = 'note-info-tech';
  
    const techData = document.createElement('p');
    techData.className = 'note-tech-name';
    techData.textContent = `${tech.displayName} | ${noteDate}, ${noteTime}`;
  
    techInfo.appendChild(techData);
  
    noteContainer.addEventListener('click', () => {
      const confirmationModal = document.createElement('div');
      confirmationModal.className = 'confirmation-note-delete-modal';
  
      const modalContent = document.createElement('div');
      modalContent.className = 'note-delete-modal-content';
  
      const confirmationMessage = document.createElement('p');
      confirmationMessage.textContent = 'Are you sure you want to delete this note?';
  
      const confirmButton = document.createElement('button');
      confirmButton.textContent = 'Confirm';
      confirmButton.className = 'confirm-delete';
  
      const cancelButton = document.createElement('button');
      cancelButton.textContent = 'Cancel';
      cancelButton.className = 'cancel-delete';
  
      confirmButton.addEventListener('click', async () => {
        await deleteNotes(jobId, newId);
        noteContainer.remove();
        confirmationModal.remove();
      });
  
      cancelButton.addEventListener('click', () => {
        confirmationModal.remove();
      });
  
      modalContent.appendChild(confirmationMessage);
      modalContent.appendChild(confirmButton);
      modalContent.appendChild(cancelButton);
      confirmationModal.appendChild(modalContent);
  
      document.body.appendChild(confirmationModal);
    });
  
    noteInfo.appendChild(noteText);
    noteInfo.appendChild(techInfo);
    noteContainer.appendChild(noteInfo);
  
    noteArea.appendChild(noteContainer);
});

  document.getElementById('note-submit').addEventListener('click', async () => {
    const jobId = document.getElementById('note-job-id').value;
    const note = document.getElementById('note-text').value;
    const tech = await getCurrentUserData();
    const newId = await generateId();
    const noteDate = await getFormattedDate();
    const noteTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  
    const newNote = {
      Note: note,
      Tech: tech.displayName,
      Date: noteDate,
      Time: noteTime,
      Job_ID: jobId,
      id: newId
    };
  
    const currentJobData = await getJobData(jobId);
    if (!currentJobData.Notes) {
      currentJobData.Notes = {};
    }
  
    currentJobData.Notes[newId] = newNote;
    await updateJobNotes(jobId, 'Notes', currentJobData.Notes);
  
    document.getElementById('note-text').value = '';
  
    const noteArea = document.getElementById('note-area');
    const noteContainer = document.createElement('div');
    noteContainer.className = 'note-pair';
    noteContainer.id = newId;
  
    const noteInfo = document.createElement('div');
    noteInfo.className = 'note-info';
  
    const noteText = document.createElement('p');
    noteText.className = 'note-p';
    noteText.textContent = note;
  
    const techInfo = document.createElement('div');
    techInfo.className = 'note-info-tech';
  
    const techData = document.createElement('p');
    techData.className = 'note-tech-name';
    techData.textContent = `${tech.displayName} | ${noteDate}, ${noteTime}`;
  
    techInfo.appendChild(techData);
  
    noteContainer.addEventListener('click', () => {
      const confirmationModal = document.createElement('div');
      confirmationModal.className = 'confirmation-note-delete-modal';
  
      const modalContent = document.createElement('div');
      modalContent.className = 'note-delete-modal-content';
  
      const confirmationMessage = document.createElement('p');
      confirmationMessage.textContent = 'Are you sure you want to delete this note?';
  
      const confirmButton = document.createElement('button');
      confirmButton.textContent = 'Confirm';
      confirmButton.className = 'confirm-delete';
  
      const cancelButton = document.createElement('button');
      cancelButton.textContent = 'Cancel';
      cancelButton.className = 'cancel-delete';
  
      confirmButton.addEventListener('click', async () => {
        await deleteNotes(jobId, newId);
        noteContainer.remove();
        confirmationModal.remove();
      });
  
      cancelButton.addEventListener('click', () => {
        confirmationModal.remove();
      });
  
      modalContent.appendChild(confirmationMessage);
      modalContent.appendChild(confirmButton);
      modalContent.appendChild(cancelButton);
      confirmationModal.appendChild(modalContent);
  
      document.body.appendChild(confirmationModal);
    });
  
    noteInfo.appendChild(noteText);
    noteInfo.appendChild(techInfo);
    noteContainer.appendChild(noteInfo);
  
    noteArea.appendChild(noteContainer);
});
  
async function updateJobNotes(jobId, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/data/${jobId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: value };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateJobNotesDB(jobId, index, value);
    updateTimestamps('jobData', 'data');
    console.log("IndexedDB update successful");
  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

async function updateJobNotesDB(jobId, index, value) {

  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      // Use the get method to fetch the individual item using jobId
      const getRequest = objectStore.get(jobId);
      console.log(getRequest)
      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;

        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;

          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function () {
            console.log(`Data updated for Job_ID ${jobId}`);
          };

          updateRequest.onerror = function () {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  };
}

async function deleteNotes(jobId, noteId) {
  try {
    // Delete from Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/data/${jobId}/Notes/${noteId}.json?auth=${idToken}`;

    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    console.log("Firebase delete successful");

    // Delete from IndexedDB
    await deleteNotesDB(jobId, noteId);
    updateTimestamps('jobData', 'data');
    console.log("IndexedDB delete successful");
  } catch (error) {
    console.error("Error deleting note:", error);
  }
}

async function deleteNotesDB(jobId, noteId) {
  console.log(jobId, noteId);
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      const getRequest = objectStore.get(jobId);

      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;

        if (itemToUpdate) {
          // Remove the note
          delete itemToUpdate.Notes[noteId];

          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function () {
            console.log(`Note deleted for Job_ID ${jobId}`);
          };

          updateRequest.onerror = function () {
            console.error("Error deleting note");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      transaction.oncomplete = function () {
        db.close();
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  }
}

async function updateCustomerInfo(customerId, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/customers/${customerId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: value };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateCustomerDB(customerId, index, value);
    updateTimestamps('customerData', 'customers');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

async function updateBoatInfo(customerId, boatId, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `${currentURL}/customers/${customerId}/Boats/${boatId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: value };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateBoatDB(customerId, boatId, index, value);
    updateTimestamps('customerData', 'customers');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

async function removeJobFromTech(jobId, tech, changed) {
  const techId = getTechId(tech);
  const firebaseUrl = currentURL;
  const schedulePath = `/scheduleDatabase/${techId}/jobs/${jobId}.json`;
  const dataPath = `/data/${jobId}.json`;

  try {
    // Remove the job from the old technician's schedule
    await fetch(firebaseUrl + schedulePath, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    console.log("Job removed successfully from old tech");

    // Update the job's Techs field in the /data node
    const updateResponse = await fetch(firebaseUrl + dataPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Techs: "" }) // Set Techs to an empty string
    });

    if (updateResponse.ok) {
      console.log(`Techs cleared for job ${jobId}`);
    } else {
      console.error(`Failed to clear Techs for job ${jobId}`);
    }
  } catch (e) {
    console.error("Error removing job from old tech or clearing Techs: " + e.toString());
  }
  if (!changed) {
    await updateJobDB(jobId, 'Techs', '');
  }
}


async function getTechSchedule() {
  document.getElementById('techField').innerHTML = ''
  document.getElementById('workList').innerHTML = ''

  const currentUser = auth.currentUser;
  const hasAccess = await getUserRole(currentUser.uid)
  const idToken = await fetchIdToken();

  const firebaseUrl = currentURL + "/scheduleDatabase.json?auth=" + idToken;
  try {
    const response = await fetch(firebaseUrl);
    const scheduleData = await response.json();
    const currentUser = auth.currentUser;
    const hasAccess = await getUserRole(currentUser.uid);
    let totalHoursForAllTechs = [];
    let techSort = []

    console.log(scheduleData);

    Object.keys(scheduleData).forEach(async techId => {
      techSort.push(scheduleData[techId])
    })

    techSort.sort((a, b) => {
      if (a.name == null && b.name == null) return 0;
      if (a.name == null) return 1;
      if (b.name == null) return -1;
      return a.name.localeCompare(b.name);
    });

    techSort.forEach(tech => {

      if (!tech.name) { return }

      const techName = tech.name;
      const techDiv = document.createElement('div');
      techDiv.className = 'tech-div'
      const techP = document.createElement('p')
      const techPHours = document.createElement('p')
      techP.innerHTML = techName;
      const techId = tech.id;
      const techJobs = Object.values(scheduleData[techId].jobs || {});
      const techHours = tech.hours;


      // Sort jobs by date as in your original code
      techJobs.sort((a, b) => {
        const dateA = new Date(a.date).getTime(); // Assuming `date` is directly under job
        const dateB = new Date(b.date).getTime();
        return dateA - dateB;
      });

      // Follow similar steps to create and append job elements to the techTableDiv
      const techTableDiv = document.querySelector('#workList');
      const techNameContainer = document.createElement('fieldset');
      const label = document.createElement('legend');


      let totalHoursTech = 0

      techJobs.forEach(job => {
        if (!job) {
          return;
        }
        var jobDiv = document.createElement('div');
        jobDiv.className = 'job-item';
        jobDiv.setAttribute('data-id', job.Job_ID);
        jobDiv.setAttribute('data-tech', tech.name);
        jobDiv.setAttribute('data-techId', tech.id);
        jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));
        jobDiv.setAttribute('data-status', job.Status);
        jobDiv.addEventListener('click', (e) => {
          opCheck(e);
        });
        /* jobDiv.addEventListener('click', (e) => {
          e.stopPropagation(); // Prevent the click from bubbling up
          const jobId = e.target.closest('.job-item').getAttribute('data-id');
          fetchAndLogRecord(jobId)
            .then(record => {
              console.log('Fetched job record:', record);
            })
            .catch(error => {
              console.error('Failed to fetch job record:', error);
            });
        }); */

        const statusDiv = document.createElement('div');
        statusDiv.className = 'status-info';
        if (job.Status === 'In Progress') {
          statusDiv.style.backgroundColor = 'yellow';
        } else if (job.Status === 'Hold') {
          statusDiv.style.backgroundColor = 'gray'
        } else { statusDiv.style.backgroundColor = 'green' };
        statusDiv.innerHTML = ' ';

        const infoDiv = document.createElement('div');
        infoDiv.className = 'info-div-tech';

        const customerDiv = document.createElement('div');
        customerDiv.className = 'customer-boat-info';

        const customerNameP = document.createElement('p');
        customerNameP.style.fontWeight = 'bold';
        customerNameP.style.fontSize = '1rem';
        customerNameP.innerHTML = job.Customer_Name;

        const boatNameP = document.createElement('p');
        boatNameP.style.color = 'gray';
        boatNameP.innerHTML = job.Boat_Name;

        customerDiv.appendChild(customerNameP);
        customerDiv.appendChild(boatNameP);

        const descriptionDiv = document.createElement('div');
        descriptionDiv.className = 'description-info';
        const jobDescriptionP = document.createElement('p');

        let jobDescription;

        if (job.Description.toLowerCase().includes('bottom paint')) {
          const originalData = job.Description;
          const startIndex = originalData.indexOf('(Paint Type:');
          const endIndex = originalData.indexOf(']', startIndex) + 1;

          if (startIndex !== -1 && endIndex !== -1) {
            const extractedSubstring = originalData.substring(startIndex, endIndex);

            if (job.Description.split(':')[0].includes(',')) {
              jobDescription = `${job.Description.split(':')[0].split(',')[0]} : ${extractedSubstring}`
            } else { jobDescription = job.Description.split(':')[0] + ": " + extractedSubstring };
          }
        } else if (job.Description.toLowerCase().includes('replace anodes')) {
          const originalData = job.Description;
          const startIndex = originalData.indexOf('(Anode List:');
          if (startIndex !== -1) {
            const extractedSubstring = originalData.substring(startIndex);
            if (job.Description.split(':')[0].includes(',')) {
              jobDescription = `${job.Description.split(':')[0].split(',')[0]} : ${extractedSubstring}`
            }
            jobDescription = job.Description.split(':')[0] + ": " + extractedSubstring;
          }
        } else {

          if (job.Description.split(':')[0].includes(',')) {
            jobDescription = job.Description.split(':')[0].split(',')[0]
          } else { jobDescription = job.Description.split(':')[0] };
        };

        jobDescriptionP.innerHTML = jobDescription;
        descriptionDiv.appendChild(jobDescriptionP);

        infoDiv.appendChild(customerDiv);
        infoDiv.appendChild(descriptionDiv);

        jobDiv.appendChild(statusDiv);
        jobDiv.appendChild(infoDiv);

        // Create a div to hold status and hours information
        const statusHoursDiv = document.createElement('div');
        statusHoursDiv.className = 'status-hours-info';
        statusHoursDiv.style.display = "none";

        const statusP = document.createElement('p');
        statusP.innerHTML = `<strong>Status:</strong> ${job.Status}`;
        statusHoursDiv.appendChild(statusP);

        const estimatedHoursP = document.createElement('p');
        estimatedHoursP.innerHTML = `<strong>Estimated Hours:</strong> ${job.Estimated_Hours}`;
        statusHoursDiv.appendChild(estimatedHoursP);

        totalHoursTech += Number(job.Estimated_Hours)

        let total = 0

        if (jobHours[job.Job_ID]) {
          total = jobHours[job.Job_ID]
        }

        const totalHoursP = document.createElement('p');
        totalHoursP.innerHTML = `<strong>Total Hours:</strong> ${total}`;
        statusHoursDiv.appendChild(totalHoursP);

        const noteDiv = document.createElement('div');
        noteDiv.className = 'description-info';
        const jobNotesP = document.createElement('p');
        jobNotesP.innerHTML = `<strong>Notes</strong><br> ${job.Notes}`;
        noteDiv.appendChild(jobNotesP);

        if (job.notes) {
          jobDiv.appendChild(noteDiv);
        }

        jobDiv.appendChild(statusHoursDiv);

        /*       if (hasAccess === 'admin') {
                  console.log('Has Access: ' + hasAccess);
                      const moveJobButton = document.createElement('button');
                      moveJobButton.innerHTML = '<i class="material-icons">menu</i>';
                      moveJobButton.className = 'move-job-button';
                      moveJobButton.addEventListener('click', async (e) => {
                        e.stopPropagation();
                        const techNames = ["Alex", "Antony", "Conrad", "Darlin", "Eduardo", "Elvin", "Henry", "Jorge", "Kyle", "Liam", "Mac"];
                        const currentTechName = e.target.closest('.job-item').getAttribute('data-tech');
                        const jobId = e.target.closest('.job-item').getAttribute('data-id');
                        const currentTechId = e.target.closest('.job-item').getAttribute('data-techId');
                        const jobInfo = e.target.closest('.job-item').getAttribute('data-jobInfo');
                        const popup = createTechPopup(techNames, currentTechName, jobId, currentTechId, jobInfo);
        
                        // Position the popup near the button
                        const rect = e.target.getBoundingClientRect();
                        popup.style.top = `${rect.bottom + window.scrollY}px`;
                        popup.style.left = `${rect.left + window.scrollX}px`;
                      });
        
                      const removeJobButton = document.createElement('button');
                      removeJobButton.innerHTML = '<i class="material-icons">close</i>';
                      removeJobButton.className = 'remove-job-button';
                      removeJobButton.addEventListener('click', async (e) => {
                        e.stopPropagation();
                        await removeJobFromTech(job.Job_ID, techName);
                      });
        
                      jobDiv.appendChild(moveJobButton);
                      jobDiv.appendChild(removeJobButton);
                    
              } */

        techNameContainer.appendChild(jobDiv);
      });

      let availHours = Number(techHours) - Number(totalHoursTech)
      label.textContent = techName + " (Available: " + availHours + " Hours)" || techId; // Fallback to techId if name is not found
      techNameContainer.appendChild(label);
      techTableDiv.appendChild(techNameContainer);
      techPHours.innerHTML = totalHoursTech
      techDiv.appendChild(techP)
      techDiv.appendChild(techPHours)
      document.getElementById('techField').appendChild(techDiv);

    });

  } catch (error) {
    console.error("Error fetching schedule data:", error);
  }
}


function createTechPopup(techNames, currentTechName, jobId, currentTechId, jobInfo) {
  // Remove existing popup if any
  const existingPopup = document.getElementById('tech-popup');
  if (existingPopup) {
    existingPopup.remove();
  }

  // Create the popup container
  const popup = document.createElement('div');
  popup.id = 'tech-popup';
  popup.style.position = 'absolute';
  popup.style.zIndex = '100';
  popup.style.backgroundColor = '#fff';
  popup.style.border = '1px solid #ddd';
  popup.style.padding = '10px';
  popup.style.borderRadius = '5px';
  popup.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';

  // Ensure the popup is placed correctly
  document.body.appendChild(popup);

  // Populate the popup with tech names
  techNames.forEach(techName => {
    if (techName !== currentTechName) { // Exclude the current technician
      const techOption = document.createElement('div');
      techOption.textContent = techName;
      techOption.style.padding = '5px';
      techOption.style.cursor = 'pointer';
      techOption.addEventListener('click', () => {

        addJobFromSchedule(techName, jobId, jobInfo)
        removeJobFromTech(jobId, currentTechName)

        popup.remove(); // Close the popup

      });
      popup.appendChild(techOption);
    }
  });
  const handleOutsideClick = (event) => {
    if (!popup.contains(event.target) && !jobDiv.contains(event.target)) {
      popup.remove();
      document.removeEventListener('click', handleOutsideClick);
    }
  };
  document.addEventListener('click', handleOutsideClick);
  return popup;
}

function jobListPopup(techNames, jobId, jobInfo, jobDiv, currentTech) {
  // Remove existing popup if any

  const existingPopup = document.getElementById('tech-popup');
  if (existingPopup) {
    existingPopup.remove();
  }

  // Create the popup container
  const popup = document.createElement('div');
  popup.id = 'tech-popup';
  popup.style.position = 'absolute';
  popup.style.zIndex = '100';
  popup.style.backgroundColor = '#fff';
  popup.style.border = '1px solid #ddd';
  popup.style.padding = '10px';
  popup.style.borderRadius = '5px';
  popup.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';

  const deleteOption = document.createElement('div');
  deleteOption.textContent = 'Delete';
  deleteOption.style.padding = '5px';
  deleteOption.style.cursor = 'pointer';
  deleteOption.addEventListener('click', async (e) => {
    e.stopPropagation();
    try {
      await removeTech(jobId)
      await updateJobDB(jobId, 'Techs', '');
      await updateWeekView();
      populateJobsForCurrentTech();
      populateJobsByWeek();
      popup.remove();
      // Update the tech name in the HTML
      if (jobDiv && jobDiv.querySelector) {
        console.log(jobDiv);
        const techInfoElement = jobDiv
        if (techInfoElement) {
          techInfoElement.innerHTML = `<strong>Tech: </strong>`;
        } else {
          console.error('Could not find .tech-info element');
        }
      } else {
        console.error('updateSection is not a valid HTML element');
      }
    } catch (error) {
      console.error('Error updating job:', error);
    }
  });
  popup.appendChild(deleteOption);

  // Ensure the popup is placed correctly
  document.body.appendChild(popup);

  // Populate the popup with tech names
  techNames.forEach(techName => {
    if (techName !== currentTech) { // Exclude the current technician
      const techOption = document.createElement('div');
      techOption.textContent = techName;
      techOption.style.padding = '5px';
      techOption.style.cursor = 'pointer';
      techOption.addEventListener('click', async (e) => {

        e.stopPropagation();
        try {

          await addJobFromJobList(techName, jobId, jobInfo);
          await updateJobDB(jobId, 'Techs', techName);
          populateJobsForCurrentTech();
          populateJobsByWeek();

          if (jobDiv && jobDiv.querySelector) {
            console.log(jobDiv);
            const techInfoElement = jobDiv
            if (techInfoElement) {
              techInfoElement.innerHTML = `<strong>Tech: </strong>${techName}`;
            } else {
              console.error('Could not find .tech-info element');
            }
          } else {
            console.error('updateSection is not a valid HTML element');
          }

          popup.remove();
        } catch (error) {
          console.error('Error updating job:', error);
        }
      });
      popup.appendChild(techOption);
    }
  });

  const handleOutsideClick = (event) => {
    if (!popup.contains(event.target) && !jobDiv.contains(event.target)) {
      popup.remove();
      document.removeEventListener('click', handleOutsideClick);
    }
  };
  document.addEventListener('click', handleOutsideClick);

  return popup;
}

async function addJobFromSchedule(techName, jobId, data) {
  const techId = getTechId(techName);
  const idToken = await fetchIdToken();
  const firebaseUrl = currentURL;
  const path = `/scheduleDatabase/${techId}/jobs/${jobId}.json?auth=${idToken}`;

  var dataObj;
  if (typeof data === 'string') {
    try {
      dataObj = JSON.parse(data);
    } catch (e) {
      console.error("Error parsing data string into JSON:", e);
      return;
    }
  } else {
    dataObj = data;
  }

  try {
    const response = await fetch(firebaseUrl + path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataObj)
    });
    const result = await response.json();
    console.log("Job added successfully", result);
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }
}

document.addEventListener('DOMContentLoaded', function () {
  // Create tooltip element and add it to the body
  var tooltip = document.createElement('div');
  tooltip.className = 'tooltip';
  document.body.appendChild(tooltip);
  tooltip.style.display = 'block'
  // Function to show the tooltip
  function showTooltip(e) {
    var tooltipText = this.getAttribute('data-tooltip');
    if (!tooltipText) return;

    tooltip.innerHTML = tooltipText;
    tooltip.style.display = 'block';
    tooltip.style.position = 'absolute'
    tooltip.style.right = '100px';
    tooltip.style.top = '50px';
  }

  // Function to hide the tooltip
  function hideTooltip() {
    tooltip.style.display = 'none';
  }

  // Attach event listeners to buttons with data-tooltip attribute
  var buttons = document.querySelectorAll('[data-tooltip]');
  buttons.forEach(function (button) {
    button.addEventListener('mousemove', showTooltip);
    button.addEventListener('mouseleave', hideTooltip);
  });
});



async function addJobFromJobList(techName, jobId, data) {

  const techId = getTechId(techName);
  const idToken = await fetchIdToken();
  const firebaseUrl = currentURL;
  const schedulePath = `/scheduleDatabase/${techId}/jobs/${jobId}.json?auth=${idToken}`;
  const dataPath = `/data/${jobId}.json?auth=${idToken}`;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;


  let dataObj;
  if (typeof data === 'string') {
    try {
      dataObj = JSON.parse(data);
    } catch (e) {
      console.error("Error parsing data string into JSON:", e);
      return;
    }
  } else {
    dataObj = data;
  }

  let dataUpload = {
    Boat_ID: dataObj.Boat_ID,
    Boat_Name: dataObj.Boat_Name,
    Category: dataObj.Category,
    Customer_Name: dataObj.Customer_Name,
    Customer_ID: dataObj.Customer_ID,
    Start_Date: dataObj.Start_Date,
    Description: dataObj.Description,
    Email: dataObj.Email,
    Haul_Date: dataObj.Haul_Date,
    Estimated_Hours: dataObj.Estimated_Hours,
    Job_ID: dataObj.Job_ID,
    Launch_Date: dataObj.Launch_Date,
    Notes: dataObj.Notes,
    OpType: dataObj.Op_Type,
    Status: dataObj.Status,
    Techs: techName,
    Work_Order: dataObj.Work_Order,
    Schedule_Date: formattedDate
  };

  try {
    // Update the job under the technician's schedule
    const scheduleResponse = await fetch(firebaseUrl + schedulePath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataUpload)
    });
    const scheduleResult = await scheduleResponse.json();
    console.log("Job added to tech schedule successfully", scheduleResult);

    // Update the Techs field for the job in /data
    const dataResponse = await fetch(firebaseUrl + dataPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Techs: techName,
      }) // This updates just the Techs and Schedule Date fields
    });
    const dataResult = await dataResponse.json();

    updateJobDB(jobId, 'Techs', techName);
    console.log("Techs field updated in /data successfully", dataResult);
  } catch (e) {
    console.error("Error updating job data: " + e.toString());
  }
}





let isUpdating = false;

function listenForUpdates() {

  if (!navigator.onLine) {
    console.log('User is offline. Skipping function.');
    return;
  }

  const scheduleRef = ref(database, '/scheduleDatabase');
  onValue(scheduleRef, async (snapshot) => {
    if (!isUpdating) {
      isUpdating = true; // Indicate an update is in progress
      document.getElementById('workList').innerHTML = ''; // Clear the content
      isUpdating = false; // Reset the flag once update is complete


    }
  });
}

listenForUpdates();



document.getElementById('job-search').addEventListener('keyup', searchJobs);

document.getElementById('schedule-search').addEventListener('keyup', searchSchedule);

document.getElementById('tech-search').addEventListener('keyup', searchTech);


function filterTechs() {
  var searchQuery = document.getElementById('searchFieldTechs').value.toLowerCase();

  var fieldsets = document.querySelectorAll('#workList fieldset');

  fieldsets.forEach(function (fieldset) {
    var jobs = fieldset.querySelectorAll('.job-item');
    var legendText = fieldset.querySelector('legend') ? fieldset.querySelector('legend').textContent.toLowerCase() : '';
    var jobMatchFound = false;

    jobs.forEach(function (job) {
      var jobText = job.textContent.toLowerCase();
      if (jobText.includes(searchQuery) || legendText.includes(searchQuery)) {
        job.style.display = '';
        jobMatchFound = true;
      } else {
        job.style.display = 'none';
      }
    });

    fieldset.style.display = jobMatchFound ? '' : 'none';
  });
}

var selectedButtons = {};

function searchCall() {
  const searchQuery = document.getElementById('search').value.trim().toLowerCase();
  const searchTerms = searchQuery.split(/\s+/).filter(term => term.length > 0);
  let allJobs;
  
  if (billingWorkOrdersActive) {
    allJobs = document.querySelectorAll('.table-item.complete-job');
  } else if (closedWorkOrdersActive) {
    allJobs = document.querySelectorAll('.table-item.closed-job');
  } else {
    allJobs = document.querySelectorAll('.table-item:not(.complete-job):not(.closed-job)');
  }

  const allJobsArray = Array.from(allJobs);

  document.querySelectorAll('.table-item').forEach(job => {
    job.style.display = 'none';
  });

  allJobsArray.forEach(job => {

    if (job.classList.contains('filtered')) {
      return;
    }

    let jobMatchFound = false;

    if (searchTerms.length === 0) {
      jobMatchFound = true;
    } else {
    
      try {
        const jobData = JSON.parse(job.getAttribute('data-jobinfo'));
        if (jobData) {
          delete jobData.Notes;
          jobMatchFound = searchTerms.every(term =>
            JSON.stringify(jobData).toLowerCase().includes(term)
          );
        } else {
          console.error('Missing or invalid data-jobinfo attribute:', job);
        }
      } catch (error) {
        console.error('Error parsing data-jobinfo:', error, job);
      }
    }

    job.style.display = jobMatchFound ? 'flex' : 'none';
  });
}

function searchJobs() {
    const searchQuery = document.getElementById('job-search').value.toLowerCase();
    const searchTerms = searchQuery.split(/\s+/).filter(term => term.length > 0);
    let allJobs;
    
    if (billingJobsActive) {
      allJobs = document.querySelectorAll('.job-item.complete-job');
    } else if (closedJobsActive) {
      allJobs = document.querySelectorAll('.job-item.closed-job');
    } else {
      allJobs = document.querySelectorAll('.job-item:not(.complete-job):not(.closed-job)');
    }
  
    const allJobsArray = Array.from(allJobs);
  
    document.querySelectorAll('.job-item').forEach(job => {
      job.style.display = 'none';
    });
  
    allJobsArray.forEach(job => {
  
      if (job.classList.contains('filtered')) {
        return;
      }
  
      let jobMatchFound = false;
  
      if (searchTerms.length === 0) {
        jobMatchFound = true;
      } else {
      
        try {
          const jobData = JSON.parse(job.getAttribute('data-jobinfo'));
          if (jobData) {
            delete jobData.Notes;
            jobMatchFound = searchTerms.every(term =>
              JSON.stringify(jobData).toLowerCase().includes(term)
            );
          } else {
            console.error('Missing or invalid data-jobinfo attribute:', job);
          }
        } catch (error) {
          console.error('Error parsing data-jobinfo:', error, job);
        }
      }
  
      job.style.display = jobMatchFound ? 'flex' : 'none';
    });
  }

function searchSchedule() {
  var searchQuery = document.getElementById('schedule-search').value.toLowerCase();
  var allJobs = document.querySelectorAll('#schedule-list .job-item:not(.filtered)');

  allJobs.forEach(function (job) {
    var jobData = JSON.parse(job.getAttribute('data-jobInfo'));
    delete jobData.Notes;
    var jobMatchFound = false;

    // Check if the job matches any of the selected buttons
    for (var button in selectedButtons) {
      if (selectedButtons[button]) {
        var buttonText = button.toLowerCase();

        for (var key in jobData) {
          if (jobData.hasOwnProperty(key)) {
            var value = jobData[key].toString().toLowerCase();
            if (value.includes(buttonText)) {
              jobMatchFound = true;
              break;
            }
          }
        }

        if (jobMatchFound) {
          break;
        }
      }
    }


    if (Object.values(selectedButtons).every(state => !state)) {
      jobMatchFound = true;
    }


    if (jobMatchFound && searchQuery) {
      jobMatchFound = false;
      for (var key in jobData) {
        if (jobData.hasOwnProperty(key)) {
          var value = jobData[key].toString().toLowerCase();
          if (value.includes(searchQuery)) {
            jobMatchFound = true;
            break;
          }
        }
      }
    }

    job.style.display = jobMatchFound ? '' : 'none';

  });
  if (searchQuery.length == 0 && Object.values(selectedButtons).every(state => !state)) {
    allJobs.forEach(function (job) {
      job.style.display = 'flex';
    });
  }
}

function searchTech() {
  var searchQuery = document.getElementById('tech-search').value.toLowerCase();
  var allJobs = document.querySelectorAll('#tech-list .job-item:not(.filtered)');

  allJobs.forEach(function (job) {
    var jobData = JSON.parse(job.getAttribute('data-jobInfo'));
    delete jobData.Notes;
    var jobMatchFound = false;

    // Check if the job matches any of the selected buttons
    for (var button in selectedButtons) {
      if (selectedButtons[button]) {
        var buttonText = button.toLowerCase();

        for (var key in jobData) {
          if (jobData.hasOwnProperty(key)) {
            var value = jobData[key].toString().toLowerCase();
            if (value.includes(buttonText)) {
              jobMatchFound = true;
              break;
            }
          }
        }

        if (jobMatchFound) {
          break;
        }
      }
    }


    if (Object.values(selectedButtons).every(state => !state)) {
      jobMatchFound = true;
    }


    if (jobMatchFound && searchQuery) {
      jobMatchFound = false;
      for (var key in jobData) {
        if (jobData.hasOwnProperty(key)) {
          var value = jobData[key].toString().toLowerCase();
          if (value.includes(searchQuery)) {
            jobMatchFound = true;
            break;
          }
        }
      }
    }

    job.style.display = jobMatchFound ? '' : 'none';

  });
  if (searchQuery.length == 0 && Object.values(selectedButtons).every(state => !state)) {
    allJobs.forEach(function (job) {
      job.style.display = 'flex';
    });
  }
}

// Toggle the selected state of a button
function toggleButton(button) {

  selectedButtons[button.textContent] = !selectedButtons[button.textContent];
  button.classList.toggle('selected');
  const selected = document.querySelectorAll('.selected');

  searchJobs();

}

// Attach click event listeners to the buttons
document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.filter-button');
  buttons.forEach(function (button) {
    button.addEventListener('click', function () {
      toggleButton(this);
    });
  });
});


// Attach input event listener to the search field
var searchField = document.getElementById('job-search');
searchField.addEventListener('input', searchJobs);

let usingSchedule = false



function fetchAndLogRecord(jobId) {
  return new Promise((resolve, reject) => {

    const laborRef = ref(database, 'data/' + jobId);

    get(laborRef).then((snapshot) => {
      const record = snapshot.val();
      if (record) {
        resolve(record);
        opCheckSchedule(record);
        usingSchedule = true;
        document.getElementById('tech-data').style.display = "none";
      } else {
        console.log('No record found with jobId:', jobId);
        reject('No record found');
      }
    }).catch((error) => {
      console.error('Error fetching record:', error);
      reject(error);
    });
  });
}




function changeJobTech() {
  const oldTech = this.getAttribute('data-selected-tech'); // Get the old technician
  const newTech = this.value; // Get the new technician
  this.setAttribute('data-selected-tech', newTech); // Update the selected technician

  const id = this.closest('tr').children[0].innerHTML; // Assuming this is the job ID
  const data = this.closest('tr').children;

  // Step 1: Remove the job from the old technician's list
  if (oldTech) {
    removeJobFromTech(id, oldTech); // You'll need to implement this
  }

  // Step 2: Add the job to the new technician's list
  addJobToTech(data, newTech);

}

async function generateLaborTable(id) {
  try {
    const laborContainer = document.getElementById('labor-table-body-current');
    laborContainer.innerHTML = '';

    const headerDiv = document.createElement('div');
    headerDiv.className = 'labor-entry header';
    headerDiv.innerHTML = `
      <p class="labor-tech hide-mobile">Tech</p>
      <p class="labor-data description">Description</p>
      <p class="labor-data hours">Hours</p>
      <p class="labor-data date hide-mobile">Date</p>
      <p class='labor-data delete></p>
    `;
    laborContainer.appendChild(headerDiv);

    const request = indexedDB.open('openDatabase');

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');

      const getRequest = objectStore.get(id);

      getRequest.onsuccess = (event) => {
        const job = event.target.result;
        if (job && job.Labor) {
          const laborEntries = Object.values(job.Labor);

          // Sort labor entries by Time_Submitted
          laborEntries.sort((a, b) => new Date(a.Time_Submitted) - new Date(b.Time_Submitted));

          laborEntries.forEach(laborEntry => {
            const laborDiv = document.createElement('div');
            laborDiv.className = 'labor-entry';
            appendLaborDataToDiv(laborDiv, laborEntry);
            laborContainer.appendChild(laborDiv);
          });

          document.getElementById('labor-header').innerHTML = 'Labor' + ' (' + laborEntries.length + ')';
        } else {
          console.log("No matching labor data found.");
        }
      };

      getRequest.onerror = (event) => {
        console.error("Error fetching labor data: ", event.target.error);
      };
    };

    request.onerror = (event) => {
      console.error("Error opening IndexedDB: ", event.target.error);
    };
  } catch (error) {
    console.error("Error fetching labor data: ", error);
  }
}

function appendLaborDataToDiv(laborDiv, laborEntry) {
  laborDiv.className = 'labor-entry';
  laborDiv.setAttribute('data-id', laborEntry.ID);

  const techDiv = document.createElement('div');
  techDiv.className = 'labor-tech hide-mobile';
  techDiv.innerHTML = `
    <p class="labor-data" contenteditable="true">${laborEntry.Tech || ''}</p>
  `;

  const descriptionP = document.createElement('p');
  descriptionP.className = 'labor-data description';
  descriptionP.contentEditable = true;
  descriptionP.textContent = laborEntry.Description || '';

  const hoursP = document.createElement('p');
  hoursP.className = 'labor-data hours';
  hoursP.contentEditable = true;
  hoursP.textContent = laborEntry.Hours || '';

  const dateP = document.createElement('p');
  dateP.className = 'labor-data date';
  hoursP.contentEditable = true;
  dateP.classList.add('hide-mobile');
  dateP.textContent = laborEntry.Time_Submitted ? laborEntry.Time_Submitted.split('-')[1] + '/' + laborEntry.Time_Submitted.split('-')[2] : '';

  const deleteDiv = document.createElement('div');
  deleteDiv.className = 'labor-part-delete-div';
  const deleteButton = document.createElement('button');
  deleteButton.className = 'labor-data-delete-button table-button';
  deleteButton.innerHTML = '<img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">';
  deleteButton.addEventListener('click', deleteLabor);

  deleteDiv.appendChild(deleteButton);

  laborDiv.appendChild(techDiv);
  laborDiv.appendChild(descriptionP);
  laborDiv.appendChild(hoursP);
  laborDiv.appendChild(dateP);
  laborDiv.appendChild(deleteDiv);

  let holdTimer;
  let holdDelayType = 3000;

  laborDiv.addEventListener('input', (e) => {
    clearTimeout(holdTimer);
  });

  laborDiv.addEventListener('keyup', (e) => {
    clearTimeout(holdTimer);
    holdTimer = setTimeout( async () => {
      const laborData = {
        'Tech': techDiv.querySelector('.labor-data').textContent,
        'Description': descriptionP.textContent,
        'Hours': hoursP.textContent,
        'Category': document.getElementById('currentCategory').value,
        'Op_Type': document.getElementById('currentOpType').value,
        'Job_ID': document.getElementById('currentJobId').value,
        'ID': laborEntry.ID,
        'Boat_Name': document.getElementById('currentBoat').value,
        'Customer_Name': document.getElementById('currentName').value,
        'Time_Submitted': laborEntry.Time_Submitted ? laborEntry.Time_Submitted : e.target.closest('.labor-entry').querySelector('.labor-data.date').innerHTML !== '' ? e.target.closest('.labor-entry').querySelector('.labor-data.date').innerHTML : await getTodaysDate(),
        'Work_Order': document.getElementById('currentWorkOrder').value
      };

      addLaborFirebase(laborData);
    }, holdDelayType);
  });
}

async function generatePartsTable(id) {
  console.log(id);
  try {
    const partsContainer = document.getElementById('parts-table-body-current');
    partsContainer.innerHTML = '';

    const headerDiv = document.createElement('div');
    headerDiv.className = 'part-entry header';
    headerDiv.innerHTML = `
      <p class="part-tech hide-mobile">Tech</p>
      <p class="part-number">Part Number</p>
      <p class="part-data quantity">Qty</p>
      <p class="part-data date hide-mobile">Date</p>
      <p class='part-data delete></p>
    `;
    partsContainer.appendChild(headerDiv);

    const request = indexedDB.open('openDatabase');

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');

      const getRequest = objectStore.get(id);

      getRequest.onsuccess = (event) => {
        const job = event.target.result;
        if (job && job.Parts) {
          const partEntries = Object.values(job.Parts);

          // Sort part entries by Time_Submitted
          partEntries.sort((a, b) => new Date(a.Time_Submitted) - new Date(b.Time_Submitted));

          partEntries.forEach(partEntry => {
            const partDiv = document.createElement('div');
            partDiv.setAttribute('data-id', partEntry.ID);
            partDiv.className = 'part-entry';
            appendPartsDataToDiv(partDiv, partEntry);
            partsContainer.appendChild(partDiv);
          });

          document.getElementById('parts-header').innerHTML = 'Parts' + ' (' + partEntries.length + ')';
        } else {
          console.log("No matching parts data found.");
        }
      };

      getRequest.onerror = (event) => {
        console.error("Error fetching parts data: ", event.target.error);
      };
    };

    request.onerror = (event) => {
      console.error("Error opening IndexedDB: ", event.target.error);
    };
  } catch (error) {
    console.error("Error fetching parts data: ", error);
  }
}

function appendPartsDataToDiv(partDiv, partEntry) {
  partDiv.className = 'part-entry';

  console.log(partEntry);
  const techDiv = document.createElement('div');
  techDiv.className = 'part-tech hide-mobile';
  techDiv.innerHTML = `
    <p class="part-data tech" contenteditable="true">${partEntry.Tech || ''}</p>
  `;

  const partNumberDiv = document.createElement('div');
  partNumberDiv.className = 'part-table-part-number';

  const partNumberP = document.createElement('p');
  partNumberP.className = 'part-table-part-data';
  partNumberP.contentEditable = true;
  partNumberP.textContent = partEntry.Part_Number || '';
  partNumberDiv.appendChild(partNumberP);

  const descriptionP = document.createElement('p');
  descriptionP.className = 'part-data part-description';
  descriptionP.textContent = partEntry.Description || '';
  descriptionP.style.color = 'gray';

  partNumberDiv.appendChild(descriptionP);

  const quantityP = document.createElement('p');
  quantityP.className = 'part-data quantity';
  quantityP.contentEditable = true;
  quantityP.textContent = partEntry.Quantity || '';

  const dateP = document.createElement('p');
  dateP.className = 'part-data date';
  dateP.classList.add('hide-mobile');
  dateP.textContent = partEntry.Time_Submitted ? partEntry.Time_Submitted.split('-')[1] + '/' + partEntry.Time_Submitted.split('-')[2] : '';

  const deleteDiv = document.createElement('div');
  deleteDiv.className = 'labor-part-delete-div';
  const deleteButton = document.createElement('button');
  deleteButton.className = 'parts-data-delete-button table-button';
  deleteButton.innerHTML = '<img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">';
  deleteButton.addEventListener('click', deleteParts);

  deleteDiv.appendChild(deleteButton);

  partDiv.appendChild(techDiv);
  partDiv.appendChild(partNumberDiv);
  partDiv.appendChild(quantityP);
  partDiv.appendChild(dateP);
  partDiv.appendChild(deleteDiv);

  let holdTimer;
  let holdDelayType = 3000;

  partDiv.addEventListener('input', (e) => {
    clearTimeout(holdTimer);
  });

  partDiv.addEventListener('keyup', (e) => {
    clearTimeout(holdTimer);
    holdTimer = setTimeout( async () => {
      const partNumber = partNumberDiv.querySelector('.part-data').textContent;
      const jobId = document.getElementById('currentJobId').value;
      const partData = {
        'Part_Number': partNumber,
        'Quantity': quantityP.textContent,
        'Category': document.getElementById('currentCategory').value,
        'Op_Type': document.getElementById('currentOpType').value,
        'Job_ID': jobId,
        'ID': partEntry.ID, // Assuming ID is still needed
        'Boat_Name': document.getElementById('currentBoat').value,
        'Customer_Name': document.getElementById('currentName').value,
        'Time_Submitted': partEntry.Time_Submitted ? partEntry.TimeSubmitted : partDiv.querySelector('part-data.date').innerHTML !== '' ? partDiv.querySelector('part-data.date').innerHTML : await getTodaysDate(),
        'Work_Order': document.getElementById('currentWorkOrder').value,
        'Tech': e.target.closest('.part-entry').querySelector('.part-data.tech').innerHTML,
        'Description': partNumberDiv.querySelector('.part-description').textContent
      };

      addPartsFirebase(partData);
      updatePartsIndex(jobId, partData);
      updateTimestamps('jobData', 'data');
    }, holdDelayType);
  });
}

document.getElementById('labor-header').addEventListener('click', () => {
  const laborHeader = document.getElementById('labor-header');
  const laborTable = document.getElementById('labor-table-body-current');
  laborHeader.classList.toggle('closed-header');
  laborTable.classList.toggle('closed');
})

document.getElementById('parts-header').addEventListener('click', () => {
  const laborHeader = document.getElementById('parts-header');
  const laborTable = document.getElementById('parts-table-body-current');
  laborHeader.classList.toggle('closed-header');
  laborTable.classList.toggle('closed');
})

document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('svg').forEach(function (svg) {
    svg.addEventListener('click', function () {
      this.classList.toggle('toggle');
    });
  });
});


document.getElementById('work-order-form-dates-launch-date').addEventListener('change', () => {
  const customerId = document.getElementById('work-order-form-customer-id').value;
  const boatId = document.getElementById('work-order-form-boat-id').value;
  const index = 'Launch_Date';
  const date = document.getElementById('work-order-form-dates-launch-date').value;
  addHaulLaunchStorageDates(customerId, boatId, index, date)
})

document.getElementById('work-order-form-dates-haul-date').addEventListener('change', () => {
  const customerId = document.getElementById('work-order-form-customer-id').value;
  const boatId = document.getElementById('work-order-form-boat-id').value;
  const index = 'Haul_Date';
  const date = document.getElementById('work-order-form-dates-haul-date').value;
  addHaulLaunchStorageDates(customerId, boatId, index, date)
})

document.getElementById('work-order-form-storage-confirm').addEventListener('click', async () => {
  
  const customerId = document.getElementById('work-order-form-customer-id').value;
  const boatId = document.getElementById('work-order-form-boat-id').value;
  const index = 'Storage_Date';

  let date;

  if(document.getElementById('work-order-form-storage-confirm').checked == false){
    date = '';
  } else {
    date = await getTodaysDate();
  }
})

async function addHaulLaunchStorageDates(customerId, boatId, index, date) {

  let updateDate
  if (index == 'Launch_Date') {
    updateDate = 'Original_Launch_Date';
  } else if (index == 'Haul_Date') {
    updateDate = 'Original_Haul_Date';
  }

  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token

    const firebaseUrl = `${currentURL}/customers/${customerId}/Boats/${boatId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: date, [updateDate]: date };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);
    addHaulLaunchDB(customerId, boatId, index, date, updateDate)
  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}

async function addHaulLaunchDB(customerId, boatId, index, value, updateDate) {
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'Customer_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      // Use the get method to fetch the individual item using customerId
      const getRequest = objectStore.get(customerId);

      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;
        if (itemToUpdate) {
          // Check if the Boats object exists in the customer data
          if (itemToUpdate.Boats && itemToUpdate.Boats[boatId]) {
            // Update the value for the specific boat
            itemToUpdate.Boats[boatId][index] = value;
            if (updateDate) {
              itemToUpdate.Boats[boatId][updateDate] = '';
            }


            // Put the updated item back into the store
            const updateRequest = objectStore.put(itemToUpdate);

            updateRequest.onsuccess = function () {
              console.log(`Data updated for Customer ${customerId} and Boat ${boatId}`);
            };

            updateRequest.onerror = function () {
              console.error("Error updating data");
            };
          } else {
            console.error(`No boat found with ID ${boatId} for Customer ${customerId}`);
          }
        } else {
          console.error(`No item found with Customer ${customerId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  }
}

/* document.getElementById('launch').addEventListener('change',() => {
  const customerId = document.getElementById('customerId').value;
  const boatId = document.getElementById('boatId').value;
  const index = 'Launch_Date';
  const date = document.getElementById('launch').value;
  updateDates(customerId, boatId, index, date)
}) */

async function updateDates(customerId, boatId, index, date) {

  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    console.log(idToken);
    const firebaseUrl = `${currentURL}/customers/${customerId}/Boats/${boatId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: date };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);
    updateBoatDB(customerId, boatId, index, date)
  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}

async function fetchDataFromStore(storeName) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase', indexedVersion);

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction([storeName], 'readonly');
      const objectStore = transaction.objectStore(storeName);
      const getRequest = objectStore.getAll();

      getRequest.onsuccess = () => {
        const data = getRequest.result;
        resolve(data);
      };

      getRequest.onerror = () => {
        reject(getRequest.error);
      };

      transaction.oncomplete = () => {
        db.close();
      };
    };
  });
}

async function populateVendorLists() {
  const data = await fetchDataFromStore('vendorData');
  const vendorList = Object.values(data).map(vendor => vendor.Vendor_Name);

  const vendorInputs = document.querySelectorAll('.vendor-select');

  vendorInputs.forEach(input => {
    // Create a wrapper div for positioning context
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative';
    input.parentNode.insertBefore(wrapper, input);
    wrapper.appendChild(input);

    const suggestionContainer = document.createElement('div');
    suggestionContainer.classList.add('suggestion-list');
    suggestionContainer.style.display = 'none';
    suggestionContainer.style.position = 'absolute';
    suggestionContainer.style.width = '100%';
    suggestionContainer.style.top = '100%';
    suggestionContainer.style.left = '0';
    wrapper.appendChild(suggestionContainer);

    input.addEventListener('input', async function () {
      const query = this.value.toLowerCase().trim();
      suggestionContainer.innerHTML = ''; // Clear previous suggestions

      const filteredVendors = vendorList.filter(vendor =>
        vendor.toLowerCase().includes(query)
      );

      filteredVendors.forEach(vendor => {
        const suggestionItem = document.createElement('div');
        suggestionItem.classList.add('suggestion-item');
        suggestionItem.textContent = vendor;
        suggestionItem.addEventListener('click', () => {
          input.value = vendor;
          suggestionContainer.style.display = 'none';
        });
        suggestionContainer.appendChild(suggestionItem);
      });

      suggestionContainer.style.display = filteredVendors.length ? 'flex' : 'none';
    });

    // Hide suggestions when clicking outside
    document.addEventListener('click', function (e) {
      if (e.target !== input && !suggestionContainer.contains(e.target)) {
        suggestionContainer.style.display = 'none';
      }
    });
  });
}

function callOpenCustomerData(customerId) {

const pageView = document.getElementById('current-page').innerHTML;
console.log(pageView);
let view = null;

  if(pageView === 'Work Orders'){
    view = 'work-order-view';
  }else if(pageView === 'Operations'){
    view = 'job-view';
  }else if(pageView === 'Schedule'){
    view = 'schedule-view'
  }

  const customersIframe = document.getElementById('customers').querySelector('iframe');
  if (customersIframe) {
    customersIframe.contentWindow.postMessage({
      type: 'customerLookup',
      customerId: customerId,
      return: view
    }, '*');
  }

  const elements = document.querySelectorAll('.page-view')
  elements.forEach(page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });

  document.getElementById('customers').style.display = 'flex';
  document.getElementById('customers').style.visibility = 'visible';
  document.getElementById('current-page').innerHTML = "Customer Database";
}

function callNewCustomerAdded(){
  const customersIframe = document.getElementById('customers').querySelector('iframe');
  if (customersIframe) {
    customersIframe.contentWindow.postMessage({
      type: 'addedNewCustomer',
    }, '*');
  }
}

window.addEventListener('message', function (event) {

  const { type, from, returnView } = event.data;

  if (from === 'maps') {

    if(type === 'updateBoatLocation'){
      const customerId = event.data.customerId;
      const boatId = event.data.boatId;
      const index = event.data.index;
      const value = event.data.value;

      updateBoatInfo(customerId, boatId, index, value);
    }

  } else if (from === 'spring') {
    if (type === 'opCheck') {
      const jobData = event.data.info;
      opCheck('', jobData);
    }
  } else if (from === 'customers') {

    // Check if the message is about a new customer being added
    if (type === 'customerAdded') {
      console.log('Sending message to rentalDatabase iframe');
      // Forward the message to the rentalDatabase iframe
      const rentalDatabaseIframe = document.getElementById('rentalDatabase');
      rentalDatabaseIframe.contentWindow.postMessage({
        type: 'generateCustomerTable',
        from: 'main'
      }, '*');

      // Update customer list in the main app
      fetchCustomerDataAndGenerateTable();
    }

 
    if (type === 'returnView') {
      const elements = document.querySelectorAll('.page-view')
      elements.forEach(page => {
        page.style.display = 'none';
        page.style.visibility = 'hidden';
      });

      let pageView = null;

      document.getElementById('open-work').style.display = 'flex';
      document.getElementById('open-work').style.visibility = 'visible';
      document.getElementById(returnView).style.display = 'flex';

          if(returnView === 'work-order-view'){
            pageView = 'Work Order';
          }else if(returnView === 'job-view'){
            pageView = 'Operations';
          } else if(returnView === 'schedule-view'){
            pageView = 'Schedule';
          }

      document.getElementById('current-page').innerHTML = pageView;

      const buttonCheck = document.getElementById('customerRecordMobile');
      const opView = window.getComputedStyle(buttonCheck).getPropertyValue('display');


      if (opView == 'flex') {
        console.log('Customer Record Mobile is open')
        document.getElementById('Update').style.display = 'flex';
      }
    }

    if (type === 'haulDate'){
      const customerId = event.data.customerId;
      const boatId = event.data.boatId;
      const haulDate = event.data.date;
      console.log('Haul Date:', haulDate);
      updateJobDatesBasedOnBoatId(customerId, boatId, 'Haul_Date', haulDate);
    }

    if(type === 'launchDate'){
      const customerId = event.data.customerId;
      const boatId = event.data.boatId;
      const launchDate = event.data.launchDate;

      updateJobDatesBasedOnBoatId(customerId, boatId, 'Launch_Date', launchDate);
    }

  } else if (from === 'hauling') {

    if (type === 'opCheck') {
      const jobData = event.data.info;
      opCheck('', jobData);
    }

    if (type === 'updateWeekView') {
      console.log('Updating week view');
      updateWeekView();
    }

    if (type === 'currentTechSchedule') {
      populateJobsForCurrentTech();
    }

    if (type === 'updateTechSchedule') {
      const jobId = event.data.id;
      const tech = event.data.tech;
      updateJobRow(jobId, 'Techs', tech);
    }

    if(type === 'updateStartDate'){
      const jobId = event.data.jobId;
      const startDate = event.data.startDate;
      const workOrder = event.data.workOrder;
      updateJobRow(jobId, 'Start_Date', startDate);
      updateJobRow(jobId, 'Haul_Date', startDate);

  }

  if(type === 'updateWorkOrder'){

    const startDate = event.data.startDate;
    const workOrder = event.data.workOrder;

    const workOrderDiv = document.querySelector('.table-item[data-workorder="' + workOrder + '"]');

    if (workOrderDiv) {
      

      workOrderDiv.querySelector('.work-order-start-date').innerHTML = startDate;
      
      const dataUpdate = JSON.parse(workOrderDiv.getAttribute('data-jobinfo'));
      
      dataUpdate.Start_Date = startDate;
      
      dataUpdate.Haul_Date = startDate;
      
      workOrderDiv.setAttribute('data-jobinfo', JSON.stringify(dataUpdate));

    }

}

  }
});


const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


/*
function numPad0(str) {
    var cStr = str.toString();
    if (cStr.length < 2) str = 0 + cStr;
    return str;
}

 function time() {
    var currDate = new Date();
    var currSec = currDate.getSeconds();
    var currMin = currDate.getMinutes();
    var curr24Hr = currDate.getHours();
    var ampm = curr24Hr >= 12 ? 'pm' : 'am';
    let currHr = curr24Hr % 12;
    currHr = currHr ? currHr : 12;
    var sttime = currHr + ':' + numPad0(currMin);
    var ssec = numPad0(currSec);
    document.getElementById('time').innerHTML = sttime;
    document.getElementById('ampm').innerHTML = ampm;
    if(curr24Hr>=5 && curr24Hr<=17)document.getElementById('dn').innerHTML = "🌞";
    else document.getElementById('dn').innerHTML = "🌜";
} 

var t;

function startClock() {
    time(); // Run once immediately
    t = setInterval(time, 50);
}

function stopClock() {
    clearInterval(t);
} */

/* async function setupLocation() {
  let latitude = localStorage.getItem('latitude');
  let longitude = localStorage.getItem('longitude');

  if (!latitude || !longitude) {
    const city = prompt("Please enter your city:");
    const state = prompt("Please enter your state abbreviation:");

    if (!city || !state) {
      console.error("City and state are required.");
      return null;
    }

    try {
      const response = await fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${city},${state},US&limit=1&appid=5d8cd967e127c059292f51ac548a5e2b`);
      const data = await response.json();

      if (data.length > 0) {
        latitude = data[0].lat;
        longitude = data[0].lon;
        localStorage.setItem('latitude', latitude);
        localStorage.setItem('longitude', longitude);
        console.log(`Location set to: ${city}, ${state}`);
      } else {
        console.error("Location not found.");
        return null;
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
      return null;
    }
  }

  return { latitude, longitude };
} */

async function fetchWeatherInfo(dateString) {
  if (!navigator.onLine) {
    console.log('User is offline. Skipping weather info fetch.');
    return null;
  }

/*   const location = await setupLocation(); */

  const location = { latitude: 40.8723, longitude: -73.5300 };
  if (!location) {
    console.error("Unable to get location. Weather info cannot be fetched.");
    return null;
  }

  const { latitude, longitude } = location;
  const apiKey = '5d8cd967e127c059292f51ac548a5e2b';

  // Use UTC dates for comparison
  const targetDate = new Date(dateString + 'T00:00:00Z');
  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

  // Compare dates using UTC
  const isToday = targetDate.getTime() === todayUTC.getTime();
  const daysDifference = Math.floor((targetDate - todayUTC) / (1000 * 60 * 60 * 24));

  try {
    if (isToday || daysDifference === 0) {
      // Fetch current weather for today
      const currentWeatherEndpoint = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=imperial`;
      const response = await fetch(currentWeatherEndpoint);
      const weatherData = await response.json();
      return {
        temperature: Math.round(weatherData.main.temp_max),
        description: weatherData.weather[0].description,
        windSpeed: Math.round(weatherData.wind.speed),
        windDirection: getWindDirection(weatherData.wind.deg),
      };
    } else if (daysDifference > 0 && daysDifference <= 5) {
      // Fetch forecast for next 5 days
      const forecastEndpoint = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=imperial`;
      const response = await fetch(forecastEndpoint);
      const data = await response.json();

      // Find the forecast for the specified date
      const forecast = data.list.find(item => {
        const itemDate = new Date(item.dt * 1000);
        return itemDate.toDateString() === targetDate.toDateString();
      });

      if (forecast) {
        return {
          temperature: Math.round(forecast.main.temp),
          description: forecast.weather[0].description,
          windSpeed: Math.round(forecast.wind.speed),
          windDirection: getWindDirection(forecast.wind.deg),
        };
      }
    }

    return null;
  } catch (error) {
    console.error('Failed to fetch weather data:', error);
    return null;
  }
}

function getWeatherEmoji(description) {
  const lowerDesc = description.toLowerCase();
  if (lowerDesc.includes('clear')) return '☀️';
  if (lowerDesc.includes('cloud')) return '☁️';
  if (lowerDesc.includes('rain')) return '🌧️';
  if (lowerDesc.includes('snow')) return '❄️';
  if (lowerDesc.includes('thunderstorm')) return '⛈️';
  if (lowerDesc.includes('mist') || lowerDesc.includes('fog')) return '🌫️';
  return '🌤️'; // default to partly cloudy
}

function getWindDirection(degrees) {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  return directions[Math.round(degrees / 45) % 8];
}

let currentWeekStart;

function initializeWeekStart() {
  currentWeekStart = new Date();
  let dayOfWeek = currentWeekStart.getDay();
  let diff = (dayOfWeek + 6) % 7;
  currentWeekStart.setDate(currentWeekStart.getDate() - diff);
  currentWeekStart.setHours(0, 0, 0, 0);
}

function navigateWeek(direction) {
  currentWeekStart.setDate(currentWeekStart.getDate() + (direction * 7));
  updateWeekView();
}

async function updateWeekView() {

  const weekContainer = document.getElementById('week-container');
  if (!weekContainer) {
    console.error("Week container not found");
    return;
  }
  weekContainer.innerHTML = '';

  let jobs;
  try {
    jobs = await getJobDataIndex();
    if (!Array.isArray(jobs)) {
      console.error("getJobDataIndex did not return an array");
      return;
    }
  } catch (error) {
    console.error("Error fetching job data:", error);
    return;
  }

 /* const techNames = handleTechNames(globalUserData) */
  const techStats = initializeTechStats(globalUserData);


  try {
    const includeSaturday = isSaturdayIncluded(jobs);
    const { weekStart, weekEnd } = getCurrentWeekRange(includeSaturday);


    await createHeaderRow(weekStart, weekEnd);

    jobs = processJobs(jobs, getTodayString());
    await distributeAndSortJobs(jobs, techStats, weekStart, weekEnd);
    globalTechStats = techStats;
    const updatedTechList = Object.keys(techStats);
    for (const tech of updatedTechList) {
      if (tech && tech !== '') {
        await createTechRow(tech, techStats, weekContainer, weekStart);
      }
    }

    adjustColumnWidths(weekContainer);
    addDragAndDropListeners();
    updateWeekLabel(weekStart, weekEnd);

  } catch (e) {
    console.error("Error in updateWeekView:", e);
    console.error("Stack trace:", e.stack);
  }
}

function getCurrentWeekRange() {
  const weekStart = new Date(currentWeekStart);
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 5);
  return { weekStart, weekEnd };
}

function initializeTechStats(userData) {

  let techList = [];
  let techIds = {};
  
  const techToday = getTodayString();
  
  Object.values(userData).forEach(tech => {

    if(tech.role?.includes('tech')){
      techList.push(tech.displayName.split(' ')[0]);
      if(!techIds[tech.displayName.split(' ')[0]]){
        techIds[tech.displayName.split(' ')[0]] = {id: tech.id, skills: tech.skills};
      }
    }
  });

  // Sort the techList alphabetically
  techList.sort((a, b) => a.localeCompare(b));

  const techStats = {};
  techList.forEach(tech => {
    techStats[tech] = {
      id: techIds[tech].id, 
      totalHours: 0, 
      skills: techIds[tech].skills,
      dailyStats: {}, 
      availableDay: techToday.split('-')[1] + '/' + techToday.split('-')[2] 
    };
  });

  return techStats;
}

function getTodayString() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.toISOString().split('T')[0];
}

async function createHeaderRow(weekStart, weekEnd) {

  document.getElementById('weekdays').innerHTML = '';

  const headerRow = document.createElement('div');
  headerRow.className = 'header-row';

  const techHeader = document.createElement('div');
  techHeader.className = 'tech-header';
  techHeader.textContent = 'TECH';
  headerRow.appendChild(techHeader);

  let numberOfDays = 6; // Default to 5 days

  /*   if (isSaturdayIncluded(jobs)) {
      console.log("Saturday is included in the jobs list");
        numberOfDays = 6; // Extend to include Saturday
    } */

  for (let i = 0; i < numberOfDays; i++) {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + i);
    const dateString = date.toISOString().split('T')[0];

    let tideInfo, weatherInfo;
    try {
      [tideInfo, weatherInfo] = await Promise.all([
        fetchTideInfo(dateString),
        fetchWeatherInfo(dateString)
      ]);
    } catch (error) {
      console.warn(`Error fetching tide or weather info for ${dateString}:`, error);
    }

    const dateHeader = document.createElement('div');
    dateHeader.className = 'date-header';
    dateHeader.setAttribute('data-date', dateString);
    dateHeader.innerHTML = `
          ${weekday[date.getDay()].slice(0, 3).toUpperCase()}<br>
          ${formatDateWithOrdinal(date)}<br>
          ${weatherInfo ? getWeatherEmoji(weatherInfo.description) : ''}
      `;
    headerRow.appendChild(dateHeader);
  }

  document.getElementById('weekdays').appendChild(headerRow);
}

function isSaturdayIncluded(jobs) {
  return jobs.some(job => {
    const jobDate = new Date(job.Schedule_Date);
    return jobDate.getUTCDay() === 6; // Saturday
  });
}

function processJobs(jobs, todayString) {
  return jobs
    .filter(job => (job.Status === "On Work Schedule" || job.Status === "In Progress") && job.Techs != '')
    .sort((a, b) => new Date(a.Start_Date) - new Date(b.Start_Date))
    .map(job => {
      if (new Date(job.Schedule_Date) < new Date(todayString)) {
        job.Schedule_Date = todayString;
        job.Sort_Date = todayString;
      } else {
        job.Sort_Date = job.Schedule_Date;
      }
      return job;
    });
}

async function distributeAndSortJobs(jobs, techStats, weekStart, weekEnd) {
  const startOfWeek = new Date(weekStart);
  const endOfWeek = new Date(weekEnd);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const techToday = getTodayString();

  let allTechs = {};

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  // Initialize allTechs and techStats
  for (const job of jobs) {
    const techNames = job.Techs.split(',').map(tech => tech.trim());
    techNames.forEach(tech => {
      if (!allTechs[tech]) {
        const scheduleDate = new Date(job.Schedule_Date);
        allTechs[tech] = {
          totalHours: 0,
          currentDate: isValidDate(scheduleDate) ? new Date(Math.max(today, scheduleDate)) : new Date(today)
        };
      }
      if (!techStats[tech]) {
        techStats[tech] = { totalHours: 0, dailyStats: {}, availableDay: techToday.split('-')[1] + '/' + techToday.split('-')[2] };
      }
    });
  }

  // Process jobs with a Schedule_Date first
  const scheduledJobs = jobs.filter(job => isValidDate(new Date(job.Schedule_Date)));
  const unscheduledJobs = jobs.filter(job => !isValidDate(new Date(job.Schedule_Date)));

  for (const job of [...scheduledJobs, ...unscheduledJobs]) {
    const techNames = job.Techs.split(',').map(tech => tech.trim());
    let remainingHours = job.Estimated_Hours ? parseFloat(job.Estimated_Hours) : 0;
    let jobScheduleDate = new Date(job.Schedule_Date);
    let jobStartDate = isValidDate(jobScheduleDate) ? new Date(Math.max(today, jobScheduleDate)) : new Date(today);

    while (remainingHours > 0) {
      let earliestDate = new Date(Math.max(jobStartDate, ...techNames.map(tech => allTechs[tech].currentDate)));
      let dateString = earliestDate.toISOString().split('T')[0];

      // Calculate available hours for the day
      let availableHours = Math.min(8, ...techNames.map(tech => 8 - (techStats[tech].dailyStats[dateString]?.hours || 0)));
      let hoursToAdd = Math.min(remainingHours, availableHours);

      if (hoursToAdd <= 0) {
        // If no hours are available, push to the next day
        earliestDate.setDate(earliestDate.getDate() + 1);
        while (earliestDate.getDay() === 0 || earliestDate.getDay() === 6) {
          earliestDate.setDate(earliestDate.getDate() + 1);
        }
        jobStartDate = new Date(earliestDate);
        continue;
      }

      try {
        // Only process if the date is within the current week
        
          techNames.forEach(tech => {
            if (!techStats[tech].dailyStats[dateString]) {
              techStats[tech].dailyStats[dateString] = { hours: 0, jobCount: 0, jobs: [] };
            }

            // Prioritize scheduled jobs
            if (scheduledJobs.includes(job)) {
              techStats[tech].dailyStats[dateString].jobs.unshift({
                ...job,
                Estimated_Hours: hoursToAdd,
                Sort_Date: dateString
              });
            } else {
              techStats[tech].dailyStats[dateString].jobs.push({
                ...job,
                Estimated_Hours: hoursToAdd,
                Sort_Date: dateString
              });
            }

            techStats[tech].dailyStats[dateString].hours += hoursToAdd;
            techStats[tech].dailyStats[dateString].jobCount++;
            techStats[tech].totalHours += hoursToAdd;
            techStats[tech].availableDay = dateString.split('-')[1] + '/' + dateString.split('-')[2];
          });
        

        techNames.forEach(tech => {
          allTechs[tech].totalHours += hoursToAdd;
          if (allTechs[tech].totalHours >= 8) {
            allTechs[tech].totalHours -= 8;
            do {
              allTechs[tech].currentDate.setDate(allTechs[tech].currentDate.getDate() + 1);
            } while (allTechs[tech].currentDate.getDay() === 0 || allTechs[tech].currentDate.getDay() === 6);
          }
        });

        remainingHours -= hoursToAdd;

        if (remainingHours > 0) {
          earliestDate.setDate(earliestDate.getDate() + 1);
          while (earliestDate.getDay() === 0 || earliestDate.getDay() === 6) {
            earliestDate.setDate(earliestDate.getDate() + 1);
          }
          jobStartDate = new Date(earliestDate);
        }
      } catch (error) {
        console.error("Error processing job:", job);
        console.error("Error details:", error);
        break;
      }
    }
  }

  globalTechList = [];
  Object.keys(techStats).forEach(tech => {
    globalTechList.push(tech + ": " + techStats[tech].availableDay);
  });

  window.globalTechList = globalTechList;

  setTechSelects(globalTechList);

  return techStats;
}

async function setTechSelects(techList) {

  const laborDropdown = document.getElementById('labor-entry-tech');
  laborDropdown.innerHTML = '';

  const partDropdown = document.getElementById('part-entry-tech');
  partDropdown.innerHTML = '';

  const techNames = techList;
  techNames.forEach(name => {
    const optionElement = document.createElement('option');
    optionElement.value = name.split(':')[0];
    optionElement.textContent = name.split(':')[0];
    laborDropdown.appendChild(optionElement);
    partDropdown.appendChild( optionElement.cloneNode(true) );
  });

}

async function createTechRow(tech, techStats, weekContainer, weekStart) {

  const techRow = document.createElement('div');
  techRow.className = 'tech-row';

  const techNameColumn = document.createElement('div');
  techNameColumn.className = 'tech-stats';

  const techName = document.createElement('div');
  techName.className = 'tech-name';
  techName.textContent = tech;

  techNameColumn.appendChild(techName);

  const progressBarContainer = document.createElement('div');
  progressBarContainer.className = 'progress-bar-container';

  const progressWrapper = document.createElement('div');
  progressWrapper.className = 'progress-wrapper';

  const progressBar = document.createElement('div');
  progressBar.className = 'progress-bar';
  progressBar.id = `${tech}-progress-bar`;

  progressWrapper.appendChild(progressBar);

  const techHours = document.createElement('div');
  techHours.className = 'tech-hours';
  techHours.id = `${tech}-hours`;

  progressBarContainer.appendChild(progressWrapper);
  progressBarContainer.appendChild(techHours);
  techNameColumn.appendChild(progressBarContainer);
  techRow.appendChild(techNameColumn);

  const numberOfDays = 6;
  let numberOfHours = 40;
  let totalRowHours = 0;

  for (let i = 0; i < numberOfDays; i++) {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + i);

    const dateString = await getFormattedDate(date); // Use the provided async function for formatted date

    const dayColumn = document.createElement('div');
    dayColumn.className = 'day-column';
    dayColumn.setAttribute('data-date', dateString);
    dayColumn.setAttribute('data-tech', tech);

    const eventContainer = document.createElement('div');
    eventContainer.className = 'event-container';

    const dayStats = techStats[tech].dailyStats[dateString] || { hours: 0, jobCount: 0, jobs: [] };

    dayStats.jobs.forEach(job => {
      try {

        if (new Date(job.Schedule_Date).getDay() == 6 && numberOfHours == 40) {
          console.log(numberOfHours);
          numberOfHours = 48;
        }

        const eventElement = createEventElement(job);
        eventContainer.appendChild(eventElement);
      } catch (error) {
        console.error(`Error creating event element for job:`, job, error);
      }
    });

    const countElement = document.createElement('div');
    countElement.className = 'count-element';
    if (dayStats.hours == 1 && dayStats.jobCount == 1) {
      countElement.textContent = `${dayStats.jobCount} JOB / ${dayStats.hours.toFixed(1)} HR`;
    } else if (dayStats.hours == 1 && dayStats.jobCount != 1) {
      countElement.textContent = `${dayStats.jobCount} JOBS / ${dayStats.hours.toFixed(1)} HR`;
    } else if (dayStats.hours != 1 && dayStats.jobCount == 1) {
      countElement.textContent = `${dayStats.jobCount} JOB / ${dayStats.hours.toFixed(1)} HRS`;
    } else {
      countElement.textContent = `${dayStats.jobCount} JOBS / ${dayStats.hours.toFixed(1)} HRS`;
    }

    totalRowHours += dayStats.hours;

    dayColumn.appendChild(eventContainer);
    dayColumn.appendChild(countElement);
    techRow.appendChild(dayColumn);
  }

  weekContainer.appendChild(techRow);

  let currentDate = new Date();
  let currentWeekStartDate = new Date(currentWeekStart);
  let remainingHours;

  // Check if the current date is within the same week as currentWeekStart
  if (currentDate >= currentWeekStartDate && currentDate < new Date(currentWeekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)) {
    let daysPassed = currentDate.getDay() - currentWeekStartDate.getDay();

    if (currentDate.getDay() === 0) {  // Sunday case
      daysPassed = 6 * 8; // Consider the whole week passed
    } else if (currentDate.getDay() === 1) { // Monday case
      daysPassed = 0; // No hours have passed yet
    } else {
      daysPassed = (currentDate.getDay() - 1) * 8;
    }

    remainingHours = Math.max(0, numberOfHours - daysPassed);
  } else {
    // If the current date is not within the current week, assume the full week is remaining
    remainingHours = numberOfHours;
  }



  const totalHours = totalRowHours;
  const progressBarWidth = (totalHours / remainingHours) * 100;

  const progressBarElement = document.getElementById(`${tech}-progress-bar`);
  if (progressBarElement) {
    progressBarElement.style.width = `${progressBarWidth}%`;
  } else {
    console.warn(`Progress bar element not found for tech: ${tech}`);
  }

  const techHoursElement = document.getElementById(`${tech}-hours`);
  if (techHoursElement) {
    if (totalHours == 1) {
      techHoursElement.textContent = `${totalHours.toFixed(1)} HOUR`;
    } else {
      techHoursElement.textContent = `${totalHours.toFixed(1)} HOURS`;
    }
  } else {
    console.warn(`Tech hours element not found for tech: ${tech}`);
  }
}

function adjustColumnWidths(weekContainer) {
  const allDayColumns = weekContainer.querySelectorAll('.day-column');
  const headerColumns = weekContainer.querySelectorAll('.date-header');
  if (headerColumns.length > 0) {
    const columnWidth = headerColumns[0].offsetWidth;
    allDayColumns.forEach(column => {
      column.style.width = `${columnWidth}px`;
    });
  } else {
    console.warn("No header columns found");
  }
}

function createEventElement(event) {
  const eventElement = document.createElement('div');
  eventElement.className = 'event';
  eventElement.draggable = true;
  eventElement.setAttribute('data-event-id', event.Job_ID || 'unknown');

  const customerDescriptionDiv = document.createElement('div');
  customerDescriptionDiv.className = 'customer-description';

  const customer = document.createElement('div');
  customer.className = 'schedule-customer';
  customer.textContent = `${event.Customer_Name.split(',')[0] || 'Unknown'} / ${event.Boat_Name || 'Unknown'}`;

  const description = document.createElement('div');
  description.className = 'schedule-description';
  description.textContent = event.Description || 'No description';

  const hours = document.createElement('div');
  hours.className = 'schedule-hours';
  const estimatedHours = parseFloat(event.Estimated_Hours) || 0;
  hours.textContent = estimatedHours === 1 ? "1 HR" : `${estimatedHours} HRS`;

  customerDescriptionDiv.appendChild(description);
  customerDescriptionDiv.appendChild(customer);
  eventElement.appendChild(customerDescriptionDiv);

  if (estimatedHours > 0) {
    eventElement.appendChild(hours);
  } else {
    eventElement.style.display = 'none';
  }

  return eventElement;
}

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return day + 'th'; // Covers 11th through 20th
  switch (day % 10) {
    case 1: return day + 'st';
    case 2: return day + 'nd';
    case 3: return day + 'rd';
    default: return day + 'th';
  }
}

function formatDateWithOrdinal(date) {
  const day = date.getDate();
  return `${getOrdinalSuffix(day)}`;
}

function addEvent(date, description) {
  events.push({ date, description });
  updateWeekView();
}

async function load() {
  try {
   await updateWeekView();

    document.getElementById('prev-week').addEventListener('click', () => navigateWeek(-1));
    document.getElementById('next-week').addEventListener('click', () => navigateWeek(1));


    if (document.getElementById('wrap')) {
      var sort = new Sort("wrap");
      sort.init();
    } else {
      console.warn("Element with id 'wrap' not found. Sort initialization skipped.");
    }
  } catch (e) {
    console.log(e)
  }
}

function addDragAndDropListeners() {

  const events = document.querySelectorAll('.event');
  const dayColumns = document.querySelectorAll('.day-column');

  events.forEach(event => {
    event.addEventListener('dragstart', dragStart);
    event.addEventListener('dragend', dragEnd);
  });

  dayColumns.forEach(column => {
    column.addEventListener('dragover', dragOver);
    column.addEventListener('dragenter', dragEnter);
    column.addEventListener('dragleave', dragLeave);
    column.addEventListener('drop', drop);
  });
}

function dragStart(e) {
  e.dataTransfer.setData('text/plain', e.target.getAttribute('data-event-id'));
  setTimeout(() => e.target.style.opacity = '0.5', 0); // Adding opacity for visual feedback
}

function dragEnd(e) {
  e.target.style.opacity = '1'; // Reset opacity after drag ends
}

function dragOver(e) {
  e.preventDefault(); // Ensure the drop is allowed
  e.dataTransfer.dropEffect = 'move'; // Indicates a move operation
}

function dragEnter(e) {
  e.preventDefault(); // Ensure the drop is allowed
  if (e.target.classList.contains('day-column') || e.target.classList.contains('event-container')) {
    e.target.classList.add('drag-over');
  }
}

function dragLeave(e) {
  if (e.target.classList.contains('day-column') || e.target.classList.contains('event-container')) {
    e.target.classList.remove('drag-over');
  }
}

function drop(e) {
  e.preventDefault();

  const eventId = e.dataTransfer.getData('text');
  const draggedElement = document.querySelector(`[data-event-id="${eventId}"]`);
  const dropZone = e.target.closest('.day-column');
  const newTech = dropZone.getAttribute('data-tech');
  const newDate = dropZone.getAttribute('data-date');

  if (!draggedElement || !dropZone || !newTech || !newDate) {
    console.error('Drag and drop failed due to missing elements or attributes.');
    return;
  }

  // Get original tech and date before the move
  const originalTech = draggedElement.closest('.day-column').getAttribute('data-tech');
  const originalDate = draggedElement.closest('.day-column').getAttribute('data-date');

  // Append the dragged event to the new event container
  dropZone.querySelector('.event-container').appendChild(draggedElement);

  // Update the event's schedule date and tech in the database
  updateEventDate(eventId, newDate, newTech)
    .then(() => {
      // Recalculate hours for the new day column
      updateDayStats(newTech, newDate);

      // Recalculate hours for the original day column
      updateDayStats(originalTech, originalDate);

      // Update the progress bar and total hours for both the original and new techs
      updateTechProgressBar(originalTech);
      updateTechProgressBar(newTech);
      updateJobInfo(eventId, "Techs", newTech);
      updateJobInfo(eventId, "Schedule_Date", newDate);
    })
    .catch(error => {
      console.error('Failed to update event date and tech:', error);
    });
}


function updateTechProgressBar(tech) {
  console.log(tech);
  const techRow = document.querySelector(`.tech-row[data-tech="${tech}"]`);
  if (!techRow) return;

  const techStats = getTechStatsForTech(tech); // Assume you have a way to retrieve the latest stats for a tech
  const progressBar = techRow.querySelector('.progress-bar');
  const techHoursElement = techRow.querySelector('.tech-hours');

  const numberOfHours = techStats.includesSaturday ? 48 : 40;
  const progressBarWidth = (techStats.totalHours / numberOfHours) * 100;

  if (progressBar) {
    progressBar.style.width = `${progressBarWidth}%`;
  }

  if (techHoursElement) {
    techHoursElement.textContent = `${techStats.totalHours.toFixed(1)} HOUR${techStats.totalHours === 1 ? '' : 'S'}`;
  }
}

function getTechStatsForTech(tech) {
  // Logic to retrieve the latest stats for the given tech
  return techStats[tech] || { totalHours: 0, includesSaturday: false };
}


function updateDayStats(tech, date) {
  const dayColumn = document.querySelector(`.day-column[data-tech="${tech}"][data-date="${date}"]`);
  if (!dayColumn) return;

  const events = dayColumn.querySelectorAll('.event');
  let totalHours = 0;
  events.forEach(event => {
    const hoursText = event.querySelector('.schedule-hours')?.textContent;
    if (hoursText) {
      totalHours += parseFloat(hoursText.replace(' HR', '').replace(' HRS', ''));
    }
  });

  const countElement = dayColumn.querySelector('.count-element');
  if (countElement) {
    countElement.textContent = `${events.length} JOB${events.length === 1 ? '' : 'S'} / ${totalHours.toFixed(1)} HR${totalHours === 1 ? '' : 'S'}`;
  } else {
    console.warn('Count element not found for day column:', dayColumn);
  }
}



function updateWeekLabel(weekStart) {
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 5); // Assuming 5-day work week
  const startStr = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  const endStr = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  document.getElementById('current-week').textContent = `${startStr} - ${endStr}`;
}

async function updateEventDate(eventId, newDate, tech) {

  try {
    await updateJobInfo(eventId, "Schedule_Date", newDate);
    await updateJobInfo(eventId, "Techs", tech);
  } catch (error) {
    console.log('Error updating event data', error);
  };

}

// Sort functionality
var Sort = function (target_id) {
  let _mobile = mobileCheck();
  let wrap;
  let list;
  let draggable;
  let dragging;
  let drIdx;
  let tarIdx;
  let moveable = false;
  let listname = "list";
  let dr_listname = "draggable";
  let _scroll = document.body;

  var handle = {
    up: (_mobile) ? "touchend" : "mouseup",
    move: (_mobile) ? "touchmove" : "mousemove",
    down: (_mobile) ? "touchstart" : "mousedown"
  };

  this.setScroll = function (obj) {
    _scroll = obj;
  }

  this.setListname = function (str) {
    listname = str;
  }

  this.setdrListname = function (str) {
    dr_listname = str;
  }

  function set_e() {
    wrap = document.getElementById(target_id);
    if (!wrap) {
      console.error(`Element with id "${target_id}" not found`);
      return;
    }
    list = wrap.getElementsByClassName(listname);
    draggable = wrap.getElementsByClassName(dr_listname);
    _mobile = mobileCheck();
    drIdx = null;

    wrap.setAttribute("sorting", "false");

    for (var i = 0; i < draggable.length; i++) {
      (function (i) {
        list[i].setAttribute("dragging", "false");
        list[i].setAttribute("drag_after", "false");
        draggable[i].addEventListener(handle.down, function (e) {
          // ... (rest of the event listener code)
        });
      })(i);
    }
  }


  function init() {
    if (document.getElementById(target_id)) {
      set_e();
      moveable = true;
    } else {
      console.error(`Element with id "${target_id}" not found. Sort initialization failed.`);
    }
  }
  this.init = init;

  function set_y(elem, y) {
    elem.style.transform = "translate3d(0, " + y + "px, 0)";
    elem.style.webkitTransform = "translate3d(0, " + y + "px, 0)";
  }

  function remove_y(elem) {
    elem.style.transform = "";
    elem.style.webkitTransform = "";
  }

  function mobileCheck() {
    var check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
}

function setupFilterListeners() {
  const filterView = document.getElementById('filter-view');
  const checkboxes = filterView.querySelectorAll('input[type="checkbox"]');

  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', applyFilters);
  });
}

document.getElementById('DOMContentLoaded', setupFilterListeners);

let selectedFilterButtons;

document.getElementById('start-date').addEventListener('change', () => applyFilters(false));
document.getElementById('end-date').addEventListener('change', () => applyFilters(false));

async function applyFilters(shouldUpdate = true) {
  try {
    const filterView = document.getElementById('filter-view');
    const checkboxes = filterView.querySelectorAll('input[type="checkbox"]:checked');
    const startDateInput = document.getElementById('start-date').value;
    const endDateInput = document.getElementById('end-date').value;

    let items = document.querySelectorAll('#table-content .table-item');
    let currentView = 'work-order-view';

    if (document.getElementById('job-view').style.display === 'flex') {
      currentView = 'job-view';
      items = document.querySelectorAll('#jobList .job-item');
    } else if (document.getElementById('schedule-view').style.display === 'flex') {
      currentView = 'schedule-view';
      items = document.querySelectorAll('#schedule-list .job-item');
    } else if (document.getElementById('tech-view').style.display === 'flex') {
      currentView = 'tech-view';
      items = document.querySelectorAll('#tech-list .job-item');
    }

    // Reset all items to hidden by default
    items.forEach(item => {
      item.classList.add('filtered');
      item.style.display = 'none';
    });

    const selectedFilters = {
      'WO Category': [],
      'Priority': [],
      'Status': [],
      'Shrinkwrap Status': [],
      'Op Type': [],
      'Assignment': [],
    };

    checkboxes.forEach(checkbox => {
      const filterType = checkbox.closest('ul').closest('li').firstChild.textContent.trim();
      selectedFilters[filterType].push(checkbox.value);
    });

    const startDate = startDateInput ? new Date(startDateInput) : null;
    const endDate = endDateInput ? new Date(endDateInput) : null;

    function filterItem(info) {
      const data = JSON.parse(info.getAttribute('data-jobinfo'));
      const hauled = info.getAttribute('data-hauled') ? true : false;
      const opTypeList = info.getAttribute('data-optypes') ? JSON.parse(info.getAttribute('data-optypes')) : [];
    
      if (data.Status === 'Complete') {
        data.Status = 'Ready to Bill';
      }
    
      let shouldShow = true;
    
      for (const [filterType, filterValues] of Object.entries(selectedFilters)) {
        if (filterValues.length > 0) {
          switch (filterType) {
            case 'WO Category':
              if (!filterValues.includes(data.Category)) shouldShow = false;
              break;
            case 'Priority':
              if (!filterValues.includes(data.Priority)) shouldShow = false;
              break;
            case 'Status':
              if (!filterValues.includes(data.Status)) {
                shouldShow = false;
              }
              if (filterValues.includes('Hauled') && hauled === 'false') {
                shouldShow = false;
              }
              break;
            case 'Op Type':
              if (currentView === 'work-order-view') {
                if (!opTypeList.some(opType => filterValues.includes(opType))) shouldShow = false;
              } else {
                if (!filterValues.includes(data.Op_Type)) shouldShow = false;
              }
              break;
            case 'Shrinkwrap Status':
              if (!filterValues.includes(data.Status)) shouldShow = false;
              break;
            case 'Assignment':
              if (filterValues.includes('Assigned') && (!data.Techs || data.Techs === '')) {
                shouldShow = false;
              }
              if (filterValues.includes('Awaiting Assignment') && (data.Techs && data.Techs !== '')) {
                shouldShow = false;
              }
              break;
          }
        }
        if (!shouldShow) break;
      }
    
      // Apply date filtering
      if (shouldShow && (startDate || endDate)) {
        const jobStartDate = data.Start_Date ? new Date(data.Start_Date) : new Date(data.Request_Date);
        if (isNaN(jobStartDate)) shouldShow = false;
        if (startDate && jobStartDate < startDate) shouldShow = false;
        if (endDate && jobStartDate > endDate) shouldShow = false;
      }
    
      // Set visibility based on filter results
      if (shouldShow) {
        info.classList.remove('filtered');
        info.style.display = 'flex';
      } else {
        info.classList.add('filtered');
        info.style.display = 'none';
      }
    }
    

    // Apply filters to all items
    items.forEach(filterItem);

    if (shouldUpdate) {
      updateTable();
    }

  } catch (error) {
    console.error('Error in applyFilters:', error);
  }
}


function toggleWorkOrders(filter) {
  const workOrders = document.querySelectorAll('.table-item');
  workOrders.forEach(wo => {
    if (wo.classList.contains(filter)) {
      wo.style.display = 'flex';
    } else {
      wo.style.display = 'none';
    }
  });

}

function toggleJobs(filter) {

  const jobs = document.querySelectorAll('.job-item');
  jobs.forEach(job => {
    if (job.classList.contains(filter)) {
      job.style.display = 'flex';
    } else {
      job.style.display = 'none';
    }
  });

}

let billingJobsActive = false;
let closedJobsActive = false;
let billingWorkOrdersActive = false;
let closedWorkOrdersActive = false;

document.getElementById('filter-complete').addEventListener('click', () => {
  const workOrderView = document.getElementById('work-order-view');
  const displayStyle = window.getComputedStyle(workOrderView).display;

  if (displayStyle === 'flex') {
    toggleWorkOrders('complete-job');
    billingWorkOrdersActive = true;
    closedWorkOrdersActive = false;
  } else {
    toggleJobs('complete-job');
    billingJobsActive = true;
    closedJobsActive = false;
  }
});

document.getElementById('filter-closed').addEventListener('click', () => {
  const workOrderView = document.getElementById('work-order-view');
  const displayStyle = window.getComputedStyle(workOrderView).display;

  if (displayStyle === 'flex') {
    toggleWorkOrders('closed-job');
    billingWorkOrdersActive = false;
    closedWorkOrdersActive = true;
  } else {
    toggleJobs('closed-job');
    billingJobsActive = false;
    closedJobsActive = true;
  }
});



function setupSortListeners() {
  const sortView = document.getElementById('sort-view');
  const sortOptions = sortView.querySelectorAll('li');

  sortOptions.forEach(option => {
    // Remove any existing listeners before adding a new one
    option.removeEventListener('click', applySorting);
    option.addEventListener('click', applySorting);
  });
}

let currentSortType = 'Start Date';
let isAscending = true;

function applySorting(e, sortType = null) {
  let targetElement;
  
  if (sortType === null) {
    sortType = e.target.textContent.trim(); // Default to event target if no sortType is passed
    targetElement = e.target;
  }

  if (sortType === currentSortType) {
    isAscending = !isAscending;
  } else {
    currentSortType = sortType;
    isAscending = true;
  }

  performSort();

  const sortView = document.getElementById('sort-view');
  sortView.querySelectorAll('li').forEach(li => {
    li.classList.remove('active-sort', 'sort-asc', 'sort-desc');
  });

  if (targetElement) {
    targetElement.classList.add('active-sort', isAscending ? 'sort-asc' : 'sort-desc');
  }

  updateTable(false);
}


function performSort() {
  const tableContent = document.getElementById('table-content');
  const jobList = document.getElementById('jobList');
  const scheduleContent = document.getElementById('schedule-list');
  const techContent = document.getElementById('tech-list');

  // Combine all items into one array for sorting
  const allItems = [
    ...Array.from(tableContent.querySelectorAll('.table-item')),
    ...Array.from(jobList.querySelectorAll('.job-item')),
    ...Array.from(scheduleContent.querySelectorAll('.job-item')),
    ...Array.from(techContent.querySelectorAll('.job-item'))
  ];

  function sortItems(items) {
    return items.sort((a, b) => {
      const dataA = JSON.parse(a.getAttribute('data-jobInfo'));
      const dataB = JSON.parse(b.getAttribute('data-jobInfo'));
      let comparison = 0;

      switch (currentSortType) {
        case 'Haul Date':
        case 'Request Date':
        case 'Scheduled Date':
        case 'Start Date': {
          let aDate, bDate;
          if (currentSortType === 'Haul Date') {
            aDate = new Date(dataA.Haul_Date || 0);
            bDate = new Date(dataB.Haul_Date || 0);
          } else if (currentSortType === 'Request Date') {
            aDate = new Date(dataA.Request_Date || 0);
            bDate = new Date(dataB.Request_Date || 0);
          } else if (currentSortType === 'Scheduled Date') {
            aDate = new Date(dataA.Schedule_Date || 0);
            bDate = new Date(dataB.Schedule_Date || 0);
          } else {
            aDate = new Date(dataA.Start_Date || 0);
            bDate = new Date(dataB.Start_Date || 0);
          }

          comparison = aDate - bDate;

          // If the dates are the same, sort by work order
          if (comparison === 0) {
            const aWorkOrder = dataA.Work_Order || '';
            const bWorkOrder = dataB.Work_Order || '';
            comparison = aWorkOrder.localeCompare(bWorkOrder);
          }
          break;
        }
        case 'Customer [Last Name]':
          comparison = (dataA.Customer_Name || '').localeCompare(dataB.Customer_Name || '');
          break;
        case 'Work Order #':
          comparison = (dataA.Work_Order || '').localeCompare(dataB.Work_Order || '');
          break;
        case 'Status':
          comparison = (dataA.Status || '').localeCompare(dataB.Status || '');
          break;
        default:
          console.warn('Unknown sort type:', currentSortType);
          return 0;
      }

      return isAscending ? comparison : -comparison;
    });
  }

  // Apply sorting and keep both filtered and non-filtered items in place
  const sortedItems = sortItems(allItems);

  // Re-insert items into the correct containers while preserving filtered state
  sortedItems.forEach(item => {
    const parentContainer = item.closest('.table-item') ? tableContent :
                            item.closest('.job-item') ? jobList :
                            item.closest('#schedule-list') ? scheduleContent :
                            techContent;
    
    // Re-append the item to its respective container
    parentContainer.appendChild(item);
  });
}


function updateTable(shouldApplySort = true) {
  // Get the different sections with items
  const tableItems = Array.from(document.querySelectorAll('#table-content .table-item'));
  const jobItems = Array.from(document.querySelectorAll('#jobList .job-item'));
  const scheduleItems = Array.from(document.querySelectorAll('#schedule-list .job-item'));
  const techItems = Array.from(document.querySelectorAll('#tech-list .job-item'));

  // Instead of removing and re-appending items, we will only handle their visibility and sorting
  const allItems = [...tableItems, ...jobItems, ...scheduleItems, ...techItems];

  allItems.forEach(item => {
    if (!item.classList.contains('filtered')) {
      item.style.display = 'flex';
    } else {
      item.style.display = 'none';
    }
  });

  if (shouldApplySort) {
    if (!currentSortType) {
      currentSortType = 'Start Date';
      isAscending = false;
    }
    performSort();
  }

  // Handle additional logic like search if necessary
  searchCall();
  searchJobs();
  searchSchedule();
  searchTech();
}


document.getElementById('clear-filters').addEventListener('click', clearFilters);

async function clearFilters() {
  billingWorkOrdersActive = false;
  closedWorkOrdersActive = false;
  billingJobsActive = false;
  closedJobsActive = false;
  try {
    const selectedFilters = document.querySelectorAll('#filter-view input[type="checkbox"]:checked');
    document.getElementById('start-date').value = '';
    document.getElementById('end-date').value = '';
    selectedFilters.forEach(filter => {
      filter.checked = false;
    });
    
    // Reset all items to visible before re-applying the filters
    let items = document.querySelectorAll('#table-content .table-item, #jobList .job-item, #schedule-list .job-item, #tech-list .job-item');
    items.forEach(item => {
      item.classList.remove('filtered');
      item.style.display = 'flex';
    });

    await applyFilters();
    updateTable();
  } catch (error) {
    console.log('Error removing filters', error);
  }
}



document.addEventListener('DOMContentLoaded', () => {
  setupFilterListeners();
  setupSortListeners();

  const filterViewButton = document.getElementById('filter-view-button');
  const sortViewButton = document.getElementById('sort-view-button');

  filterViewButton.addEventListener('click', () => {
    document.getElementById('filter-view').classList.toggle('hideBox');
  });

  sortViewButton.addEventListener('click', () => {
    document.getElementById('sort-view').classList.toggle('hideBox');
  });

  const searchInput = document.getElementById('search');
  searchInput.addEventListener('input', updateTable);

  // Add event listeners for filter checkboxes
  const filterCheckboxes = document.querySelectorAll('#filter-view input[type="checkbox"]');
  filterCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', updateTable);
  });

  // Note: We don't need to add sort listeners here because setupSortListeners() already does this
});

function fillInCustomerData() {
  const customerData = document.getElementById('customer-data-template');
  const laborData = document.getElementById('labor-customer-data');
  const partsData = document.getElementById('parts-customer-data');

  laborData.innerHTML = '';
  partsData.innerHTML = '';

  if (customerData && laborData && partsData) {
    const laborDataClone = customerData.cloneNode(true);
    const partsDataClone = customerData.cloneNode(true);

    laborData.appendChild(laborDataClone);
    partsData.appendChild(partsDataClone);
  } else {
    console.error('One or more required elements are missing');
  }
}


async function getJobDataIndex() {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", indexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore("jobData", { keyPath: "id" });
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("jobData", "readonly");
      const store = tx.objectStore("jobData");
      const request = store.getAll();

      request.onsuccess = function (event) {
        const data = event.target.result;
        resolve(data);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    };

    openRequest.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function getCustomerDataIndex() {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", indexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore("customerData", { keyPath: "id" });
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("customerData", "readonly");
      const store = tx.objectStore("customerData");
      const request = store.getAll();

      request.onsuccess = function (event) {
        const data = event.target.result;
        resolve(data);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    };

    openRequest.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
recognition.lang = 'es-ES';
recognition.continuous = true;
recognition.interimResults = true;

let finalTranscript = '';

recognition.onresult = (event) => {
  let interimTranscript = '';
  for (let i = event.resultIndex; i < event.results.length; ++i) {
    if (event.results[i].isFinal) {
      finalTranscript += event.results[i][0].transcript;
    } else {
      interimTranscript += event.results[i][0].transcript;
    }
  }

  document.getElementById('interim').innerHTML = interimTranscript;

  if (event.results[event.resultIndex].isFinal) {
    document.getElementById('final').innerHTML = finalTranscript;
    translateText(finalTranscript);
  }
};

document.getElementById('startButton').onclick = () => {
  recognition.start();
};

document.getElementById('stopButton').onclick = () => {
  recognition.stop();
};

async function translateText(text) {
  const apiKey = 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U'; // Your API key
  const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        q: text,
        source: 'es',
        target: 'en',
        format: 'text'
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const translatedText = result.data.translations[0].translatedText;
    document.getElementById('translation').innerHTML = translatedText;
  } catch (error) {
    console.error("Translation error:", error);
    document.getElementById('translation').innerHTML = "Translation failed. Please try again.";
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.update-form');
  let debounceTimer;

  elements.forEach(element => {
    // Create the icon element
    const iconElement = document.createElement('span');
    iconElement.className = 'update-icon';

    // Insert the icon after the element
    element.parentNode.insertBefore(iconElement, element.nextSibling);

    element.addEventListener('input', (e) => {
      clearTimeout(debounceTimer);
      iconElement.textContent = '🔄';
      iconElement.classList.add('updating');
      iconElement.classList.remove('updated');

      debounceTimer = setTimeout(async () => {
        const jobId = document.getElementById('currentJobId').value;
        const customerId = document.getElementById('updateCustomerId').value;
        const jobIndex = e.target.getAttribute('data-update');
        const customerIndex = e.target.getAttribute('data-update-customer');
        const value = e.target.value;

        const haulingIframe = document.querySelector('#hauling iframe'); // Find the iframe inside your div
        if (haulingIframe) {
          const iframeDocument = haulingIframe.contentDocument || haulingIframe.contentWindow.document;
          if(iframeDocument.querySelector('.haul-item[data-id="' + jobId + '"]')){
          let operationData = JSON.parse(iframeDocument.querySelector('.haul-item[data-id="' + jobId + '"]')?.getAttribute('data-jobInfo'));
          operationData[jobIndex] = value;
          iframeDocument.querySelector('.haul-item[data-id="' + jobId + '"]')?.setAttribute('data-jobInfo', JSON.stringify(operationData));
          }
        } else {
          console.log("Hauling not found");
        }

        const launchingIframe = document.querySelector('#spring iframe'); // Find the iframe inside your div
        if (launchingIframe) {
          const iframeDocument = launchingIframe.contentDocument || launchingIframe.contentWindow.document;
          if(iframeDocument.querySelector('.launch-item[data-id="' + jobId + '"]')){
          let operationData = JSON.parse(iframeDocument.querySelector('.launch-item[data-id="' + jobId + '"]')?.getAttribute('data-jobInfo'));
          operationData[jobIndex] = value;
          iframeDocument.querySelector('.launch-item[data-id="' + jobId + '"]')?.setAttribute('data-jobInfo', JSON.stringify(operationData));
          }
        } else {
          console.log("Spring not found");
        }

        if(document.querySelector('.job-item[data-id="' + jobId + '"]')) {
        let operationData = JSON.parse(document.querySelector('.job-item[data-id="' + jobId + '"]')?.getAttribute('data-jobInfo'));
        operationData[jobIndex] = value;
        document.querySelector('.job-item[data-id="' + jobId + '"]')?.setAttribute('data-jobInfo', JSON.stringify(operationData));
        }

        if(document.querySelector('.haul-item[data-id="' + jobId + '"]')) {
          let operationData = JSON.parse(document.querySelector('.haul-item[data-id="' + jobId + '"]')?.getAttribute('data-jobInfo'));
          operationData[jobIndex] = value;
          document.querySelector('.haul-item[data-id="' + jobId + '"]')?.setAttribute('data-jobInfo', JSON.stringify(operationData));
          }

        if(document.querySelector('.active-work-order')){
          let workOrderData = JSON.parse(document.querySelector('.active-work-order')?.getAttribute('data-jobInfo'));
          workOrderData[jobIndex] = value;
          document.querySelector('.active-work-order')?.setAttribute('data-jobInfo', JSON.stringify(workOrderData));
        }

        if(document.querySelector('.op-table-data-row[data-id="' + jobId + '"]')){
        let workOrderOpData = JSON.parse(document.querySelector('.op-table-data-row[data-id="' + jobId + '"]')?.getAttribute('data-jobInfo'));
        workOrderOpData[jobIndex] = value;
        document.querySelector('.op-table-data-row[data-id="' + jobId + '"]')?.setAttribute('data-jobInfo', JSON.stringify(workOrderOpData));
        };

  
        try {
          if (jobIndex) {
            await updateJobInfo(jobId, jobIndex, value);
            console.log(`Updated job ${jobIndex} to ${value} for job ${jobId}`);
          } else if (customerIndex) {
            await updateCustomerInfo(customerId, customerIndex, value);
            console.log(`Updated customer ${customerIndex} to ${value} for customer ${customerId}`);
          }

          iconElement.textContent = '✅';
          iconElement.classList.remove('updating');
          iconElement.classList.add('updated');

          // Remove the 'updated' class and clear the icon after 1 second
          setTimeout(() => {
            iconElement.classList.remove('updated');
            iconElement.textContent = '';
          }, 1000);
        } catch (error) {
          console.error('Error updating info:', error);
          iconElement.classList.remove('updating');
          iconElement.textContent = '❌';
          toastMessage('Failed to update info. Please try again.', 'red');
        }
      }, 2000);
    });
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.work-order-form-customer .update-customer-form');
  let debounceTimer;

  elements.forEach(element => {
    // Create the icon element
    const iconElement = document.createElement('span');
    iconElement.className = 'update-icon';

    // Insert the icon after the element
    element.parentNode.insertBefore(iconElement, element.nextSibling);

    element.addEventListener('input', (e) => {
      clearTimeout(debounceTimer);
      iconElement.textContent = '🔄';
      iconElement.classList.add('updating');
      iconElement.classList.remove('updated');

      debounceTimer = setTimeout(async () => {

        const customerId = document.getElementById('work-order-form-customer-id').value;
        const boatId = document.getElementById('work-order-form-boat-id').value;
        const customerIndex = e.target.getAttribute('data-update-customer');
        const value = e.target.value;
  
        try {

            await updateCustomerInfo(customerId, customerIndex, value);
            console.log(`Updated customer ${customerIndex} to ${value} for customer ${customerId}`);

          iconElement.textContent = '✅';
          iconElement.classList.remove('updating');
          iconElement.classList.add('updated');

          setTimeout(() => {
            iconElement.classList.remove('updated');
            iconElement.textContent = '';
          }, 1000);
        } catch (error) {
          console.error('Error updating info:', error);
          iconElement.classList.remove('updating');
          iconElement.textContent = '❌';
          toastMessage('Failed to update info. Please try again.', 'red');
        }
      }, 2000);
    });
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.work-order-form-boat .update-boat-form');
  let debounceTimer;

  elements.forEach(element => {
    // Create the icon element
    const iconElement = document.createElement('span');
    iconElement.className = 'update-icon';

    // Insert the icon after the element
    element.parentNode.insertBefore(iconElement, element.nextSibling);

    element.addEventListener('input', (e) => {
      clearTimeout(debounceTimer);
      iconElement.textContent = '🔄';
      iconElement.classList.add('updating');
      iconElement.classList.remove('updated');

      debounceTimer = setTimeout(async () => {

        const customerId = document.getElementById('work-order-form-customer-id').value;
        const boatId = document.getElementById('work-order-form-boat-id').value;
        const customerIndex = e.target.getAttribute('data-update-boat');
        const value = e.target.value;
  
        try {

            await updateBoatInfo(customerId, boatId, customerIndex, value);
            console.log(`Updated customer ${customerIndex} to ${value} for customer ${customerId}`);

          iconElement.textContent = '✅';
          iconElement.classList.remove('updating');
          iconElement.classList.add('updated');

          setTimeout(() => {
            iconElement.classList.remove('updated');
            iconElement.textContent = '';
          }, 1000);
        } catch (error) {
          console.error('Error updating info:', error);
          iconElement.classList.remove('updating');
          iconElement.textContent = '❌';
          toastMessage('Failed to update info. Please try again.', 'red');
        }
      }, 2000);
    });
  });
});

class CustomTechSelect extends HTMLElement {
  constructor() {
    super();
    this.techList = ["Alex", "Antony", "Conrad", "Darlin", "Eduardo", "Elvin", "Henry", "Jorge", "Kyle", "Liam", "Mac"];
    this.selectedTechs = new Set();
  }

  connectedCallback() {
    this.render();
    this.addEventListeners();
  }

  render() {
    const classes = this.getAttribute('class') || '';
    const id = this.getAttribute('id') || '';

    this.innerHTML = `
      <div class="custom-select ${classes}" id="${id}">
        <div class="selected-techs">Select Techs</div>
        <div class="tech-list"></div>
      </div>
    `;
    this.updateTechList();
  }

  addEventListeners() {
    const selectedTechs = this.querySelector('.selected-techs');
    const techList = this.querySelector('.tech-list');

    if (selectedTechs) {
      selectedTechs.addEventListener('click', () => {
        techList.classList.toggle('open');
        selectedTechs.classList.toggle('open');
      });
    }

    if (techList) {
      techList.addEventListener('change', (e) => this.handleTechSelection(e));
    }
  }

  updateTechList() {
    const container = this.querySelector('.tech-list');
    if (!container) return;
  
    // Clear existing options
    container.innerHTML = '';
  
    this.techList.forEach(tech => {
      const techItem = document.createElement('div');
      techItem.className = 'tech-item';
      
      const checkbox = document.createElement('input');
      checkbox.type = 'checkbox';
      checkbox.id = tech;
      checkbox.value = tech;
      
      const label = document.createElement('label');
      label.htmlFor = tech;
      label.textContent = tech;
  
      techItem.appendChild(checkbox);
      techItem.appendChild(label);
      container.appendChild(techItem);
    });
  }

  handleTechSelection(event) {
    if (event.target.type === 'checkbox') {
      const tech = event.target.value;
      if (event.target.checked) {
        this.selectedTechs.add(tech);
      } else {
        this.selectedTechs.delete(tech);
      }
      this.updateSelectedTechs();
      this.dispatchEvent(new Event('change'));
    }
  }

  resetSelection() {
    this.selectedTechs.clear();
    this.updateSelectedTechs();
    this.updateTechList();
  }

  updateSelectedTechs() {
    const selectedTechs = Array.from(this.selectedTechs).join(', ');
    this.querySelector('.selected-techs').textContent = selectedTechs || 'Select Techs';
  }

  get value() {
    return Array.from(this.selectedTechs).join(', ');
  }

  set value(techs) {
    this.resetSelection();
    techs.split(',').forEach(tech => {
      tech = tech.trim();
      if (this.techList.includes(tech)) {
        this.selectedTechs.add(tech);
        const checkbox = this.querySelector(`input[value="${tech}"]`);
        if (checkbox) checkbox.checked = true;
      }
    });
    this.updateSelectedTechs();
  }
}

customElements.define('custom-tech-select', CustomTechSelect);


async function checkIndexedDBUsage() {
  if (navigator.storage && navigator.storage.estimate) {
    try {
      const estimate = await navigator.storage.estimate();
      const totalBytes = estimate.quota;
      const usedBytes = estimate.usage;
      const usedMB = Math.round(usedBytes / (1024 * 1024));
      const totalMB = Math.round(totalBytes / (1024 * 1024));
      const percentageUsed = ((usedBytes / totalBytes) * 100).toFixed(2);

      console.log(`Storage Usage:
        Used: ${usedMB} MB
        Total: ${totalMB} MB
        Percentage Used: ${percentageUsed}%`);

      // Optionally, you can check usage for specific storage types
      if (estimate.usageDetails) {
        const indexedDBUsage = estimate.usageDetails.indexedDB;
        const indexedDBUsageMB = Math.round(indexedDBUsage / (1024 * 1024));
        console.log(`IndexedDB Usage: ${indexedDBUsageMB} MB`);
      }
    } catch (error) {
      console.error('Error checking storage estimate:', error);
    }
  } else {
    console.log('Storage estimation API is not available');
  }
}

async function addStorageBoat(boatData){





  const storageBoat = {
    "ID": "b158d1ac-b388-4431-b10b-5e1f03c58a85",
    "Customer Name": "Rush, Daniel",
    "Customer ID": "31df828a-02e9-435e-85b5-bf291a33b79a",
    "Boat Name": "Boston Whaler",
    "Boat ID": "a7be4f14-61c1-4ff5-87fc-db160f50580a",
    "Length": 17,
    "Beam": 6,
    "Discount": null,
    "Original Price": 1490.52,
    "Updated Price": null,
    "Haul Status": "1-11",
    "Haul Date": null,
    "Storage Yard": "TRUE",
    "Billed": "H3",
    "Location": null,
    "Storage Year": 2022
  }
  
}

document.getElementById("addCustomer").addEventListener("click", async ()=>{
  try{
    document.getElementById('addNewCustomer').style.display="flex"
    document.getElementById('customerSalutation').focus()
    document.getElementById('newCustomerId').value = await generateId();
    document.getElementById('newBoatId').value = await generateId();
  }catch(error){
    console.error(error)
  }
})

async function addNewCustomer(){

  try {
    toastMessage("Adding Customer. Please wait...", 'blue' ,2000)
    const idToken = await fetchIdToken();

    let customerId = document.getElementById('newCustomerId').value;
    let boatId = document.getElementById('newBoatId').value;
    let DMCustomer = await createCustomerDM();
    console.log(DMCustomer);

    console.log(customerId);
    console.log(boatId);

    let firebaseUrl = `${currentURL}/customers/${customerId}.json?auth=${idToken}`;

    var customerData = {
      Customer_ID : customerId,
      Salutation : document.getElementById('customerSalutation').value,
      Last_Name : document.getElementById('customerLastName').value,
      First_Name : document.getElementById('customerFirstName').value,
      Street : document.getElementById('customerStreet').value,
      City : document.getElementById('customerCity').value,
      State : document.getElementById('customerState').value,
      Zip : document.getElementById('customerZip').value,
      Phone : document.getElementById('customerPhone').value,
      Email : document.getElementById('customerEmail').value,
      DM_Customer: DMCustomer,
      Boats: {}
    }

  if(document.getElementById('customerBoatName').value !== ''){
    let DMBoat = await createBoatDM(DMCustomer);
    let boatData = {
      Boat_ID: boatId,
      Boat_Name : document.getElementById('customerBoatName').value,
      Boat_Length : document.getElementById('customerBoatLength').value,
      Boat_Beam : document.getElementById('customerBoatBeam').value,
      Combo_Key : document.getElementById('customerComboKey').value,
      Mast_Length : document.getElementById('customerMast').value,
      Boat_Type : document.getElementById('customerBoatType').value,
      Bottom_Paint_Type : document.getElementById('paintType').value,
      Bottom_Paint_Color : document.getElementById('paintColor').value,
      Insurance_Company: document.getElementById('insuranceCompany').value,
      Insurance_Policy: document.getElementById('insurancePolicy').value,
      Customer_ID: customerId,
      DM_Boat: DMBoat
    }
    customerData.Boats[boatId] = boatData;
  }

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(customerData),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Custom field added to customer successfully", result);
    await addCustomerIndexedDB(customerData);
    await fetchCustomerDataAndGenerateTable();
    callNewCustomerAdded();
    toastMessage("Customer Added Successfully", 'green' ,2000)
    updateTimestamps('customerData','customers');
    document.getElementById('addNewCustomer').style.display = 'none';

  } catch (error) {
    console.error("Error adding custom field to customer:", error);
  }
}

async function createCustomerDM(){
  try{
    const functions = getFunctions(app);
    const updateDMCustomer = httpsCallable(functions, 'updateDMCustomer');
    
    var dataLog = {
      "LastName": document.getElementById('customerLastName').value,
      "FirstName": document.getElementById('customerFirstName').value,
      "Address1": document.getElementById('customerStreet').value,
      "City": document.getElementById('customerCity').value,
      "State": document.getElementById('customerState').value,
      "Zip": document.getElementById('customerZip').value,
      "CellPhone": document.getElementById('customerPhone').value,
      "Email": document.getElementById('customerEmail').value,
    };

    const result = await updateDMCustomer(dataLog);
    const DMCustomerId = result.data.CustomerID;
    console.log(DMCustomerId);
    return DMCustomerId;
    
  } catch (error) {
    console.log(error);
    throw error; // Re-throw the error after logging it
  } 
}

async function addCustomerIndexedDB(customerInfo) {
  console.log(customerInfo.Customer_ID);
  try {
    // Open (or create) the IndexedDB database
    const dbPromise = indexedDB.open('openDatabase', indexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('customerData')) {
          db.createObjectStore('customerData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };
      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    // Start a transaction and add the customer data
    const transaction = db.transaction(['customerData'], 'readwrite');
    const store = transaction.objectStore('customerData');
    customerInfo.id = customerInfo.Customer_ID;
    // Add the customer data
    store.put(customerInfo);

    transaction.oncomplete = () => {
      console.log('Customer data added successfully to IndexedDB.');
    };
    transaction.onerror = (event) => {
      console.error('Transaction error:', event.target.errorCode);
    };
  } catch (error) {
    console.error('Error adding customer to IndexedDB:', error);
  }
}

async function createBoatDM(DMCustomer){

  try{
  const functions = getFunctions(app);
  const updateDMBoat = httpsCallable(functions, 'updateDMBoat');
  
  console.log(DMCustomer);

  var dataLog = {
    "OwnerId": DMCustomer,
    "Name": document.getElementById('customerBoatName').value,
    "LOA": document.getElementById('customerBoatLength').value,
    "Beam": document.getElementById('customerBoatBeam').value,
    "Draft": document.getElementById('customerBoatDraft').value,
    "InsuranceCompany": document.getElementById('insuranceCompany').value
  };

  const result = await updateDMBoat(dataLog)

      var DMBoatId = result.data.BoatID;
      return DMBoatId;

}catch(error){
  console.log(error);
}
 
}

document.getElementById('work-order-form-dates-haul-date').addEventListener('click', showWeekCounts);


function showWeekCounts(){
  const weeksPanel = document.getElementById('week-count-container');
  const weekCount = document.getElementById('week-count-body');
  weekCount.innerHTML = '';

  const weekCountData = countDatesByWeek(haulWeeks);
  const weekCountArray = Object.entries(weekCountData);

  weekCountArray.forEach(week => {
    console.log(week);
    const weekRow = document.createElement('div');


    const dates = week[0].split('/');
    const startDate = dates[0].split('-')[1] + '/' + dates[0].split('-')[2];
    const endDate = dates[1].split('-')[1] + '/' + dates[1].split('-')[2];
    const dateRange = startDate + ' - ' + endDate;

    const count = week[1];


    weekRow.innerHTML = `
      <strong>${dateRange}</strong>: ${count} boats
    `;
    weekCount.appendChild(weekRow);
  });
  
  weeksPanel.style.display = "flex";
}


// 10-15-2024 Codebase Start

document.getElementById('mooring-haul-open-button').addEventListener('click', () => {

  const elements = document.querySelectorAll('.page-view')

  elements.forEach(page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });

  document.getElementById('mooringHaul').style.display = 'flex';
  document.getElementById('mooringHaul').style.visibility = 'visible';
  document.getElementById('current-page').innerHTML = "Mooring List";
});

document.getElementById('open-billing').addEventListener('click', async () => {
  const jobId = document.getElementById('jobId').value;
  const jobInfo = await getJobData(jobId);
  billingModal(jobInfo);
});

function billingModal(jobData) {
  console.log(jobData);
  const modal = document.createElement("div");
  modal.className = "modal";
  modal.id = "billing-modal";
  modal.style.display = "flex";

  const modalContent = document.createElement("div");
  modalContent.className = "modal-content";

  // Add header
  modalContent.innerHTML = `
      <div class="button-exit-row">
          <button class="exitButtonSpacer">
              <img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">
          </button>
          <h1 id="entryTitle">Billing</h1>
          <button class="exitButton" data-close="billing-modal">
              <img src="/assets/close_icon.png" alt="Close Icon" width="24" height="24">
          </button>
      </div>

    <div id="billing-body">
          <div class="billing-data">
              <section>
                  <p><strong>Customer Name:</strong> ${jobData.Customer_Name}</p>
                  <p><strong>Boat Name:</strong> ${jobData.Boat_Name}</p>
              </section>

              <section>
                  <p><strong>Work Order:</strong> ${jobData.Work_Order}</p>
                  <p><strong>Op Code:</strong> ${jobData.OpCode}</p>
              </section>

              <section>
                  <h2>Job Description</h2>
                  <p class="billing-description">${jobData.Description}</p>
              </section>

            <section class="billing-labor-parts">

              <section id="tabs">
                  <button id="laborTab" class="tab active">Labor</button>
                  <button id="partsTab" class="tab">Parts</button>
              </section>

              <section id="laborSection" class="tab-content">
              <div class="grid-header">
                          <span>Tech</span>
                          <span>Description</span>
                          <span>Hours</span>
                          <span>Date</span>
                      </div>
                  <div class="grid-container" id="laborGrid">
                  </div>
              </section>

              <section id="partsSection" class="tab-content hidden">
                      <div class="grid-header">
                          <span>Part Number</span>
                          <span>Description</span>
                          <span>Quantity</span>
                          <span>Date</span>
                      </div>
                  <div class="grid-container" id="partsGrid">
                  </div>
              </section>

            </section>
        </div>
        
        <div class="billing-sidebar">
        <section class="billing-entries">
        
          <section class="billing-entry">
          <div class="billing-label-pair">
              <label for="billing-labor">Labor</label>
              <input type="number" id="billing-labor" placeholder="$0.00" value="${jobData.Labor_Billed}">
          </div>

          <div class="billing-label-pair">
              <label for="billing-parts">Parts</label>
              <input type="number" id="billing-parts" placeholder="$0.00" value="${jobData.Parts_Billed}">
          </div>
          
          <div class="billing-label-pair">
              <label for="billing-description">Labor Description</label>
              <textarea id="billing-description">${jobData.Billing_Description || ''}</textarea>
          </div>

          <div class="billing-label-pair">
              <label for="billing-notes">Notes</label>
              <textarea id="billing-notes">${jobData.Billing_Notes || ''}</textarea>
          </div>

          <div class="billing-buttons">
              <button id="saveBilling" class="button">Save</button>
              <button id="completeBilling" class="button">Marked Complete</button>
          </div>

            </section>
              

        </section>
        </div>

    </div>
  `;

  modal.appendChild(modalContent);
  document.body.appendChild(modal);

  // Populate Labor grid
  const laborGrid = document.getElementById("laborGrid");
  for (const laborId in jobData.Labor) {
      const labor = jobData.Labor[laborId];
      const row = document.createElement("div");
      row.className = "grid-row";
      row.innerHTML = `
          <span>${labor.Tech}</span>
          <span>${labor.Description}</span>
          <span>${labor.Hours}</span>
          <span>${labor.Time_Submitted}</span>
      `;
      laborGrid.appendChild(row);
  }

  // Populate Parts grid
  const partsGrid = document.getElementById("partsGrid");
  for (const partId in jobData.Parts) {
      const part = jobData.Parts[partId];
      const row = document.createElement("div");
      row.className = "grid-row";
      row.innerHTML = `
          <span>${part.Part_Number}</span>
          <span>${part.Description}</span>
          <span>${part.Quantity}</span>
          <span>${part.Time_Submitted}</span>
      `;
      partsGrid.appendChild(row);
  }

  // Add tab switching functionality
  document.getElementById("laborTab").addEventListener("click", () => {
      document.getElementById("laborTab").classList.add("active");
      document.getElementById("partsTab").classList.remove("active");
      document.getElementById("laborSection").classList.remove("hidden");
      document.getElementById("partsSection").classList.add("hidden");
  });

  document.getElementById("partsTab").addEventListener("click", () => {
      document.getElementById("partsTab").classList.add("active");
      document.getElementById("laborTab").classList.remove("active");
      document.getElementById("partsSection").classList.remove("hidden");
      document.getElementById("laborSection").classList.add("hidden");
  });

  // Add close functionality
  modal.querySelector(".exitButton").addEventListener("click", () => {
      document.body.removeChild(modal);
  });

  // Add save functionality
  modal.querySelector("#saveBilling").addEventListener("click", async () => {
      const laborBilled = document.getElementById("billing-labor").value;
      const partsBilled = document.getElementById("billing-parts").value;
      const billingNotes = document.getElementById("billing-notes").value;
      const billingDescription = document.getElementById("billing-description").value;

      const jobId = document.getElementById('jobId').value;

      const billingData = {
          Labor_Billed: laborBilled,
          Parts_Billed: partsBilled,
          Billing_Notes: billingNotes,
          Billing_Description: billingDescription
      }

      try {
        const jobRef = ref(database, `/data/${jobId}`);
        await update(jobRef, billingData);
        toastMessage("Billing information saved successfully", 'green', 2000);
      }catch(error){
        console.error("Error saving billing information", error);
        toastMessage("Failed to save billing information. Please try again.", 'red', 2000);
      }

      document.body.removeChild(modal);
  });

  // Add complete functionality
  modal.querySelector("#completeBilling").addEventListener("click", async () => {
      const laborBilled = document.getElementById("billing-labor").value;
      const partsBilled = document.getElementById("billing-parts").value;
      const billingNotes = document.getElementById("billing-notes").value;
      const billingDescription = document.getElementById("billing-description").value;

      const jobId = document.getElementById('jobId').value;

      const billingData = {
          Labor_Billed: laborBilled,
          Parts_Billed: partsBilled,
          Billing_Notes: billingNotes,
          Billing_Completed: await getTodaysDate(),
          Billing_Description: billingDescription,
          Status: "Closed"
      };

      try {
        const jobRef = ref(database, `/data/${jobId}`);
        await update(jobRef, billingData);
        document.body.removeChild(modal);
        toastMessage("Billing information saved successfully", 'green', 2000);
      }catch(error){
        console.error("Error saving billing information", error);
        toastMessage("Failed to save billing information. Please try again.", 'red', 2000);
      }

    });
          

}
